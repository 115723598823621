import { Member } from "../../models/data/members/Member";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMemberNameSectionStyles } from "./useMemberNameSectionStyles";

type Props = {
  member: Member;
};

export default function MemberNameSection(props: Props) {
  const theme = useTheme();
  const { classes } = useMemberNameSectionStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      className={isMobile ? classes.trapezeMobile : classes.trapeze}
      width={isMobile ? "68%" : "40%"}
    >
      <Grid item className={classes.content}>
        <Typography variant="h6" color={theme.palette.primary.dark}>
          {props.member?.personalData.firstName}{" "}
          {props.member?.personalData.lastName}
        </Typography>
      </Grid>
    </Grid>
  );
}
