import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  pathname?: string;
  iconElement: JSX.Element;
  title: string;
  isSidebarOpen: boolean;
  onClick?: (e?: any) => any;
  iconSize?: number;
};

export default function SidebarListItem(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (e?: any) => {
    if (props.pathname) {
      navigate(props.pathname);
    } else {
      if (props.onClick) {
        props.onClick(e);
      }

      return;
    }
  };

  const label = (
    <ListItemText
      primary={
        <Typography
          textAlign="start"
          fontWeight={location.pathname === props.pathname ? "bold" : "normal"}
        >
          {props?.title ?? ""}
        </Typography>
      }
    />
  );

  return (
    <ListItem
      sx={{
        px: 0,
        marginBottom: 0,
      }}
    >
      <ListItemButton
        onClick={handleNavigate}
        disabled={location.pathname === props.pathname}
        sx={{
          justifyContent: props.isSidebarOpen ? "start" : "center",
        }}
      >
        <ListItemIcon
          sx={{
            justifyContent: props.isSidebarOpen ? "start" : "center",

            "& > svg": {
              width: props.isSidebarOpen ? "auto" : `${props.iconSize ?? 25}px`,
              height: props.isSidebarOpen
                ? "auto"
                : `${props.iconSize ?? 25}px`,
            },

            "& > div": {
              width: props.isSidebarOpen ? "auto" : `${props.iconSize ?? 25}px`,
              height: props.isSidebarOpen
                ? "auto"
                : `${props.iconSize ?? 25}px`,
            },
          }}
        >
          {props?.iconElement}
        </ListItemIcon>

        {props.isSidebarOpen && label}
      </ListItemButton>
    </ListItem>
  );
}
