import { Divider, List, Theme, Typography, useTheme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import LogoCircle from "./LogoCircle";
import logo from "../../images/Midenas_Logo.jpg";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import { hexToRgb } from "../../common/helpers/theme";
import SidebarListItem from "./SidebarListItem";

type OrganizationSectionStylesProps = {
  logo: string;
  logoSize: number;
};

const useOrganizationSectionStyles =
  makeStyles<OrganizationSectionStylesProps>()(
    (theme: Theme, props: OrganizationSectionStylesProps) => ({
      box: {
        backgroundColor: "rgba(210, 210, 210, 0.3)",
        padding: `20px`,
        position: "relative",
        marginBottom: "20px",
      },
      circle: {
        position: "absolute",
        bottom: `-${props.logoSize / 2}px` /* width or height divided by 2 */,
        left: "50%",
        transform: "translateX(-50%)",
        width: `${props.logoSize}px`,
        height: `${props.logoSize}px`,
        borderRadius: "50%",
        border: "3px solid #fff",
        backgroundImage: `url(${props.logo})`,
        backgroundSize: "cover",
        transition: "transform 0.2s ease-in-out",

        "&:hover": {
          transform: "translateX(-50%) scale(1.1)",
          cursor: "pointer",
        },
      },
    })
  );

type Props = {
  organizationName: string;
  logo: string;
  isSidebarOpen: boolean;
};

export default function OrganizationSection(props: Props) {
  const theme = useTheme();
  const { isSmallScreen } = useScreenWidth();
  const primaryRgb = hexToRgb(theme.palette.primary.main);

  const { classes } = useOrganizationSectionStyles({
    logo: props.logo,
    logoSize: 80,
  });

  const getVariant = () => {
    if (
      props.organizationName.length > 0 &&
      props.organizationName.length <= 15
    ) {
      return "h4";
    } else if (
      props.organizationName.length > 15 &&
      props.organizationName.length <= 20
    ) {
      return "h5";
    } else if (
      props.organizationName.length > 20 &&
      props.organizationName.length <= 25
    ) {
      return "h6";
    } else if (props.organizationName.length > 25) {
      return "body1";
    }
  };

  return props.isSidebarOpen ? (
    <div className={classes.box}>
      <Typography
        variant={getVariant()}
        fontWeight="bold"
        mb={5}
        textAlign="center"
        color={theme.palette.info.light}
      >
        {props.organizationName}
      </Typography>

      <div className={classes.circle} />
    </div>
  ) : !isSmallScreen ? (
    <>
      <List>
        <SidebarListItem
          iconElement={
            <LogoCircle
              fillColor={`rgb(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b})`}
              letter={props.organizationName[0] || "M"}
            />
          }
          isSidebarOpen={props.isSidebarOpen}
          iconSize={45}
          title=""
        />
        {/* <NavigateButton
          icon={
            <LogoCircle
              fillColor={`rgb(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b})`}
              letter="M"
            />
          }
          title="Dashboard"
          isOpen={props.isSidebarOpen}
          largeIconSize={45}
        /> */}
      </List>

      <Divider
        style={{
          width: "85%",
          margin: "auto",
          height: "2px",
          marginTop: "1px",
          marginBottom: "1px",
        }}
      />
    </>
  ) : (
    <></>
  );
}
