import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";
import { Theme } from "@mui/material";

export const useUserManagementSaveModalStyles = makeStyles()(
  (theme: Theme) => ({
    title: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      textAlign: "center",
    },
    textCenter: { textAlign: "center" },
    closeBtnWrapper: { textAlign: "right", marginTop: "10px" },
    closeBtn: {
      textTransform: "none",
      backgroundColor: "#fff",
      borderRadius: "5px",
      border: "1px solid #2f4454",
      color: colors.primary,
      marginBottom: "5px",
      width: "40%",
      "&:hover": {
        background: colors.primary,
        color: "white",
      },
    },
    saveBtn: {
      textTransform: "none",
      backgroundColor: "#primary",
      borderRadius: "5px",
      color: colors.green,
      "&:hover": {
        background: colors.green,
        color: "white",
      },
    },
    autocompleteTextField: {
      "& .MuiInputBase-input": {
        marginTop: "-0.2rem",
      },

      "& .MuiInputBase-root": {
        borderRadius: "5px",
        height: "2rem",
      },
      backgroundColor: "transparent",
    },
    autocomplete: {
      "& .MuiOutlinedInput-root.Mui-focused": {
        "& > fieldset": {
          borderColor: theme.palette.info.dark,
        },
      },
    },
    inputTitle: { color: theme.palette.primary.dark, textAlign: "start" },
    circularProgressIcon: {
      color: colors.primary,
    },
    dialogTitle: {
      cursor: "pointer",
    },
    circularProgress: {
      color: colors.primary,
      display: "block",
      margin: "auto",
      fontSize: "20px",
      width: "20px",
    },
    userModalImage: {
      position: "absolute",
      top: "0%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      width: "100%",
      height: "35%",
    },
  })
);
