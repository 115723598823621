import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import ColumnCheckBoxes from "../columnCheckboxes/ColumnCheckBoxes";
import { tableInitialData } from "../data/initialData";
import { TableHeadNamesType } from "../data/tableHeadNames";
import { isAllVisible } from "../helpers/helperMethods";
import { useTableModalStyles } from "./styles/tableModalStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppButton from "../../../component/button/AppButton";
import { colors } from "../../../app/theme/colors";
import CloseIcon from "@mui/icons-material/Close";
import sidebarBackgroundImage from "../../../images/sidebarBackgroundImage.png";
import { hexToRgb } from "../../../common/helpers/theme";

type Props = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  openModal: boolean;
  toggleModal: () => void;

  tableHeadNames: TableHeadNamesType;
  setTableHeadNames: Dispatch<SetStateAction<TableHeadNamesType>>;
};

const TableModal = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const primaryRgb = hexToRgb(theme.palette.primary.main);
  const [activeAccordion, setActiveAccordion] = useState(0);
  const { classes } = useTableModalStyles();
  const { parentPropertyNames, selectAllColumnsModalValues } = {
    ...tableInitialData,
  };

  const handleSetPage = (value: number): void => {
    props.setPage(value);
  };

  const setAllVisibility = (visible: boolean, parentPropName: string) => {
    const tempArray: any[] = props.tableHeadNames[parentPropName];
    const index = parentPropertyNames.indexOf(parentPropName);

    const updTableHeadNames = tempArray.map((element: any) => {
      element.visible = visible;

      return element;
    });

    const updatedTableHeadNames = {
      ...props.tableHeadNames,
      [parentPropName]: updTableHeadNames as typeof tempArray,
    };

    props.setTableHeadNames(updatedTableHeadNames);
  };

  const handleVisibilityOn = (parentPropName: string) => {
    setAllVisibility(true, parentPropName);
  };

  const handleVisibilityOff = (parentPropName: string) => {
    setAllVisibility(false, parentPropName);
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={props.toggleModal}
      scroll="paper"
      fullWidth={true}
    >
      <DialogTitle className={classes.dialogTitle} onClick={props.toggleModal}>
        <Grid className={classes.grid}>
          <CloseIcon
            onClick={props.toggleModal}
            className={classes.closeIcon}
          />
        </Grid>
        <img
          src={sidebarBackgroundImage}
          alt="Sidebar Background"
          className={classes.tableModalImage}
          style={{
            background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
          }}
        />
        <h3 className={classes.title}>
          {t("memberTablePage.selectionOfTableColumns")}
        </h3>
      </DialogTitle>
      <DialogContent dividers>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((num, i) => (
          <Accordion
            key={i}
            className={classes.root}
            expanded={activeAccordion === i}
            onChange={() => setActiveAccordion(activeAccordion === i ? -1 : i)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => handleSetPage(num)}
              className={classes.accordionTitle}
            >
              <Grid container style={{ display: "flex" }}>
                <Grid item md={1} xs={2} key={i}>
                  {props.tableHeadNames[parentPropertyNames[num - 1]] &&
                    isAllVisible(
                      props.tableHeadNames[parentPropertyNames[num - 1]]
                    ) && (
                      <span
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleVisibilityOff(parentPropertyNames[num - 1]);
                        }}
                      >
                        <VisibilityOutlined
                          fontSize="medium"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleVisibilityOff(parentPropertyNames[num - 1]);
                          }}
                        />
                      </span>
                    )}
                  {props.tableHeadNames[parentPropertyNames[num - 1]] &&
                    !isAllVisible(
                      props.tableHeadNames[parentPropertyNames[num - 1]]
                    ) && (
                      <span
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleVisibilityOn(parentPropertyNames[num - 1]);
                        }}
                      >
                        <VisibilityOffOutlined
                          fontSize="medium"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleVisibilityOn(parentPropertyNames[num - 1]);
                          }}
                        />
                      </span>
                    )}
                </Grid>
                <Grid item md={11} xs={10}>
                  <Typography className={classes.span_parentPropertyName}>
                    {t(`memberTablePage.${parentPropertyNames[num - 1]}`)}
                  </Typography>
                </Grid>
                <br />
              </Grid>
            </AccordionSummary>
            {parentPropertyNames.map(
              (name, i) =>
                props.page === i + 1 && (
                  <AccordionDetails
                    key={i}
                    className={classes.accordionContent}
                  >
                    <ColumnCheckBoxes
                      array={props.tableHeadNames[parentPropertyNames[num - 1]]}
                      arrayName={parentPropertyNames[num - 1]}
                      tableHeadNames={props.tableHeadNames}
                      setTableHeadNames={props.setTableHeadNames}
                      selectAllLabelName={t(
                        `memberTablePage.${
                          selectAllColumnsModalValues[num - 1]
                        }`
                      )}
                    />
                  </AccordionDetails>
                )
            )}
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions className={classes.closeBtnWrapper}>
        <AppButton
          id="tableModal_button-close"
          onClick={props.toggleModal}
          color={theme.palette.primary.main}
          label={t("buttons.close")}
          hover={theme.palette.primary.main}
        />
      </DialogActions>
    </Dialog>
  );
};

export default TableModal;
