import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/api/hooks";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import useGetAllMembershipTypes from "../../../hooks/member-expire-lists/useGetAllMembershipTypes";
import { Member } from "../../../models/data/members/Member";
import { MemberAddress } from "../../../models/data/members/MemberAddress";
import { MemberCard } from "../../../models/data/members/MemberCard";
import { MemberContact } from "../../../models/data/members/MemberContact";
import { MemberEducation } from "../../../models/data/members/MemberEducation";
import { MemberEmployment } from "../../../models/data/members/MemberEmployment";
import { MemberOtherData } from "../../../models/data/members/MemberOtherData";
import { MemberPersonalData } from "../../../models/data/members/MemberPersonalData";
import { MembershipFee } from "../../../models/data/members/membershipFee";
import { MembershipType } from "../../../models/data/members/membershipType";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";
import {
  getNextCardNumber,
  selectAvailableCardNumbers,
  selectNextCardNumber,
  selectSelectedMember,
} from "../../../slices/members/memberSlice";
import { useAddressSectionStyles } from "../address-section/addressSectionStyles";
import { useCardSectionStyles } from "./cardSectionStyles";

interface Props {
  onChange: (
    value: any,
    name:
      | keyof MemberPersonalData
      | keyof MemberAddress
      | keyof MemberCard
      | keyof MemberContact
      | keyof MemberOtherData
      | keyof MemberEmployment
      | keyof MemberEducation
      | keyof MembershipFee,
    type: MemberSectionType
  ) => any;
  member: Member;
}

export const MemberCardData = ({ member, onChange }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useCardSectionStyles();
  const { classes: addressSectionStyles } = useAddressSectionStyles();

  const { membershipTypes } = useGetAllMembershipTypes();
  const selectedMember = useAppSelector(selectSelectedMember);
  const nextCardNumber = useAppSelector(selectNextCardNumber);
  const availableCardNumbers = useAppSelector(selectAvailableCardNumbers);

  const [enableManual, setEnableManual] = useState<boolean>(false);
  const [valueCardNumber, setValueCardNumber] = useState<number | null>(null);
  const [membershipType, setMembershipType] = useState<MembershipType | null>(
    null
  );

  const formattedDate = () =>
    member?.card?.establishedDate &&
    member?.card?.establishedDate.substring(0, 10);

  const defaultCardNumbersProps = {
    isOptionEqualToValue: (option: number, value: number) => option === value,
    disablePortal: true,
    getOptionLabel: (num: number) => num.toString(),
    fullWidth: true,
    className: addressSectionStyles.autocomplete,
  };

  const displayAmount =
    member?.membershipFee?.amount === 0 ? "" : member?.membershipFee?.amount;

  useEffect(() => {
    if (selectedMember && selectedMember.card.number) {
      setValueCardNumber(selectedMember.card.number);
    } else {
      (async () => {
        await dispatch(getNextCardNumber());
      })();

      setValueCardNumber(nextCardNumber);
    }
  }, [enableManual, selectedMember]);

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      switch (id) {
        case "membershipFee-amount":
          var amount = document.getElementById("membershipFee-amount");
          amount?.focus();
          break;
        case "membershipFee-startDate":
          var startDate = document.getElementById("membershipFee-startDate");
          startDate?.focus();
          break;
        case "memberCardData_input-number":
          var number = document.getElementById("memberCardData_input-number");
          number?.focus();
          break;
        case "membershipType":
          var membershipType = document.getElementById("membershipType");
          membershipType?.focus();
          break;
        case "memberCardData_autocomplete-cardNumber":
          var cardNumber = document.getElementById(
            "memberCardData_autocomplete-cardNumber"
          );
          cardNumber?.focus();
          break;
        case "memberCardData_input-placeOfIssue":
          var placeOfIssue = document.getElementById(
            "memberCardData_input-placeOfIssue"
          );
          placeOfIssue?.focus();
          break;
        case "memberCardData_input-dateOfIssue":
          var dateOfIssue = document.getElementById(
            "memberCardData_input-dateOfIssue"
          );
          dateOfIssue?.focus();
          break;
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} mb={3}>
        <Grid item lg md={12} sm={12} xs={12} display="flex">
          <FormGroup sx={{ justifyContent: "center" }}>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  id="membershipFee-paying"
                  checked={member?.membershipFee?.paying}
                  onChange={(e) => {
                    const { checked } = e.target;

                    onChange(
                      checked,
                      "paying",
                      MemberSectionType.membershipFee
                    );
                  }}
                  size="small"
                  className={classes.checkbox}
                />
              }
              label={t("memberExpireList.paying")}
            />
          </FormGroup>
        </Grid>

        <Grid item lg md={12} sm={12} xs={12}>
          <BasicInput
            id="membershipFee-amount"
            type="number"
            onWheel={(e: any) => e.target.blur()}
            label={t("memberExpireList.amount")}
            value={member.membershipFee === null ? "" : displayAmount}
            onChange={(e) => {
              if (!isNaN(+e.target.value)) {
                onChange(
                  +e.target.value,
                  "amount",
                  MemberSectionType.membershipFee
                );
              }
            }}
            disabled={!member?.membershipFee?.paying}
            onKeyDown={(e: any) =>
              handleOnKeyPressed(e, "membershipFee-startDate")
            }
          />
        </Grid>

        <Grid item lg md={12} sm={12} xs={12}>
          <BasicInput
            id="membershipFee-startDate"
            type="date"
            label={t("memberExpireList.startDate")}
            value={member?.membershipFee?.startDate?.substring(0, 10) ?? ""}
            onChange={(e) => {
              onChange(
                e.target.value,
                "startDate",
                MemberSectionType.membershipFee
              );
            }}
            disabled={!member?.membershipFee?.paying}
            onKeyDown={(e: any) => handleOnKeyPressed(e, "membershipType")}
          />
        </Grid>

        <Grid item lg md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.inputTitle}>
            {t("memberExpireList.membershipType")}:
          </Typography>
          <Autocomplete
            options={membershipTypes || []}
            value={
              member.membershipFee === null
                ? null
                : membershipTypes?.find(
                    (x) => x.id === member?.membershipFee?.membershipTypeId
                  ) ||
                  membershipType ||
                  null
            }
            onChange={(e: any, membershipType: MembershipType | null) => {
              setMembershipType(membershipType);

              if (!membershipType) {
                return;
              }

              onChange(
                membershipType.id,
                "membershipTypeId",
                MemberSectionType.membershipFee
              );
            }}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            getOptionLabel={(option) => option.name}
            className={addressSectionStyles.autocomplete}
            id="membershipType"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={addressSectionStyles.autocompleteTextField}
                size="small"
              />
            )}
            disabled={!member?.membershipFee?.paying}
            onKeyDown={(e: any) =>
              handleOnKeyPressed(e, "memberCardData_autocomplete-cardNumber")
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          {enableManual ? (
            <>
              <Typography variant="body1" className={classes.inputTitle}>
                {t("memberManagementPage.cardDataNumber")}:
              </Typography>
              <Autocomplete
                {...defaultCardNumbersProps}
                value={valueCardNumber}
                onChange={(e: any, newValue: number | null) => {
                  setValueCardNumber(newValue);
                  if (newValue) {
                    onChange(newValue, "number", MemberSectionType.memberCard);
                  } else {
                    onChange(
                      nextCardNumber,
                      "number",
                      MemberSectionType.memberCard
                    );
                  }
                }}
                id="memberCardData_autocomplete-cardNumber"
                options={availableCardNumbers || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={addressSectionStyles.autocompleteTextField}
                    size="small"
                  />
                )}
              />
            </>
          ) : (
            <>
              <BasicInput
                id="memberCardData_input-number"
                disabled={!enableManual}
                label={t("memberManagementPage.cardDataNumber")}
                value={
                  member?.card?.number !== 0
                    ? member?.card?.number
                    : nextCardNumber || 0
                }
                onChange={(e) =>
                  onChange(
                    +e.target.value,
                    "number",
                    MemberSectionType.memberCard
                  )
                }
                errorMessage={t(
                  "memberManagementPage.cardDataErrorMessageCardNumber"
                )}
              />
            </>
          )}

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormGroup>
              <FormControlLabel
                className={classes.checkboxLabel}
                control={
                  <Checkbox
                    id="memberCardData_checkbox-enableManual"
                    checked={enableManual}
                    onChange={(e) => setEnableManual(e.target.checked)}
                    size="small"
                    className={classes.checkbox}
                  />
                }
                label={
                  <>
                    {t("memberManagementPage.cardDataManual")}
                    <CustomTooltip
                      infoText={t("tooltips.manuallySelectCardDataText")}
                      infoTitle={t("tooltips.tooltipTitle")}
                    />
                  </>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12} textAlign={"start"}>
          <Grid container>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body1" className={classes.inputTitle}>
                {t("memberManagementPage.cardDataIssued")}:
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12}>
              <FormControlLabel
                value={
                  member !== null &&
                  member?.card !== null &&
                  member?.card?.isPublished === false
                    ? true
                    : false
                }
                checked={
                  member?.card?.isPublished !== null &&
                  member?.card?.isPublished === false
                    ? true
                    : false
                }
                control={
                  <Radio
                    id="memberCardData_radioButton-isPublishedNo"
                    className={classes.radioButton}
                    onClick={(e) =>
                      onChange(
                        false,
                        "isPublished",
                        MemberSectionType.memberCard
                      )
                    }
                  />
                }
                label={
                  <Typography variant="body1" color="textSecondary">
                    {t("memberManagementPage.employmentDataIsEmplyedNo")}
                  </Typography>
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 14,
                  },
                }}
              />
              <FormControlLabel
                value={
                  member?.card?.isPublished !== null &&
                  member?.card?.isPublished === true
                    ? true
                    : false
                }
                checked={
                  member?.card?.isPublished !== null &&
                  member?.card?.isPublished === true
                    ? true
                    : false
                }
                control={
                  <Radio
                    id="memberCardData_radioButton-isPublishedYes"
                    className={classes.radioButton}
                    onClick={(e) =>
                      onChange(
                        true,
                        "isPublished",
                        MemberSectionType.memberCard
                      )
                    }
                  />
                }
                label={
                  <Typography variant="body1" color="textSecondary">
                    {t("memberManagementPage.employmentDataIsEmplyedYes")}
                  </Typography>
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 14,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
          <BasicInput
            id="memberCardData_input-placeOfIssue"
            label={t("memberManagementPage.cardDataPlaceOfIssue")}
            value={member?.card?.publishingCity}
            onChange={(e) =>
              onChange(
                e.target.value,
                "publishingCity",
                MemberSectionType.memberCard
              )
            }
            onKeyDown={(e: any) =>
              handleOnKeyPressed(e, "memberCardData_input-dateOfIssue")
            }
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
          <BasicInput
            id="memberCardData_input-dateOfIssue"
            type="date"
            label={t("memberManagementPage.cardDataDateOfIssue")}
            value={formattedDate()}
            onChange={(e) =>
              onChange(
                e.target.value,
                "establishedDate",
                MemberSectionType.memberCard
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
