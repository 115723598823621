import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { CircularProgress, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { colors } from "../../app/theme/colors";
import AppButton from "../../component/button/AppButton";
import { Account } from "../../models/data/accounts/account";
import { AccountStatus } from "../../models/requests/accounts/accountStatus";
import { APIStatus } from "../../models/types/api/APIStatus";
import {
  clearSelectedAccount,
  saveAccount,
  selectAccount,
  setAccountStatus,
} from "../../slices/accounts/accountSlice";
import { selectDisplayLanguage } from "../../slices/displayLanguages/displayLanguageSlice";
import { useGetAccounts, useGetRoles } from "./hooks/userManagementHooks";
import UserManagementModal from "./modal/UserManagementModal";
import UserManagementTable from "./table/UserManagementTable";
import { useUserManagementStyle } from "./userManagementStyle";
import themeSlice from "../../slices/theme/themeSlice";

const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const INIT_ACCOUNT: Account = {
  id: EmptyGuid,
  firstName: "",
  lastName: "",
  email: "",
  roleName: "",
  phone: "",
  isActive: false,
  isDeleted: false,
  associationId: EmptyGuid,
};

export enum UserFormState {
  Create = "Create",
  Edit = "Edit",
  View = "View",
  None = "None",
}

const UserManagement = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentAccount = useAppSelector(
    (state) => state.auth.authenticatedAccount
  );

  const { classes } = useUserManagementStyle();
  const { accounts, refetchAccounts, accountsStatus } = useGetAccounts();
  const { roles, refetch: refetchRoles } = useGetRoles();
  const [account, setAccount] = useState<Account>({
    ...INIT_ACCOUNT,
    associationId: currentAccount?.associationId || EmptyGuid,
  });
  
  const [userFormState, setUserFormState] = useState<UserFormState>(
    UserFormState.None
  );
  const displayLanguage = useAppSelector(selectDisplayLanguage);

  const OnSave = async () => {
    if (
      !(
        userFormState === UserFormState.Create ||
        userFormState === UserFormState.Edit
      )
    ) {
      return;
    }

    const response = await dispatch(
      saveAccount({
        ...account,
        associationId: currentAccount?.associationId || EmptyGuid,
      })
    );
    await refetchAccounts();

    const isError = (response as any).error;

    if (isError) {
      return;
    }

    const savedAccount = response.payload as Account;

    dispatch(selectAccount(savedAccount));
    setAccount(savedAccount);
  };

  const OnToggleIsActive = async (acc: Account) => {
    const { id, isActive, firstName, lastName, isDeleted } = acc;

    const canDelete = window.confirm(
      `${t("userTablePage.userTableAlert")} ${
        isActive
          ? t("userTablePage.userTableAlertDeactivate")
          : t("userTablePage.userTableAlertActivate")
      } ${t("userTablePage.userTableAlertUser")} "${firstName} ${lastName}"?`
    );

    if (!canDelete) {
      return;
    }

    const request: AccountStatus = {
      id,
      isActive: !isActive,
      isDeleted: isDeleted,
    };

    await dispatch(setAccountStatus(request));
    refetchAccounts();
  };

  const OnToggleIsDeleted = async (acc: Account) => {
    const { id, isActive, firstName, lastName, isDeleted } = acc;

    const canDelete = window.confirm(
      `${t("userTablePage.userTableAlert")} ${
        isDeleted
          ? t("userTablePage.userTableAlertGetBack")
          : t("userTablePage.userTableAlertRemove")
      } ${t("userTablePage.userTableAlertUser")} "${firstName} ${lastName}"?`
    );

    if (!canDelete) {
      return;
    }

    const request: AccountStatus = {
      id,
      isActive: isActive,
      isDeleted: !isDeleted,
    };

    await dispatch(setAccountStatus(request));
    refetchAccounts();
  };

  // Refetch on change display language
  useEffect(() => {
    if (!displayLanguage) {
      return;
    }

    refetchAccounts();
    refetchRoles();
  }, [displayLanguage]);

  return (
    <>
      <AppButton
        id="userManagement_button-createNewUser"
        onClick={() => {
          dispatch(clearSelectedAccount());
          setAccount({
            ...INIT_ACCOUNT,
            associationId: currentAccount?.associationId || EmptyGuid,
          });
          setUserFormState(UserFormState.Create);
        }}
        startIcon={<PersonAddIcon fontSize="small" />}
        color={theme.palette.primary.main}
        hover={theme.palette.primary.main}
        label={t("buttons.createNewUser")}
        float="left"
      />
      {accountsStatus === APIStatus.PENDING ? (
        <Grid style={{ padding: "20px" }}>
          <span>
            <CircularProgress size={70} className={classes.circularProgress} />
          </span>
        </Grid>
      ) : (
        <UserManagementTable
          setAccount={setAccount}
          accounts={accounts}
          setUserFormState={setUserFormState}
          onToggleIsActive={OnToggleIsActive}
          onToggleIsDeleted={OnToggleIsDeleted}
        />
      )}
      <UserManagementModal
        account={account}
        setAccount={setAccount}
        accountStatus={accountsStatus}
        userFormState={userFormState}
        setUserFormState={setUserFormState}
        onSave={OnSave}
        roles={roles}
      />
    </>
  );
};

export default UserManagement;
