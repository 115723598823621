type Props = {
  fillColor?: string;
};

export default function LocationIcon(props: Props) {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99967 8.99996C7.45801 8.99996 7.85037 8.83677 8.17676 8.51038C8.50315 8.18399 8.66634 7.79163 8.66634 7.33329C8.66634 6.87496 8.50315 6.4826 8.17676 6.15621C7.85037 5.82982 7.45801 5.66663 6.99967 5.66663C6.54134 5.66663 6.14898 5.82982 5.82259 6.15621C5.4962 6.4826 5.33301 6.87496 5.33301 7.33329C5.33301 7.79163 5.4962 8.18399 5.82259 8.51038C6.14898 8.83677 6.54134 8.99996 6.99967 8.99996ZM6.99967 17.3333C4.76356 15.4305 3.09342 13.6632 1.98926 12.0312C0.885091 10.3993 0.333008 8.88885 0.333008 7.49996C0.333008 5.41663 1.00315 3.7569 2.34342 2.52079C3.6837 1.28468 5.23579 0.666626 6.99967 0.666626C8.76356 0.666626 10.3156 1.28468 11.6559 2.52079C12.9962 3.7569 13.6663 5.41663 13.6663 7.49996C13.6663 8.88885 13.1143 10.3993 12.0101 12.0312C10.9059 13.6632 9.23579 15.4305 6.99967 17.3333Z"
        fill={props.fillColor ?? "#7B7B7B"}
      />
    </svg>
  );
}
