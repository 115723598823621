import {useLogoCircleStyles} from "./useLogoCircleStyles";

type Props = {
  letter: string;
  fillColor: string;
};

export default function LogoCircle(props: Props) {
  const { classes } = useLogoCircleStyles({ fillColor: props.fillColor });

  return <div className={classes.root}>{props.letter}</div>;
}
