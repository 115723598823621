import { Box, Grid, SxProps, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../app/api/hooks";
import { t } from "i18next";
import {
    selectMemberCountStatistic as selectCountStatistic,
  } from "../../slices/members/memberSlice";

type CardProps = {
  disablePadding?: boolean;
};

export default function DashboardInfoCard(props: CardProps) {
  const theme = useTheme();

  const nextAvailableCardNumber = useAppSelector(x => x.members.nextCardNumber);
  const countStatistic = useAppSelector(x => x.members.memberCountStatistic) || [];

  const sx: SxProps = {
    borderRadius: 3,
    background: theme.palette.background.default,
    boxShadow: 3,
    fontSize: 12,
  };

  return (
    <Box
      sx={{
        ...sx,
        p: props.disablePadding ? 0 : 2,
        overflow: "hidden",
        marginBottom: "15px",
      }}
    >
      <Grid container overflow="hidden">
      <Grid item xs>
          <Typography variant="body2"><i>{t("dashboardPage.totalCountMember")}</i>: <b>{countStatistic.length > 0 ? countStatistic[countStatistic.length-1].totalCount : 0}</b></Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2"><i>{t("dashboardPage.nextCardNumberLabel")}</i>: <b>{nextAvailableCardNumber}</b></Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
