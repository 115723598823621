import React, { useEffect } from "react";
import { Member } from "../../../models/data/members/Member";
import { Street } from "../../../models/data/addresses/Street";
import { City } from "../../../models/data/addresses/City";
import { EmptyGuid } from "../initialData";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { getStreetsByCityId } from "../../../app/api/npdddApi";
import { useAddressSectionStyles } from "./addressSectionStyles";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import { useAppDispatch } from "../../../app/api/hooks";
import {
  findElectionPlaceByAddress,
  getElectionPlaceRegionsByCityId,
  getElectionPlacesByCityId,
  resetElectionPlaces,
  resetSelectedElectionPlace,
} from "../../../slices/electionPlaces/electionPlaceSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { FindElectionPlaceRequest } from "../../../models/requests/election-places/FindElectionPlaceRequest";

interface Props {
  selectedMember: Member;
  setSelectedMember: any;
  cities: City[];
  streets: Street[];
  setStreets: any;
  residenceCities: City[];
  residenceStreets: Street[];
  setResidenceStreets: any;
  selectedResidenceCity: City | null;
  selectedResidenceStreet: Street | null;
  setSelectedResidenceCity: any;
  setSelectedResidenceStreet: any;
  selectedCity: City | null;
  selectedStreet: Street | null;
  setSelectedCity: any;
  setSelectedStreet: any;
}

const MemberAddressForm: React.FC<Props> = ({
  selectedMember,
  setSelectedMember,
  cities,
  streets,
  setStreets,
  selectedCity,
  setSelectedCity,
  selectedStreet,
  setSelectedStreet,
  residenceCities,
  residenceStreets,
  setResidenceStreets,
  selectedResidenceCity,
  setSelectedResidenceCity,
  selectedResidenceStreet,
  setSelectedResidenceStreet,
}) => {
  const { t } = useTranslation();
  const { classes } = useAddressSectionStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    if (selectedMember) {
      const foundResidenceCity =
        residenceCities.find(
          (city) => city.id === selectedMember?.address?.cityResidenceId
        ) || null;
      setSelectedResidenceCity(foundResidenceCity);
      setSelectedCity(
        cities.find((city) => city.id === selectedMember?.address?.cityId) ||
          null
      );

      getStreetsByCityId(selectedMember?.address?.cityResidenceId || EmptyGuid)
        .then((streets) => {
          setResidenceStreets(streets.data || []);
          setSelectedResidenceStreet(
            streets.data?.find(
              (street) =>
                street.streetId === selectedMember?.address?.streetResidenceId
            ) || null
          );
        })
        .then(() => {
          getStreetsByCityId(selectedMember?.address?.cityId || EmptyGuid).then(
            (streets) => {
              setStreets(streets.data || []);
              setSelectedStreet(
                streets.data?.find(
                  (street) =>
                    street.streetId === selectedMember?.address?.streetId
                ) || null
              );
            }
          );
        });
    }
  }, [selectedMember]);

  const handleResidentCity = (event: any, value: City | null) => {
    if (value) {
      getStreetsByCityId(value.id).then((streets) => {
        setResidenceStreets(streets.data || []);
        setSelectedResidenceStreet(null);
      });

      dispatch(getElectionPlaceRegionsByCityId(value.id));
      dispatch(getElectionPlacesByCityId(value.id));

      if (
        selectedMember &&
        selectedMember?.address?.cityResidenceId !== value?.id
      ) {
        const newMemberAddress = {
          ...selectedMember?.address,
          cityResidenceId: value?.id || null,
          cityResidenceName: value?.name || "",
        };
        const newMember = { ...selectedMember, address: newMemberAddress };
        setSelectedMember(newMember);
      }
    } else {
      const newMemberAddress = {
        ...selectedMember?.address,
        cityResidenceId: null,
        cityResidenceName: "",
        streetResidenceId: null,
        streetResidenceName: "",
      };
      const newMember = { ...selectedMember, address: newMemberAddress };
      setSelectedMember(newMember);
      dispatch(resetElectionPlaces());
    }
  };

  const handleResidentStreet = (event: any, value: Street | null) => {
    if (
      selectedMember.address.cityResidenceName !== "" &&
      value?.streetName != "" &&
      selectedMember.address.homeNumberResidence !== ""
    ) {
      const filter: FindElectionPlaceRequest = {
        cityId: selectedMember?.address?.cityResidenceId || EmptyGuid,
        cityName: selectedMember?.address?.cityResidenceName || "",
        streetName: value?.streetName || "",
        streetNumber: selectedMember?.address?.homeNumberResidence || "0",
      };
      dispatch(findElectionPlaceByAddress(filter));
    }
    if (
      selectedMember &&
      selectedMember.address.streetResidenceId !== value?.streetId
    ) {
      const newMemberAddress = {
        ...selectedMember?.address,
        streetResidenceId: value?.streetId || null,
        streetResidenceName: value?.streetName || "",
      };
      const newMember = { ...selectedMember, address: newMemberAddress };
      setSelectedMember(newMember);
    }

    if (value === null) {
      dispatch(resetSelectedElectionPlace());
    }
  };

  const handleCity = (event: any, value: City | null) => {
    if (value) {
      getStreetsByCityId(value.id).then((streets) => {
        setStreets(streets.data || []);
        setSelectedStreet(null);
      });
      if (
        selectedMember &&
        selectedMember.address.cityId !== selectedCity?.id
      ) {
        const newMemberAddress = {
          ...selectedMember?.address,
          cityId: value?.id || null,
          cityName: value?.name || "",
        };
        const newMember = { ...selectedMember, address: newMemberAddress };
        setSelectedMember(newMember);
      }
    } else {
      const newMemberAddress = {
        ...selectedMember?.address,
        cityId: null,
        cityRName: "",
        streetId: null,
        streetName: "",
      };
      const newMember = { ...selectedMember, address: newMemberAddress };
      setSelectedMember(newMember);
    }
  };

  const handleStreet = (event: any, value: Street | null) => {
    if (selectedMember && selectedMember.address.streetId !== value?.streetId) {
      const newMemberAddress = {
        ...selectedMember?.address,
        streetId: value?.streetId || null,
        streetName: value?.streetName || "",
      };
      const newMember = { ...selectedMember, address: newMemberAddress };
      setSelectedMember(newMember);
    }
  };

  const handleChange = (event: any) => {
    const newMemberAddress = {
      ...selectedMember?.address,
      [event.target.name]: event.target.value,
    };
    const newMember = { ...selectedMember, address: newMemberAddress };

    if (
      newMember.address.cityResidenceId !== EmptyGuid &&
      newMember.address.streetId != EmptyGuid &&
      newMember.address.homeNumberResidence !== ""
    ) {
      const filter: FindElectionPlaceRequest = {
        cityId: selectedMember?.address?.cityResidenceId || EmptyGuid,
        cityName: selectedMember?.address?.cityResidenceName || "",
        streetName: selectedMember?.address?.streetResidenceName || "",
        streetNumber: event.target.value || "0",
      };
      dispatch(findElectionPlaceByAddress(filter));
    }

    setSelectedMember(newMember);
  };

  const handleIsAddresSame = (event: any) => {
    const newMemberAddress = {
      ...selectedMember?.address,
      [event.target.name]: event.target.checked,
    };
    const newMember = { ...selectedMember, address: newMemberAddress };
    setSelectedMember(newMember);
  };

  const defaultStreetProps = {
    isOptionEqualToValue: (option: Street, value: Street) =>
      option.streetId === value.streetId,
    disablePortal: true,
    getOptionLabel: (street: Street) => street.streetName ?? "",
    fullWidth: true,
    className: classes.autocomplete,
  };

  const defaultCityProps = {
    isOptionEqualToValue: (option: City, value: City) => option.id === value.id,
    disablePortal: true,
    getOptionLabel: (city: City) => city.name ?? "",
    fullWidth: true,
    className: classes.autocomplete,
  };

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      switch (id) {
        case "memberAddressSection_autocomplete-residenceCity":
          console.log("firstName field");
          var residenceCity = document.getElementById(
            "memberAddressSection_autocomplete-residenceCity"
          );
          residenceCity?.focus();
          break;
        case "memberAddressSection_autocomplete-resudenceStreet":
          console.log("parentName field");
          var resudenceStreet = document.getElementById(
            "memberAddressSection_autocomplete-resudenceStreet"
          );
          resudenceStreet?.focus();
          break;
        case "addressSection_input-homeNumberResidence":
          console.log("parentName field");
          var homeNumberResidence = document.getElementById(
            "addressSection_input-homeNumberResidence"
          );
          homeNumberResidence?.focus();
          break;

        case "memberAddressSection_autocomplete-city":
          console.log("firstName field");
          var city = document.getElementById(
            "memberAddressSection_autocomplete-city"
          );
          city?.focus();
          break;
        case "memberAddressSection_autocomplete-street":
          console.log("parentName field");
          var street = document.getElementById(
            "memberAddressSection_autocomplete-street"
          );
          street?.focus();
          break;
        case "memberAddressSection_autocomplete-homeNumber":
          console.log("parentName field");
          var homeNumber = document.getElementById(
            "memberAddressSection_autocomplete-homeNumber"
          );
          homeNumber?.focus();
          break;
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Typography variant="body1" className={classes.inputTitle}>
            {t("memberManagementPage.addressesCity")}
            <span
              style={
                selectedResidenceCity === null
                  ? { color: theme.palette.error.main }
                  : {}
              }
            >
              *
            </span>
            :
          </Typography>
          <Autocomplete
            {...defaultCityProps}
            id="memberAddressSection_autocomplete-residenceCity"
            getOptionLabel={(option) => option.name || ""}
            value={selectedResidenceCity}
            onChange={handleResidentCity}
            onKeyDown={(e: any) =>
              handleOnKeyPressed(
                e,
                "memberAddressSection_autocomplete-resudenceStreet"
              )
            }
            options={residenceCities}
            loadingText={
              <CircularProgress
                size="14px"
                className={classes.circularProgress}
              />
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
                error={!selectedResidenceCity}
              />
            )}
          />

          {!selectedResidenceCity && (
            <span
              style={{
                textAlign: "start",
                color: theme.palette.error.main,
                fontSize: "12px",
              }}
            >
              {t("memberManagementPage.addressesDataErrorMessageCity")}
            </span>
          )}
        </FormControl>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <Typography variant="body1" className={classes.inputTitle}>
            {t("memberManagementPage.addressesStreet")}
            <span
              style={
                selectedResidenceStreet === null
                  ? { color: theme.palette.error.main }
                  : {}
              }
            >
              *
            </span>
            :
          </Typography>
          <Autocomplete
            {...defaultStreetProps}
            id="memberAddressSection_autocomplete-resudenceStreet"
            options={residenceStreets}
            getOptionLabel={(option) => option.streetName || ""}
            loadingText={
              <CircularProgress
                size="14px"
                className={classes.circularProgress}
              />
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                autoComplete="off"
                fullWidth
                className={classes.autocompleteTextField}
                size="small"
                error={!selectedResidenceStreet}
              />
            )}
            value={selectedResidenceStreet}
            onChange={handleResidentStreet}
            onKeyDown={(e: any) =>
              handleOnKeyPressed(e, "addressSection_input-homeNumberResidence")
            }
          />

          {!selectedResidenceStreet && (
            <span
              style={{
                textAlign: "start",
                color: theme.palette.error.main,
                fontSize: "12px",
              }}
            >
              {t("memberManagementPage.addressesDataErrorMessageStreet")}
            </span>
          )}
        </FormControl>
      </Grid>

      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FormControl variant="outlined" fullWidth>
          <BasicInput
            id="addressSection_input-homeNumberResidence"
            value={selectedMember?.address?.homeNumberResidence || ""}
            onChange={handleChange}
            name={"homeNumberResidence"}
            label={t("memberManagementPage.addressesHomeNumber")}
            error={
              selectedMember?.address?.homeNumberResidence !== null
                ? selectedMember.address.homeNumberResidence.length < 1
                : true
            }
            errorMessage={t(
              "memberManagementPage.addressesDataErrorHomeNumber"
            )}
          />
        </FormControl>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FormGroup>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <div>
                <Checkbox
                  id="memberAddressForm_checkbox-isAddressSame"
                  name="isAddressSame"
                  checked={selectedMember?.address?.isAddressSame || false}
                  onChange={handleIsAddresSame}
                  size="small"
                  className={classes.checkbox}
                />
              </div>
            }
            label={
              <>
                {t("memberManagementPage.addressesResidential")}
                <CustomTooltip
                  infoText={t("tooltips.isAddressSameAddressSectionText")}
                  infoTitle={t("tooltips.tooltipTitle")}
                />
              </>
            }
          />
        </FormGroup>
      </Grid>

      {selectedMember && selectedMember?.address?.isAddressSame === false && (
        <Grid container spacing={2} style={{ padding: "15px" }}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" className={classes.inputTitle}>
                {t("memberManagementPage.addressesCity")}:
              </Typography>
              <Autocomplete
                {...defaultCityProps}
                id="memberAddressSection_autocomplete-city"
                options={cities}
                getOptionLabel={(option) => option.name || ""}
                loadingText={
                  <CircularProgress
                    size="14px"
                    className={classes.circularProgress}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
                value={selectedCity}
                onChange={handleCity}
                onKeyDown={(e: any) =>
                  handleOnKeyPressed(
                    e,
                    "memberAddressSection_autocomplete-street"
                  )
                }
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" className={classes.inputTitle}>
                {t("memberManagementPage.addressesStreet")}:
              </Typography>
              <Autocomplete
                {...defaultStreetProps}
                id="memberAddressSection_autocomplete-street"
                options={streets}
                getOptionLabel={(option) => option.streetName || ""}
                loadingText={
                  <CircularProgress
                    size="14px"
                    className={classes.circularProgress}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    className={classes.autocompleteTextField}
                    size="small"
                  />
                )}
                value={selectedStreet}
                onChange={handleStreet}
                disabled={!selectedCity}
                onKeyDown={(e: any) =>
                  handleOnKeyPressed(
                    e,
                    "memberAddressSection_autocomplete-homeNumber"
                  )
                }
              />
            </FormControl>
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormControl variant="outlined" fullWidth>
              <Typography variant="body1" className={classes.inputTitle}>
                {t("memberManagementPage.addressesHomeNumber")}:
              </Typography>
              <OutlinedInput
                id="memberAddressSection_autocomplete-homeNumber"
                autoComplete="off"
                name={"homeNumber"}
                fullWidth
                type={"text"}
                value={selectedMember?.address?.homeNumber || ""}
                onChange={handleChange}
                className={classes.input}
                style={{ background: "transparent" }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MemberAddressForm;
