import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AppButton from "../../component/button/AppButton";
import SynchAddressesRequest from "../../models/synch/synchAddressesRequest";
import {
  setAddressSynchronizationStarted,
  setMembershiopSynchronizationStarted,
  updateElectionPlace,
} from "../../slices/synch/synchSlice";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import Connector from "../../signalr-connection";
import SynchMessage from "../../models/synch/synchMessage";
import { INIT_SYNCH_RESULT } from "../../INITIAL_DATA/initialData";
import { EmptyGuid } from "../smart-membership/initialData";
import { updateMembersElectionPlace } from "../../slices/synch/synchApi";
import NotUpdatedMembersTable from "./syncMembersTable";

const Input = styled("input")({
  display: "none",
});

export default function SyncAddresses() {
  var dispatch = useAppDispatch();
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const addressHub = "address";
  const membershipHub = "membership";

  const isSynchronizationStarted = useAppSelector(
    (x) => x.synch.isAddressSynchronizationStarded
  );
  const isMemberSynchronizationStarted = useAppSelector(
    (x) => x.synch.isMembershipSynchronizationStarted
  );
  const currentAccount = useAppSelector((x) => x.auth.authenticatedAccount);

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [synchMessage, setSynchMessage] =
    useState<SynchMessage>(INIT_SYNCH_RESULT);
  const [synchAccountId, setSynchAccountId] = useState(EmptyGuid);

  useEffect(() => {
    const accountId = currentAccount?.id || EmptyGuid;

    const addresConnector = Connector.getInstance(addressHub, accountId);
    addresConnector.onMessageReceived = (accountId, message) => {
      if (currentAccount?.id === accountId) {
        const newMessage = {
          ...synchMessage,
          electionPlaceTotal: message.electionPlaceTotal,
          electionPlaceCurrentCount: message.electionPlaceCurrentCount,
          electionPlaceRegionCurrentCount:
            message.electionPlaceRegionCurrentCount,
          citiesCurrentCount: message.citiesCurrentCount,
          streetsCurrentCount: message.streetsCurrentCount,
        };
        setSynchMessage(newMessage);
        setSynchAccountId(accountId);
        if (isSynchronizationStarted === false) {
          dispatch(setAddressSynchronizationStarted(true));
        } else {
          if (
            message.electionPlaceTotal === message.electionPlaceCurrentCount
          ) {
            dispatch(setAddressSynchronizationStarted(false));
          }
        }
      }
    };

    const memberConnector = Connector.getInstance(membershipHub, accountId);
    memberConnector.onMessageReceived = (accountId, message) => {
      if (currentAccount?.id === accountId) {
        const newMessage = {
          ...synchMessage,
          membersTotal: message.membersTotal,
          updatedMembersCount: message.updatedMembersCount,
          notUpdatedMembersCount: message.notUpdatedMembersCount,
          notUpdatedMembers: message.notUpdatedMembers,
        };
        setSynchMessage(newMessage);
        setSynchAccountId(accountId);
        if (isMemberSynchronizationStarted === false) {
          dispatch(setMembershiopSynchronizationStarted(true));
        } else {
          if (
            message.membersTotal ===
            message.updatedMembersCount + message.notUpdatedMembersCount
          ) {
            dispatch(setMembershiopSynchronizationStarted(false));
          }
        }
      }
    };
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    var uploadedFile = event.target.files![0];
    if (uploadedFile) {
      setFileName(uploadedFile.name);
      setFile(event.target.files![0]);
    } else {
      setFile(null);
      setFileName(null);
    }
  };

  const handleElectionPlacesUploadFile = async () => {
    if (file) {
      var formData = new FormData();
      formData.append("file", file);
      var newRequest: SynchAddressesRequest = { file: formData };

      dispatch(updateElectionPlace(newRequest));
    }
  };

  const handleButtonClick = () => {
    handleElectionPlacesUploadFile();
  };

  const handleUpdateMembershipElectionPlaces = () => {
    dispatch(updateMembersElectionPlace);
  };

  useEffect(() => {}, [synchMessage]);

  function calculateProgress(currentCount: number, totalCount: number): number {
    if (totalCount === 0) {
      return 0;
    }
    const percentage = (currentCount / totalCount) * 100;
    return percentage;
  }

  interface LinearProgressProps {
    total: number;
    value: number;
    startText: string;
    endText: string;
  }

  function LinearProgressWithLabel({
    total,
    value,
    startText,
    endText,
  }: LinearProgressProps) {
    const progress = calculateProgress(value, total);

    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} mt={3}>
          <LinearProgress variant="determinate" value={progress} />
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography
            variant="body1"
            fontSize={"12px"}
            align="center"
            justifyContent={"center"}
          >
            <i>{`${startText} ${value} ${endText} ${total}`}</i>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid sx={{ border: "1px solid black", padding: "20px" }}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="caption">
            {t("updateElectionPlaceModal.uploadDocumnet")}
          </Typography>

          <Input
            accept=".docx,.xls"
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }} // Hide the file input
            ref={inputRef}
            disabled={isSynchronizationStarted}
          />
          <label htmlFor="contained-button-file">
            <AppButton
              startIcon={<CloudUploadIcon />}
              color={theme.palette.primary.main}
              hover={theme.palette.primary.main}
              label={t("updateElectionPlaceModal.upload")}
              disabled={isSynchronizationStarted}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.click();
                }
              }}
            />
          </label>
          {fileName !== null && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2">{fileName}</Typography>
              <IconButton
                edge="end"
                size="small"
                color="error"
                aria-label="delete"
                disabled={isSynchronizationStarted}
                onClick={() => {
                  setFile(null);
                  setFileName(null);
                  const input = document.getElementById(
                    "contained-button-file"
                  ) as HTMLInputElement;
                  if (input) {
                    input.value = "";
                  }
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
          )}
        </Stack>
      </Grid>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        mt={1}
      >
        <Typography variant="caption">
          {t("updateElectionPlaceModal.allowedDocumentTypes")}
        </Typography>
      </Grid>
      <Box
        sx={{
          m: 1,
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
        className="text-center"
      >
        <Grid item xs={6}>
          <AppButton
            variant="contained"
            color={theme.palette.primary.main}
            hover={theme.palette.primary.main}
            disabled={
              isSynchronizationStarted ||
              fileName === null ||
              isMemberSynchronizationStarted
            }
            onClick={handleButtonClick}
            label={t("updateElectionPlaceModal.updateElectionPlaces")}
          />
        </Grid>
        <Grid item xs={6} ml={3}>
          <AppButton
            color={theme.palette.primary.main}
            hover={theme.palette.primary.main}
            onClick={handleUpdateMembershipElectionPlaces}
            label={t("syncAddressesPage.updateMembership")}
            width="100%"
            disabled={isSynchronizationStarted}
          />
        </Grid>
      </Box>
      {isSynchronizationStarted === true &&
        synchAccountId === currentAccount?.id && (
          <LinearProgressWithLabel
            total={synchMessage.electionPlaceTotal || 0}
            value={synchMessage.electionPlaceCurrentCount || 0}
            startText={t("synch.processed")}
            endText={t("synch.electionPlaces")}
          />
        )}
      {isMemberSynchronizationStarted === true &&
        synchAccountId === currentAccount?.id && (
          <LinearProgressWithLabel
            total={synchMessage.membersTotal || 0}
            value={
              synchMessage.updatedMembersCount +
                synchMessage.notUpdatedMembersCount || 0
            }
            startText={t("synch.processed")}
            endText={t("synch.members")}
          />
        )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
        className="text-center"
      >
        <Grid
          item
          md={6}
          xs={12}
          mr={isMobile ? 0 : 3}
          style={{ textAlign: "left", flex: 1 }}
        >
          <Box
            sx={{
              background: theme.palette.background.paper,
            }}
            mt={2}
          >
            <Grid sx={{ padding: "20px" }}></Grid>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography variant="body2">
                  {t("synch.updatedTotalElectionPlaces")}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {"" + synchMessage.electionPlaceCurrentCount + ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography variant="body2">
                  {t("synch.updatedTotalElectionPlacesRegion")}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {"" + synchMessage.electionPlaceRegionCurrentCount + ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography variant="body2">
                  {t("synch.updatedTotalCities")}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {"" + synchMessage.citiesCurrentCount + ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography variant="body2">
                  {t("synch.updatedTotalStreets")}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {"" + synchMessage.streetsCurrentCount + ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          ml={isMobile ? 0 : 3}
          style={{ textAlign: "left", flex: 1 }}
        >
          <Box
            sx={{
              background: theme.palette.background.paper,
            }}
            mt={2}
          >
            <Grid sx={{ padding: "20px" }}></Grid>
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography variant="body2">
                  {t("synch.updatedTotalMembers")}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {"" + synchMessage.updatedMembersCount + ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              md={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid>
                <Typography variant="body2">
                  {t("synch.notUpdatedTotalMembers")}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {"" + synchMessage.notUpdatedMembersCount + ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Box>
        </Grid>
      </Box>
      {synchMessage.notUpdatedMembers.length > 0 && (
        <Grid container mt={10} display={"flex"} justifyContent={"center"}>
          <Grid item xs={12} alignContent={"center"}>
            <Typography variant="body1" fontWeight={"bold"} align="center">
              {t("synch.notUpdatedTotalMembers")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ overflowX: "auto", width: "100%" }}>
              <NotUpdatedMembersTable
                members={synchMessage.notUpdatedMembers}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
