import * as signalR from "@microsoft/signalr";
import SynchMessage from "./models/synch/synchMessage";

const MembershipEnv = {
    Local: "https://localhost:5001/hub/",
    Prod: "https://membership.api.easymembership.net/hub/",
  };

const URL = MembershipEnv.Prod;

class Connector {
    private connection: signalR.HubConnection;
    public onMessageReceived!: (accountId: string, message: SynchMessage) => void;
    static instance: Connector;
    constructor(hubName: string, accountId: string) {
            this.connection = new signalR.HubConnectionBuilder()
                .withUrl(URL+hubName+"?accountId="+accountId)
                .withAutomaticReconnect()
                .build();

            this.connection.on("messageReceived", (accountId, message) => {
                if (this.onMessageReceived) {
                    this.onMessageReceived(accountId, message);
                }
            });
            
            this.connection.start().catch(err => document.write(err));
        };

    public startConnection = () => {
        this.connection.start().catch(err => document.write(err));
    };

    public stopConnection = () => {
        this.connection.stop().catch(err => document.write(err));
    };

    public newMessage = (messages: string) => {
        this.connection.send("newMessage", "foo", messages).then(x => console.log("sent"))
    };

    public static getInstance(hubName: string, accountId: string): Connector {
            Connector.instance = new Connector(hubName, accountId);
        return Connector.instance;
    }
}
export default Connector;