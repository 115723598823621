import { APIError } from "../../models/types/api/APIError";
import { APIStatus } from "../../models/types/api/APIStatus";

export const execute = async (
  promise: Promise<any>,
  rejectWithValue: (value: APIError) => any
) => {
  try {
    const response = await promise;
    return response;
  } catch (error) {
    return rejectWithValue(error as APIError);
  }
};

export const pending = (apiData: any) => {
  apiData.status = APIStatus.PENDING;
};

export const fulfilled = (apiData: any, action: any) => {
  apiData.status = APIStatus.FULFILLED;

  apiData.response = action.payload;

  const tempResult = action.payload.data;

  if (tempResult) {
    apiData.data = tempResult;
  }
};

export const rejected = (apiData: any, action: any) => {
  apiData.status = APIStatus.REJECTED;
  apiData.error = action.payload;
};
