import { Delete, EmailOutlined, Refresh } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/api/hooks";
import { colors } from "../../app/theme/colors";
import { getInitialsFromFullName } from "../../common/helpers/account";
import { LightTooltip } from "../../component/tooltip/lightTooltip";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import { MemberBasicData } from "../../models/members/memberExpire";
import {
  getMemberExpireList,
  handleExpiredMember,
  renewMembershipNotify,
} from "../../slices/members/memberSlice";

type Props = {
  list: MemberBasicData[];
  handleToggleEditModal: any;
  disableRenew?: boolean;
};

export default function MembershipFeeList(props: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { isSmallScreen } = useScreenWidth();
  const { t } = useTranslation();

  // const listStyle = (index: number): string => {
  //   return index%2 === 0 ? theme.palette.background.default : theme.palette.background.dark;
  // }

  return (
    <List>
      {props.list?.map((m, index) => (
        <ListItem
          key={m.firstName + index}
          disablePadding
          sx={{
            flexDirection: "row",
            // background: listStyle(index)
          }}
        >
          <ListItemButton
            key={m.lastName + index}
            sx={{ width: "auto" }}
            onClick={() => props.handleToggleEditModal(m.id)}
          >
            <ListItemAvatar>
              <Avatar
                key={m.firstName + m.email + index}
                sx={{ bgcolor: colors.primary, color: "white" }}
                aria-label="recipe"
              >
                {getInitialsFromFullName(`${m.firstName} ${m.lastName}`)}
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              key={m.firstName + m.lastName + index}
              primary={`${m.firstName} ${m.lastName}`}
              secondary={
                m.daysRemaining < 0
                  ? t("memberExpireList.daysAgo", {
                      day: Math.abs(m.daysRemaining),
                    })
                  : t("memberExpireList.daysRemaining", {
                      day: m.daysRemaining,
                    })
              }
              secondaryTypographyProps={{
                sx: {
                  color:
                    m.priority === 0
                      ? "inherit"
                      : m.priority === 1
                      ? colors.chart.orange
                      : colors.chart.red,
                },
              }}
            />
          </ListItemButton>

          <Box>
            {!props.disableRenew && (
              <LightTooltip
                title={t("memberExpireList.renewActionLabel")}
                placement="top-start"
              >
                <IconButton
                  key={m.lastName + m.email + index}
                  onClick={async () => {
                    await dispatch(
                      handleExpiredMember({
                        id: m.id,
                        delete: false,
                      })
                    );
                    await dispatch(getMemberExpireList());
                  }}
                  children={<Refresh style={{ fontSize: "20px" }} />}
                />
              </LightTooltip>
            )}

            <LightTooltip
              title={t("memberExpireList.deactivateActionLabel")}
              placement="top-start"
            >
              <IconButton
                onClick={async () => {
                  await dispatch(
                    handleExpiredMember({
                      id: m.id,
                      delete: true,
                    })
                  );
                  await dispatch(getMemberExpireList());
                }}
                children={<Delete style={{ fontSize: "20px" }} />}
              />
            </LightTooltip>

            <LightTooltip
              title={t("memberExpireList.notifyActionLabel")}
              placement="top-start"
            >
              <span>
                <IconButton
                  onClick={async () => {
                    await dispatch(renewMembershipNotify({ email: m.email }));
                    await dispatch(getMemberExpireList());
                  }}
                  children={<EmailOutlined style={{ fontSize: "20px" }} />}
                  disabled={!m.email}
                />
              </span>
            </LightTooltip>
          </Box>
        </ListItem>
      ))}
    </List>
  );
}
