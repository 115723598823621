import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/api/store";
import { NotificationType } from "../../models/app/notification/notificationType";

type NotificationState = {
  message: string | null;
  type: NotificationType | null;
};

const initialState: NotificationState = {
  message: null,
  type: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setPendingNotification: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.type = NotificationType.Pending;
    },
    setSuccessNotification: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.type = NotificationType.Success;
    },
    setErrorNotification: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
      state.type = NotificationType.Error;
    },
    clearNotification: (state) => {
      state.message = null;
      state.type = null;
    },
  },
});

export const {
  setPendingNotification,
  setSuccessNotification,
  setErrorNotification,
  clearNotification,
} = notificationSlice.actions;

export const selectNotification = (state: RootState) => {
  return { ...state.notification };
};

export default notificationSlice.reducer;
