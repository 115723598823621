import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/api/hooks";
import {
  getAllAccounts,
  getAllRoles,
  selectAccounts,
  selectGetAllAccountsStatus,
  selectRoles
} from "../../../slices/accounts/accountSlice";

export const useGetAccounts = () => {
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const accountsStatus = useAppSelector(selectGetAllAccountsStatus);

  useEffect(() => {
    refetchAccounts();
  }, []);

  const refetchAccounts = async () => await dispatch(getAllAccounts());

  return { accounts, refetchAccounts, accountsStatus };
};

export const useGetRoles = () => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRoles);

  useEffect(() => {
    refetch();
  }, []);

  const refetch = async () => await dispatch(getAllRoles());

  return { roles, refetch };
};
