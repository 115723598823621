import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../app/theme/colors";
import { Theme } from "@mui/material";

export const useTableModalStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    "&.Mui-expanded": {
      margin: "5px 0",
    },
  },
  modalTitle: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    textAlign: "center",
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  profileModalImage: {
    position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "100%",
    height: "40%",
  },
  accordionTitle: {
    fontWeight: "bold",
  },
  accordionContent: {
    backgroundColor: "rgba(47,68,84, 0.05)",
    color: "#000",
    padding: "15px",
    borderRadius: "10px",
  },
  title: {
    color: theme.palette.primary.dark,
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  span_parentPropertyName: {
    width: "100%",
    textAlign: "start",
    cursor: "pointer",
  },
  arrowBackIcon_wrapper: {
    color: colors.primary,
    textTransform: "none",
    borderRadius: "5px",
    borderColor: "transparent",
    marginTop: "15px",
  },
  textCenter: { textAlign: "center" },
  closeBtnWrapper: { textAlign: "center", marginTop: "10px" },
  closeBtn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: colors.primary,
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  visibilityOutlined: {
    color: colors.primary,
  },
  label: {
    backgroundColor: "#ddd",
    color: colors.primary,
    textTransform: "none",
    borderRadius: "5px",
    marginTop: "1rem",
    fontSize: "1.2em",
    width: "80%",
    fontWeight: "bold",
    padding: ".75rem",
  },
  dialogTitle: {
    color: "white",
  },
  grid: {
    position: "sticky",
    textAlign: "end",
    border: "none",
    outline: "none",
    zIndex: 2,
  },
  h4: {
    color: colors.primary,
    fontWeight: "bold",
  },
  circularProgress: {
    marginTop: "5px",
    color: colors.primary,
    display: "block",
    margin: "auto",
  },
  closeIcon: {
    cursor: "pointer",
  },
  tableModalImage: {
    position: "absolute",
    top: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "100%",
    height: "21.5%",
    "@media (max-width: 1024px) and (max-height: 600px)": {
      height: "35% !important",
    },
    "@media (max-width: 920px)": {
      height: "18%!important",
    },
    "@media (max-width: 820px) and (max-height: 1180px)": {
      height: "18% !important",
    },
    "@media (max-width: 540px) and (max-height: 720px)": {
      height: "30% !important",
    },
    "@media (max-width: 420px)": {
      height: "24%!important",
    },
    "@media (max-width: 400px)": {
      height: "30%!important",
    },
    "@media (max-width: 390px)": {
      height: "37.5%!important",
    },
    "@media (max-width: 300px)": {
      height: "40%!important",
    },
  },
  tableDataModalImage: {
    position: "absolute",
    top: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "100%",
    height: "24%",

    "@media (max-width: 1024px) and (max-height: 600px)": {
      height: "35% !important",
    },
    "@media (max-width: 820px) and (max-height: 1180px)": {
      height: "18% !important",
    },
    "@media (max-width: 540px) and (max-height: 720px)": {
      height: "35% !important",
    },
    "@media (max-width: 420px)": {
      height: "30%!important",
    },
    "@media (max-width: 390px)": {
      height: "30%!important",
    },
    "@media (max-width: 375px)": {
      height: "45%!important",
    },
  },
  tableDataImage: {
    position: "absolute",
    top: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "100%",
    height: "24%",
    "@media (max-width: 1024px) and (max-height: 600px)": {
      height: "35% !important",
    },
    "@media (max-width: 912px) and (max-height: 1368px)": {
      height: "16% !important",
    },
    "@media (max-width: 820px) and (max-height: 1180px)": {
      height: "18% !important",
    },
    "@media (max-width: 540px) and (max-height: 720px)": {
      height: "30% !important",
    },
    "@media (max-width: 420px)": {
      height: "30%!important",
    },
    "@media (max-width: 390px)": {
      height: "30%!important",
    },
    "@media (max-width: 375px)": {
      height: "36%!important",
    },
    "@media (max-width: 300px)": {
      height: "30%!important",
    },
  },
}));
