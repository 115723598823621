import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useBasicAutocompleteStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { color: theme.palette.info.dark, textAlign: "start" },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
    },

    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "2rem",
    },
    backgroundColor:"transparent"
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: theme.palette.info.dark,
      },
    },
  },
}));
