type Props = {
  fillColor?: string;
};

export default function BirthDateCakeIcon(props: Props) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 17.3333C1.09722 17.3333 0.899306 17.2534 0.739583 17.0937C0.579861 16.934 0.5 16.7361 0.5 16.5V13.1666C0.5 12.7083 0.663194 12.3159 0.989583 11.9895C1.31597 11.6632 1.70833 11.5 2.16667 11.5H13.8333C14.2917 11.5 14.684 11.6632 15.0104 11.9895C15.3368 12.3159 15.5 12.7083 15.5 13.1666V16.5C15.5 16.7361 15.4201 16.934 15.2604 17.0937C15.1007 17.2534 14.9028 17.3333 14.6667 17.3333H1.33333ZM2.16667 9.83329V7.33329C2.16667 6.87496 2.32986 6.4826 2.65625 6.15621C2.98264 5.82982 3.375 5.66663 3.83333 5.66663H7.16667V4.45829C6.91667 4.29163 6.71528 4.09024 6.5625 3.85413C6.40972 3.61801 6.33333 3.33329 6.33333 2.99996C6.33333 2.79163 6.375 2.58676 6.45833 2.38538C6.54167 2.18399 6.66667 1.99996 6.83333 1.83329L8 0.666626L9.16667 1.83329C9.33333 1.99996 9.45833 2.18399 9.54167 2.38538C9.625 2.58676 9.66667 2.79163 9.66667 2.99996C9.66667 3.33329 9.59028 3.61801 9.4375 3.85413C9.28472 4.09024 9.08333 4.29163 8.83333 4.45829V5.66663H12.1667C12.625 5.66663 13.0174 5.82982 13.3438 6.15621C13.6701 6.4826 13.8333 6.87496 13.8333 7.33329V9.83329H2.16667Z"
        fill={props.fillColor ?? "#7B7B7B"}
      />
    </svg>
  );
}
