import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import { Member } from "../../../models/data/members/Member";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";

interface Props {
  member: Member;
  onChange: any;
}

export const MemberOtherData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();

  const formattedDate = () =>
    member?.otherData?.saintDayDate &&
    member?.otherData?.saintDayDate.substring(0, 10);

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      switch (id) {
        case "memberOtherData_input-hobby":
          var hobby = document.getElementById("memberOtherData_input-hobby");
          hobby?.focus();
          break;
        case "memberOtherData_input-saintDay":
          var saintDay = document.getElementById(
            "memberOtherData_input-saintDay"
          );
          saintDay?.focus();
          break;
        case "memberOtherData_input-saintDayDate":
          var saintDayDate = document.getElementById(
            "memberOtherData_input-saintDayDate"
          );
          saintDayDate?.focus();
          break;
        case "memberOtherData_input-recommendation":
          var recommendation = document.getElementById(
            "memberOtherData_input-recommendation"
          );
          recommendation?.focus();
          break;
        case "memberOtherData_input-noteOne":
          var noteOne = document.getElementById(
            "memberOtherData_input-noteOne"
          );
          noteOne?.focus();
          break;
        case "memberOtherData_input-noteSecond":
          var noteOne = document.getElementById(
            "memberOtherData_input-noteSecond"
          );
          noteOne?.focus();
          break;
        case "memberOtherData_input-noteThird":
          var noteThird = document.getElementById(
            "memberOtherData_input-noteThird"
          );
          noteThird?.focus();
          break;
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          id="memberOtherData_input-hobby"
          label={t("memberManagementPage.otherDataHobby")}
          value={member?.otherData?.hobby || ""}
          onChange={(e) =>
            onChange(e.target.value, "hobby", MemberSectionType.memberOtherData)
          }
          width="91%"
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberOtherData_input-saintDay")
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          id="memberOtherData_input-saintDay"
          label={t("memberManagementPage.otherDataSaintDay")}
          value={member?.otherData?.saintDay || ""}
          onChange={(e) =>
            onChange(
              e.target.value,
              "saintDay",
              MemberSectionType.memberOtherData
            )
          }
          width="90.5%"
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberOtherData_input-saintDayDate")
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          id="memberOtherData_input-saintDayDate"
          label={t("memberManagementPage.otherDataSaintDayDate")}
          type="date"
          value={formattedDate() || ""}
          onChange={(e) =>
            onChange(
              e.target.value,
              "saintDayDate",
              MemberSectionType.memberOtherData
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberOtherData_input-recommendation")
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} display="flex">
        <BasicInput
          id="memberOtherData_input-recommendation"
          label={t("memberManagementPage.otherDataRecommendation")}
          value={member?.otherData?.recommendation || ""}
          onChange={(e) =>
            onChange(
              e.target.value,
              "recommendation",
              MemberSectionType.memberOtherData
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberOtherData_input-noteOne")
          }
        />
        <CustomTooltip
          infoText={t("tooltips.memberRecommendationOtherText")}
          infoTitle={t("tooltips.tooltipTitle")}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12} display="flex">
        <BasicInput
          id="memberOtherData_input-noteOne"
          label={t("memberManagementPage.otherDataNoteOne")}
          value={member?.otherData?.note1 || ""}
          onChange={(e) =>
            onChange(e.target.value, "note1", MemberSectionType.memberOtherData)
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberOtherData_input-noteSecond")
          }
        />
        <CustomTooltip
          infoText={t("tooltips.memberNoteOtherText")}
          infoTitle={t("tooltips.tooltipTitle")}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          id="memberOtherData_input-noteSecond"
          label={t("memberManagementPage.otherDataNoteSecond")}
          value={member?.otherData?.note2 || ""}
          onChange={(e) =>
            onChange(e.target.value, "note2", MemberSectionType.memberOtherData)
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberOtherData_input-noteThird")
          }
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <BasicInput
          id="memberOtherData_input-noteThird"
          label={t("memberManagementPage.otherDataNoteThird")}
          value={member?.otherData?.note3 || ""}
          onChange={(e) =>
            onChange(e.target.value, "note3", MemberSectionType.memberOtherData)
          }
          width="91%"
        />
      </Grid>
    </Grid>
  );
};
