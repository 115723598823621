import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BasicInput from "../../component/input/basic-input/BasicInput";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { LoginRequest } from "../../models/requests/auth/loginRequest";
import { LoginResponse } from "../../models/Responses/accounts/loginResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import { login, selectLoginStatus } from "../../slices/auth/authSlice";
import { getEmailFromToken } from "../../slices/auth/authUtils";
import RequestForgotPasswordModal from "../modals/forgotPasswordModal/RequestForgotPasswordModal";
import "./Login.css";
import { useLoginStyles } from "./LoginStyles";
import ResetPasswordOnFirstLoginModal from "./resetPasswordOnFirstLogin/ResetPasswordOnFirstLoginModal";
import AppButton from "../../component/button/AppButton";
import { colors } from "../../app/theme/colors";
import LoginIcon from "@mui/icons-material/Login";
import LanguageSwitcher from "../../component/languageSwitcher/languageSwitcher";

const theme = createTheme({
  palette: {
    secondary: {
      main: colors.primary,
    },
  },
});

export default function Login() {
  const { t } = useTranslation();
  const { classes } = useLoginStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();

  const loginStatus = useAppSelector(selectLoginStatus);

  const [requestForgotPasswordModalOpen, setRequestForgotPasswordModalOpen] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [
    isOpenResetPasswordOnFirstLoginModal,
    setIsOpenResetPasswordOnFirstLoginModal,
  ] = useState<boolean>(false);
  const [emailFirstLogin, setEmailFirstLogin] = useState<string>("");
  const [tempOldPassFirstLogin, setTempOldPassFirstLogin] =
    useState<string>("");
  const [loginResponse, setLoginResponse] = useState<LoginResponse>();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [loginRequest, setLoginRequest] = useState<LoginRequest>({
    email: "",
    password: "",
  });

  const loginButtonDisabled = loginStatus === APIStatus.PENDING;

  const onLogin = async () => {
    const response = await dispatch(
      login({
        ...loginRequest,
      })
    );

    const isError = (response as any).error;

    if (isError) {
      return;
    }

    const payload = response.payload as any;
    const loginResponse = payload.data as LoginResponse;

    // Cases:

    // Case 1: Exception
    if (!loginResponse) {
      return;
    }

    setLoginResponse(loginResponse);

    // Case 2: firstLogin = false
    if (loginResponse.isFirstLogin === false) {
    }

    // Case 3: firstLogin = true
    else {
      setIsOpenResetPasswordOnFirstLoginModal(true);

      const email = getEmailFromToken(loginResponse.accessToken);
      if (email) {
        setEmailFirstLogin(email);
        setTempOldPassFirstLogin(loginRequest.password);
      }
    }
  };

  const handleRequestForgotPasswordCloseModal = (): void => {
    setRequestForgotPasswordModalOpen(false);
    // dispatch(resetResetRequestPassword());
  };

  const handleRequestForgotPasswordOpenModal = (): void => {
    setRequestForgotPasswordModalOpen(true);
  };

  const onEnterClicked = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (
        loginRequest.email.trim().length > 0 &&
        loginRequest.password.trim().length > 0
      ) {
        if (loginStatus !== APIStatus.PENDING) {
          await onLogin();
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ResetPasswordOnFirstLoginModal
        isOpen={isOpenResetPasswordOnFirstLoginModal}
        handleClose={() => setIsOpenResetPasswordOnFirstLoginModal(false)}
        email={emailFirstLogin ?? ""}
        oldPass={tempOldPassFirstLogin ?? ""}
        loginResponse={loginResponse}
      />
      <Container maxWidth="md">
        <CssBaseline />
        <Grid
          container
          style={{
            background: "#F0FAFA",
            borderRadius: "15px",
          }}
        >
          {isMobile && (
            <Grid xs={12} className={classes.backgroundMobile}></Grid>
          )}
          <Grid lg={7} xs={12} className={classes.trapeze}></Grid>
          <Grid lg={5} xs={12} sx={{ padding: isMobile ? 3 : 2.5 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} mt={1}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  style={{
                    color: "#696969",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  {t("loginPage.loginTittle")}
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={1}>
                <Typography
                  variant="body1"
                  className={classes.title}
                  style={{ fontSize: "0.8rem" }}
                >
                  {t("loginPage.loginHeader")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                mt={1}
                className={classes.gridBasicInput}
              >
                <BasicInput
                  id="login_input-email"
                  type="email"
                  name="email"
                  label={t("loginPage.loginEmail")}
                  onChange={(e) =>
                    setLoginRequest({ ...loginRequest, email: e.target.value })
                  }
                  value={loginRequest.email}
                  height="35px"
                  onKeyDown={onEnterClicked}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                mt={1}
                className={classes.gridBasicInput}
              >
                <BasicInput
                  id="login_input-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label={t("loginPage.loginPassword")}
                  value={loginRequest.password}
                  onChange={(e) =>
                    setLoginRequest({
                      ...loginRequest,
                      password: e.target.value,
                    })
                  }
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  height="35px"
                  onKeyDown={onEnterClicked}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                mb={3}
                mt={3}
              >
                <FormLabel
                  id="login_link-forgotPassword"
                  onClick={handleRequestForgotPasswordOpenModal}
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontSize: "0.8rem", color: "#5495ff" }}
                  >
                    {t("loginPage.loginForgotPassword")}
                  </Typography>
                </FormLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                container
                spacing={1}
                direction="column"
                alignItems="center"
                justifyContent="center"
                mb={3}
              >
                <LanguageSwitcher />
              </Grid>
              <Grid item md={12} xs={12} sm={12} lg={12}>
                <AppButton
                  id="login_button-login"
                  onClick={onLogin}
                  color={colors.primary}
                  label={
                    loginButtonDisabled
                      ? t("messages.login.pending")
                      : t("buttons.loginButton")
                  }
                  hover={colors.primary}
                  width="100%"
                  startIcon={
                    loginButtonDisabled ? (
                      <CircularProgress
                        size={"20px"}
                        className={classes.circularProgress}
                      />
                    ) : (
                      <LoginIcon />
                    )
                  }
                  disabled={loginStatus === APIStatus.PENDING}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <RequestForgotPasswordModal
          open={requestForgotPasswordModalOpen}
          closeModalFn={handleRequestForgotPasswordCloseModal}
        />
      </Container>
    </ThemeProvider>
  );
}
