import { Grid, Typography, useTheme } from "@mui/material";
import MemberSectionNameProps from "./MemberSectionNameProps";

export default function MemberSectionName(props: MemberSectionNameProps) {
    const theme = useTheme();
      
    return (
      <Grid container mb={1}>
        <Grid item>
          <Typography variant="h6" color={theme.palette.primary.dark}>
            {props.name}
          </Typography>
        </Grid>
      </Grid>
    );
  }