import { CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { APIStatus } from "../../models/types/api/APIStatus";
import { useChartsDashboardStyles } from "../dashboard/useChartsDashboardStyles";

type Props = {
  title: string;
  status: APIStatus;
  element: JSX.Element;
  style?: React.CSSProperties;
};

export default function ChartWrapper(props: Props) {
  const { classes } = useChartsDashboardStyles();

  return (
    <Grid
      item
      md={6}
      sm={12}
      xs={12}
      style={{
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "column",
        ...props.style,
      }}
      height={450}
    >
      <Grid container flexDirection={"row"} spacing={3} mt={1}>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Typography
            variant="h5"
            textAlign={"center"}
            className={classes.typographyText}
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
          height={300}
        >
          <>
            {props.status === APIStatus.PENDING ? (
              <CircularProgress
                size={100}
                className={classes.circularProgress}
              />
            ) : (
              props.element
            )}
          </>
        </Grid>
      </Grid>
    </Grid>
  );
}
