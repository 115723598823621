type Props = {
  fillColor?: string;
};

export default function DashboardIcon(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 6V0H18V6H10ZM0 10V0H8V10H0ZM10 18V8H18V18H10ZM0 18V12H8V18H0Z"
        fill={props.fillColor ?? "#fff"}
      />
    </svg>
  );
}
