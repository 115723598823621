import { Grid, GridSize, Typography, useMediaQuery, useTheme } from "@mui/material";

type MemberPropAndValueProps = {
    prop: string;
    propBoxSize: GridSize;
    value: string | null | undefined;
    valueBoxSize: GridSize;
  };
  
  export default function MemberPropAndValue(props: MemberPropAndValueProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  
    return (
      <Grid container sx={{ display: "flex" }}>
        <Grid
          item
          xs={12}
          md={props.propBoxSize}
          sx={{
            borderBottom: isMobile
              ? ""
              : "1px solid " + theme.palette.primary.dark,
          }}
        >
          {props.prop}:
        </Grid>
        <Grid
          item
          xs={12}
          md={props.propBoxSize}
          sx={{
            borderBottom: "1px solid " + theme.palette.primary.dark,
            justifyContent: "flex-end",
          }}
        >
          <Typography sx={{ float: isMobile ? "left" : "right" }}>
            {props?.value}
          </Typography>
        </Grid>
      </Grid>
    );
  }