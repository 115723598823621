import {
  CreateOutlined,
  DeleteOutlineOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import {
  Grid,
  IconButton,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled as muiStyled,
  styled,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyGuid } from "../../INITIAL_DATA/initialData";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { formatDate, formatNumber } from "../../common/helpers/formatData";
import AppButton from "../../component/button/AppButton";
import { LightTooltip } from "../../component/tooltip/lightTooltip";
import { Donated } from "../../models/donations/donated";
import { Donation } from "../../models/donations/donation";
import { RemoveDonationModel } from "../../models/donations/removeDonationModel";
import {
  getAllDonations,
  removeDonation,
  saveDonation,
  selectDonations,
} from "../../slices/donations/donationSlice";
import { getMemberFullDetailsById } from "../../slices/members/memberSlice";
import { DonationSaveModal } from "./DonationSaveModal";

const noBorder = "0px solid transparent!important";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: noBorder,
}));

type Props = {
  donated: Donated;
};

const initialDonation: Donation = {
  id: EmptyGuid,
  donated: Donated.ToAssociationFromIndividuals,
  firstName: "",
  lastName: "",
  phone: "",
  amount: 0,
  donatedDate: "",
  purpose: "",
  isBusiness: false,
  companyName: "",
  companyPib: "",
  memberId: null,
};

export enum DonationFormType {
  Create,
  Read,
  Update,
  None,
}

export default function DonationTable(props: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const [donationFormType, setDonationFormType] = useState<DonationFormType>(
    DonationFormType.None
  );
  const [donation, setDonation] = useState<Donation>({
    ...initialDonation,
    donated: props.donated,
  });
  const donations = useAppSelector(selectDonations) ?? [];

  const onOpenModal = () => {
    setDonationFormType(DonationFormType.Create);
  };

  const onSave = async () => {
    const response = await dispatch(saveDonation(donation));

    const result = response.payload as Donation;

    if (!result) {
      return;
    }

    setDonation({ ...donation, id: result.id });

    dispatch(getAllDonations(props.donated));
  };

  const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const onEdit = async (x: Donation) => {
    if (x.memberId && x.memberId !== EmptyGuid) {
      await dispatch(getMemberFullDetailsById(x.memberId));
    }

    const date: string = new Date(x.donatedDate).toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    setDonation({ ...x, donatedDate: date });

    setDonationFormType(DonationFormType.Update);
  };

  const onRemove = async (id: string) => {
    if (!id || !id.trim() || id === EmptyGuid) {
      return;
    }

    const request: RemoveDonationModel = {
      id: id,
    };

    await dispatch(removeDonation(request));

    dispatch(getAllDonations(props.donated));
  };

  const onView = async (x: Donation) => {
    if (x.memberId && x.memberId !== EmptyGuid) {
      await dispatch(getMemberFullDetailsById(x.memberId));
    }

    const date: string = new Date(x.donatedDate).toLocaleDateString("en-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    setDonation({ ...x, donatedDate: date });

    setDonationFormType(DonationFormType.Read);
  };

  const sx: SxProps = {
    border: noBorder,
  };

  return (
    <>
      {/* <Grid container p={2} justifyContent="space-between">
        <Grid item display="flex">
          <Typography
            variant="body1"
            textAlign="start"
            fontWeight="bold"
            overflow="hidden"
          >
            {t("common.donations")}
          </Typography>
        </Grid>

        <Grid item display="flex">
          <AppButton
            color={theme.palette.error.dark}
            hover={theme.palette.error.dark}
            label={t("donationTable.createDonation")}
            onClick={onOpenModal}
            startIcon={<VolunteerActivismIcon />}
          />
        </Grid>
      </Grid> */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{"Action"}</StyledTableCell>
              <StyledTableCell>{t("donationTable.firstName")}</StyledTableCell>
              <StyledTableCell>{t("donationTable.lastName")}</StyledTableCell>
              <StyledTableCell>{t("donationTable.phone")}</StyledTableCell>
              <StyledTableCell>{t("donationTable.amount")}</StyledTableCell>
              <StyledTableCell>
                {t("donationTable.donatedDate")}
              </StyledTableCell>
              <StyledTableCell>{t("donationTable.purpose")}</StyledTableCell>
              <StyledTableCell>{t("donationTable.isBusiness")}</StyledTableCell>
              <StyledTableCell>
                {t("donationTable.companyName")}
              </StyledTableCell>
              <StyledTableCell>{t("donationTable.companyPib")}</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={sx}>
            {donations.map((x, i) => (
              <StyledTableRow key={i} sx={sx}>
                <StyledTableCell>
                  <LightTooltip
                    title={t("donationTable.editDonationTooltip")}
                    placement="top"
                    arrow
                  >
                    <IconButton onClick={() => onEdit(x)}>
                      <CreateOutlined
                        style={{
                          color: "#00B2FF",
                          fontSize: "20px",
                        }}
                      />
                    </IconButton>
                  </LightTooltip>

                  <LightTooltip
                    title={t("donationTable.viewDonationTooltip")}
                    placement="top"
                    arrow
                  >
                    <IconButton onClick={() => onView(x)}>
                      <VisibilityOutlined
                        style={{
                          color: "#3C7F9C",
                          fontSize: "20px",
                        }}
                      />
                    </IconButton>
                  </LightTooltip>

                  <LightTooltip
                    title={t("donationTable.removeDonationTooltip")}
                    placement="top"
                    arrow
                  >
                    <IconButton onClick={() => onRemove(x.id)}>
                      <DeleteOutlineOutlined
                        style={{ color: "#DE2C2C", fontSize: "20px" }}
                      />
                    </IconButton>
                  </LightTooltip>
                </StyledTableCell>
                <StyledTableCell>{x.firstName}</StyledTableCell>
                <StyledTableCell>{x.lastName}</StyledTableCell>
                <StyledTableCell>{x.phone}</StyledTableCell>
                <StyledTableCell>{formatNumber(x.amount)}</StyledTableCell>
                <StyledTableCell>{formatDate(x.donatedDate)}</StyledTableCell>
                <StyledTableCell>{x.purpose}</StyledTableCell>
                <StyledTableCell>
                  {t(`common.${x.isBusiness.toString()}`)}
                </StyledTableCell>
                <StyledTableCell>{x.companyName ?? "/"}</StyledTableCell>
                <StyledTableCell>{x.companyPib ?? "/"}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DonationSaveModal
        donationFormType={donationFormType}
        setDonationFormType={setDonationFormType}
        onSave={onSave}
        donation={donation}
        setDonation={setDonation}
      />
    </>
  );
}
