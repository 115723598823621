import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useActiveFillterStyles = makeStyles()((theme: Theme) => ({
    divider: {
        width: '100%',
        height: '1px',
        backgroundColor: '#ccc',
        margin: '10px 0px',
        alignContent: 'center'
    },
    list: {
        // backgroundColor: theme.palette.background.dark,
        borderRadius: '8px',
        marginTop: '5px',
    }
}));