import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { adminApi } from "../../app/api/api";
import { RootState } from "../../app/api/store";
import i18n from "../../i18n";
import { Account } from "../../models/data/accounts/account";
import { Organization, Token } from "../../models/enums/auth/token";
import { LoginRequest } from "../../models/requests/auth/loginRequest";
import { RefreshTokenRequest } from "../../models/requests/auth/refreshTokenRequest";
import { LoginResponse } from "../../models/Responses/accounts/loginResponse";
import { APIResponse } from "../../models/types/api/APIResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import {
  setErrorNotification,
  setPendingNotification,
  setSuccessNotification,
} from "../notification/notificationSlice";
import { RoleOrdinal } from "./../../models/enums/roles/roles";
import { getRoleFromToken } from "./authUtils";

interface IAuthApi {
  Login: (request: LoginRequest) => Promise<APIResponse<LoginResponse>>;
  GetAuthenticatedUser: () => Promise<APIResponse<Account>>;
  RefreshToken: (
    request: RefreshTokenRequest
  ) => Promise<APIResponse<LoginResponse>>;
}

class AuthApi implements IAuthApi {
  public Login(request: LoginRequest) {
    const promise = adminApi.post("auth/login", request) as Promise<
      APIResponse<LoginResponse>
    >;

    return promise;
  }

  public GetAuthenticatedUser() {
    const promise = adminApi.get("auth/get-authenticated-user") as Promise<
      APIResponse<Account>
    >;
    return promise;
  }

  public RefreshToken(request: RefreshTokenRequest) {
    const promise = adminApi.post("auth/refresh-token", request) as Promise<
      APIResponse<LoginResponse>
    >;

    return promise;
  }
}

const authApi = new AuthApi();

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  organizationName: string | null;
  organizationLogo: string | null;
  authenticatedAccount: Account | null;
  role: RoleOrdinal | null;

  statuses: {
    login: APIStatus;
    refreshToken: APIStatus;
  };
}

const initialState: AuthState = {
  accessToken: localStorage.getItem(Token.AccessToken) || null,
  refreshToken: localStorage.getItem(Token.RefreshToken) || null,
  organizationName: localStorage.getItem(Organization.Name) || null,
  organizationLogo: localStorage.getItem(Organization.Logo) || null,
  authenticatedAccount: null,
  role: getRoleFromToken(),

  statuses: {
    login: APIStatus.IDLE,
    refreshToken: APIStatus.IDLE,
  },
};

export const login = createAsyncThunk(
  "auth/login",
  async (request: LoginRequest, { rejectWithValue, dispatch, getState }) => {
    try {
      const pending = i18n.t("messageKey.Login_Pending");
      dispatch(setPendingNotification(pending));
      const response = await authApi.Login(request);
      const success = i18n.t(`messageKey.${response.messageKey}`);
      dispatch(setSuccessNotification(success));
      return response;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const messageKey = errorApiResponse.messageKey;
      const errorMessage = i18n.t(`messageKey.${messageKey}`);
      dispatch(setErrorNotification(errorMessage)); 
      const state = getState() as RootState; 
      state.auth.statuses.login = APIStatus.REJECTED; 
      return rejectWithValue(messageKey);
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refresh-token",
  async (request: RefreshTokenRequest) => {
    const response = await authApi.RefreshToken(request);
    return response;
  }
);

export const getAuthenticatedUser = createAsyncThunk(
  "user/get_authenticated_user",
  async () => {
    const response = await authApi.GetAuthenticatedUser();
    return response;
  }
);

const onSetTokens = (
  state: AuthState,
  action: PayloadAction<APIResponse<LoginResponse>>
) => {
  if (!action?.payload?.data) {
    return;
  }

  const { accessToken, refreshToken, organizationLogo, organizationName } = action.payload.data;
  state.accessToken = accessToken;
  state.refreshToken = refreshToken;
  state.organizationLogo = organizationLogo;
  state.organizationName = organizationName;
  state.role = getRoleFromToken(accessToken);

  localStorage.setItem(Token.AccessToken, accessToken);
  localStorage.setItem(Token.RefreshToken, refreshToken);
  localStorage.setItem(Organization.Logo, organizationLogo);
  localStorage.setItem(Organization.Name, organizationName);

};

const onLogout = (state: AuthState) => {
  localStorage.removeItem(Token.AccessToken);
  localStorage.removeItem(Token.RefreshToken);
  localStorage.removeItem(Organization.Name);
  localStorage.removeItem(Organization.Logo);

  state.accessToken = null;
  state.refreshToken = null;

  state.authenticatedAccount = null;
  state.role = null;

  state.statuses.login = APIStatus.IDLE;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      onLogout(state);
    },
    setTokens: (state, action: PayloadAction<APIResponse<LoginResponse>>) => {
      onSetTokens(state, action);
    },
  },
  extraReducers: (builder) => {
    builder

      // Login
      .addCase(login.pending, (state) => {
        state.statuses.login = APIStatus.PENDING;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload?.data?.isFirstLogin === false) {
          onSetTokens(state, action);
        }

        state.statuses.login = APIStatus.FULFILLED;
      })
      .addCase(login.rejected, (state) => {
        state.statuses.login = APIStatus.REJECTED;
      })

      // Refresh token
      .addCase(refreshToken.pending, (state, action) => {
        state.statuses.refreshToken = APIStatus.PENDING;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        onSetTokens(state, action);
        state.statuses.refreshToken = APIStatus.FULFILLED;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        onLogout(state);
        state.statuses.refreshToken = APIStatus.REJECTED;
      })

      // Get authenticated user
      .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
        state.authenticatedAccount = action.payload.data;
      });
  },
});

export const { logout, setTokens } = authSlice.actions;

export const selectLoginStatus = (state: RootState) =>
  state.auth.statuses.login;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const selectAuthenticatedAccount = (state: RootState) =>
  state.auth.authenticatedAccount;

export const selectRole = (state: RootState) => state.auth.role;

export default authSlice.reducer;
