import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../app/theme/colors";
import AppButton from "../../../../component/button/AppButton";
import BasicInput from "../../../../component/input/basic-input/BasicInput";
import { useTableModalStyles } from "../../../table/modal/styles/tableModalStyles";
import sidebarBackgroundImage from "../../../../images/sidebarBackgroundImage.png";
import { hexToRgb } from "../../../../common/helpers/theme";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  isOpen: boolean;
  onClose: () => any;
  reportHeaderLabel: string;
  setReportHeaderLabel: Dispatch<SetStateAction<string>>;
  onSubmit: () => any;
  reportStatus: any;
  reportType: any;
};

const SetReportHeaderTitleModal = (props: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes } = useTableModalStyles();
  const primaryRgb = hexToRgb(theme.palette.primary.main);

  return (
    <>
      <Dialog
        open={props.isOpen}
        onClose={props.onClose}
        scroll="paper"
        fullWidth={true}
      >
        <DialogTitle id="myProfileModal" className={classes.dialogTitle}>
          <img
            src={sidebarBackgroundImage}
            alt="Sidebar Background"
            className={classes.profileModalImage}
            style={{
              background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
            }}
          />
          <h3 className={classes.modalTitle}>
            {t("reportHeaderTitleModal.title")}
          </h3>
        </DialogTitle>
        {}
        <DialogContent style={{ marginTop: "1rem", marginBottom: 0 }}>
          <BasicInput
            id="setReportHeaderTitleModal_input-titleInReport"
            type="text"
            label={t("reportHeaderTitleModal.titleInReport")}
            value={props.reportHeaderLabel}
            onChange={(e) => props.setReportHeaderLabel(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"end"} spacing={1}>
            <Grid item>
              <AppButton
                color={theme.palette.primary.main}
                hover={theme.palette.primary.main}
                label={t("myProfileData.close")}
                onClick={props.onClose}
              />
            </Grid>
            <Grid item>
              <AppButton
                id="setReportHeaderTitleModal_button-getTheReport"
                label={t("reportHeaderTitleModal.getTheReport")}
                color={colors.green}
                onClick={props.onSubmit}
                hover={colors.green}
                disabled={
                  props.reportStatus === "Pending" && props.reportType === 0
                }
                startIcon={
                  props.reportStatus === "Pending" &&
                  props.reportType === 0 && (
                    <CircularProgress
                      className={classes.circularProgress}
                      size="20px"
                    />
                  )
                }
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SetReportHeaderTitleModal;
