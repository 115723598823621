import React, { useEffect, useRef, useState } from "react";
import { Divider, IconButton, Paper, Popper, Typography } from "@mui/material";
import InfoOutline from "@mui/icons-material/InfoOutlined";
import { useCustomTooltipStyle } from "./customTooltipStyle";

interface Props {
  infoText: string;
  infoTitle: string;
}

const CustomTooltip: React.FC<Props> = ({ infoText, infoTitle }) => {
  const { classes } = useCustomTooltipStyle();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (open) {
      setOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
      setOpen(true);
      timeoutId.current = setTimeout(handleClose, 5000);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return (
    <>
      <IconButton onClick={handleOpen} className={classes.iconButton}>
        <InfoOutline fontSize="small" />
      </IconButton>
      <Popper className={classes.popper} open={open} anchorEl={anchorEl}>
        <Paper className={classes.paper}>
          <>
            <div className={classes.paperTitle}>
              <InfoOutline />
              <Typography style={{ marginLeft: ".5rem" }}>
                {infoTitle || "INFO"}
              </Typography>
            </div>
            <Divider style={{ margin: "5px" }} />
            <Typography>{infoText}</Typography>
          </>
        </Paper>
      </Popper>
    </>
  );
};

export default CustomTooltip;
