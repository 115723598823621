import { useState } from "react";
import { AppModalModel } from "../../models/app/modal/appModalModel";

export default function useModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const result: AppModalModel = { isOpen, open, close };

  return result;
}
