import { Theme, createStyles } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../app/theme/colors";

export const usePredefinedReportSureVotesModalStyle = makeStyles()(
  (theme: Theme) => ({
    modalBtnWrapper: {
      textAlign: "center",
      marginTop: "10px!important",
      alignItems: "end",
    },
    closeModalBtn: {
      textTransform: "none",
      backgroundColor: "#fff",
      borderRadius: "5px",
      color: colors.primary,
      "&:hover": {
        background: colors.primary,
        color: "white",
      },
    },
    profileModalImage: {
      position: "absolute",
      top: "0%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      width: "100%",
      height: "31%",
    },
    modalTitle: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      textAlign: "center",
    },
    divider: {
      width: "100%",
      height: "1px",
      backgroundColor: "#ccc",
      margin: "10px 10px",
      alignContent: "center",
    },
    exportExcButton: {
      textTransform: "none",
      backgroundColor: "#fff",
      borderRadius: "5px",
      boxShadow:
        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
      float: "right",
      color: colors.green,
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": {
        background: colors.green,
        color: "white",
      },
      "&:disabled": {
        boxShadow: "none",
      },
      margin: 0,
      padding: 0,
      marginTop: "15px",
    },
    exportPdfButton: {
      textTransform: "none",
      backgroundColor: "#fff",
      borderRadius: "5px",
      boxShadow:
        "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
      float: "right",
      color: colors.red,
      fontWeight: "bold",
      cursor: "pointer",
      "&:hover": {
        background: colors.red,
        color: "white",
      },
      "&:disabled": {
        boxShadow: "none",
      },
      margin: 0,
      padding: 0,
      marginTop: "15px",
    },
    loader: {
      margin: 0,
      padding: 0,
      minWidth: 0,
      color: colors.primary,
    },
    icoButton: {
      background: "white",
      "&:hover": {
        background: "white",
      },
    },
    dialogTitle: {
      cursor: "pointer",
      background: "#2f4454",
    },
  })
);
