import { Grid, Typography } from "@mui/material";
import { Guid } from "guid-typescript";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import useGetMemberExpireList from "../../hooks/member-expire-lists/useGetMemberExpireList";
import { Member } from "../../models/data/members/Member";
import { Donated } from "../../models/donations/donated";
import { APIResponse } from "../../models/types/api/APIResponse";
import {
  getMemberAgeStatistic,
  getMemberCountStatistic,
  getMemberFullDetailsById,
  getMemberGenderStatistic,
  getNextCardNumber,
  selectMemberAgeStatistic as selectAgeStatistic,
  selectMemberCountStatistic as selectCountStatistic,
  selectMemberGenderStatistic as selectGenderStatistic,
  setIsEditing,
} from "../../slices/members/memberSlice";
import ChartMemberAgeStatistic from "../charts/memberAgeStatistic/ChartMemberAgeStatistic";
import ChartMemberCountStatistic from "../charts/memberCountStatistic/ChartMemberCountStatistic";
import ChartMemberGenderStatistic from "../charts/memberGenderStatistic/ChartMemberGenderStatistic";
import DashboardCard from "./DashboardCard";
import DashboardDonationTabs from "./DashboardDonationTabs";
import DashboardMembershipFeeTabs from "./DashboardMembershipFeeTabs";
import DonationTable from "./DonationTable";
import MembershipFeeList from "./MembershipFeeList";
import DashboardInfoCard from "./DashboardInfoCard";

export default function Dashboard() {
  const { isSmallScreen } = useScreenWidth();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: countStatistic } = useAppSelector(selectCountStatistic);
  const { data: ageStatistic } = useAppSelector(selectAgeStatistic);
  const { data: genderStatistic } = useAppSelector(selectGenderStatistic);
  const { memberExpire } = useGetMemberExpireList();
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const handleToggleEditModal = async (memberId: string) => {
    if (!Guid.isGuid(memberId)) {
      setIsOpenEditModal(!isOpenEditModal);
      return;
    }
    

    const response = await dispatch(getMemberFullDetailsById(memberId));

    const result = response.payload as APIResponse<Member>;

    if (!result) {
      return;
    }

    dispatch(setIsEditing(true));
    setIsOpenEditModal(!isOpenEditModal);
  };

  useEffect(() => {
    dispatch(getMemberCountStatistic());
    dispatch(getMemberAgeStatistic());
    dispatch(getMemberGenderStatistic());
    dispatch(getNextCardNumber());
  }, [dispatch]);

  return (
    <>
    <Grid container spacing={3}>
      <Grid item xl={12} xs={12}>
        <DashboardInfoCard />
      </Grid>
    </Grid>

      <Grid container spacing={3}>
        <Grid item xl={5} lg={6} md={12} sm={12} xs={12}>
          <DashboardCard title={t("chart.countStatistic.title")}>
            {countStatistic && (
              <ChartMemberCountStatistic
                memberCountStatistic={countStatistic}
              />
            )}
          </DashboardCard>
        </Grid>

        <Grid item xl={4} lg={6} md={12} sm={12} xs={12}>
          <DashboardCard title={t("chart.ageStatistic.title")}>
            {ageStatistic && (
              <ChartMemberAgeStatistic memberAgeStatistic={ageStatistic} />
            )}
          </DashboardCard>
        </Grid>

        <Grid item xl={3} lg={12} md={12} sm={12} xs={12}>
          <DashboardCard title={t("chart.genderStatistic.title")}>
            {genderStatistic && (
              <ChartMemberGenderStatistic
                memberGenderStatistic={genderStatistic}
              />
            )}
          </DashboardCard>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={3}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <DashboardCard title={t("common.membershipFees")} disablePadding>
            <DashboardMembershipFeeTabs
              tab1={
                <>
                  <MembershipFeeList
                    list={memberExpire?.membershipRenewal ?? []}
                    handleToggleEditModal={handleToggleEditModal}
                  />
                </>
              }
              tab2={
                <>
                  <MembershipFeeList
                    list={memberExpire?.membershipExpired ?? []}
                    handleToggleEditModal={handleToggleEditModal}
                    disableRenew
                  />
                </>
              }
              isOpenEditModal={isOpenEditModal}
              handleToggleEditModal={handleToggleEditModal}
            />
          </DashboardCard>
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <DashboardCard title={t("common.donations")} disablePadding>
            
            <DashboardDonationTabs
              tab1={
                <DonationTable donated={Donated.ToAssociationFromIndividuals} />
              }
              tab2={
                <DonationTable
                  donated={Donated.ToAssociationFromLegalEntities}
                />
              }
              tab3={<DonationTable donated={Donated.FromAssociation} />}
            />
          </DashboardCard>
        </Grid>
      </Grid>
    </>
  );
}
