import { Add } from "@mui/icons-material";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
  PopoverOrigin,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors } from "../../app/theme/colors";
import AppButton from "../../component/button/AppButton";
import BasicInput from "../../component/input/basic-input/BasicInput";
import { DataTypeEnum } from "../../models/app/table-container/dataTypeEnum";
import { FilterValue } from "../../models/app/table-container/filterValue";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { MemberFilterDto } from "../../models/requests/members/memberFilterDto";
import { useSelectMemberSectionStyles } from "../smart-membership/select-member/selectMemberSectionStyles";
import { useTableFiltersStyle } from "./tableFilterStyle";

type Props = {
  filterPopover: any;
  memberProperties: any;
  filter: MemberFilterDto;
  setFilter: any;
  filterProperty: any;
  setFilterProperty: any;
  addFilter: any;
  filterValue: FilterValue;
  setFilterValue: any;
  refetchMemberPagedList: (request: MemberDataManipulationRequest) => any;
  dataManipulation: any;
};

const TableFilters = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes: selectMemberSectionClasses } =
    useSelectMemberSectionStyles();
  const { classes } = useTableFiltersStyle();

  const dataType = props.filterProperty?.dataType;

  const maxNumberCardNumber = 100_000_000;
  const maxNumberElectionPlaceNumber = 10_000;
  const maxNumberAge = 150;
  const maxNumberDegree = 10;

  const popoverProps = {
    id: props.filterPopover.id,
    open: props.filterPopover.isOpen,
    anchorEl: props.filterPopover.anchorEl,
    onClose: props.filterPopover.handleClose,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    } as PopoverOrigin,
  };

  const containerStyles: React.CSSProperties = {
    padding: "2rem",
    minHeight: "100px",
    minWidth: "300px",
  };

  const propertyNameTitleStyles: React.CSSProperties = {
    color: theme.palette.primary.dark,
    textAlign: "start",
  };

  return (
    <Popover {...popoverProps} sx={{ width: "30rem" }}>
      <DialogTitle className={classes.popoverTitle} textAlign="center">
        <Typography>{t("icons.filters")}</Typography>
      </DialogTitle>
      <DialogContent style={{ ...containerStyles }}>
        <Grid item md={12} xs={12}>
          <Typography variant="body1" style={{ ...propertyNameTitleStyles }}>
            {t("common.selectProperty")}:
          </Typography>
          <Autocomplete
            id="tableFilter_autocomplete-filterProperty"
            options={props.memberProperties}
            value={props.filterProperty}
            onChange={(e, newValue) => {
              props.setFilterProperty(newValue);
            }}
            groupBy={(option) => option.parentLabel}
            getOptionLabel={(option) => option.childLabel ?? ""}
            isOptionEqualToValue={(opt, value) =>
              opt.parent === value.parent && opt.child === value.child
            }
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                color="secondary"
                variant="outlined"
                size="small"
                fullWidth
                className={selectMemberSectionClasses.autocompleteTextField}
              />
            )}
            className={selectMemberSectionClasses.autocomplete}
          />
        </Grid>
        <>
          {dataType && (
            <Grid item md={12} xs={12} mt={2}>
              {dataType === DataTypeEnum.string ? (
                <>
                  <BasicInput
                    id="tableFilters_input-value"
                    type="text"
                    label={t("common.value")}
                    value={props.filterValue.string ?? ""}
                    onChange={(e) => {
                      props.setFilterValue({
                        ...props.filterValue,
                        string: e.target.value,
                      });
                    }}
                  />
                </>
              ) : (
                <>
                  <BasicInput
                    id="tableFilters_input-filterValue"
                    type={dataType}
                    label={t("common.from") + " / " + t("common.equals")}
                    value={
                      dataType === DataTypeEnum.date
                        ? props.filterValue.minDate ?? ""
                        : props.filterValue.minNumber === 0
                        ? null
                        : props.filterValue.minNumber
                    }
                    onChange={(e) => {
                      const updatedFilterValue = { ...props.filterValue };

                      if (dataType === DataTypeEnum.date) {
                        updatedFilterValue.minDate = e.target.value;
                      } else {
                        if (isNaN(+e.target.value)) {
                          return;
                        }

                        if (
                          props.filterProperty.parent === "PersonalData" &&
                          props.filterProperty.child === "Age"
                        ) {
                          if (+e.target.value > maxNumberAge) {
                            return;
                          }
                        }

                        if (
                          props.filterProperty.parent === "Address" &&
                          props.filterProperty.child === "ElectionPlaceNumber"
                        ) {
                          if (+e.target.value > maxNumberElectionPlaceNumber) {
                            return;
                          }
                        }

                        if (
                          props.filterProperty.parent === "Card" &&
                          props.filterProperty.child === "Number"
                        ) {
                          if (+e.target.value > maxNumberCardNumber) {
                            return;
                          }
                        }

                        if (
                          props.filterProperty.parent === "Education" &&
                          (props.filterProperty.child === "HighSchoolYear" ||
                            props.filterProperty.child ===
                              "AcademicStudiesYear" ||
                            props.filterProperty.child ===
                              "MasterAcademicStudiesYear")
                        ) {
                          if (+e.target.value > maxNumberDegree) {
                            return;
                          }
                        }

                        updatedFilterValue.minNumber = +e.target.value;
                      }

                      props.setFilterValue({
                        ...updatedFilterValue,
                      });
                    }}
                  />

                  <BasicInput
                    id="tableFilters_input-maxAge"
                    type={dataType}
                    label={t("common.to")}
                    value={
                      dataType === DataTypeEnum.date
                        ? props.filterValue.maxDate ?? ""
                        : props.filterValue.maxNumber === 0
                        ? null
                        : props.filterValue.maxNumber
                    }
                    onChange={(e) => {
                      const updatedFilterValue = { ...props.filterValue };

                      if (dataType === DataTypeEnum.date) {
                        updatedFilterValue.maxDate = e.target.value;
                      } else {
                        if (isNaN(+e.target.value)) {
                          return;
                        }

                        if (
                          props.filterProperty.parent === "PersonalData" &&
                          props.filterProperty.child === "Age"
                        ) {
                          if (+e.target.value > maxNumberAge) {
                            return;
                          }
                        }

                        if (
                          props.filterProperty.parent === "Address" &&
                          props.filterProperty.child === "ElectionPlaceNumber"
                        ) {
                          if (+e.target.value > maxNumberElectionPlaceNumber) {
                            return;
                          }
                        }

                        if (
                          props.filterProperty.parent === "Card" &&
                          props.filterProperty.child === "Number"
                        ) {
                          if (+e.target.value > maxNumberCardNumber) {
                            return;
                          }
                        }

                        if (
                          props.filterProperty.parent === "Education" &&
                          (props.filterProperty.child === "HighSchoolYear" ||
                            props.filterProperty.child ===
                              "AcademicStudiesYear" ||
                            props.filterProperty.child ===
                              "MasterAcademicStudiesYear")
                        ) {
                          if (+e.target.value > maxNumberDegree) {
                            return;
                          }
                        }

                        updatedFilterValue.maxNumber = +e.target.value;
                      }

                      props.setFilterValue({
                        ...updatedFilterValue,
                      });
                    }}
                  />
                </>
              )}
            </Grid>
          )}
        </>
      </DialogContent>
      <DialogActions>
        <AppButton
          id="tableFilters_button-addFilter"
          label={t("common.add")}
          onClick={props.addFilter}
          color={colors.green}
          startIcon={<Add fontSize="small" />}
          disabled={props.filterProperty === null}
          hover={colors.green}
        />
      </DialogActions>
    </Popover>
  );
};

export default TableFilters;
