type Props = {
  fillColor?: string;
};

export default function StatisticsIcon(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4 19L4 17.6L10.9 10.675L14.4 14.175L19.575 9L21 10.425L14.4 17L10.9 13.5L5.4 19ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V6.2H2V18Z"
        fill={props.fillColor ?? "#fff"}
      />
    </svg>
  );
}
