import { Box, CssBaseline, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import Header from "./Header";
import Sidebar from "./Sidebar";

type Props = {
  children: JSX.Element;
};

const defaultSidebarWidth = 280;

export default function Layout(props: Props) {
  const { isSmallScreen } = useScreenWidth();

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(!isSmallScreen);
  const [sidebarWidth, setSidebarWidth] = useState<number>(defaultSidebarWidth);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const commonProps = {
    sidebarWidth,
    isSidebarOpen,
    toggleSidebar,
  };

  // Handle sidebar state by viewport (small screen)
  useEffect(() => {
    if (isSmallScreen) {
      if (isSidebarOpen) {
        setSidebarWidth(defaultSidebarWidth);
      } else {
        setSidebarWidth(0);
      }
    } else {
      if (isSidebarOpen) {
        setSidebarWidth(defaultSidebarWidth);
      } else {
        setSidebarWidth(80);
      }
    }
  }, [isSidebarOpen, isSmallScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Header {...commonProps} />

      <Sidebar {...commonProps} />

      <Box
        component="main"
        sx={{
          p: 3,
          width: `calc(100% - ${sidebarWidth}px)`,
          ml: `${sidebarWidth}px`,
        }}
      >
        <Toolbar />

        {props.children}
      </Box>
    </Box>
  );
}
