import { FilterValue } from "../../models/app/table-container/filterValue";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";

const initDataManipulationRequest: MemberDataManipulationRequest = {
  memberFilter: {},
  sort: {
    prop: "",
    direction: 0,
  },
  metaData: {
    currentPage: 1,
    pageSize: 15,
  },
  skip: true,
};

const initFilterValue: FilterValue = {
  string: null,
  number: null,
  minNumber: null,
  maxNumber: null,
  date: null,
  minDate: null,
  maxDate: null,
};

export const initialDataTableContainer = {
  initDataManipulationRequest,
  initFilterValue,
};
