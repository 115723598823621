
import CircularProgress from '@mui/material/CircularProgress';
import {
    Autocomplete,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import { useBasicAutocompleteStyles } from "./basicAutocompleteStyles";

interface Props {
    options: any;
    value: any;
    label: any;
    onChange: any;
    id: string;
    getOptionLabel?: any;
    loading?: boolean;
};

const SimpleAutocomplete = ({
    options,
    value,
    label,
    onChange,
    id,
    getOptionLabel,
    loading,
}: Props) => {
    const { classes } = useBasicAutocompleteStyles();
    return (
        <Grid container spacing={1}>
            <Grid item lg={8} md={10} sm={12} xs={12}>
                <Typography variant="body1" className={classes.inputTitle}>
                    {label}:
                </Typography>
                <Autocomplete
                    disablePortal
                    id={id}
                    options={options}
                    value={value}
                    onChange={onChange}
                    fullWidth 
                    loading={loading}
                    loadingText={<CircularProgress size={"20px"}/>}
                    getOptionLabel={getOptionLabel}
                    className={classes.autocomplete}
                    renderInput={(params) => (
                        <TextField
                            onFocus={(event) => {
                                if (event.target.autocomplete) {
                                    event.target.autocomplete = "whatever";
                                }
                            }}
                            autoComplete="off"
                            {...params}
                            label=""
                            className={classes.autocompleteTextField}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default SimpleAutocomplete;