import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import RadioButtonUncheckedTwoToneIcon from "@mui/icons-material/RadioButtonUncheckedTwoTone";
import { DialogContent, Grid, IconButton, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { ChangeEvent, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/api/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../component/button/AppButton";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { ForgotPasswordRequest } from "../../../models/requests/auth/forgotPasswordRequest";
import { RequestForgotPasswordRequest } from "../../../models/requests/auth/requestForgotPasswordRequest";
import { APIStatus } from "../../../models/types/api/APIStatus";
import { forgotPassword, requestForgotPassword, selectRequestForgotPasswordStatus } from "../../../slices/accounts/accountSlice";
import { useRequestForgotPasswordStyles } from "./RequestForgotPasswordModalStyles";

const INIT_REQUEST_FORGOT_PASSWORD_REQUEST: RequestForgotPasswordRequest = {
  email: "",
};
const INIT_FORGOT_PASSWORD_REQUEST: ForgotPasswordRequest = {
  token: "",
  newPassword: "",
};

type Props = {
  open: boolean;
  closeModalFn: () => void;
};

const RequestForgotPasswordModal = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useRequestForgotPasswordStyles();
  const dispatch = useAppDispatch();

  const status = useAppSelector(selectRequestForgotPasswordStatus);

  const [password, setPassword] = useState({
    passwordNew: "",
    passwordConfirm: "",
  });

  const [
    requestForgotPasswordRequestState,
    setRequestForgotPasswordRequestState,
  ] = useState<RequestForgotPasswordRequest>(
    INIT_REQUEST_FORGOT_PASSWORD_REQUEST
  );
  const [forgotPasswordRequestState, setForgotPasswordRequestState] =
    useState<ForgotPasswordRequest>(INIT_FORGOT_PASSWORD_REQUEST);
  const [confirmNewPasswordState, setConfirmNewPasswordState] =
    useState<string>("");
  const [showNewPasswordState, setShowNewPasswordState] =
    useState<boolean>(false);
  const [showConfirmNewPasswordState, setShowConfirmNewPasswordState] =
    useState<boolean>(false);

  const [validLength, setValidLength] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [upperCase, setUpperCase] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [match, setMatch] = useState(false);
  const [requiredLength, setRequiredLength] = useState(8);
  const [validLenghtIcon, setValidLenghtIcon] = useState(false);
  const [validHasNumberIcon, setValidHasNumbertIcon] = useState(false);
  const [validUpperCaseIcon, setValidUpperCaseIcon] = useState(false);
  const [validSpecialCharIcon, setValidSpecialCharIcon] = useState(false);
  const [validMatchIcon, setValidMatchIcon] = useState(false);

  const handleOnChangeRequest = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;

    if (name) {
      setRequestForgotPasswordRequestState({
        ...requestForgotPasswordRequestState,
        [name]: value.toLowerCase().trim(),
      });
    }
  };

  const handleOnChangeForgotPassword = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const { name, value } = e.target;

    if (name) {
      setForgotPasswordRequestState({
        ...forgotPasswordRequestState,
        [name]: value.trim(),
      });
    }
  };

  const handleSendRequest = (): void => {
    const request = { ...requestForgotPasswordRequestState };

    dispatch(requestForgotPassword(request));
  };

  const handleForgotPassword = (): void => {
    const request = { ...forgotPasswordRequestState };

    dispatch(forgotPassword(request));
    // dispatch(resetResetRequestPassword());
  };

  const handleShowNewPassword = (): void =>
    setShowNewPasswordState(!showNewPasswordState);
  const handleShowConfirmNewPassword = (): void =>
    setShowConfirmNewPasswordState(!showConfirmNewPasswordState);

  useEffect(() => {
    setValidLength(
      forgotPasswordRequestState.newPassword.length >= requiredLength
        ? true
        : false
    );
    setValidLenghtIcon(
      forgotPasswordRequestState.newPassword.length >= requiredLength
        ? true
        : false
    );
    setUpperCase(
      forgotPasswordRequestState.newPassword.toLowerCase() !==
        password.passwordNew
    );
    setValidUpperCaseIcon(
      forgotPasswordRequestState.newPassword.toLowerCase() !==
        password.passwordNew
    );
    setHasNumber(/\d/.test(forgotPasswordRequestState.newPassword));
    setValidHasNumbertIcon(/\d/.test(forgotPasswordRequestState.newPassword));
    setMatch(
      !!forgotPasswordRequestState.newPassword &&
        forgotPasswordRequestState.newPassword === confirmNewPasswordState
    );
    setValidMatchIcon(
      !!forgotPasswordRequestState.newPassword &&
        forgotPasswordRequestState.newPassword === confirmNewPasswordState
    );
    setSpecialChar(
      /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(
        forgotPasswordRequestState.newPassword
      )
    );
    setValidSpecialCharIcon(
      /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(
        forgotPasswordRequestState.newPassword
      )
    );
  }, [forgotPasswordRequestState, requiredLength, confirmNewPasswordState]);

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModalFn}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="simple-dialog-title" className={classes.title}>
        {t("forgotPasswordModal.forgotPasswordTitle")}
      </DialogTitle>
      <DialogContent>
        <Row>
          <form autoComplete="off">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} mb={1}>
                <BasicInput
                  id="requestForgotPasswordModal_input-email"
                  autofocus={true}
                  name="email"
                  label={t("forgotPasswordModal.forgotPasswordEmail")}
                  value={requestForgotPasswordRequestState.email}
                  helperText={t(
                    "forgotPasswordModal.forgotPasswordEmailHelper"
                  )}
                  onChange={(e) => handleOnChangeRequest(e)}
                  height="35px"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <AppButton
                  id="requestForgotPasswordResponse_button-sendRequest"
                  onClick={handleSendRequest}
                  label={<Typography>{t("buttons.sendRequest")}</Typography>}
                  color={colors.primary}
                  hover={colors.primary}
                  width="100%"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={1}>
                <BasicInput
                  id="forgot-password-token"
                  type="text"
                  name="token"
                  label={t("forgotPasswordModal.codeTitle")}
                  value={forgotPasswordRequestState.token}
                  onChange={(e) => handleOnChangeForgotPassword(e)}
                  helperText={t("forgotPasswordModal.codeHelper")}
                  height="35px"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={1}>
                <BasicInput
                  type={showNewPasswordState ? "text" : "password"}
                  id="forgot-password-new-password"
                  name="newPassword"
                  label={t("forgotPasswordModal.newPasswordTitle")}
                  value={forgotPasswordRequestState.newPassword}
                  onChange={(e) => handleOnChangeForgotPassword(e)}
                  helperText={t("forgotPasswordModal.newPasswordHelper")}
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowNewPassword}
                    >
                      {showNewPasswordState ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  }
                  height="35px"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} mt={1}>
                <BasicInput
                  id="forgot-password-confirm-new-password"
                  name="confirmNewPassword"
                  label={t("forgotPasswordModal.confirmNewPassword")}
                  type={showConfirmNewPasswordState ? "text" : "password"}
                  value={confirmNewPasswordState}
                  helperText={t("forgotPasswordModal.confirmNewPasswordHelper")}
                  onChange={(e) =>
                    setConfirmNewPasswordState(e.target.value.trim())
                  }
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowConfirmNewPassword}
                    >
                      {showConfirmNewPasswordState ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  }
                  height="35px"
                />
              </Grid>
              {status === APIStatus.FULFILLED && (
                <Grid
                  item
                  md={12}
                  style={{ padding: "20px", marginTop: "-20px" }}
                >
                  <div className={classes.passwordValidationWrapper}>
                    {validLenghtIcon ? (
                      <CheckCircleOutlineTwoToneIcon
                        id="resetPassword_icon-passwordValidationSuccess"
                        fontSize="small"
                        className={classes.passwordValidationSuccessIcon}
                      />
                    ) : (
                      <RadioButtonUncheckedTwoToneIcon
                        id="resetPassword_icon-passwordValidationError"
                        fontSize="small"
                        className={classes.passwordValidationErrorIcon}
                      />
                    )}
                    {validLength ? (
                      <Typography
                        id="resetPassword_validationSuccess-minimumCharacters"
                        className={classes.passwordValidationSuccess}
                      >
                        {t("forgotPasswordModal.minimumCharacters")}
                      </Typography>
                    ) : (
                      <Typography
                        id="resetPassword_validationError-minimumCharacters"
                        className={classes.passwordValidationError}
                      >
                        {t("forgotPasswordModal.minimumCharacters")}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.passwordValidationWrapper}>
                    {validUpperCaseIcon ? (
                      <CheckCircleOutlineTwoToneIcon
                        id="resetPassword_icon-passwordValidationSuccess"
                        fontSize="small"
                        className={classes.passwordValidationSuccessIcon}
                      />
                    ) : (
                      <RadioButtonUncheckedTwoToneIcon
                        id="resetPassword_icon-passwordValidationError"
                        fontSize="small"
                        className={classes.passwordValidationErrorIcon}
                      />
                    )}
                    {upperCase ? (
                      <Typography
                        id="resetPassword_validationSuccess-capitalLetter"
                        className={classes.passwordValidationSuccess}
                      >
                        {t("forgotPasswordModal.minimumCapital")}
                      </Typography>
                    ) : (
                      <Typography
                        id="resetPassword_validationError-capitalLetter"
                        className={classes.passwordValidationError}
                      >
                        {t("forgotPasswordModal.minimumCapital")}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.passwordValidationWrapper}>
                    {validHasNumberIcon ? (
                      <CheckCircleOutlineTwoToneIcon
                        id="resetPassword_icon-passwordValidationSuccess"
                        fontSize="small"
                        className={classes.passwordValidationSuccessIcon}
                      />
                    ) : (
                      <RadioButtonUncheckedTwoToneIcon
                        id="resetPassword_icon-passwordValidationError"
                        fontSize="small"
                        className={classes.passwordValidationErrorIcon}
                      />
                    )}
                    {hasNumber ? (
                      <Typography
                        id="resetPassword_validationSuccess-minimumNumber"
                        className={classes.passwordValidationSuccess}
                      >
                        {t("forgotPasswordModal.minimumNumber")}
                      </Typography>
                    ) : (
                      <Typography
                        id="resetPassword_validationError-minimumNumber"
                        className={classes.passwordValidationError}
                      >
                        {t("forgotPasswordModal.minimumNumber")}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.passwordValidationWrapper}>
                    {validSpecialCharIcon ? (
                      <CheckCircleOutlineTwoToneIcon
                        id="resetPassword_icon-passwordValidationSuccess"
                        fontSize="small"
                        className={classes.passwordValidationSuccessIcon}
                      />
                    ) : (
                      <RadioButtonUncheckedTwoToneIcon
                        id="resetPassword_icon-passwordValidationError"
                        fontSize="small"
                        className={classes.passwordValidationErrorIcon}
                      />
                    )}
                    {specialChar ? (
                      <Typography
                        id="resetPassword_validationSuccess-specialCharacter"
                        className={classes.passwordValidationSuccess}
                      >
                        {t("forgotPasswordModal.minimumSpecialCharacter")}
                      </Typography>
                    ) : (
                      <Typography
                        id="resetPassword_validationError-specialCharacter"
                        className={classes.passwordValidationError}
                      >
                        {t("forgotPasswordModal.minimumSpecialCharacter")}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.passwordValidationWrapper}>
                    {validMatchIcon ? (
                      <CheckCircleOutlineTwoToneIcon
                        id="resetPassword_icon-passwordValidationSuccess"
                        fontSize="small"
                        className={classes.passwordValidationSuccessIcon}
                      />
                    ) : (
                      <RadioButtonUncheckedTwoToneIcon
                        id="resetPassword_icon-passwordValidationError"
                        fontSize="small"
                        className={classes.passwordValidationErrorIcon}
                      />
                    )}
                    {match ? (
                      <Typography
                        id="resetPassword_validationSuccess-passwordConfirm"
                        className={classes.passwordValidationSuccess}
                      >
                        {t("forgotPasswordModal.passwordConfirmed")}
                      </Typography>
                    ) : (
                      <Typography
                        id="resetPassword_validationError-passwordConfirm"
                        className={classes.passwordValidationError}
                      >
                        {t("forgotPasswordModal.passwordConfirmed")}
                      </Typography>
                    )}
                  </div>
                </Grid>
              )}

              <Grid item md={12} xs={12} mt={1}>
                <AppButton
                  id="requestForgotPasswordResponse_button-resetPassword"
                  onClick={handleForgotPassword}
                  disabled={
                    forgotPasswordRequestState.token === "" ||
                    confirmNewPasswordState === "" ||
                    forgotPasswordRequestState.newPassword === "" ||
                    confirmNewPasswordState !==
                      forgotPasswordRequestState.newPassword
                  }
                  label={t("buttons.resetPassword")}
                  color={colors.green}
                  hover={colors.green}
                  variant="contained"
                  width="100%"
                />
                <AppButton
                  id="requestForgotPasswordResponse_button-close"
                  label={t("buttons.close")}
                  width="100%"
                  mt="5px"
                  onClick={props.closeModalFn}
                  color={colors.primary}
                  hover={colors.primary}
                />
              </Grid>
            </Grid>
          </form>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default RequestForgotPasswordModal;
