type Props = {
  fillColor?: string;
};

export default function PhoneIcon(props: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.625 15.5C12.8333 15.5 11.0868 15.1007 9.38542 14.3021C7.68403 13.5035 6.17708 12.4479 4.86458 11.1354C3.55208 9.82292 2.49653 8.31597 1.69792 6.61458C0.899306 4.91319 0.5 3.16667 0.5 1.375C0.5 1.125 0.583333 0.916667 0.75 0.75C0.916667 0.583333 1.125 0.5 1.375 0.5H4.75C4.94444 0.5 5.11806 0.5625 5.27083 0.6875C5.42361 0.8125 5.51389 0.972222 5.54167 1.16667L6.08333 4.08333C6.11111 4.27778 6.10764 4.45486 6.07292 4.61458C6.03819 4.77431 5.95833 4.91667 5.83333 5.04167L3.83333 7.08333C4.41667 8.08333 5.14583 9.02083 6.02083 9.89583C6.89583 10.7708 7.86111 11.5278 8.91667 12.1667L10.875 10.2083C11 10.0833 11.1632 9.98958 11.3646 9.92708C11.566 9.86458 11.7639 9.84722 11.9583 9.875L14.8333 10.4583C15.0278 10.5 15.1875 10.5938 15.3125 10.7396C15.4375 10.8854 15.5 11.0556 15.5 11.25V14.625C15.5 14.875 15.4167 15.0833 15.25 15.25C15.0833 15.4167 14.875 15.5 14.625 15.5Z"
        fill={props.fillColor ?? "#7B7B7B"}
      />
    </svg>
  );
}
