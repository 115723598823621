type Props = {
  fillColor?: string;
  width?: number;
  height?: number;
};

export default function CreditCardIcon(props: Props) {
  return (
    <svg
      width={props.width ?? "20"}
      height={props.height ?? "20"}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33366 13.6667C1.87533 13.6667 1.48296 13.5035 1.15658 13.1771C0.830187 12.8507 0.666992 12.4584 0.666992 12V2.00004C0.666992 1.54171 0.830187 1.14935 1.15658 0.822957C1.48296 0.496569 1.87533 0.333374 2.33366 0.333374H15.667C16.1253 0.333374 16.5177 0.496569 16.8441 0.822957C17.1705 1.14935 17.3337 1.54171 17.3337 2.00004V12C17.3337 12.4584 17.1705 12.8507 16.8441 13.1771C16.5177 13.5035 16.1253 13.6667 15.667 13.6667H2.33366ZM2.33366 7.00004H15.667V3.66671H2.33366V7.00004Z"
        fill={props.fillColor ?? "#7B7B7B"}
      />
    </svg>
  );
}
