import { useTranslation } from "react-i18next";
import { TableHeadNamesType } from "../features/table/data/tableHeadNames";
import {
  MemberPersonalDataColumnsDto,
  MemberAddressColumnsDto,
  MemberCardColumnsDto,
  MemberContactColumnsDto,
  MemberEducationColumnsDto,
  MemberEmploymentColumnsDto,
  MemberLanguageColumnsDto,
  MemberOtherDataColumnsDto,
  MemberColumnsDto,
} from "../models/data/members/columns/memberColumnsDto";

type Props = {
  tableHeadNames: TableHeadNamesType;
};

export const useGetVisibleMemberColumns = (props: Props) => {
  const { t } = useTranslation();

  const isEmptyObject = (obj: any) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  };

  const getVisibleMemberColumns = (): MemberColumnsDto => {
    const personalDataColumns: MemberPersonalDataColumnsDto = {};
    const addressColumns: MemberAddressColumnsDto = {};
    const cardColumns: MemberCardColumnsDto = {};
    const contactColumns: MemberContactColumnsDto = {};
    const educationColumns: MemberEducationColumnsDto = {};
    const employmentColumns: MemberEmploymentColumnsDto = {};
    const languageColumns: MemberLanguageColumnsDto = {};
    const otherDataColumns: MemberOtherDataColumnsDto = {};

    // Personal Data
    for (let i = 0; i < props.tableHeadNames.personalData.length; i++) {
      const property = props.tableHeadNames.personalData[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        personalDataColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    // Address
    for (let i = 0; i < props.tableHeadNames.address.length; i++) {
      const property = props.tableHeadNames.address[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        addressColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    // Card
    for (let i = 0; i < props.tableHeadNames.card.length; i++) {
      const property = props.tableHeadNames.card[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        cardColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    // Contact
    for (let i = 0; i < props.tableHeadNames.contact.length; i++) {
      const property = props.tableHeadNames.contact[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        contactColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    // Education
    for (let i = 0; i < props.tableHeadNames.education.length; i++) {
      const property = props.tableHeadNames.education[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        educationColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    // Employment
    for (let i = 0; i < props.tableHeadNames.employment.length; i++) {
      const property = props.tableHeadNames.employment[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        employmentColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    // Language
    for (let i = 0; i < props.tableHeadNames.languages.length; i++) {
      const property = props.tableHeadNames.languages[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        languageColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    // Other Data
    for (let i = 0; i < props.tableHeadNames.otherData.length; i++) {
      const property = props.tableHeadNames.otherData[i];

      if (property.visible === true) {
        const actualPropertyName = Object.keys(property)[0];
        const propertyTranslation = Object.values(property)[0];

        otherDataColumns[actualPropertyName] = t(`${propertyTranslation}`);
      }
    }

    const columns: MemberColumnsDto = {
      personalData: personalDataColumns,
      address: addressColumns,
      card: cardColumns,
      contact: contactColumns,
      employment: employmentColumns,
      education: educationColumns,
      language: languageColumns,
      otherData: otherDataColumns,
    };

    if (isEmptyObject(columns.personalData)) delete columns.personalData;
    if (isEmptyObject(columns.address)) delete columns.address;
    if (isEmptyObject(columns.card)) delete columns.card;
    if (isEmptyObject(columns.contact)) delete columns.contact;
    if (isEmptyObject(columns.employment)) delete columns.employment;
    if (isEmptyObject(columns.education)) delete columns.education;
    if (isEmptyObject(columns.language)) delete columns.language;
    if (isEmptyObject(columns.otherData)) delete columns.otherData;

    return columns;
  };

  return { visibleColumns: getVisibleMemberColumns() };
};
