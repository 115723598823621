import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { colors } from "../../../app/theme/colors";
import { MemberCountStatisticModel } from "../../../models/Responses/members/statistics/memberCountStatisticModel";

type Props = {
  memberCountStatistic: MemberCountStatisticModel[];
};

export default function ChartMemberCountStatistic(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <ResponsiveContainer width="100%" height={210}>
        <LineChart data={props.memberCountStatistic}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            tickFormatter={(month: number) => t(`month.${month}`)}
          />
          <YAxis />
          <Tooltip labelFormatter={(month: number) => t(`month.${month}`)} />
          <Legend />
          <Line
            name={t("chart.countStatistic.created") ?? ""}
            type="monotone"
            dataKey="created"
            stroke={colors.chart.blue}
            activeDot={{ r: 8 }}
          />
          <Line
            name={t("chart.countStatistic.deleted") ?? ""}
            type="monotone"
            dataKey="deleted"
            stroke={colors.chart.red}
          />
          <Line
            name={t("chart.countStatistic.newCount") ?? ""}
            type="monotone"
            dataKey="newCount"
            stroke={colors.chart.purple}
          />
          <Line
            name={t("chart.countStatistic.totalCount") ?? ""}
            type="monotone"
            dataKey="totalCount"
            stroke={colors.chart.green}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
