import { RequestForgotPasswordRequest } from "./../../models/requests/auth/requestForgotPasswordRequest";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { adminApi } from "../../app/api/api";
import { RootState } from "../../app/api/store";
import i18n from "../../i18n";
import { EmptyGuid } from "../../INITIAL_DATA/initialData";
import { Account } from "../../models/data/accounts/account";
import { Role } from "../../models/data/accounts/Role";
import { SaveAccountModel } from "../../models/requests/accounts/saveAccountModel";
import { AccountStatus } from "../../models/requests/accounts/accountStatus";
import { APIResponse } from "../../models/types/api/APIResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import {
  setErrorNotification,
  setPendingNotification,
  setSuccessNotification,
} from "../notification/notificationSlice";
import { ResetPasswordRequest } from "../../models/requests/auth/resetPasswordRequest";
import { ForgotPasswordRequest } from "../../models/requests/auth/forgotPasswordRequest";

interface IAccountApi {
  GetAllAccounts: () => Promise<APIResponse<Account[]>>;
  GetAllRoles: () => Promise<APIResponse<Role[]>>;
  Save: (request: Account) => Promise<APIResponse<Account>>;
  SetStatus: (request: AccountStatus) => Promise<APIResponse<AccountStatus>>;
  ResetPassword: (
    request: ResetPasswordRequest
  ) => Promise<APIResponse<Account>>;
  RequestForgotPassword: (
    request: RequestForgotPasswordRequest
  ) => Promise<APIResponse<null>>;
  ForgotPassword: (
    request: ForgotPasswordRequest
  ) => Promise<APIResponse<Account>>;
}

class AccountApi implements IAccountApi {
  GetAllAccounts() {
    const promise = adminApi.get("/accounts") as Promise<
      APIResponse<Account[]>
    >;

    return promise;
  }

  GetAllRoles() {
    const promise = adminApi.get("/accounts/get-all-roles") as Promise<
      APIResponse<Role[]>
    >;

    return promise;
  }

  Save(request: Account) {
    const promise = adminApi.post("/accounts/save", request) as Promise<
      APIResponse<Account>
    >;

    return promise;
  }

  SetStatus(request: AccountStatus) {
    const promise = adminApi.put("/accounts/set-status", request) as Promise<
      APIResponse<AccountStatus>
    >;

    return promise;
  }

  ResetPassword(request: ResetPasswordRequest) {
    const promise = adminApi.put(
      "/accounts/reset-password",
      request
    ) as Promise<APIResponse<Account>>;

    return promise;
  }

  RequestForgotPassword(request: RequestForgotPasswordRequest) {
    const promise = adminApi.post(
      "/accounts/request-forgot-password",
      request
    ) as Promise<APIResponse<null>>;

    return promise;
  }

  ForgotPassword(request: ForgotPasswordRequest) {
    const promise = adminApi.post(
      "/accounts/forgot-password",
      request
    ) as Promise<APIResponse<Account>>;

    return promise;
  }
}

const accountApi = new AccountApi();

type AccountState = {
  account: Account | null;

  accounts: Account[] | null;

  roles: Role[] | null;

  statuses: {
    getAllAccounts: APIStatus;
    saveAccountStatus: APIStatus;
    requestForgotPassword: APIStatus;
  };

  exceptions: {
    saveAccountException: number | null;
  };
};

const initialState: AccountState = {
  account: null,

  accounts: null,
  roles: null,

  statuses: {
    getAllAccounts: APIStatus.IDLE,
    saveAccountStatus: APIStatus.IDLE,
    requestForgotPassword: APIStatus.IDLE,
  },

  exceptions: {
    saveAccountException: null,
  },
};

export const getAllAccounts = createAsyncThunk(
  "Account/Get_All_Accounts",
  async (_: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await accountApi.GetAllAccounts();
      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "Account/Get_All_Roles",
  async (_: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await accountApi.GetAllRoles();
      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const saveAccount = createAsyncThunk(
  "Account/SaveAccount",
  async (request: SaveAccountModel, { rejectWithValue, dispatch }) => {
    try {
      const isCreating = request.id === EmptyGuid;
      const pending = i18n.t(
        `messageKey.${isCreating ? "Create" : "Update"}Account_Pending`
      );
      dispatch(setPendingNotification(pending));
      const response = await accountApi.Save(request);
      const success = i18n.t(`messageKey.${response.messageKey}`);
      dispatch(setSuccessNotification(success));
      return response;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const setAccountStatus = createAsyncThunk(
  "Account/Set_Status",
  async (request: AccountStatus, { rejectWithValue, dispatch }) => {
    try {
      const response = await accountApi.SetStatus(request);
      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "Account/Reset_Password",
  async (request: ResetPasswordRequest, { rejectWithValue, dispatch }) => {
    try {
      const pending = i18n.t("messageKey.ResetPassword_Pending");
      dispatch(setPendingNotification(pending));
      const response = await accountApi.ResetPassword(request);
      const success = i18n.t(`messageKey.${response.messageKey}`);
      dispatch(setSuccessNotification(success));
      return response;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const requestForgotPassword = createAsyncThunk(
  "Account/Request_Forgot_Password",
  async (
    request: RequestForgotPasswordRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const pending = i18n.t("messageKey.RequestForgotPassword_Pending");
      dispatch(setPendingNotification(pending));
      const response = await accountApi.RequestForgotPassword(request);
      const success = i18n.t(`messageKey.${response.messageKey}`);
      dispatch(setSuccessNotification(success));
      return response;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "Account/Forgot_Password",
  async (request: ForgotPasswordRequest, { rejectWithValue, dispatch }) => {
    try {
      const pending = i18n.t("messageKey.ForgotPassword_Pending");
      dispatch(setPendingNotification(pending));
      const response = await accountApi.ForgotPassword(request);
      const success = i18n.t(`messageKey.${response.messageKey}`);
      dispatch(setSuccessNotification(success));
      return response;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    selectAccount: (state, action: PayloadAction<Account>) => {
      state.account = action.payload;
    },
    clearSelectedAccount: (state) => {
      state.account = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all accounts
      .addCase(getAllAccounts.pending, (state) => {
        state.statuses.getAllAccounts = APIStatus.PENDING;
      })
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.statuses.getAllAccounts = APIStatus.FULFILLED;
        state.accounts = action.payload;
      })
      .addCase(getAllAccounts.rejected, (state) => {
        state.statuses.getAllAccounts = APIStatus.REJECTED;
      })

      // Get all roles
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })

      // Save Account
      .addCase(saveAccount.pending, (state) => {
        state.statuses.saveAccountStatus = APIStatus.PENDING;
      })
      .addCase(saveAccount.fulfilled, (state, action) => {
        state.account = action.payload.data;
        state.statuses.saveAccountStatus = APIStatus.FULFILLED;
      })
      .addCase(saveAccount.rejected, (state, action) => {
        state.account = null;
        state.statuses.saveAccountStatus = APIStatus.REJECTED;
      })

      // Request forgot password
      .addCase(requestForgotPassword.pending, (state) => {
        state.statuses.requestForgotPassword = APIStatus.PENDING;
      })
      .addCase(requestForgotPassword.fulfilled, (state, action) => {
        state.statuses.requestForgotPassword = APIStatus.FULFILLED;
      })
      .addCase(requestForgotPassword.rejected, (state) => {
        state.statuses.requestForgotPassword = APIStatus.REJECTED;
      });
  },
});

export const { selectAccount, clearSelectedAccount } = accountSlice.actions;

export const selectAccountSelector = (state: RootState) =>
  state.account.account;

export const selectStatusSavedAccount = (state: RootState) =>
  state.account.statuses.saveAccountStatus;

export const selectExceptionSavedAccount = (state: RootState) =>
  state.account.exceptions.saveAccountException;

export const selectAccounts = (state: RootState) => state.account.accounts;

export const selectRoles = (state: RootState) => state.account.roles;

export const selectGetAllAccountsStatus = (state: RootState) =>
  state.account.statuses.getAllAccounts;

export const selectRequestForgotPasswordStatus = (state: RootState) =>
  state.account.statuses.requestForgotPassword;

export default accountSlice.reducer;
