import { useState } from "react";
import TableContainer from "../features/table-container/TableContainer";

export default function TablePage() {
  const [page, setPage] = useState<number>(0);

  return (
    <div>
      <TableContainer page={page} setPage={setPage} />
    </div>
  );
}
