import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useTableContainerStyles = makeStyles()(() => ({
  iconWrapper: {
    backgroundColor: "transparent",
    padding: "20px",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "center",
  },
  icon: {
    backgroundColor: colors.primary,
    color: "white",
    width: "50px",
    height: "50px",
    padding: "10px",
    borderRadius: "15%",
    alignContent: "center"
  },
  gridSelector: {
    display: "flex",
    justifyContent: "start",
    alignContent: "center"
  },
  myCard: {
    padding: "20px",
    background: "transparent"
  },
  dFlex: { display: "flex" },
  gridSelectorBox: { alignContent: "center" },
  headerWrapper: {
    background: "whitesmoke",
    alignContent: "center",
    height: "70px"
  },
  gridSelectorParent: { alignContent: "center" },
  colsSelectorButton: {
    textTransform: "none",
    borderRadius: "5px"
  },
  rbGridSelector: {
    color: "#fff!important",
    alignContent: "center"
  },
  activityDropdown: {
    alignContent: "space-around",
    minHeight: "120px"
  },
  bgTransparent: { background: "transparent" },
  gearIcon: {
    float: "right",
    alignContent: "center",
    minHeight: "120px",
    color: "#7f7f7f",
    height: "30px",
    width: "30px",
    marginRight: "10px",
    cursor: "pointer"
  },
  // reportsIcons: {
  //   alignContent: "center",
  //   minHeight: "120px",
  //   background: "transparent"
  // },
  tablePageIcon: {
    borderRadius: "50%",
    width: "36px",
    height: "36px",
    padding: "4px",
    background: colors.primary,
    color: "#fff",
    textAlign: "center",
    fontSize: "18px",
    cursor: "pointer",
  },
  paginatonStartEndIcon: { backgroundColor: "#bebdbd!important" },
  table: {
    border: 0,
    display: "block",
    margin: "0 auto",
    overflowX: "auto",
    whiteSpace: "nowrap",
    textAlign: "center",
  },
  paginationContainer: {
    marginBottom: "100px",
    marginTop: "15px",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#546268",
    color: "#fff",
    textTransform: "none",
    borderRadius: "5px",
    "&:hover": {
      background: colors.primary,
    },
  },
  columnSelectButton: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: colors.primary,
    alignContent: "center",
    alignSelf: "center",
    "&:hover": {
      background: colors.primary,
      color: "white"
    },
  },
}));
