import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useChartsDashboardStyles = makeStyles()((theme: Theme) => ({
  circularProgress: {
    color: theme.palette.info.dark,
    display: "block",
    margin: "auto",
  },
  typographyText: {
    fontWeight: 700,
    color: theme.palette.info.dark,
    border: "none",
    background: "transparent",
  },
}));
