import {
  Avatar,
  Box,
  Divider,
  Grid,
  GridSize,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Member } from "../../models/data/members/Member";
import memberViewModalBackground from "../../images/memberViewModalBackground.png";
import { getInitialsFromFullName } from "../../common/helpers/account";
import { formatDate } from "../../common/helpers/formatData";
import { hexToRgb } from "../../common/helpers/theme";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import MemberPropAndValue from "./MemberPropAndValueProps";
import MemberSectionName from "./MemberSectionName";

type MemberDataSectionProps = {
  member: Member;
};

export default function MemberDataSection(props: MemberDataSectionProps) {
  const theme = useTheme();
  const primaryColor = hexToRgb(theme.palette.primary.main);
  const memberName =
    props.member.personalData.firstName +
    " " +
    props.member.personalData.lastName;

  return (
    <Box
      p={2}
      style={{
        backgroundImage: `url(${memberViewModalBackground}), 
        linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper})`,
      }}
    >
      {/* Personal data */}
      <Box p={2}>
        <Grid container justifyContent="space-around">
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            justifyContent="center"
          >
            <IconButton>
              <Avatar sx={{ width: 160, height: 160, fontSize: "2.2em" }}>
                {getInitialsFromFullName(memberName)}
              </Avatar>
            </IconButton>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
            <MemberSectionName name={t("memberTablePage.personalData")} />
            <MemberPropAndValue
              prop={t("memberTablePage.tableLastNameParentName")}
              value={props.member.personalData.parentName}
              propBoxSize={6}
              valueBoxSize={6}
            />
            <MemberPropAndValue
              prop={t("memberTablePage.tableUinTitle")}
              value={props.member.personalData.jmbg}
              propBoxSize={6}
              valueBoxSize={6}
            />
            <MemberPropAndValue
              prop={t("memberTablePage.tableBirthDateTitle")}
              value={formatDate(props.member.personalData.birthDate)}
              propBoxSize={6}
              valueBoxSize={6}
            />
            <MemberPropAndValue
              prop={t("memberTablePage.tableAgeTitle")}
              value={props.member.personalData?.age?.toString()}
              propBoxSize={6}
              valueBoxSize={6}
            />
            <MemberPropAndValue
              prop={t("memberTablePage.tableIdNumberTitle")}
              value={props.member.personalData.idNumber}
              propBoxSize={6}
              valueBoxSize={6}
            />
            <MemberPropAndValue
              prop={t("memberTablePage.tableGenderTitle")}
              value={props.member.personalData.gender}
              propBoxSize={6}
              valueBoxSize={6}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Address data */}
      <Box p={2}>
        <Grid container>
          <Grid item xs={12}>
            <MemberSectionName name={t("memberTablePage.address")} />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around">
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
                <MemberPropAndValue
                  prop={t("memberTablePage.tableResidenceCityRegionTitle")}
                  value={props.member.address.cityRegionResidenceName}
                  propBoxSize={5.6}
                  valueBoxSize={5}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableResidenceMunicipalityTitle")}
                  value={props.member.address.municipalityResidenceName}
                  propBoxSize={5.6}
                  valueBoxSize={5}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableResidenceCityTitle")}
                  value={props.member.address.cityResidenceName}
                  propBoxSize={5.6}
                  valueBoxSize={5}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableLocalCommunityNameTitle")}
                  value={props.member.address.localCommunityName}
                  propBoxSize={5.6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("synchTableHeader.address")}
                  value={props.member.address.streetResidenceName + ' ' + props.member.address.homeNumberResidence}
                  propBoxSize={5.6}
                  valueBoxSize={5}
                />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
             {props.member.address.isAddressSame !== true && (
              <>
            <MemberPropAndValue
              prop={t("memberTablePage.tableCityRegionTitle")}
              value={props.member.address.cityRegionName}
              propBoxSize={6}
              valueBoxSize={5}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableMunicipalityTitle")}
              value={props.member.address.municipalityName}
              propBoxSize={6}
              valueBoxSize={5}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableCityTitle")}
              value={props.member.address.cityName}
              propBoxSize={6}
              valueBoxSize={5}
            />

            <MemberPropAndValue
              prop={t("synchTableHeader.address")}
              value={props.member.address.streetName + ' ' + props.member.address.homeNumber}
              propBoxSize={6}
              valueBoxSize={6}
            />
            </>)}

            <MemberPropAndValue
              prop={t("memberTablePage.tableElectionPlaceNameTitle")}
              value={props.member.address?.electionPlaceName}
              propBoxSize={6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableElectionPlaceAddressTitle")}
              value={props.member.address?.electionPlaceAddress}
              propBoxSize={6}
              valueBoxSize={6}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Contact data & Card data */}
      <Box p={2}>
        <Grid container justifyContent="space-around">
          {/* Contact data */}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
            <Grid container>
              <Grid item>
                <MemberSectionName name={t("memberTablePage.contact")} />
              </Grid>
            </Grid>
            <MemberPropAndValue
              prop={t("memberTablePage.tableLandingPhoneTitle")}
              value={props.member.contact.landPhone}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableMobilePhoneTitle")}
              value={props.member.contact.mobilePhone}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableBusinessPhoneTitle")}
              value={props.member.contact.workPhone}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableEmailTitle")}
              value={props.member.contact.email}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableFacebookTitle")}
              value={props.member.contact.facebook}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableInstagramTitle")}
              value={props.member.contact.instagram}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableTwitterTitle")}
              value={props.member.contact.twiter}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableOtherContactTitle")}
              value={props.member.contact.other}
              propBoxSize={5.6}
              valueBoxSize={6}
            />
          </Grid>
          {/* Card data */}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
            <Grid container>
              <Grid item>
                <MemberSectionName name={t("memberTablePage.card")} />
              </Grid>
            </Grid>
            <MemberPropAndValue
              prop={t("memberTablePage.tableIdCardNumberTitle")}
              value={props.member.card?.number?.toString()}
              propBoxSize={6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableIsCardPublishedTitle")}
              value={props.member.card?.isPublished ? t("common.true") : t("common.false")}
              propBoxSize={6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableMemberCardCityTitle")}
              value={props.member.card.publishingCity}
              propBoxSize={6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableMemberCardDateTitle")}
              value={formatDate(props.member.card.establishedDate)}
              propBoxSize={6}
              valueBoxSize={6}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Education */}
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Box px={3} mt={3}>
            <Grid container>
              <Grid item>
                <MemberSectionName name={t("memberTablePage.education")} />
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                display="flex"
                flexDirection="column"
                justifyContent="start"
                my={1}
                width="100%"
              >
                <MemberPropAndValue
                  prop={t("memberTablePage.tableElementarySchoolTitle")}
                  value={props.member.education.elementarySchool}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableHighSchoolTitle")}
                  value={props.member.education.highSchool}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableHighSchoolLevelTitle")}
                  value={props.member.education?.highSchoolYear?.toString() + ' ' + t("common.degree")}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableVocationalStudiesTitle")}
                  value={props.member.education.basicVocationalStudies}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t(
                    "memberTablePage.tableSpecialistVocationalStudiesTitle"
                  )}
                  value={props.member.education.specialVocationalStudies}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableBasicAcademicStudiesTitle")}
                  value={props.member.education.academicStudies}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t(
                    "memberTablePage.tableBasicAcademicStudiesLevelTitle"
                  )}
                  value={props.member.education?.academicStudiesYear?.toString() + ' ' + t("common.degree")}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableMasterAcademicStudiesTitle")}
                  value={props.member.education.masterAcademicStudies}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t(
                    "memberTablePage.tableMasterAcademicStudiesLevelTitle"
                  )}
                  value={props.member.education?.masterAcademicStudiesYear?.toString() + ' ' + t("common.degree")}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableSpecialAcademicStudiesTitle")}
                  value={props.member.education.specialAcademicStudies}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableDoctoralAcademicStudiesTitle")}
                  value={props.member.education.doctoralAcademicStudies}
                  propBoxSize={6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableWorkOnComputerTitle")}
                  value={props.member.education?.workOnComputer ? t("common.true") : t("common.false")}
                  propBoxSize={6}
                  valueBoxSize={6}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Language data & Employment data */}
      <Box p={2}>
        <Grid container justifyContent="space-around">
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
            <Grid container>
              <Grid item>
                <MemberSectionName name={t("memberTablePage.languages")} />
              </Grid>
            </Grid>
            {props.member?.languages?.map((x, i) => (
              <>
                <MemberPropAndValue
                  prop={t("memberTablePage.tableLanguageSubtitleLanguage")}
                  value={x.language}
                  propBoxSize={5.6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableLanguageSubtitleLevel")}
                  value={x.level}
                  propBoxSize={5.6}
                  valueBoxSize={6}
                />

                <MemberPropAndValue
                  prop={t("memberTablePage.tableLanguageSubtitleActive")}
                  value={x?.activeSpeaking ? t("common.true") : t("common.false")}
                  propBoxSize={5.6}
                  valueBoxSize={6}
                />

                {props.member?.languages?.length >= 1 && (
                  <Grid sx={{ my: 1 }}></Grid>
                )}
              </>
            ))}
          </Grid>
          {/* Card data */}
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
            <Grid container>
              <Grid item>
                <MemberSectionName name={t("memberTablePage.employment")} />
              </Grid>
            </Grid>
            <MemberPropAndValue
              prop={t("memberTablePage.tableIsEmployedTitle")}
              value={props.member.employment?.isEmployed ? t("common.true") : t("common.false")}
              propBoxSize={5.9}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableCompanyTitle")}
              value={props.member.employment.company}
              propBoxSize={5.9}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableWorkPlaceTitle")}
              value={props.member.employment.workPlace}
              propBoxSize={5.9}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableTemporaryEmployed")}
              value={props.member.employment?.permanentEmployed ? t("common.true") : t("common.false")}
              propBoxSize={5.9}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableOtherEmployment")}
              value={props.member.employment.other}
              propBoxSize={5.9}
              valueBoxSize={6}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Other data */}
      <Box px={3} mt={3}>
        <Grid container>
          <Grid item>
            <MemberSectionName name={t("memberTablePage.otherData")} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
            <MemberPropAndValue
              prop={t("memberTablePage.tableHobbyTitle")}
              value={props.member.otherData.hobby}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableRecommendationTitle")}
              value={props.member.otherData.recommendation}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableSaintDay")}
              value={props.member.otherData.saintDay}
              propBoxSize={5.6}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableSaintDayDate")}
              value={formatDate(props.member.otherData.saintDayDate)}
              propBoxSize={5.6}
              valueBoxSize={6}
            />
          </Grid>

          <Grid
            item
            xs
            md={6}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            my={1}
          >
            <MemberPropAndValue
              prop={t("memberTablePage.tableNoteFirstTitle")}
              value={props.member.otherData.note1}
              propBoxSize={5.9}
              valueBoxSize={6}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableNoteSecondTitle")}
              value={props.member.otherData.note2}
              propBoxSize={5.9}
              valueBoxSize={5}
            />

            <MemberPropAndValue
              prop={t("memberTablePage.tableNoteThirdTitle")}
              value={props.member.otherData.note3}
              propBoxSize={5.9}
              valueBoxSize={6}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
