type Props = {
  fillColor?: string;
};

export default function MemberManagementIcon(props: Props) {
  return (
    <svg
      width="30"
      height="16"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 7.95C12.9833 7.41667 13.3542 6.80833 13.6125 6.125C13.8708 5.44167 14 4.73333 14 4C14 3.26667 13.8708 2.55833 13.6125 1.875C13.3542 1.19167 12.9833 0.583333 12.5 0.05C13.5 0.183333 14.3333 0.625 15 1.375C15.6667 2.125 16 3 16 4C16 5 15.6667 5.875 15 6.625C14.3333 7.375 13.5 7.81667 12.5 7.95ZM18 16V13C18 12.4 17.8667 11.8292 17.6 11.2875C17.3333 10.7458 16.9833 10.2667 16.55 9.85C17.4 10.15 18.1875 10.5375 18.9125 11.0125C19.6375 11.4875 20 12.15 20 13V16H18ZM20 9V7H18V5H20V3H22V5H24V7H22V9H20ZM8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.6333 0.145833 12.1125 0.4375 11.6375C0.729167 11.1625 1.11667 10.8 1.6 10.55C2.63333 10.0333 3.68333 9.64583 4.75 9.3875C5.81667 9.12917 6.9 9 8 9C9.1 9 10.1833 9.12917 11.25 9.3875C12.3167 9.64583 13.3667 10.0333 14.4 10.55C14.8833 10.8 15.2708 11.1625 15.5625 11.6375C15.8542 12.1125 16 12.6333 16 13.2V16H0Z"
        fill={props.fillColor ?? "#fff"}
      />
    </svg>
  );
}
