import {Autocomplete, Box, createTheme, Grid, TextField, ThemeProvider, Typography, useTheme,} from "@mui/material";
import {t} from "i18next";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/api/hooks";
import {useSelectMember} from "../../../hooks/smartMembership/selectMember/useSelectMember";
import {MemberBasicDetails} from "../../../models/Responses/members/memberBasicDetails";
import {selectDisplayLanguage} from "../../../slices/displayLanguages/displayLanguageSlice";
import {getMemberFullDetailsById, getNextCardNumber, resetSelectedMember,} from "../../../slices/members/memberSlice";
import {useSelectMemberSectionStyles} from "./selectMemberSectionStyles";
import {formatDate} from "../../../common/helpers/formatData";



type Props = {
  fullWidth?: boolean;
};

const SelectMemberSection = (props: Props) => {
  const dispatch = useAppDispatch();
  const { classes } = useSelectMemberSectionStyles();
  const theme = useTheme();

  const members = useAppSelector((state) => state.members.membersBasicDetails);

  const { value, setValue, displayValue, setDisplayValue } = useSelectMember();

  const displayLanguage = useAppSelector(selectDisplayLanguage);

  // Refetch on change display language
  useEffect(() => {
    if (!displayLanguage) {
      return;
    }

    if (value) {
      dispatch(getMemberFullDetailsById(value.id));
    }
  }, [displayLanguage]);

  const onChangeValue = (e: any, value: MemberBasicDetails | null) => {
    if (!value) {
      dispatch(getNextCardNumber());
      dispatch(resetSelectedMember());
      return;
    }

    setValue(value);
    dispatch(getMemberFullDetailsById(value.id));
  };

  const defaultProps = {
    isOptionEqualToValue: (option: any, value: any) =>
      option.value === value.value,
    options: members,
    getOptionLabel: (member: MemberBasicDetails) =>
      `${member.firstName ?? ""} ${member.lastName ?? ""} - ${
        (member.birthDate && formatDate(member.birthDate)) ?? ""
      }`,
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container spacing={2}>
          <Grid
            item
            lg={props.fullWidth ? 12 : 6}
            md={props.fullWidth ? 12 : 6}
            sm={12}
            xs={12}
          >
            <Grid container spacing={1}>
              <Grid
                item
                lg={props.fullWidth ? 12 : 6}
                md={props.fullWidth ? 12 : 6}
                sm={12}
                xs={12}
              >
                <Typography variant="body1" className={classes.inputTitle}>
                  {t("common.selectMemberLabel")}:
                </Typography>
                <Autocomplete
                  {...defaultProps}
                  id="selectMemberSection_autocomplete-selectMember"
                  value={value}
                  inputValue={displayValue}
                  onInputChange={(e, newInputValue) => {
                    setDisplayValue(newInputValue);
                  }}
                  onChange={onChangeValue}
                  fullWidth
                  className={classes.autocomplete}
                  renderInput={(params) => (
                    <TextField
                      onFocus={(event) => {
                        if (event.target.autocomplete) {
                          event.target.autocomplete = "whatever";
                        }
                      }}
                      autoComplete="off"
                      {...params}
                      label=""
                      className={classes.autocompleteTextField}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default SelectMemberSection;
