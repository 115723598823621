import React from "react";
import {
  styled as muiStyled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useButtonWithTooltipStyle } from "./buttonWithTootipStyle";
import styled, { keyframes } from "styled-components";

interface Props {
  infoText: string;
  contentIcon: any;
  onSubmit?: any;
  color: any;
  fontSize?: any;
  delay?: number;
  disabled?: boolean;
  reportStatus?: string;
}

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  
  50% {
    transform: scale(1.1);
  }
  
  100% {
    transform: scale(1);
  }
`;

const Button = styled.button<{ delay?: number }>`
  animation: ${pulseAnimation} 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    ${({ delay = 0 }) => `${delay}s`};
`;

const LightTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    padding: 5,
  },
}));

const ButtonWithTooltip: React.FC<Props> = ({
  infoText,
  contentIcon,
  onSubmit,
  color,
  fontSize,
  delay = 0,
  disabled,
}) => {
  const { classes } = useButtonWithTooltipStyle();
  const validateClasses = () => {
    switch (color) {
      case "red":
        return classes.exportPdfButton;
      case "green":
        return classes.exportExcButton;
      case "disabled":
        return classes.disabled;
      default:
        return classes.exportDefaultButton;
    }
  };
  return (
    <>
      <LightTooltip title={infoText} placement="top-start">
        <Button
          className={validateClasses()}
          onClick={onSubmit}
          delay={delay}
          disabled={disabled}
        >
          <Typography fontSize={fontSize ?? "34px"}>{contentIcon}</Typography>
        </Button>
      </LightTooltip>
    </>
  );
};

export default ButtonWithTooltip;
