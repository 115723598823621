import { makeStyles } from "tss-react/mui";
import memberViewModalBackground from "../../images/memberViewModalBackground.png";

export const useMemberNameSectionStyles = makeStyles()((theme) => ({
  trapeze: {
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "80px",
      backgroundImage: `url(${memberViewModalBackground}), linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper})`, // adjust color as needed
      transform: "skew(30deg)",
      transformOrigin: "bottom right",
    },
    "&::after": {
      background: "transparent",
    },
  },
  trapezeMobile: {
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "80px",
      backgroundImage: `url(${memberViewModalBackground}), linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper})`, // adjust color as needed
      transform: "skew(35deg)",
      transformOrigin: "bottom right",
    },
    "&::after": {
      background: "transparent",
    },
  },
  content: {
    display: "flex",
    padding: theme.spacing(2),
    zIndex: 1,
  },
}));
