import { SyntheticEvent, useState } from "react";
import {
  Box,
  Grid,
  Tab,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TableEditModal from "../table/modal/editTableDataModal/editTableDataModal";
import { useTranslation } from "react-i18next";
import membershipExpired from "../../images/membership_expired.png";
import membershipWarning from "../../images/membership_warning.png";
import { useTabSyles } from "./useTabStyles";
import AppButton from "../../component/button/AppButton";
import { LightTooltip } from "../../component/tooltip/lightTooltip";

enum MembershipFeeCardTabs {
  ForRenewMembership = "forRenewMembership",
  ExpiredMembership = "expiredMembership",
}

type MembershipFeeTabsProps = {
  tab1: JSX.Element | null;
  tab2: JSX.Element | null;
  isOpenEditModal: boolean;
  handleToggleEditModal: (memberId: string) => any;
};

export default function DashboardMembershipFeeTabs(
  props: MembershipFeeTabsProps
) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const tabStyles = useTabSyles();

  const [value, setValue] = useState<MembershipFeeCardTabs>(
    MembershipFeeCardTabs.ForRenewMembership
  );

  const handleChange = (
    event: SyntheticEvent,
    newValue: MembershipFeeCardTabs
  ) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        typography: "body1",
        height: 280,
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <div className={tabStyles.classes.container}>
            <TabList onChange={handleChange}>
              <Tab
                label={
                  <Tooltip
                    title={t(
                      "membershipFeeTabs." +
                        MembershipFeeCardTabs.ForRenewMembership
                    )}
                    arrow
                    placement="top"
                  >
                    <Box component="span">
                      <img
                        src={membershipWarning}
                        alt="Image"
                        style={{
                          width: "24px",
                          height: "auto",
                          marginRight: "8px",
                        }}
                      />
                    </Box>
                  </Tooltip>
                }
                value={MembershipFeeCardTabs.ForRenewMembership}
                sx={{
                  textTransform: "none",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              />

              <Tab
                label={
                  <Tooltip
                    title={t(
                      "membershipFeeTabs." +
                        MembershipFeeCardTabs.ExpiredMembership
                    )}
                    arrow
                    placement="top"
                  >
                    <Box component="span">
                      <img
                        src={membershipExpired}
                        alt="Image"
                        style={{
                          width: "24px",
                          height: "auto",
                          marginRight: "8px",
                        }}
                      />
                    </Box>
                  </Tooltip>
                }
                value={MembershipFeeCardTabs.ExpiredMembership}
                sx={{
                  textTransform: "none",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              />
            </TabList>
            <div className={tabStyles.classes.buttonContainer}>
              {!isMobile && (
                <Typography
                  variant="body1"
                  mr={5}
                  mt={1}
                  textAlign="start"
                  fontWeight="bold"
                  overflow="hidden"
                >
                  {t("common.membershipFees")}
                </Typography>
              )}
            </div>
          </div>
        </Box>

        <TabPanel
          value={MembershipFeeCardTabs.ForRenewMembership}
          sx={{
            p: 0,
            pb: 3,
            maxHeight: "100%",
            overflow: "auto",
          }}
        >
          {props.tab1}
        </TabPanel>
        <TabPanel
          value={MembershipFeeCardTabs.ExpiredMembership}
          sx={{
            p: 0,
            pb: 3,
            maxHeight: "100%",
            overflow: "auto",
          }}
        >
          {props.tab2}
        </TabPanel>
      </TabContext>

      <TableEditModal
        handleClose={props.handleToggleEditModal}
        isOpen={props.isOpenEditModal}
      />
    </Box>
  );
}
