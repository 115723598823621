import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useElectionPlaceDetailsModalStyle } from "./electionPlaceDetailsModelStyle";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ElectionPlace from "../../../models/data/addresses/electionPlaceModel";
import sidebarBackgroundImage from "../../../images/sidebarBackgroundImage.png";
import { fontSize } from "@mui/system";
import AppButton from "../../../component/button/AppButton";
import { hexToRgb } from "../../../common/helpers/theme";

interface Props {
  open: boolean;
  onClose: any;
  electionPlace: ElectionPlace | null;
}

export default function ElectionPlaceDetailsModal({
  onClose,
  open,
  electionPlace,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const primaryRgb = hexToRgb(theme.palette.primary.main);
  const { classes } = useElectionPlaceDetailsModalStyle();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle} variant="caption">
        <img
          src={sidebarBackgroundImage}
          alt="Sidebar Background"
          className={classes.electionModalImage}
          style={{
            background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
          }}
        />
        <Grid className={classes.grid}>
          <CloseIcon onClick={(e) => onClose(e)} className={classes.icon} />
        </Grid>
        <Grid>
          <Typography variant="h5" className={classes.modalTitle}>
            {t("memberManagementPage.addressesElectionPlaceDetailsModalTitle")}
          </Typography>
        </Grid>
      </DialogTitle>
      <Grid container style={{ justifyContent: "center" }}>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center">
            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1">
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalNumber"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.electionPlaceNumber}
                </span>
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1">
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalName"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.name}
                </span>
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1">
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalAddress"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.address}
                </span>
              </Typography>
            </Grid>

            <Grid item md={12} sm={12} lg={12} xs={12}>
              <Typography variant="body1">
                {t(
                  "memberManagementPage.addressesElectionPlaceDetailsModalPlace"
                )}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {electionPlace?.cityName}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.electionDivider} />
          <Grid
            item
            md={12}
            sm={12}
            className={classes.electionButtonContainer}
          >
            <AppButton
              color={theme.palette.primary.main}
              hover={theme.palette.primary.main}
              label={t("buttons.close")}
              onClick={onClose}
            />
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
}
