import { MemberAddressModel } from "../../../models/members/memberAddressModel";
import { MemberCardModel } from "../../../models/members/memberCardModel";
import { MemberContactModel } from "../../../models/members/memberContactModel";
import { MemberEducationModel } from "../../../models/members/memberEducationModel";
import { MemberEmploymentModel } from "../../../models/members/memberEmploymentModel";
import { MemberLanguageModel } from "../../../models/members/memberLanguageModel";
import { MemberOtherDataModel } from "../../../models/members/memberOtherDataModel";
import { MemberPersonalDataModel } from "../../../models/members/memberPersonalDataModel";
import { MemberResult } from "../../../models/tableColumns/memberResult";
import { MemberPropertyNamesPaginationSort } from "./tableTypes";

export type ManagedDetailData = {
  personalData: MemberPersonalDataModel | null;
  address: MemberAddressModel | null;
  card: MemberCardModel | null;
  contact: MemberContactModel | null;
  education: MemberEducationModel | null;
  employment: MemberEmploymentModel | null;
  languages: MemberLanguageModel[];
  otherData: MemberOtherDataModel | null;
};

export interface ManagedDetail {
  data: ManagedDetailData;
  visible: boolean;
}

const INIT_MEMBER_RESULT: MemberResult = {
  personalData: {
    firstName: "tableFirstNameTitle",
    lastName: "tableLastNameTitle",
    parentName: "tableLastNameParentName",
    jmbg: "tableUinTitle",
    birthDate: "tableBirthDateTitle",
    age: "tableAgeTitle",
    birthPlace: "tableBirthPlaceTitle",
    idNumber: "tableIdNumberTitle",
    gender: "tableGenderTitle",
  },
  address: {
    cityRegionName: "tableCityRegionTitle",
    municipalityName: "tableMunicipalityTitle",
    cityName: "tableCityTitle",
    streetName: "tableStreetTitle",
    homeNumber: "tableHomeNumberTitle",

    cityRegionResidenceName: "tableResidenceCityRegionTitle",
    municipalityResidenceName: "tableResidenceMunicipalityTitle",
    cityResidenceName: "tableResidenceCityTitle",
    streetResidenceName: "tableResidenceStreetTitle",
    homeNumberResidence: "tableResidenceHomeNumberTitle",

    localCommunityName: "tableLocalCommunityNameTitle",

    electionPlaceNumber: "tableElectionPlaceNumberTitle",
    electionPlaceName: "tableElectionPlaceNameTitle",
    electionPlaceAddress: "tableElectionPlaceAddressTitle",
  },
  card: {
    number: "tableIdCardNumberTitle",
    isPublished: "tableIsCardPublishedTitle",
    publishingCity: "tableMemberCardCityTitle",
    establishedDate: "tableMemberCardDateTitle",
  },
  contact: {
    landPhone: "tableLandingPhoneTitle",
    mobilePhone: "tableMobilePhoneTitle",
    workPhone: "tableBusinessPhoneTitle",
    email: "tableEmailTitle",
    facebook: "tableFacebookTitle",
    instagram: "tableInstagramTitle",
    twiter: "tableTwitterTitle",
    other: "tableOtherContactTitle",
  },
  education: {
    elementarySchool: "tableElementarySchoolTitle",
    highSchool: "tableHighSchoolTitle",
    highSchoolYear: "tableHighSchoolLevelTitle",
    basicVocationalStudies: "tableVocationalStudiesTitle",
    specialVocationalStudies: "tableSpecialistVocationalStudiesTitle",
    academicStudies: "tableBasicAcademicStudiesTitle",
    academicStudiesYear: "tableBasicAcademicStudiesLevelTitle",
    masterAcademicStudies: "tableMasterAcademicStudiesTitle",
    masterAcademicStudiesYear: "tableMasterAcademicStudiesLevelTitle",
    specialAcademicStudies: "tableSpecialAcademicStudiesTitle",
    doctoralAcademicStudies: "tableDoctoralAcademicStudiesTitle",
    workOnComputer: "tableWorkOnComputerTitle",
  },
  employment: {
    isEmployed: "tableIsEmployedTitle",
    company: "tableCompanyTitle",
    workPlace: "tableWorkPlaceTitle",
    permanentEmployed: "tableTemporaryEmployed",
    other: "tableOtherEmployment",
  },
  languages: {
    language: "tableLanguageSubtitleLanguage",
    level: "tableLanguageSubtitleLevel",
    activeSpeaking: "tableLanguageSubtitleActive",
  },
  otherData: {
    hobby: "tableHobbyTitle",
    recommendation: "tableRecommendationTitle",
    saintDay: "tableSaintDay",
    saintDayDate: "tableSaintDayDate",
    note1: "tableNoteFirstTitle",
    note2: "tableNoteSecondTitle",
    note3: "tableNoteThirdTitle",
  },
};

const INIT_MEMBER_PROPERTY_NAMES_PAGINATION_SORT: MemberPropertyNamesPaginationSort =
  {
    personalData: "MemberPersonalData",
    address: "MemberAddress",
    card: "MemberCard",
    contact: "MemberContact",
    education: "MemberEducation",
    employment: "MemberEmployment",
    languages: "MemberLanguages",
    otherData: "MemberOtherData",
  };

const INIT_PARENT_PROPERTY_NAMES: string[] = [
  "personalData",
  "address",
  "card",
  "contact",
  "education",
  "employment",
  "languages",
  "otherData",
];

const INIT_SELECT_ALL_COLUMNS_MODAL_VALUES: string[] = [
  "allMember",
  "allAddress",
  "allCard",
  "allContact",
  "allEducation",
  "allEmployment",
  "allLanguages",
  "allOtherData",
];

type MembersTableInitialData = {
  memberResult: MemberResult;
  propertyNamesPaginationSort: MemberPropertyNamesPaginationSort;
  parentPropertyNames: string[];
  selectAllColumnsModalValues: string[];
};

export const tableInitialData: MembersTableInitialData = {
  memberResult: INIT_MEMBER_RESULT,
  propertyNamesPaginationSort: INIT_MEMBER_PROPERTY_NAMES_PAGINATION_SORT,
  parentPropertyNames: INIT_PARENT_PROPERTY_NAMES,
  selectAllColumnsModalValues: INIT_SELECT_ALL_COLUMNS_MODAL_VALUES,
};
