import {
  FormControlLabel,
  Radio,
  Grid,
  FormGroup,
  Checkbox,
  FormControl,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import { Member } from "../../../models/data/members/Member";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";
import { useEmploymentSectionStyles } from "./employmentSectionStyles";

interface Props {
  member: Member;
  onChange: any;
}

export const MemberEmploymentData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();
  const { classes } = useEmploymentSectionStyles();

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      switch (id) {
        case "memberEmploymentData_input-company":
          var company = document.getElementById(
            "memberEmploymentData_input-company"
          );
          company?.focus();
          break;
        case "memberEmploymentData_input-nameOfCompany":
          var nameOfCompany = document.getElementById(
            "memberEmploymentData_input-nameOfCompany"
          );
          nameOfCompany?.focus();
          break;
        case "memberEmploymentData_input-workPlace":
          var workPlace = document.getElementById(
            "memberEmploymentData_input-workPlace"
          );
          workPlace?.focus();
          break;
        case "memberEmploymentData_checkbox-permanentEmployed":
          var permanentEmployed = document.getElementById(
            "memberEmploymentData_checkbox-permanentEmployed"
          );
          permanentEmployed?.focus();
          break;
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xl={6}
        lg={6}
        md={6}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
      >
        <FormControl>
          <RadioGroup name="gender">
            <Grid container>
              <Grid item>
                <FormControlLabel
                  value={
                    member?.employment?.isEmployed !== null &&
                    member?.employment?.isEmployed === true
                      ? true
                      : false
                  }
                  checked={
                    member?.employment?.isEmployed !== null &&
                    member?.employment?.isEmployed === true
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      id="memberEmploymentData_radioButton-isEmployedYes"
                      className={classes.radioButton}
                      onClick={(e) =>
                        onChange(
                          true,
                          "isEmployed",
                          MemberSectionType.memberEmpoyment
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {t("memberManagementPage.employmentDataIsEmplyedYes")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value={
                    member?.employment?.isEmployed !== null &&
                    member?.employment?.isEmployed === false
                      ? true
                      : false
                  }
                  checked={
                    member?.employment?.isEmployed !== null &&
                    member?.employment?.isEmployed === false
                      ? true
                      : false
                  }
                  control={
                    <Radio
                      id="memberEmploymentData_radioButton-isEmployedNo"
                      className={classes.radioButton}
                      onClick={(e) =>
                        onChange(
                          false,
                          "isEmployed",
                          MemberSectionType.memberEmpoyment
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {t("memberManagementPage.employmentDataIsEmplyedNo")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value={member?.employment?.isEmployed === null ? true : false}
                  checked={
                    member?.employment?.isEmployed === null ? true : false
                  }
                  control={
                    <Radio
                      id="memberEmploymentData_radioButton-isEmployedOther"
                      className={classes.radioButton}
                      onClick={(e) =>
                        onChange(
                          null,
                          "isEmployed",
                          MemberSectionType.memberEmpoyment
                        )
                      }
                    />
                  }
                  label={
                    <Typography variant="body1" color="textSecondary">
                      {t("memberManagementPage.employmentDataIsEmplyedOther")}
                    </Typography>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 14,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} display="flex">
        <BasicInput
          id="memberEmploymentData_input-company"
          disabled={member?.employment?.isEmployed === "Ne"}
          label={t("memberManagementPage.employmentDataCompany")}
          value={member?.employment?.other}
          onChange={(e) =>
            onChange(e.target.value, "other", MemberSectionType.memberEmpoyment)
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberEmploymentData_input-nameOfCompany")
          }
        />
        <CustomTooltip
          infoText={t("tooltips.otherEmploymentText")}
          infoTitle={t("tooltips.tooltipTitle")}
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <BasicInput
          id="memberEmploymentData_input-nameOfCompany"
          disabled={member?.employment?.isEmployed === "Ne"}
          label={t("memberManagementPage.employmentDataNameOfCompany")}
          value={member?.employment?.company}
          onChange={(e) =>
            onChange(
              e.target.value,
              "company",
              MemberSectionType.memberEmpoyment
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberEmploymentData_input-workPlace")
          }
        />
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <BasicInput
          id="memberEmploymentData_input-workPlace"
          disabled={member?.employment?.isEmployed === "Ne"}
          label={t("memberManagementPage.employmentDataWorkPlace")}
          value={member?.employment?.workPlace}
          width="94.5%"
          onChange={(e) =>
            onChange(
              e.target.value,
              "workPlace",
              MemberSectionType.memberEmpoyment
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(
              e,
              "memberEmploymentData_checkbox-permanentEmployed"
            )
          }
        />
      </Grid>
      <Grid item xl={4} lg={6} md={6} sm={12} xs={12} display="flex">
        <FormGroup>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <Checkbox
                id="memberEmploymentData_checkbox-permanentEmployed"
                disabled={member?.employment?.isEmployed === "Ne"}
                checked={member?.employment?.permanentEmployed === true}
                onChange={(e) =>
                  onChange(
                    e.target.checked,
                    "permanentEmployed",
                    MemberSectionType.memberEmpoyment
                  )
                }
                className={classes.checkbox}
              />
            }
            label={t("memberManagementPage.employmentDataTemporary")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
