import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { Grid, useTheme } from "@mui/material";
import { t } from "i18next";
import { useAppSelector } from "../../../app/api/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../component/button/AppButton";
import { Member } from "../../../models/data/members/Member";
import { APIStatus } from "../../../models/types/api/APIStatus";
import {
  selectDeleteMemberStatus,
  selectSaveMemberStatus,
} from "../../../slices/members/memberSlice";
import { CanSaveMember, IsNewMember } from "../../../utilis/MemberValidations";
import { EmptyGuid } from "../initialData";
import { useManageMemberButtonsStyles } from "./manageMemberButtonsStyles";

interface Props {
  onSave: any;
  onClear: any;
  member: Member;
  openModal: any;
}

export const ManageMemberButtons = ({
  onSave,
  onClear,
  member,
  openModal,
}: Props) => {
  const theme = useTheme();
  const { classes } = useManageMemberButtonsStyles();
  const saveMemberStatus = useAppSelector(selectSaveMemberStatus);
  const deleteMemberStatus = useAppSelector(selectDeleteMemberStatus);

  return (
    <Grid
      container
      component="div"
      style={{ marginBottom: "20px", marginTop: "20px" }}
    >
      <hr className={classes.divider} />
      <Grid container justifyContent="flex-start" width={"40%"}>
        <Grid item>
          <AppButton
            id="menageMemberButtons_button-clearFields"
            onClick={onClear}
            startIcon={<CleaningServicesIcon fontSize="small" />}
            color={theme.palette.primary.main}
            hover={theme.palette.primary.main}
            label={t("buttons.dashboardClearFields")}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" width={"60%"}>
        <Grid item marginRight={"10px"}>
          <AppButton
            id="menageMemberButtons_button-deleteMember"
            label={t("buttons.dashboardDeleteMember")}
            startIcon={<PersonRemoveIcon fontSize="small" />}
            onClick={openModal}
            disabled={
              (member?.personalData?.id === EmptyGuid ? true : false) ||
              deleteMemberStatus === APIStatus.PENDING
            }
            color={theme.palette.error.main}
            hover={theme.palette.error.main}
          />
        </Grid>
        <Grid item>
          <AppButton
            id="menageMemberButtons_button-addMember"
            disabled={
              !CanSaveMember(member) || saveMemberStatus === APIStatus.PENDING
            }
            startIcon={
              IsNewMember(member) ? (
                <PersonAddIcon fontSize="small" />
              ) : (
                <PersonIcon fontSize="small" />
              )
            }
            onClick={onSave}
            color={theme.palette.success.main}
            hover={theme.palette.success.main}
            label={
              IsNewMember(member)
                ? t("buttons.dashboardAddMember")
                : t("buttons.dashboardUpdateMember")
            }
          />
        </Grid>
      </Grid>
      <hr className={classes.divider} />
    </Grid>
  );
};
