import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SynchAddressesRequest from "../../models/synch/synchAddressesRequest";
import { synchElectionPlace as synchElectionPlaceAPI, updateMembersElectionPlace as updateMembersElectionPlaceAPI } from "./synchApi";


type SynchState = {
    isAddressSynchronizationStarded: boolean;
    isMembershipSynchronizationStarted: boolean;
  };
  
  const initialState: SynchState = {
    isAddressSynchronizationStarded: false,
    isMembershipSynchronizationStarted: false
  };

export const updateElectionPlace = createAsyncThunk('synch_election_place', async (request: SynchAddressesRequest) => {
    const response = await synchElectionPlaceAPI(request);
    return response;
});

export const updateMembersElectionPlace = createAsyncThunk('update_members_election_place', async () => {
    const response = await updateMembersElectionPlaceAPI();
    return response;
});

export const setAddressSynchronizationStarted = createAsyncThunk("set_address_synchronization_started", async (isStarted: boolean) => {
    return isStarted;
})

export const setMembershiopSynchronizationStarted = createAsyncThunk("set_membership_synchronization_started", async (isStarted: boolean) => {
    return isStarted;
})


const synchSlice = createSlice({
    name: "synch",
    initialState: initialState,
    reducers: {
        updateElectionPlace(state, action: PayloadAction<SynchState>) {
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateElectionPlace.fulfilled, (state, { payload }) => {
                state.isAddressSynchronizationStarded =false;
             })
            .addCase(updateElectionPlace.rejected, (state, { payload }) => {
                state.isAddressSynchronizationStarded =false;
             })
            .addCase(updateElectionPlace.pending, (state,  { payload }) => {
                state.isAddressSynchronizationStarded = true;
                state.isMembershipSynchronizationStarted = false;
             })
             .addCase(updateMembersElectionPlace.fulfilled, (state, { payload }) => {
                state.isMembershipSynchronizationStarted =false;
             })
            .addCase(updateMembersElectionPlace.rejected, (state, { payload }) => {
                state.isMembershipSynchronizationStarted =false;
             })
            .addCase(updateMembersElectionPlace.pending, (state, { payload }) => {
                state.isMembershipSynchronizationStarted = true;
                state.isAddressSynchronizationStarded = false;
             })
             .addCase(setAddressSynchronizationStarted.fulfilled, (state, { payload }) => {
                state.isAddressSynchronizationStarded = payload;
             })
             .addCase(setMembershiopSynchronizationStarted.fulfilled, (state, { payload }) => {
                state.isMembershipSynchronizationStarted = payload;
             })
    },
});

export default synchSlice.reducer;