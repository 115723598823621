import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import {
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  styled as muiStyled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SortAlphaDown, SortAlphaUp } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/api/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../component/button/AppButton";
import { LightTooltip } from "../../../component/tooltip/lightTooltip";
import { MemberDetailsModel } from "../../../models/members/memberDetailsModel";
import { MemberLanguageModel } from "../../../models/members/memberLanguageModel";
import { SortDirection } from "../../../models/requests/dataManipulations/sortDirection";
import { MemberDataManipulationRequest } from "../../../models/requests/members/memberDataManipulationRequest";
import { PagedList } from "../../../models/Responses/dataManipulations/pagedList";
import { APIStatus } from "../../../models/types/api/APIStatus";
import { setIsEditing } from "../../../slices/members/memberSlice";
import { ManagedDetail } from "../data/initialData";
import { TableHeadNamesType } from "../data/tableHeadNames";
import { formatResult, isAtLeastOneVisible } from "../helpers/helperMethods";
import TableEditModal from "../modal/editTableDataModal/editTableDataModal";
import TableModal from "../modal/TableModal";
import TableViewModal from "../modal/viewTableDataModal/viewTableDataModal";
import TablePagination from "../pagination/TablePagination";
import TableActionRows from "../TableActionRows";
import { useTableStyles } from "./useTableStyles";

interface Props {
  tableHeadNames: TableHeadNamesType;
  dataManipulation: MemberDataManipulationRequest;
  page: number;
  numberOfResultsPerPage: number;
  setDataManipulation: Dispatch<SetStateAction<MemberDataManipulationRequest>>;
  setPage: any;
  tableHeadNamesInit: TableHeadNamesType;
  setTableHeadNames: Dispatch<SetStateAction<TableHeadNamesType>>;
  toggleModal: () => void;
  isOpenTableColumnModal: boolean;
  setNumberOfResultsPerPage: any;

  membersPagedListStatus: any;
  membersPagedList: any;
  refetchMemberPagedList: any;
  handleOpenFilterPopover: any;
}

const useAnimationOnMount = () => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(true);
  }, []);

  return shouldRender;
};

export default function TableComponent({
  tableHeadNames,
  dataManipulation: dataManipulationProps,
  page,
  numberOfResultsPerPage,
  setDataManipulation,
  setPage,
  tableHeadNamesInit,
  setTableHeadNames,
  toggleModal,
  isOpenTableColumnModal: openModal,
  setNumberOfResultsPerPage,
  membersPagedListStatus,
  membersPagedList,
  refetchMemberPagedList: fetchMembersPagedList,
  handleOpenFilterPopover,
}: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useTableStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const shouldRender = useAnimationOnMount();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [filteredManagedDetails, setFilteredManagedDetails] = useState<
    ManagedDetail[]
  >([]);

  const [managedDetails, setManagedDetails] = useState<ManagedDetail[]>([]);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isOpenModalView, setIsOpenModalView] = useState<boolean>(false);

  const [idForDelete, setIdForDelete] = useState<string>("");

  const [totalCountMembersPagination, setTotalCountMembersPagination] =
    useState<number>(0);
  const [totalPagesMembersPagination, setTotalPagesMembersPagination] =
    useState<number>(0);

  const resultPerPageLabel =
    membersPagedList?.metaData?.currentPageCount < numberOfResultsPerPage
      ? membersPagedList?.metaData?.currentPageCount +
        " " +
        t("memberTablePage.showingOf") +
        " " +
        membersPagedList?.metaData?.totalCount
      : numberOfResultsPerPage +
        " " +
        t("memberTablePage.showingOf") +
        " " +
        membersPagedList?.metaData?.totalCount;

  const PARENT = {
    personalData: "PersonalData",
    address: "Address",
    contact: "Contact",
    card: "Card",
    employment: "Employment",
    education: "Education",
    languages: "Languages",
    otherData: "OtherData",
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    const request: MemberDataManipulationRequest = {
      ...dataManipulationProps,
      skip: true,
      metaData: {
        currentPage: value,
        pageSize: numberOfResultsPerPage,
      },
    };
    fetchMembersPagedList(request);
    setDataManipulation(request);
    setPage(request.metaData.currentPage);
  };

  useEffect(() => {
    setPage(1);
  }, [dataManipulationProps.memberFilter]);

  const handleSort = (
    direction: SortDirection,
    parentPropName: string,
    childPropName: string
  ): void => {
    const updateChildPropName =
      childPropName.charAt(0).toUpperCase() + childPropName.slice(1);

    const dataManipulation: MemberDataManipulationRequest = {
      ...dataManipulationProps,
      skip: true,
      metaData: {
        currentPage: page,
        pageSize: numberOfResultsPerPage,
      },
      sort: {
        prop: `${PARENT[parentPropName]}.${updateChildPropName}`,
        direction: direction,
      },
    };
    fetchMembersPagedList(dataManipulation);
    setDataManipulation(dataManipulation);
    setPage(dataManipulation.metaData.currentPage);
    // setDataManipulation(dataManipulation);
  };

  const handleToggleEditModal = () => {
    setIsOpenEditModal(!isOpenEditModal);
  };
  useEffect(() => {
    if (!isOpenEditModal) {
      dispatch(setIsEditing(false));
    }
  }, [isOpenEditModal]);

  const handleToggleViewModal = () => {
    setIsOpenModalView(!isOpenModalView);
  };

  const handleCloseModalView = () => {
    setIsOpenModalView(false);
  };

  const setManagedDetailsData = (
    fetchedData: PagedList<MemberDetailsModel>
  ): void => {
    const managedDetails: ManagedDetail[] = [];

    fetchedData.result.forEach((item, i) => {
      const temp: ManagedDetail = {
        data: {
          personalData: item.personalData,
          address: item.address,
          card: item.card,
          contact: item.contact,
          education: item.education,
          employment: item.employment,
          languages: item.languages ?? [],
          otherData: item.otherData,
        },
        visible: true,
      };

      managedDetails.push(temp);
    });
    setManagedDetails(managedDetails);
    setFilteredManagedDetails(managedDetails);
    setTotalCountMembersPagination(
      Math.ceil(fetchedData.metaData.totalCount / numberOfResultsPerPage)
    );
    setTotalPagesMembersPagination(fetchedData.metaData.totalPages);
  };

  const setTableData = (): void => {
    if (membersPagedList) {
      const mapped = [...membersPagedList.result].map((item) => {
        if (item?.languages?.length === 0) {
          return {
            ...item,
            languages: [
              {
                id: "",
                language: "",
                level: "",
                activeSpeaking: null,
                rowNumber: 1,
                memberId: "",
              },
            ],
          };
        }
        return item;
      });
      const upd = { ...membersPagedList };
      upd.result = mapped;
      setManagedDetailsData(upd);
    }
  };

  const onChangePageSize = (value: number) => {
    setNumberOfResultsPerPage(value);
    const request: MemberDataManipulationRequest = {
      ...dataManipulationProps,
      skip: true,
      metaData: {
        currentPage: 1,
        pageSize: value,
      },
    };
    fetchMembersPagedList(request);
    setDataManipulation(request);
    setPage(1);
  };

  useEffect(() => {
    if (membersPagedListStatus === APIStatus.FULFILLED) {
      setTableData();
    }
  }, [membersPagedListStatus]);

  useEffect(() => {
    if (
      totalPagesMembersPagination >= 1 &&
      totalPagesMembersPagination < page
    ) {
      setPage(1);
      // setForcedPagePagination(1);
    }
  }, [page, totalPagesMembersPagination]);

  const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const SortableCell = ({
    label,
    property,
    column,
    index,
  }: {
    label?: string;
    property: string;
    column: any;
    index: number;
  }) => {
    return (
      <TableCell
        className={classes.tableCell}
        onClick={() =>
          handleSort(
            dataManipulationProps.sort &&
              dataManipulationProps.sort.direction === SortDirection.Descending
              ? SortDirection.Ascending
              : SortDirection.Descending,
            property,
            Object.keys(column)[index]
          )
        }
        style={{ cursor: "pointer" }}
      >
        <>
          {label && t(label)}
          {dataManipulationProps?.sort?.prop?.toLowerCase() ===
          (property + "." + Object.keys(column)[index]).toLowerCase() ? (
            <>
              {dataManipulationProps.sort &&
              dataManipulationProps.sort.direction ===
                SortDirection.Ascending ? (
                <>
                  <SortAlphaDown className={classes.sortIcon} />
                </>
              ) : (
                <>
                  <SortAlphaUp className={classes.sortIcon} />
                </>
              )}
            </>
          ) : (
            ""
          )}
        </>
      </TableCell>
    );
  };

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "none",
        background: "inherit",
      }}
    >
      <TableModal
        page={page}
        setPage={setPage}
        openModal={openModal}
        toggleModal={toggleModal}
        tableHeadNames={tableHeadNames}
        setTableHeadNames={setTableHeadNames}
      />
      <TableEditModal
        handleClose={handleToggleEditModal}
        isOpen={isOpenEditModal}
      />
      <TableViewModal
        handleClose={handleCloseModalView}
        isOpen={isOpenModalView}
      />

      <Grid item md={1}>
        <IconButton
          sx={{ float: "right" }}
          onClick={handleOpenFilterPopover}
          className={classes.iconButton}
        >
          <LightTooltip title={t("icons.filters")} placement="top">
            <FilterAltOutlinedIcon fontSize="large" />
          </LightTooltip>
        </IconButton>
      </Grid>

      <Grid item md={11}>
        <AppButton
          id="tableComponent_button-selectionOfTableColumns"
          onClick={toggleModal}
          startIcon={<ViewWeekIcon fontSize="small" />}
          float="left"
          label={t("buttons.selectionOfTableColumns")}
          color={theme.palette.primary.main}
          hover={theme.palette.primary.main}
        />
      </Grid>

      <TableContainer>
        <Table id="member" size="small">
          <Fade
            in={membersPagedListStatus === APIStatus.FULFILLED}
            timeout={600}
          >
            <TableHead
              style={{
                background: colors.primary,
                color: "white",
                borderBottom: "none",
                border: "none",
              }}
            >
              {membersPagedListStatus !== APIStatus.PENDING && (
                <StyledTableRow>
                  {/* Actions column */}
                  {tableHeadNames.actions.map((action: any, i: number) => (
                    <React.Fragment key={i}>
                      {action.visible &&
                        Object.values(action).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <TableCell
                                className={classes.tableCell}
                                key={`${i}-${j}`}
                                style={{
                                  textAlign: "center",
                                  background: colors.primary,
                                  position: "sticky",
                                  left: 0,
                                  zIndex: 1,
                                }}
                              >
                                {t(item)}
                              </TableCell>
                            )
                        )}
                    </React.Fragment>
                  ))}

                  {/* Ordinal number column */}
                  {tableHeadNames.ordinalNumber.map(
                    (action: any, i: number) => (
                      <React.Fragment key={i}>
                        {action.visible &&
                          Object.values(action).map(
                            (item, j) =>
                              typeof item === "string" && (
                                <TableCell
                                  className={classes.tableCell}
                                  key={`${i}-${j}`}
                                >
                                  {t(item)}
                                </TableCell>
                              )
                          )}
                      </React.Fragment>
                    )
                  )}

                  {/* Personal data columns */}
                  {tableHeadNames.personalData.map((action: any, i: number) => (
                    <React.Fragment key={i}>
                      {action.visible &&
                        Object.values(action).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <SortableCell
                                label={item}
                                property="personalData"
                                column={action}
                                index={j}
                                key={`${i}-${j}`}
                              />
                            )
                        )}
                    </React.Fragment>
                  ))}

                  {/* Address columns */}
                  {tableHeadNames.address.map((address: any, i: number) => (
                    <React.Fragment key={i}>
                      {address.visible &&
                        Object.values(address).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <SortableCell
                                label={item}
                                property="address"
                                column={address}
                                index={j}
                                key={`${i}-${j}`}
                              />
                            )
                        )}
                    </React.Fragment>
                  ))}

                  {/* Card columns */}
                  {tableHeadNames.card.map((action: any, i: number) => (
                    <React.Fragment key={i}>
                      {action.visible &&
                        Object.values(action).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <SortableCell
                                label={item}
                                property="card"
                                column={action}
                                index={j}
                                key={`${i}-${j}`}
                              />
                            )
                        )}
                    </React.Fragment>
                  ))}

                  {/* Contact columns */}
                  {tableHeadNames.contact.map((action: any, i: number) => (
                    <React.Fragment key={i}>
                      {action.visible &&
                        Object.values(action).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <SortableCell
                                label={item}
                                property="contact"
                                column={action}
                                index={j}
                                key={`${i}-${j}`}
                              />
                            )
                        )}
                    </React.Fragment>
                  ))}

                  {/* Education columns */}
                  {tableHeadNames.education.map((education: any, i: number) => (
                    <React.Fragment key={i}>
                      {education.visible &&
                        Object.values(education).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <SortableCell
                                label={item}
                                property="education"
                                column={education}
                                index={j}
                                key={`${i}-${j}`}
                              />
                            )
                        )}
                    </React.Fragment>
                  ))}

                  {/* Employment columns */}
                  {tableHeadNames.employment.map((employment, i) => (
                    <React.Fragment key={i}>
                      {employment.visible &&
                        Object.values(employment).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <SortableCell
                                label={item}
                                property="employment"
                                column={employment}
                                index={j}
                                key={`${i}-${j}`}
                              />
                            )
                        )}
                    </React.Fragment>
                  ))}

                  {/* Language columns */}
                  {isAtLeastOneVisible(tableHeadNames.languages) && (
                    <TableCell className={classes.tableCell}>
                      {t("memberTablePage.tableLanguagesTitle")}
                      <Table border={1}>
                        <TableHead>
                          <StyledTableRow>
                            {tableHeadNames.languages.map((language, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {language.visible &&
                                    Object.values(language).map(
                                      (item, j) =>
                                        typeof item === "string" && (
                                          <div
                                            style={{
                                              minWidth: "120px",
                                              display: "inline",
                                            }}
                                          >
                                            <SortableCell
                                              label={item}
                                              property="languages"
                                              column={language}
                                              index={j}
                                              key={`${i}-${j}`}
                                            />
                                          </div>
                                        )
                                    )}
                                </React.Fragment>
                              );
                            })}
                          </StyledTableRow>
                        </TableHead>
                      </Table>
                    </TableCell>
                  )}

                  {/* Other data columns */}
                  {tableHeadNames.otherData.map((otherData, i) => (
                    <React.Fragment key={i}>
                      {otherData.visible &&
                        Object.values(otherData).map(
                          (item, j) =>
                            typeof item === "string" && (
                              <SortableCell
                                label={item}
                                property="otherData"
                                column={otherData}
                                index={j}
                                key={`${i}-${j}`}
                              />
                            )
                        )}
                    </React.Fragment>
                  ))}
                </StyledTableRow>
              )}
            </TableHead>
          </Fade>
          {membersPagedListStatus === APIStatus.PENDING ? (
            <Grid style={{ padding: "20px" }}>
              <span>
                <CircularProgress
                  size={70}
                  className={classes.circularProgress}
                />
              </span>
            </Grid>
          ) : (
            <Fade
              in={membersPagedListStatus === APIStatus.FULFILLED}
              timeout={800}
            >
              <TableBody>
                {filteredManagedDetails.map((detail, i) => {
                  const languagesForDisplayWhenEmpty: MemberLanguageModel[] =
                    [];

                  if (detail.data.languages.length === 0) {
                    languagesForDisplayWhenEmpty.push({
                      id: "",
                      language: null,
                      level: null,
                      activeSpeaking: null,
                      memberId: "",
                    });
                  }

                  return (
                    <StyledTableRow
                      key={i}
                      style={{
                        display: detail.visible ? "table-row" : "none",
                      }}
                    >
                      {/* Action rows */}
                      {tableHeadNames.actions.map((action, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {action.visible &&
                            Object.keys(action).map(
                              (item, j) =>
                                item !== Object.keys(action)[1] && (
                                  <td
                                    key={`${i}-${j}`}
                                    style={{
                                      textAlign: "center",
                                      background: "transparent",
                                      left: 0,
                                    }}
                                  >
                                    <TableActionRows
                                      handleToggleViewModal={
                                        handleToggleViewModal
                                      }
                                      memberId={detail?.data?.personalData?.id}
                                      memberName={`${detail?.data?.personalData?.firstName} ${detail?.data?.personalData?.lastName}`}
                                      setIdForDelete={setIdForDelete}
                                      handleToggleEditModal={
                                        handleToggleEditModal
                                      }
                                      dataManipulation={dataManipulationProps}
                                      fetchMembersPagedList={
                                        fetchMembersPagedList
                                      }
                                    />
                                  </td>
                                )
                            )}
                        </React.Fragment>
                      ))}

                      {/* Ordinal number rows */}
                      {tableHeadNames.ordinalNumber.map((ordNumber, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {ordNumber.visible && (
                            <td key={`${i}-${k}-`}>
                              {i + 1 + (page - 1) * numberOfResultsPerPage}
                            </td>
                          )}
                        </React.Fragment>
                      ))}

                      {/* Personal data rows */}
                      {tableHeadNames.personalData.map((personalData, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {personalData.visible &&
                            Object.keys(personalData).map(
                              (item, j) =>
                                item !== Object.keys(personalData)[1] && (
                                  <td key={`${i}-${j}`}>
                                    {detail.data.personalData
                                      ? formatResult(
                                          detail.data.personalData[item]
                                        )
                                      : formatResult(null)}
                                  </td>
                                )
                            )}
                        </React.Fragment>
                      ))}

                      {/* Address rows */}
                      {tableHeadNames.address.map((address, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {address.visible &&
                            Object.keys(address).map(
                              (item, j) =>
                                item !== Object.keys(address)[1] && (
                                  <td key={`${i}-${j}`}>
                                    {detail.data.address
                                      ? formatResult(detail.data.address[item])
                                      : formatResult(null)}
                                  </td>
                                )
                            )}
                        </React.Fragment>
                      ))}

                      {/* Card rows */}
                      {tableHeadNames.card.map((card, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {card.visible &&
                            Object.keys(card).map(
                              (item, j) =>
                                item !== Object.keys(card)[1] && (
                                  <td key={`${i}-${j}`}>
                                    {detail.data.card
                                      ? formatResult(detail.data.card[item])
                                      : formatResult(null)}
                                  </td>
                                )
                            )}
                        </React.Fragment>
                      ))}

                      {/* Contact rows */}
                      {tableHeadNames.contact.map((contact, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {contact.visible &&
                            Object.keys(contact).map(
                              (item, j) =>
                                item !== Object.keys(contact)[1] && (
                                  <td key={`${i}-${j}`}>
                                    {detail.data.contact
                                      ? formatResult(detail.data.contact[item])
                                      : formatResult(null)}
                                  </td>
                                )
                            )}
                        </React.Fragment>
                      ))}

                      {/* Education rows */}
                      {tableHeadNames.education.map((education, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {education.visible &&
                            Object.keys(education).map(
                              (item, j) =>
                                item !== Object.keys(education)[1] && (
                                  <td key={`${i}-${j}`}>
                                    {detail.data.education
                                      ? formatResult(
                                          detail.data.education[item]
                                        )
                                      : formatResult(null)}
                                  </td>
                                )
                            )}
                        </React.Fragment>
                      ))}

                      {/* Employment rows */}
                      {tableHeadNames.employment.map((employment, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {employment.visible &&
                            Object.keys(employment).map((item, j) => {
                              return (
                                item !== Object.keys(employment)[1] && (
                                  <td key={`${i}-${j}`}>
                                    {detail.data.employment
                                      ? formatResult(
                                          detail.data.employment[item]
                                        )
                                      : formatResult(null)}
                                  </td>
                                )
                              );
                            })}
                        </React.Fragment>
                      ))}

                      {/* Langauges rows */}
                      {isAtLeastOneVisible(tableHeadNames.languages) && (
                        <td>
                          <Table border={1}>
                            <TableBody>
                              {detail.data.languages.length === 0 ? (
                                <>
                                  {languagesForDisplayWhenEmpty.map(
                                    (memberLanguage, k) => {
                                      return (
                                        <StyledTableRow key={`${i}-${k}`}>
                                          {tableHeadNames.languages.map(
                                            (language) =>
                                              language.visible &&
                                              Object.keys(language).map(
                                                (item, j) => {
                                                  return (
                                                    item !==
                                                      Object.keys(
                                                        language
                                                      )[1] && (
                                                      <td
                                                        key={`${i}-${k}-${j}`}
                                                        style={{
                                                          minWidth: "120px",
                                                        }}
                                                      >
                                                        {memberLanguage
                                                          ? formatResult(
                                                              memberLanguage[
                                                                item
                                                              ]
                                                            )
                                                          : formatResult(null)}
                                                      </td>
                                                    )
                                                  );
                                                }
                                              )
                                          )}
                                        </StyledTableRow>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <>
                                  {detail.data.languages.map(
                                    (memberLanguage, k) => {
                                      return (
                                        <tr key={`${i}-${k}`}>
                                          {tableHeadNames.languages.map(
                                            (language) =>
                                              language.visible &&
                                              Object.keys(language).map(
                                                (item, j) => {
                                                  return (
                                                    item !==
                                                      Object.keys(
                                                        language
                                                      )[1] && (
                                                      <td
                                                        key={`${i}-${k}-${j}`}
                                                        style={{
                                                          minWidth: "120px",
                                                        }}
                                                      >
                                                        {memberLanguage
                                                          ? formatResult(
                                                              memberLanguage[
                                                                item
                                                              ]
                                                            )
                                                          : formatResult(null)}
                                                      </td>
                                                    )
                                                  );
                                                }
                                              )
                                          )}
                                        </tr>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </td>
                      )}

                      {/* Other data rows */}
                      {tableHeadNames.otherData.map((otherData, k) => (
                        <React.Fragment key={`${i}-${k}`}>
                          {otherData.visible &&
                            Object.keys(otherData).map((item, j) => {
                              return (
                                item !== Object.keys(otherData)[1] && (
                                  <td key={`${i}-${j}`}>
                                    {detail.data.otherData
                                      ? formatResult(
                                          detail.data.otherData[item]
                                        )
                                      : formatResult(null)}
                                  </td>
                                )
                              );
                            })}
                        </React.Fragment>
                      ))}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Fade>
          )}
        </Table>
      </TableContainer>

      {isMobile && (
        <Grid container mb={6}>
          <Grid item xs={12}>
            <TablePagination
              totalCountMembers={totalCountMembersPagination}
              page={page}
              handleChangePage={handleChangePage}
            />
          </Grid>
          <Grid item xs={12} style={{ display: "flex" }}>
            <Typography>{t("memberTablePage.rowsPerPage")}</Typography>
            <select
              style={{ outline: "none", border: "none", width: "40px" }}
              className="table_select_rowsPerPage"
              value={numberOfResultsPerPage}
              onChange={(e: any) => {
                onChangePageSize(e.target.value);
              }}
            >
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
            </select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{resultPerPageLabel}</Typography>
          </Grid>
        </Grid>
      )}
      {isDesktop && (
        <Grid
          item
          md={12}
          xs={12}
          style={{
            display: "flex",
            float: "right",
            border: "none",
            marginTop: "2rem",
          }}
          mb={6}
        >
          <Grid item md={3} style={{ display: "flex" }}>
            <Typography>{t("memberTablePage.rowsPerPage")}</Typography>
          </Grid>
          <Grid item md={3} xs={12} ml={1}>
            <select
              className={classes.select}
              value={numberOfResultsPerPage}
              onChange={(e: any) => {
                onChangePageSize(e.target.value);
              }}
            >
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
            </select>
          </Grid>

          <Grid item md={3} ml={1} xs={12}>
            <Typography variant="body1">{resultPerPageLabel}</Typography>
          </Grid>
          <Grid item md={3} xs={12} style={{ marginTop: "-5px" }} ml={1}>
            <TablePagination
              totalCountMembers={totalCountMembersPagination}
              page={page}
              handleChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}
