import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { APIStatus } from "../../models/types/api/APIStatus";
import {
  getMemberAgeStatistic,
  getMemberGenderStatistic,
  selectMemberAgeStatistic,
  selectMemberGenderStatistic,
} from "../../slices/members/memberSlice";
import ChartWrapper from "../charts/ChartWrapper";
import ChartMemberAgeStatistic from "../charts/memberAgeStatistic/ChartMemberAgeStatistic";
import ChartMemberGenderStatistic from "../charts/memberGenderStatistic/ChartMemberGenderStatistic";

export default function ChartsStatistic() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: memberAgeStatistic, status: memberAgeStatisticStatus } =
    useAppSelector(selectMemberAgeStatistic);

  const { data: memberGenderStatistic, status: memberGenderStatisticStatus } =
    useAppSelector(selectMemberGenderStatistic);

  useEffect(() => {
    dispatch(getMemberAgeStatistic());
    dispatch(getMemberGenderStatistic());
  }, []);

  return (
    <>
      <Container>
        <Grid container flexDirection={"row"}>
          {/* 1. Member Age Statistic */}
          <ChartWrapper
            title={t("chart.ageStatistic.title")}
            status={memberAgeStatisticStatus}
            element={
              memberAgeStatistic &&
              memberAgeStatisticStatus === APIStatus.FULFILLED ? (
                <ChartMemberAgeStatistic
                  memberAgeStatistic={memberAgeStatistic}
                />
              ) : (
                <></>
              )
            }
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />

          {/* 2. Member Gender Statistic */}
          <ChartWrapper
            title={t("chart.genderStatistic.title")}
            status={memberGenderStatisticStatus}
            element={
              memberGenderStatistic &&
              memberGenderStatisticStatus === APIStatus.FULFILLED ? (
                <ChartMemberGenderStatistic
                  memberGenderStatistic={memberGenderStatistic}
                />
              ) : (
                <></>
              )
            }
            style={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        </Grid>
      </Container>
    </>
  );
}
