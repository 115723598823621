export enum Role {
  SuperUser = "SuperUser",
  Administrator = "Administrator",
  User = "User",
  Owner = "Owner"
}

export enum RoleOrdinal {
  SuperUser = 2,
  Administrator = 3,
  User = 4,
  Owner = 5
}
