import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { selectDisplayLanguage } from "../../slices/displayLanguages/displayLanguageSlice";
import { SyntheticEvent, useEffect, useState } from "react";
import {
  getAllDonations,
  saveDonation,
} from "../../slices/donations/donationSlice";
import { Donated } from "../../models/donations/donated";
import {
  Box,
  Grid,
  Tab,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useTabSyles } from "./useTabStyles";
import AppButton from "../../component/button/AppButton";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { DonationFormType } from "./DonationTable";
import { Donation } from "../../models/donations/donation";
import { EmptyGuid } from "../smart-membership/initialData";
import { DonationSaveModal } from "./DonationSaveModal";
import donation_person from "../../images/donation_person.png";
import donation_business from "../../images/donation_business.png";
import donation_to_business from "../../images/donation_to_business.png";

enum DonationsCardTabs {
  ToAssociationFromIndividuals = "toAssociationFromIndividuals",
  ToAssociationFromLegalEntities = "toAssociationFromLegalEntities",
  FromAssociation = "fromAssociation",
}

type DonationsTabsProps = {
  tab1: JSX.Element | null;
  tab2: JSX.Element | null;
  tab3: JSX.Element | null;
};

const initialDonation: Donation = {
  id: EmptyGuid,
  donated: Donated.ToAssociationFromIndividuals,
  firstName: "",
  lastName: "",
  phone: "",
  amount: 0,
  donatedDate: "",
  purpose: "",
  isBusiness: false,
  companyName: "",
  companyPib: "",
  memberId: null,
};

export default function DashboardDonationTabs(props: DonationsTabsProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const displayLanguage = useAppSelector(selectDisplayLanguage);
  const tabStyles = useTabSyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [value, setValue] = useState<DonationsCardTabs>(
    DonationsCardTabs.ToAssociationFromIndividuals
  );
  const [donationFormType, setDonationFormType] = useState<DonationFormType>(
    DonationFormType.None
  );
  const [donation, setDonation] = useState<Donation>({
    ...initialDonation,
  });

  const handleChange = (event: SyntheticEvent, newValue: DonationsCardTabs) => {
    setValue(newValue);
  };

  const getDonations = () => {
    dispatch(
      getAllDonations(
        value === DonationsCardTabs.ToAssociationFromIndividuals
          ? Donated.ToAssociationFromIndividuals
          : value === DonationsCardTabs.ToAssociationFromLegalEntities
          ? Donated.ToAssociationFromLegalEntities
          : Donated.FromAssociation
      )
    );
  };

  const onSave = async () => {
    const response = await dispatch(saveDonation(donation));

    const result = response.payload as Donation;

    if (!result) {
      return;
    }

    setDonation({ ...donation, id: result.id });
  };

  const onOpenModal = () => {
    setDonationFormType(DonationFormType.Create);
  };

  useEffect(() => {
    getDonations();
  }, [value]);

  // Refetch on change display language
  useEffect(() => {
    if (!displayLanguage) {
      return;
    }

    getDonations();
  }, [displayLanguage]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: "100%",
            typography: "body2",
            height: 280,
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className={tabStyles.classes.container}>
                <TabList onChange={handleChange}>
                  <Tab
                    label={
                      <Tooltip
                        title={t(
                          "donationTabs." +
                            DonationsCardTabs.ToAssociationFromIndividuals
                        )}
                        arrow
                        placement="top"
                      >
                        <Box component="span">
                          <img
                            src={donation_person}
                            alt="Image"
                            style={{
                              width: "24px",
                              height: "auto",
                              marginRight: "8px",
                            }}
                          />
                        </Box>
                      </Tooltip>
                    }
                    value={DonationsCardTabs.ToAssociationFromIndividuals}
                    sx={{
                      textTransform: "none",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  />

                  <Tab
                    label={
                      <Tooltip
                        title={t(
                          "donationTabs." +
                            DonationsCardTabs.ToAssociationFromLegalEntities
                        )}
                        arrow
                        placement="top"
                      >
                        <Box component="span">
                          <img
                            src={donation_business}
                            alt="Image"
                            style={{
                              width: "24px",
                              height: "auto",
                              marginRight: "8px",
                            }}
                          />
                        </Box>
                      </Tooltip>
                    }
                    value={DonationsCardTabs.ToAssociationFromLegalEntities}
                    sx={{
                      textTransform: "none",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  />

                  <Tab
                    label={
                      <Tooltip
                        title={t(
                          "donationTabs." + DonationsCardTabs.FromAssociation
                        )}
                        arrow
                        placement="top"
                      >
                        <Box component="span">
                          <img
                            src={donation_to_business}
                            alt="Image"
                            style={{
                              width: "24px",
                              height: "auto",
                              marginRight: "8px",
                            }}
                          />
                        </Box>
                      </Tooltip>
                    }
                    value={DonationsCardTabs.FromAssociation}
                    sx={{
                      textTransform: "none",
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                    }}
                  />
                </TabList>
                <div className={tabStyles.classes.buttonContainer}>
                  {!isMobile && (
                    <Typography
                      variant="body1"
                      mr={2}
                      mt={1}
                      textAlign="start"
                      fontWeight="bold"
                      overflow="hidden"
                    >
                      {t("common.donations")}
                    </Typography>
                  )}
                  <Grid item display="flex">
                    <AppButton
                      color={theme.palette.error.dark}
                      hover={theme.palette.error.dark}
                      label=""
                      onClick={onOpenModal}
                      startIcon={
                        <VolunteerActivismIcon style={{ fontSize: "20px" }} />
                      }
                      tooltipTitle={t("donationTable.createDonation")}
                    />
                  </Grid>
                </div>
              </div>
            </Box>

            <TabPanel
              value={DonationsCardTabs.ToAssociationFromIndividuals}
              sx={{
                p: 0,
                pb: 3,
                maxHeight: "100%",
                overflow: "auto",
              }}
            >
              {props.tab1}
            </TabPanel>
            <TabPanel
              value={DonationsCardTabs.ToAssociationFromLegalEntities}
              sx={{
                p: 0,
                pb: 3,
                maxHeight: "100%",
                overflow: "auto",
              }}
            >
              {props.tab2}
            </TabPanel>
            <TabPanel
              value={DonationsCardTabs.FromAssociation}
              sx={{
                p: 0,
                pb: 3,
                maxHeight: "100%",
                overflow: "auto",
              }}
            >
              {props.tab3}
            </TabPanel>
          </TabContext>
        </Box>
        <DonationSaveModal
          donationFormType={donationFormType}
          setDonationFormType={setDonationFormType}
          onSave={onSave}
          donation={donation}
          setDonation={setDonation}
        />
      </ThemeProvider>
    </>
  );
}
