import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  useTheme,
} from "@mui/material";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import PredefinedReportSureVotesModal from "../modals/predefinedReportSureVotesModal/PredefinedReportSureVotesModal";
import SetReportHeaderTitleModal from "../modals/setReportHeaderTitleModal/SetReportHeaderTitleModal";
import { ReportsDisabledButtons } from "../TableContainer";
import ButtonWithTooltip from "./ButtonWithTooltip";
import { useReportsButtonsStyle } from "./reportsButtonsStyle";
import styled, { keyframes } from "styled-components";
import { useAppSelector } from "../../../app/api/hooks";
import {
  selectReportStatus,
  selectReportType,
} from "../../../slices/reports/reportSlice";

type Props = {
  classes: any;
  excelDefault: any;
  pdfDefault: any;
  excelCity: any;
  pdfCity: any;
  reportsDisabledButtons: ReportsDisabledButtons;

  excelLocalCommunity: any;
  pdfLocalCommunity: any;
  pdfElectionPlace: any;
  excelElectionPlace: any;

  reportHeaderLabel: string;
  setReportHeaderLabel: Dispatch<SetStateAction<string>>;
};

const growAnimation = keyframes`
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.2);
  }

  80% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
`;

const GrowTransition = styled(Transition)`
  &.Mui-enter {
    animation: ${growAnimation} 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &.Mui-exit {
    animation: ${growAnimation} 300ms cubic-bezier(0.6, -0.28, 0.735, 0.045)
      reverse;
  }
`;

const ReportsIconsWithModals = (props: Props) => {
  const theme = useTheme();
  const { classes } = useReportsButtonsStyle();
  const [showComponent, setShowComponent] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const reportStatus = useAppSelector(selectReportStatus);
  const reportType = useAppSelector(selectReportType);

  const [isOpenSetReportHeaderTitleModal, setIsOpenSetReportHeaderTitleModal] =
    useState<boolean>(false);

  const handleModal = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const handleOpenSetReportHeaderTitleModal = () => {
    setIsOpenSetReportHeaderTitleModal(true);

    // Todo:
    // props.handleGetPdfReportCurrentData
  };

  useEffect(() => {
    if (reportStatus === "Fulfilled" && reportType === 0) {
      setIsOpenSetReportHeaderTitleModal(false);
      props.setReportHeaderLabel("");
    }
  }, [reportStatus, reportType]);

  return (
    <Grid container spacing={1}>
      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={
              <ButtonWithTooltip
                infoText={t("tooltips.predefinedReports")}
                onSubmit={handleModal}
                contentIcon={<FolderCopyIcon fontSize="inherit" />}
                color={theme.palette.primary.main}
              />
            }
            label=""
          />
        </FormGroup>
      </Grid>

      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={
              <ButtonWithTooltip
                infoText={t("memberTablePage.exportPdf")}
                contentIcon={<PictureAsPdfIcon fontSize="inherit" />}
                onSubmit={handleOpenSetReportHeaderTitleModal}
                color={"red"}
              />
            }
            label=""
          />
        </FormGroup>
      </Grid>

      <Grid item>
        <FormGroup>
          <FormControlLabel
            control={
              <ButtonWithTooltip
                infoText={t("memberTablePage.exportExcel")}
                contentIcon={
                  reportStatus === "Pending" && reportType === 1 ? (
                    <CircularProgress className={classes.circularProgress} />
                  ) : (
                    <TableViewIcon fontSize="inherit" />
                  )
                }
                onSubmit={props.excelDefault}
                color={
                  reportStatus === "Pending" && reportType === 1
                    ? "disabled"
                    : "green"
                }
                disabled={reportStatus === "Pending" && reportType === 1}
                reportStatus={reportStatus}
              />
            }
            label=""
          />
        </FormGroup>
      </Grid>
      <hr className={classes.divider} />

      <PredefinedReportSureVotesModal
        excelDefault={props.excelCity}
        pdfDefault={props.pdfCity}
        excelLocalCommunity={props.excelLocalCommunity}
        pdfLocalCommunity={props.pdfLocalCommunity}
        pdfElectionPlace={props.pdfElectionPlace}
        excelElectionPlace={props.excelElectionPlace}
        reportsDisabledButtons={props.reportsDisabledButtons}
        open={isOpen}
        closeModal={() => setIsOpen(false)}
        sx={12}
      />

      <SetReportHeaderTitleModal
        reportType={reportType}
        reportStatus={reportStatus}
        isOpen={isOpenSetReportHeaderTitleModal}
        onClose={() => setIsOpenSetReportHeaderTitleModal(false)}
        reportHeaderLabel={props.reportHeaderLabel}
        setReportHeaderLabel={props.setReportHeaderLabel}
        onSubmit={props.pdfDefault}
      />
    </Grid>
  );
};

export default ReportsIconsWithModals;
