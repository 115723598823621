import { MembershipRoute } from "../../models/app/router/appRoute";
import { RoleOrdinal } from "../../models/enums/roles/roles";
import LoginPage from "../../pages/LoginPage";
import NotFoundPage from "../../pages/NotFoundPage";
import SmartMembershipPage from "../../pages/SmartMembershipPage";
import StatisticsPage from "../../pages/StatisticsPage";
import TablePage from "../../pages/TablePage";
import UserManagementPage from "../../pages/UserManagementPage";
import DashboardPage from "../../pages/DashboardPage";
import MemberCardsPage from "../../pages/MemberCardsPage";
import SyncAddressesPage from "../../pages/SyncAddressesPage";

export const routes: MembershipRoute = {
  Login: {
    pathname: "/",
    authorize: false,
    element: <LoginPage />,
    inHeader: false,
    titleI18n: "",
  },
  Dashboard: {
    pathname: "/dashboard",
    authorize: true,
    element: <DashboardPage />,
    inHeader: true,
    titleI18n: "header.dashboardTitle",
  },
  MemberCards: {
    pathname: "/members",
    authorize: true,
    element: <MemberCardsPage />,
    inHeader: true,
    titleI18n: "header.memberCards",
  },
  MemberTable: {
    pathname: "/member-table",
    authorize: true,
    element: <TablePage />,
    inHeader: true,
    titleI18n: "header.memberTableTitle",
  },
  MemberManagement: {
    pathname: "/member-management",
    authorize: true,
    element: <SmartMembershipPage />,
    inHeader: true,
    titleI18n: "header.smartMembershipTitle",
  },
  Statistics: {
    pathname: "/statistics",
    authorize: true,
    element: <StatisticsPage />,
    inHeader: true,
    titleI18n: "header.statisticTitle",
    forbiddenRoles: [RoleOrdinal.User],
  },
  UserManagement: {
    pathname: "/user-management",
    authorize: true,
    element: <UserManagementPage />,
    inHeader: true,
    titleI18n: "header.userManagementTitle",
    forbiddenRoles: [RoleOrdinal.User],
  },
  SyncAddresses: {
    pathname: "/sync-addresses",
    authorize: true,
    element: <SyncAddressesPage />,
    inHeader: true,
    titleI18n: "header.syncAddressesTitle",
    // forbiddenRoles: [RoleOrdinal.User],
  },
  // CapillarVotes: {
  //   pathname: "http://capillary-votes.easymembership.net/dashboard",
  //   authorize: true,
  //   // element: <SyncAddressesPage />,
  //   inHeader: true,
  //   titleI18n: "header.capillarVotesTitle",
  //   // forbiddenRoles: [RoleOrdinal.User],
  // },
  // SyncAddresses: {
  //   pathname: "/sync-addresses",
  //   authorize: true,
  //   element: <SyncAddressesPage />,
  //   inHeader: true,
  //   titleI18n: "header.syncAddressesTitle",
  //   // forbiddenRoles: [RoleOrdinal.User],
  // },
  NotFound: {
    pathname: "*",
    authorize: false,
    element: <NotFoundPage />,
    inHeader: false,
    titleI18n: "header.notFoundTitle",
  },
};
