import { createTheme } from "@mui/material";
import { useAppSelector } from "../../app/api/hooks";
import { selectThemeMode } from "../../slices/theme/themeSlice";
import "@fontsource/manrope/500.css";

const appPalette = {
  "color-primary-100": "#F0FAFA",
  "color-primary-200": "#E2F4F5",
  "color-primary-300": "#C9DFE3",
  "color-primary-400": "#ABC2C8",
  "color-primary-500": "#849BA4",
  "color-primary-600": "#607D8D",
  "color-primary-700": "#426076",
  "color-primary-800": "#2A455F",
  "color-primary-900": "#19314E",

  "color-success-100": "#D9FBD8",
  "color-success-200": "#B3F7B8",
  "color-success-300": "#88E998",
  "color-success-400": "#65D383",
  "color-success-500": "#38B767",
  "color-success-600": "#289D5F",
  "color-success-700": "#1C8357",
  "color-success-800": "#116A4C",
  "color-success-900": "#0A5745",

  "color-info-100": "#CCF9FE",
  "color-info-200": "#99EEFE",
  "color-info-300": "#66DBFE",
  "color-info-400": "#40C6FD",
  "color-info-500": "#02A4FC",
  "color-info-600": "#017FD8",
  "color-info-700": "#015FB5",
  "color-info-800": "#004392",
  "color-info-900": "#003078",

  "color-warning-100": "#FEFCD6",
  "color-warning-200": "#FEF8AE",
  "color-warning-300": "#FDF286",
  "color-warning-400": "#FBEC67",
  "color-warning-500": "#F9E336",
  "color-warning-600": "#D6C027",
  "color-warning-700": "#B39E1B",
  "color-warning-800": "#907D11",
  "color-warning-900": "#77660A",

  "color-danger-100": "#FFECD9",
  "color-danger-200": "#FFD3B3",
  "color-danger-300": "#FFB58D",
  "color-danger-400": "#FF9871",
  "color-danger-500": "#FF6842",
  "color-danger-600": "#DB4530",
  "color-danger-700": "#B72921",
  "color-danger-800": "#931517",
  "color-danger-900": "#7A0C17",

  "file-100": "#FDFCF4",
  "file-200": "#FBF8EA",
  "file-300": "#F5F0DC",
  "file-400": "#EBE4CD",
  "file-500": "#DFD5B9",
  "file-600": "#BFAF87",
  "file-700": "#A08B5D",
  "file-800": "#81693B",
  "file-900": "#6B5023",

  "section-title-100": "#F1F5F6",
  "section-title-200": "#E3EAEE",
  "section-title-300": "#BFC7CD",
  "section-title-400": "#8E959B",
  "section-title-500": "#4F5459",
  "section-title-600": "#39424C",
  "section-title-700": "#273140",
  "section-title-800": "#192233",
  "section-title-900": "#0F172A",

  "field-100": "#ffff",
  "field-900": "#2f4454",
};

export default function useAppTheme() {
  const themeMode = useAppSelector(selectThemeMode);

  const theme = createTheme({
    palette: {
      mode: themeMode,
      ...(themeMode === "light"
        ? {
            primary: {
              main: appPalette["color-primary-500"],
              dark: appPalette["color-primary-900"],
              light: appPalette["field-100"],
            },
            success: {
              main: appPalette["color-success-500"],
            },
            info: {
              main: appPalette["color-info-500"],
              dark: appPalette["section-title-900"],
              light: appPalette["section-title-500"],
            },
            warning: {
              main: appPalette["color-warning-500"],
            },
            error: {
              main: appPalette["color-danger-600"],
            },
            background: {
              paper: appPalette["color-primary-100"],
              default: appPalette["color-primary-100"],
              // dark: appPalette["color-primary-200"],
            },
          }
        : {
            primary: {
              main: appPalette["color-primary-400"],
              dark: appPalette["color-primary-200"],
              light: appPalette["field-900"],
            },
            success: {
              main: appPalette["color-success-400"],
            },
            info: {
              main: appPalette["color-info-400"],
              dark: appPalette["section-title-100"],
              light: appPalette["section-title-200"],
            },
            warning: {
              main: appPalette["color-warning-400"],
            },
            error: {
              main: appPalette["color-danger-600"],
            },
            background: {
              paper: appPalette["color-primary-900"],
              default: appPalette["color-primary-900"],
              // dark: appPalette["color-primary-800"],
            },
          }),
    },
    shape: {
      borderRadius: 4,
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "20px",
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "::-webkit-scrollbar": {
            width: "12px",
          },
          "::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.25)",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: `${
              themeMode === "light"
                ? appPalette["color-primary-500"]
                : appPalette["color-primary-700"]
            }`,
            borderRadius: "10px",
          },
          "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: `${
              themeMode === "light"
                ? appPalette["color-primary-600"]
                : appPalette["color-primary-800"]
            }`,
            borderRadius: "10px",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          ...(themeMode === "light"
            ? {
                root: {
                  backgroundColor: appPalette["color-primary-100"],
                  color: appPalette["color-primary-200"],
                  "&.Mui-selected": {
                    backgroundColor: appPalette["color-primary-200"],
                    color: appPalette["color-primary-900"],
                  },
                },
              }
            : {
                root: {
                  backgroundColor: appPalette["color-primary-900"],
                  color: appPalette["color-primary-200"],

                  "&.Mui-selected": {
                    backgroundColor: appPalette["color-primary-800"],
                    color: appPalette["color-primary-500"],
                  },
                },
              }),
        },
      },
      MuiTableHead: {
        styleOverrides: {
          ...(themeMode === "light"
            ? {
                root: {
                  backgroundColor: appPalette["color-primary-200"],
                  color: appPalette["color-primary-900"],
                },
              }
            : {
                root: {
                  backgroundColor: appPalette["color-primary-800"],
                  color: appPalette["color-primary-500"],
                },
              }),
        },
      },
      MuiTableBody: {
        styleOverrides: {
          ...(themeMode === "light"
            ? {
                root: {
                  ">.MuiTableCell-root MuiTableCell-head": {
                    th: {
                      borderStyle: "hidden !important",
                      fontSize: "0.8rem !important",
                      fontWeight: "100",
                    },
                  },
                  "> .MuiTableRow-root": {
                    borderBottom: `1px solid ${appPalette["color-primary-500"]} !important`,
                    td: {
                      borderStyle: "hidden !important",
                      fontSize: "0.8rem !important",
                    },
                    th: {
                      borderStyle: "hidden !important",
                      fontSize: "0.8rem !important",
                    },
                  },
                },
              }
            : {}),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          ...(themeMode === "light"
            ? {
                tooltip: {
                  backgroundColor: `${appPalette["color-primary-500"]} !important`, // appPalette["color-primary-500"],
                  color: "white !important",
                  fontSize: "14px !important",
                  border: `1px solid ${appPalette["color-primary-500"]}`,
                  borderRadius: "10px !important",
                },
              }
            : {
                tooltip: {
                  backgroundColor: `${appPalette["color-primary-300"]} !important`,
                  color: `${appPalette["color-primary-700"]} !important`,
                  fontSize: "14px !important",
                  border: `1px solid ${appPalette["color-primary-300"]}`,
                  borderRadius: "10px !important",
                },
              }),
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem !important",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
            ul: {
              paddingTop: 0,
              paddingBootom: 0,
              paddingLeft: "5px",
              paddingRight: "5px",
            },
            li: {
              paddingTop: 0,
              paddingBootom: 0,
              paddingLeft: "5px",
              paddingRight: "5px",
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            button: {
              p: {
                svg: {
                  width: "35px !important",

                  height: "35px !important",
                },
              },
            },
          },
        },
      },
    },

    typography: {
      fontFamily: "Arial, sans-serif",

      fontSize: 16,

      body1: {
        fontSize: "0.8rem",
      },

      body2: {
        fontSize: "0.8rem",
      },

      h4: {
        fontSize: "1.5rem",
        fontWeight: "bold",
      },

      h5: {
        fontSize: "1.2rem",
        fontWeight: "bold",
      },
      h6: {
        fontSize: "1.1rem",
        fontWeight: "bold",
      },
    },
  });

  return { theme };
}
