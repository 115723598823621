import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../app/theme/colors";

const useDetailExcelFileModalStyle = makeStyles()(() =>
({
  titleWrapper: {
    cursor: "pointer",
    background: colors.primary,
  },
  title: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  inputBase: {
    marginLeft: "1rem!important",
    flex: 1,
    fontSize: "inherit!important",
    backgroundColor: "#fff!important",
    borderRadius: "5px!important",
    boxShadow: "0px 3px 7px 0px rgba(0, 0, 0, 0.2)!important",
  },
  successBtn: {
    marginTop: "10px",
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#8dc741",
    "&:hover": {
      background: colors.primary
    },
  },
  dangerBtn: {
    marginTop: "10px",
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: "#e76b6b",
    marginLeft: "10px",
    "&:hover": {
      background: colors.primary,
      color: "white"
    },
  },
  generalWrapper: {
    padding: "25px",
    borderRadius: "5px",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.2)",
  },
  headerWrapper: {
    padding: "25px",
    borderRadius: "5px",
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.2)",
    marginTop: "20px",
  },
  buttonGrid: {
    textAlign: "center",
    marginTop: "10px",
  },
  mt10: {
    marginTop: "10px!important",
  },
  textCenter: { textAlign: "center" },
})
);

export default useDetailExcelFileModalStyle;