import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useAddressSectionStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { color: theme.palette.info.dark, textAlign: "start" },
  input: {
    borderRadius: "5px",
    height: "2rem",
    backgroundColor: "transparent",
  },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
    },

    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "2rem",
    },
    backgroundColor: "transparent",
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: theme.palette.info.dark,
      },
    },
  },
  card: {
    boxShadow: "0 0 10px rgb(0 0 0 / 0.3)",
    borderRadius: "5px",
  },
  sectionTitle: {
    textAlign: "start",
    color: theme.palette.info.dark,
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  sectionTitleErrors: {
    textAlign: "start",
    color: theme.palette.error.main,
    textTransform: "uppercase",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  inputContainer: {
    marginBottom: "1rem",
  },
  checkboxLabel: {
    textAlign: "start",
    "& .MuiFormControlLabel-label": {
      color: theme.palette.info.dark,
    },
    "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
      fontSize: "0.9rem",
    },
  },
  checkbox: {
    color: theme.palette.info.dark,
    "&.Mui-checked": {
      color: theme.palette.info.dark,
      "& svg": {
        color: theme.palette.info.dark,
      },
    },
  },
  button: {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: theme.palette.primary.main,
    marginBottom: "5px",
    width: "147px",
    height: "35px",
    "&:hover": {
      background: "transparent",
      color: theme.palette.primary.light,
    },
    "&:disabled": {
      boxShadow: "none",
    },
  },
  buttonGreen: {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: theme.palette.success.main,
    marginBottom: "5px",
    fontWeight: "bold",
    "&:hover": {
      background: "transparent",
      color: theme.palette.primary.light,
    },
    "&:disabled": {
      boxShadow: "none",
    },
    ".MuiButton-startIcon": {
      marginLeft: "10px",
    },
    ".MuiTypography-root": {
      marginRight: "10px",
    },
  },
  tooltip: {
    "& .MuiTooltip-label": {
      width: "500px",
      maxWidth: "500px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  circularProgress: {
    color: theme.palette.primary.main,
    display: "block",
    margin: "auto",
  },
}));
