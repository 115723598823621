import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useResetPasswordOnFirstLoginModalStyles = makeStyles()(
  (theme: Theme) => ({
    passwordValidationWrapper: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      marginTop: "20px",
    },
    passwordValidationSuccessIcon: {
      color: colors.green,
    },
    passwordValidationErrorIcon: {
      color: colors.red,
    },
    passwordValidationSuccess: {
      color: colors.green,
      marginLeft: "5px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "15px",
      lineHeight: "17px",
    },
    passwordValidationError: {
      color: colors.red,
      marginLeft: "5px",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "15px",
      lineHeight: "17px",
    },
  })
);
