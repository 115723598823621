import { Grid } from "@mui/material";
import Login from "../features/login/Login";
import backgraundImage from "../images/login_texture2.png";

export default function LoginPage() {
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        backgroundImage: `url(${backgraundImage})`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        background: "#19314E",
        height: "100vh",
        margin: 0,
        padding: 0,
      }}
    >
      <Login />
    </Grid>
  );
}
