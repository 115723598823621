import * as React from "react";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTooltip from "../../component/tooltip/CustomTooltip";
import { useEffect, useState } from "react";
import { useStatisticsStyles } from "./useStatisticsStyles";
import ChartsStatistic from "./ChartsStatistic";

interface Props {
  statisticDetails: any;
}
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Statistic({ statisticDetails }: Props) {
  const { t } = useTranslation();
  const { classes } = useStatisticsStyles();
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const [copied, setCopied] = useState(0);
  const [text, setText] = useState("");

  const handleCopy = (e: any) => {
    const id = e.target.id;
    switch (id) {
      case "from21to30":
        setText(statisticDetails?.from21to30);
        navigator.clipboard.writeText(text);
        setCopied(1);
        break;
      case "from31to40":
        setText(statisticDetails?.from31to40);
        navigator.clipboard.writeText(text);
        setCopied(2);
        break;
      case "from41to50":
        setText(statisticDetails?.from41to50);
        navigator.clipboard.writeText(text);
        setCopied(3);
        break;
      case "from51to60":
        setText(statisticDetails?.from51to60);
        navigator.clipboard.writeText(text);
        setCopied(4);
        break;
      case "from61to70":
        setText(statisticDetails?.from61to70);
        navigator.clipboard.writeText(text);
        setCopied(5);
        break;
      case "femaleMembers":
        setText(statisticDetails?.femaleMembers);
        navigator.clipboard.writeText(text);
        setCopied(6);
        break;
      case "maleMembers":
        setText(statisticDetails?.maleMembers);
        navigator.clipboard.writeText(text);
        setCopied(7);
    }
  };

  useEffect(() => {
    let timeoutId: number;
    if (text) {
      navigator.clipboard.writeText(text);
      timeoutId = window.setTimeout(() => {
        setCopied(0);
      }, 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [text]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }}>
        <AppBar position="static">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={t("statisticPage.ageStatistic")} {...a11yProps(0)} />
            <Tab
              label={t("statisticPage.statisticByCity")}
              {...a11yProps(1)}
              disabled
            />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0} dir={theme.direction}>
          <Card>
            <Box style={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <ChartsStatistic />
            </Box>

            <>
              <Grid container>
                <Grid item md={6} sm={12} xs={12}>
                  <CardHeader
                    title={t("statisticPage.ageOfMembers")}
                    style={{ textAlign: "center" }}
                  />
                  <CardContent>
                    <Grid item md={12} display="flex" justifyContent={"center"}>
                      <Typography
                        className={classes.typography}
                        id="from21to30"
                        variant="body1"
                        onClick={(e: any) => handleCopy(e)}
                      >
                        {statisticDetails?.from21to30}
                      </Typography>
                      <CustomTooltip
                        infoText={t("tooltips.tooltipsStatistic")}
                        infoTitle={t("tooltips.tooltipTitle")}
                      />
                      {copied === 1 && (
                        <Alert className={classes.alert} severity="success">
                          {t("alerts.statisticPageAlert")}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item md={12} display="flex" justifyContent={"center"}>
                      <Typography
                        className={classes.typography}
                        id="from31to40"
                        variant="body1"
                        onClick={(e: any) => handleCopy(e)}
                      >
                        {statisticDetails?.from31to40}
                      </Typography>
                      <CustomTooltip
                        infoText={t("tooltips.tooltipsStatistic")}
                        infoTitle={t("tooltips.tooltipTitle")}
                      />
                      {copied === 2 && (
                        <Alert className={classes.alert} severity="success">
                          {t("alerts.statisticPageAlert")}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item md={12} display="flex" justifyContent={"center"}>
                      <Typography
                        className={classes.typography}
                        id="from41to50"
                        variant="body1"
                        onClick={handleCopy}
                      >
                        {statisticDetails?.from41to50}
                      </Typography>
                      <CustomTooltip
                        infoText={t("tooltips.tooltipsStatistic")}
                        infoTitle={t("tooltips.tooltipTitle")}
                      />
                      {copied === 3 && (
                        <Alert className={classes.alert} severity="success">
                          {t("alerts.statisticPageAlert")}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item md={12} display="flex" justifyContent={"center"}>
                      <Typography
                        className={classes.typography}
                        id="from51to60"
                        variant="body1"
                        onClick={handleCopy}
                      >
                        {statisticDetails?.from51to60}
                      </Typography>
                      <CustomTooltip
                        infoText={t("tooltips.tooltipsStatistic")}
                        infoTitle={t("tooltips.tooltipTitle")}
                      />
                      {copied === 4 && (
                        <Alert className={classes.alert} severity="success">
                          {t("alerts.statisticPageAlert")}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item md={12} display="flex" justifyContent={"center"}>
                      <Typography
                        className={classes.typography}
                        id="from61to70"
                        variant="body1"
                        onClick={handleCopy}
                      >
                        {statisticDetails?.from61to70}
                      </Typography>
                      <CustomTooltip
                        infoText={t("tooltips.tooltipsStatistic")}
                        infoTitle={t("tooltips.tooltipTitle")}
                      />
                      {copied === 5 && (
                        <Alert className={classes.alert} severity="success">
                          {t("alerts.statisticPageAlert")}
                        </Alert>
                      )}
                    </Grid>
                  </CardContent>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                  <CardHeader
                    title={
                      t("statisticPage.totalMembers") +
                      statisticDetails?.membersCount
                    }
                    style={{ textAlign: "center" }}
                  />
                  <CardContent>
                    <Grid item md={12} display="flex" justifyContent={"center"}>
                      <Typography
                        className={classes.typography}
                        id="femaleMembers"
                        variant="body1"
                        onClick={(e: any) => handleCopy(e)}
                      >
                        {statisticDetails?.femaleMembers}
                      </Typography>
                      <CustomTooltip
                        infoText={t("tooltips.tooltipsStatistic")}
                        infoTitle={t("tooltips.tooltipTitle")}
                      />
                      {copied === 6 && (
                        <Alert className={classes.alert} severity="success">
                          {t("alerts.statisticPageAlert")}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item md={12} display="flex" justifyContent={"center"}>
                      <Typography
                        className={classes.typography}
                        id="maleMembers"
                        variant="body1"
                        onClick={(e: any) => handleCopy(e)}
                      >
                        {statisticDetails?.maleMembers}
                      </Typography>
                      <CustomTooltip
                        infoText={t("tooltips.tooltipsStatistic")}
                        infoTitle={t("tooltips.tooltipTitle")}
                      />
                      {copied === 7 && (
                        <Alert className={classes.alert} severity="success">
                          {t("alerts.statisticPageAlert")}
                        </Alert>
                      )}
                    </Grid>
                  </CardContent>
                </Grid>
              </Grid>
            </>
          </Card>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}></TabPanel>
      </Box>
    </>
  );
}
