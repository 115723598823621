import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useButtonWithTooltipStyle = makeStyles()((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    maxWidth: "250px",
    padding: "10px",
    position: "relative",
    "&:before": {
      borderColor: "transparent #f5f5f5 transparent transparent",
      borderStyle: "solid",
      borderWidth: "0 8px 8px 8px",
      content: "''",
      left: "-8px",
      position: "absolute",
      top: "8px",
    },
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: colors.primary,
    color: theme.palette.common.white,
    maxWidth: "300px",
  },
  iconButton: {
    top: "-5px",
    left: "-5px",
  },
  paperTitle: {
    verticalAlign: "middle",
    display: "inline-flex",
    fontSize: "16px",
  },
  exportExcButton: {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "5px",
    border: `none`,

    width: "70px",
    color: colors.green,
    fontWeight: "bold",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: colors.green,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    margin: 0,
    padding: 0,
    marginTop: "15px",
  },
  exportPdfButton: {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "5px",
    border: `none`,

    width: "70px",
    color: colors.red,
    fontWeight: "bold",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: colors.red,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    margin: 0,
    padding: 0,
    marginTop: "15px",
  },
  exportDefaultButton: {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "5px",
    border: `none`,
    color: colors.primary,
    fontWeight: "bold",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: colors.primary,
      color: "white",
    },
    "&:disabled": {
      boxShadow: "none",
    },
    margin: 0,
    padding: 0,
    marginTop: "15px",
    width: "70px",
  },
  disabled: {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "5px",
    border: `none`,
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    width: "70px",
    fontWeight: "bold",
    transition: "all 0.2s ease-in-out",
    "&:disabled": {
      boxShadow: "none",
    },
    margin: 0,
    padding: 0,
    marginTop: "15px",
  },
  circularProgress: {
    marginTop: "5px",
    color: colors.primary,
    display: "block",
    margin: "auto",
  },
}));
