import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/api/store";
import { ReportBy } from "../../models/enums/reportBy";
import { ReportType } from "../../models/enums/reportType";
import { APIStatus } from "../../models/types/api/APIStatus";
import { CreateReportRequest } from "../../models/requests/reports/createReportRequest";
import { ReportApi } from "./reportApi";
import {
  setErrorNotification,
  setPendingNotification,
  setSuccessNotification,
} from "../notification/notificationSlice";
import i18n from "../../i18n";
import { APIResponse } from "../../models/types/api/APIResponse";

type ReportState = {
  report: Blob | null;
  reportType: ReportType | null;
  reportBy: ReportBy | null;

  statuses: {
    reportStatus: string;
  };
};

const initialState: ReportState = {
  report: null,
  reportType: null,
  reportBy: null,

  statuses: {
    reportStatus: APIStatus.IDLE,
  },
};

export const createReport = createAsyncThunk(
  "Report/Create",
  async (request: CreateReportRequest, { rejectWithValue, dispatch }) => {
    try {
      const reportTranslationString =
        request.type === ReportType.Pdf ? "pdfReport" : "excelReport";

      dispatch(
        setPendingNotification(
          i18n.t(`messages.reports.${reportTranslationString}.pending`) + "..."
        )
      );

      const response = await ReportApi.CreateReport(request);

      dispatch(
        setSuccessNotification(
          i18n.t(`messages.reports.${reportTranslationString}.error`) + "."
        )
      );

      return response;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const setReportTypeAndBy = createAsyncThunk(
  "Report/SetTypeAndBy",
  async ({ type, by }: { type: ReportType; by: ReportBy }) => {
    return { type, by };
  }
);

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Create Report
      .addCase(createReport.pending, (state) => {
        state.statuses.reportStatus = APIStatus.PENDING;
      })
      .addCase(createReport.fulfilled, (state, action) => {
        state.statuses.reportStatus = APIStatus.FULFILLED;
        state.report = action.payload;
      })
      .addCase(createReport.rejected, (state) => {
        state.statuses.reportStatus = APIStatus.REJECTED;
      })

      .addCase(setReportTypeAndBy.fulfilled, (state, action) => {
        state.reportBy = action.payload.by;
        state.reportType = action.payload.type;
      });
  },
});

export const selectReport = (state: RootState) => state.report.report;
export const selectReportType = (state: RootState) => state.report.reportType;
export const selectReportBy = (state: RootState) => state.report.reportBy;

export const selectReportStatus = (state: RootState) =>
  state.report.statuses.reportStatus;

export default reportSlice.reducer;
