import { City } from "../../models/data/addresses/City";
import ElectionPlace from "../../models/data/addresses/electionPlaceModel";
import ElectionPlaceRegion from "../../models/data/addresses/electionPlaceRegion";
import { Street } from "../../models/data/addresses/Street";
import { Member } from "../../models/data/members/Member";
import { MemberPersonalData } from "../../models/data/members/MemberPersonalData";
import { FindElectionPlaceRequest } from "../../models/requests/election-places/FindElectionPlaceRequest";
import { FilterMembersBasicDetails } from "../../models/requests/filterMembersBasicDetails";
import { MemberBasicDetails } from "../../models/Responses/members/memberBasicDetails";
import { MemberDashboard } from "../../models/Responses/members/memberDashboard";
import StatisticModel from "../../models/statistic/statisticModel";
import { APIResponse } from "../../models/types/api/APIResponse";
import { FilterMembers } from "../../models/requests/filterMembers";
import { api } from "./api";

// Card

export const isAvailableCardNumber = async (
  cardNumber: number,
  memberCardId: string | null
) => {
  return api.get(
    `/member/is-available-card-number?cardNumber=${cardNumber}${
      memberCardId !== null ? `&memberCardId=${memberCardId}` : ``
    }`
  ) as Promise<APIResponse<boolean>>;
};
export const getAvailableCardNumbers = async () => {
  return api.get("/member/available-card-numbers") as Promise<
    APIResponse<number[]>
  >;
};
export const getNextCardNumber = async () => {
  return api.get("/member/available_card_number") as Promise<
    APIResponse<number>
  >;
};

// Addresses

export const getCities = async () => {
  return api.get("/city") as Promise<APIResponse<City[]>>;
};

export const getStreetsByCityId = async (cityId: string) => {
  return api.get("/street/city/" + cityId) as Promise<APIResponse<Street[]>>;
};

// Member

export const getMemberFullDetailsById = async (id: string) => {
  return api.get(`/member/details/${id}`) as Promise<APIResponse<Member>>;
};

export const getMemberDetailsDashboard = async (filter: FilterMembers) => {
  return api.post("/member/details-dashboard", filter) as Promise<
    APIResponse<MemberDashboard>
  >;
};

export const getMemberBasicDetails = async (
  filter: FilterMembersBasicDetails
) => {
  return api.post("/member/basic-details", filter) as Promise<
    APIResponse<MemberBasicDetails[]>
  >;
};

export const saveMember = async (member: Member) => {
  return api.put("/member/save-member", member) as Promise<APIResponse<Member>>;
};

export const deleteMember = async (id: string) => {
  return api.delete(`/member/${id}`) as Promise<
    APIResponse<MemberPersonalData>
  >;
};

// Election Place

export const getElectionPlaceRegionsByCityId = async (cityId: string) => {
  return api.get(`/election-place-region/city-id?cityId=${cityId}`) as Promise<
    APIResponse<ElectionPlaceRegion[]>
  >;
};
export const getElectionPlaceById = async (id: string) => {
  return api.get(`/election-place/${id}`) as Promise<
    APIResponse<ElectionPlace>
  >;
};
export const getElectionPlacesByCityId = async (cityId: string) => {
  return api.get(`/election-place/city-id?cityId=${cityId}`) as Promise<
    APIResponse<ElectionPlace[]>
  >;
};
export const getElectionPlaceByAddress = async (
  filter: FindElectionPlaceRequest
) => {
  return api.post(
    "/election-place/find-by-city-id-and-region",
    filter
  ) as Promise<APIResponse<ElectionPlace>>;
};

// Statistic

export const getStatistic = async () => {
  return api.get(`/statistic`) as Promise<StatisticModel>;
};
