import axios from "axios";

const MembershipEnv = {
  Local: "https://localhost:5001/api",
  Qa: "https://membership.api.qa.midenas.rs/api",
  Prod: "https://membership.api.easymembership.net/api",
};

const AdminEnv = {
  Local: "https://localhost:6001/api",
  Prod: "https://admin.api.easymembership.net/api"
};

export const api = axios.create({
  baseURL: MembershipEnv.Prod,
});

export const adminApi = axios.create({
  baseURL: AdminEnv.Prod,
});
