import {} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { getInitialsFromFullName } from "../../common/helpers/account";
import {
  formatDate,
  isNullOrWhiteSpace,
} from "../../common/helpers/formatData";
import BirthDateCakeIcon from "../../component/icons/BirthDateCakeIcon";
import CreditCardIcon from "../../component/icons/CreditCardIcon";
import LocationIcon from "../../component/icons/LocationIcon";
import PhoneIcon from "../../component/icons/PhoneIcon";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import cardBackgroundImage from "../../images/cardBackground.png";
import { MemberDetailsDashboard } from "../../models/Responses/members/memberDetailsDashboard";
import { useMemberCardStyles } from "./useMemberCardStyles";
import { useAppSelector } from "../../app/api/hooks";

type Props = {
  member: MemberDetailsDashboard;
};

export default function MemberCard(props: Props) {
  const theme = useTheme();
  const { isSmallScreen } = useScreenWidth();
  const organizationLogo = useAppSelector(
    (state) => state.auth.organizationLogo
  );
  const iconSize = 100;
  const memberName = props.member.firstName + " " + props.member.lastName;
  const { classes } = useMemberCardStyles({
    iconSize,
    isSmallScreen,
    backgroundImage: cardBackgroundImage,
  });
  const primaryColor = theme.palette.primary.main;

  const getPhone = () => {
    return !isNullOrWhiteSpace(props.member.mobilePhone)
      ? props.member.mobilePhone
      : !isNullOrWhiteSpace(props.member.workPhone)
      ? props.member.workPhone
      : !isNullOrWhiteSpace(props.member.landPhone)
      ? props.member.landPhone
      : "";
  };

  return (
    <Box className={classes.root} sx={{ boxShadow: 5 }}>
      {/* Background image and member icon */}
      <Grid
        container
        sx={{
          height: 120,
          boxShadow: 2,
        }}
        className={classes.box}
      >
        <img
          src={`${organizationLogo}`}
          alt="Organization logo"
          className={classes.imgLogo}
        />
        <IconButton
          className={classes.circle}
          sx={{
            left: { xs: "18%", sm: "13%", md: "16%", lg: "13%", xl: "13%" },
          }}
        >
          <Avatar sx={{ width: iconSize, height: iconSize, fontSize: "2.2em" }}>
            {getInitialsFromFullName(memberName)}
          </Avatar>
        </IconButton>
      </Grid>

      {/* Member name */}
      <Grid
        container
        sx={{
          ml: { xs: 18, sm: 18, md: 20, lg: 20, xl: 20 },
          mt: 2,
          width: "auto",
        }}
      >
        <Grid item>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {memberName}
          </Typography>
        </Grid>
      </Grid>

      {/* Member data */}
      <Box
        sx={{
          ml: { xs: 5, sm: 20, md: 20, lg: 20, xl: 20 },
          mt: 1,
          width: "auto",
        }}
      >
        <Grid container justifyContent="space-between" mb={3}>
          <Grid item xs={7}>
            <LocationIcon fillColor={primaryColor} />
            <span style={{ marginRight: 5 }}></span>
            {props.member.city}
          </Grid>

          <Grid item xs={5}>
            <CreditCardIcon fillColor={primaryColor} />
            <span style={{ marginRight: 5 }}></span>
            {props.member.memberCardNumber}
          </Grid>
        </Grid>

        <Grid container justifyContent="space-between" mb={3}>
          <Grid item xs={7}>
            <PhoneIcon fillColor={primaryColor} />
            <span style={{ marginRight: 5 }}></span>
            {getPhone()}
          </Grid>

          <Grid item xs={5}>
            <BirthDateCakeIcon fillColor={primaryColor} />
            <span style={{ marginRight: 5 }}></span>
            {formatDate(props.member.birthDate)}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
