import spinner from "../assets/spinner.gif";
import React from "react";

type Props = {
  style?: React.CSSProperties;
};

export default function Spinner(props: Props) {
  return props.style ? (
    <img src={spinner} alt="Loading..." style={{ ...props.style }} />
  ) : (
    <span>
      <img
        src={spinner}
        alt="Loading..."
        style={{ width: "100px", margin: "auto", display: "block" }}
      />
    </span>
  );
}