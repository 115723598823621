import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import { Member } from "../../../models/data/members/Member";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";

interface Props {
  member: Member;
  onChange: any;
}

export const MemberContactData = ({ member, onChange }: Props) => {
  const { t } = useTranslation();

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      switch (id) {
        case "memberContactData_input-mobilePhone":
          var mobilePhone = document.getElementById(
            "memberContactData_input-mobilePhone"
          );
          mobilePhone?.focus();
          break;
        case "memberContactData_input-landingPhone":
          var landingPhone = document.getElementById(
            "memberContactData_input-landingPhone"
          );
          landingPhone?.focus();
          break;
        case "memberContactData_input-bussinesPhone":
          var bussinesPhone = document.getElementById(
            "memberContactData_input-bussinesPhone"
          );
          bussinesPhone?.focus();
          break;
        case "memberContactData_input-email":
          var email = document.getElementById("memberContactData_input-email");
          email?.focus();
          break;
        case "memberContactData_input-twitter":
          var twitter = document.getElementById(
            "memberContactData_input-twitter"
          );
          twitter?.focus();
          break;
        case "memberContactData_input-instagram":
          var instagram = document.getElementById(
            "memberContactData_input-instagram"
          );
          instagram?.focus();
          break;
        case "memberContactData_input-facebook":
          var facebook = document.getElementById(
            "memberContactData_input-facebook"
          );
          facebook?.focus();
          break;
        case "memberContactData_input-other":
          var other = document.getElementById("memberContactData_input-other");
          other?.focus();
          break;
      }
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          id="memberContactData_input-mobilePhone"
          label={t("memberManagementPage.contactMobilePhone")}
          value={member?.contact?.mobilePhone}
          onChange={(e) =>
            onChange(
              e.target.value,
              "mobilePhone",
              MemberSectionType.memberContact
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberContactData_input-landingPhone")
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          id="memberContactData_input-landingPhone"
          label={t("memberManagementPage.contactLandingPhone")}
          value={member?.contact?.landPhone}
          onChange={(e) =>
            onChange(
              e.target.value,
              "landPhone",
              MemberSectionType.memberContact
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberContactData_input-bussinesPhone")
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          id="memberContactData_input-bussinesPhone"
          label={t("memberManagementPage.contactBussinesPhone")}
          value={member?.contact?.workPhone}
          onChange={(e) =>
            onChange(
              e.target.value,
              "workPhone",
              MemberSectionType.memberContact
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberContactData_input-email")
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          id="memberContactData_input-email"
          label={t("memberManagementPage.contactEmail")}
          value={member?.contact?.email}
          onChange={(e) =>
            onChange(e.target.value, "email", MemberSectionType.memberContact)
          }
          width="87.5%"
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberContactData_input-twitter")
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          id="memberContactData_input-twitter"
          label={t("memberManagementPage.contactTwitter")}
          value={member?.contact?.twiter}
          onChange={(e) =>
            onChange(e.target.value, "twiter", MemberSectionType.memberContact)
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberContactData_input-instagram")
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          id="memberContactData_input-instagram"
          label={t("memberManagementPage.contactInstagram")}
          value={member?.contact?.instagram}
          onChange={(e) =>
            onChange(
              e.target.value,
              "instagram",
              MemberSectionType.memberContact
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberContactData_input-facebook")
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <BasicInput
          id="memberContactData_input-facebook"
          label={t("memberManagementPage.contactFacebook")}
          value={member?.contact?.facebook}
          onChange={(e) =>
            onChange(
              e.target.value,
              "facebook",
              MemberSectionType.memberContact
            )
          }
          onKeyDown={(e: any) =>
            handleOnKeyPressed(e, "memberContactData_input-other")
          }
        />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Grid item md={12} xs={12} style={{ display: "flex" }}>
          <BasicInput
            id="memberContactData_input-other"
            label={t("memberManagementPage.contactOther")}
            value={member?.contact?.other}
            onChange={(e) =>
              onChange(e.target.value, "other", MemberSectionType.memberContact)
            }
          />
          <CustomTooltip
            infoText={t("tooltips.contactOtherContactSectionText")}
            infoTitle={t("tooltips.tooltipTitle")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
