import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import {
  getAllMembershipTypes,
  selectMembershipTypes,
} from "../../slices/members/memberSlice";

export default function useGetAllMembershipTypes() {
  const dispatch = useAppDispatch();
  const membershipTypes = useAppSelector(selectMembershipTypes);

  const get = () => dispatch(getAllMembershipTypes());

  useEffect(() => {
    get();
  }, []);

  return { membershipTypes };
}
