import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from "@mui/icons-material/TableView";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app/api/hooks";
import { ReportBy } from "../../../../models/enums/reportBy";
import { ReportType } from "../../../../models/enums/reportType";
import { APIStatus } from "../../../../models/types/api/APIStatus";
import {
  selectReportBy,
  selectReportStatus,
  selectReportType,
} from "../../../../slices/reports/reportSlice";
import { ReportsDisabledButtons } from "../../TableContainer";
import { usePredefinedReportSureVotesModalStyle } from "./PredefinedReportSureVotesModalStyle";
import sidebarBackgroundImage from "../../../../images/sidebarBackgroundImage.png";
import { useTheme } from "@mui/system";
import { hexToRgb } from "../../../../common/helpers/theme";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

const PredefinedReportSureVotesModal = ({
  open,
  closeModal,
  sx,
  excelDefault,
  pdfDefault,

  excelLocalCommunity,
  pdfLocalCommunity,

  pdfElectionPlace,
  excelElectionPlace,
  reportsDisabledButtons,
}: {
  open: boolean;
  closeModal: any;
  sx: {};
  excelDefault: any;
  pdfDefault: any;
  excelLocalCommunity: any;
  pdfLocalCommunity: any;
  pdfElectionPlace: any;
  excelElectionPlace: any;
  reportsDisabledButtons: ReportsDisabledButtons;
}) => {
  const { t } = useTranslation();
  const { classes } = usePredefinedReportSureVotesModalStyle();
  const theme = useTheme();
  const primaryRgb = hexToRgb(theme.palette.primary.main);

  const reportStatus = useAppSelector(selectReportStatus);
  const isExporting = reportStatus === APIStatus.PENDING;

  const reportType = useAppSelector(selectReportType);
  const reportBy = useAppSelector(selectReportBy);

  const isPredefinedPdfByCityExporting =
    isExporting && reportType === ReportType.Pdf && reportBy === ReportBy.City;

  const isPredifinedExcelByCityExporting =
    isExporting &&
    reportType === ReportType.Excel &&
    reportBy === ReportBy.City;

  const isPredefinedPdfByLocalCommunityExporting =
    isExporting &&
    reportType === ReportType.Pdf &&
    reportBy === ReportBy.LocalCommunity;

  const isPredifinedExcelByLocalCommunityExporting =
    isExporting &&
    reportType === ReportType.Excel &&
    reportBy === ReportBy.LocalCommunity;

  const isPdfByElectionPlaceExporting =
    isExporting &&
    reportType === ReportType.Pdf &&
    reportBy === ReportBy.ElectionPlace;

  const isExcelByElectionPlaceExporting =
    isExporting &&
    reportType === ReportType.Excel &&
    reportBy === ReportBy.ElectionPlace;

  const rows = [
    {
      pdfExport: pdfDefault,
      excelExport: excelDefault,
      reportName: t("memberTablePage.predefinedReportByCity"),
      exportingPdf: isPredefinedPdfByCityExporting,
      exportingExcel: isPredifinedExcelByCityExporting,
    },
    {
      pdfExport: pdfLocalCommunity,
      excelExport: excelLocalCommunity,
      reportName: t("memberTablePage.predefinedReportByLocalCommunity"),
      exportingPdf: isPredefinedPdfByLocalCommunityExporting,
      exportingExcel: isPredifinedExcelByLocalCommunityExporting,
    },
    {
      pdfExport: pdfElectionPlace,
      excelExport: excelElectionPlace,
      reportName: t("memberTablePage.predefinedReportByElectionPlace"),
      exportingPdf: isPdfByElectionPlaceExporting,
      exportingExcel: isExcelByElectionPlaceExporting,
    },
  ];

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle id="myProfileModal" className={classes.dialogTitle}>
        <img
          src={sidebarBackgroundImage}
          alt="Sidebar Background"
          className={classes.profileModalImage}
          style={{
            background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
          }}
        />
        <h3 className={classes.modalTitle}>
          {t("reportHeaderTitleModal.title")}
        </h3>
      </DialogTitle>
      <Grid container style={{ justifyContent: "center" }}>
        <DialogContent>
          <Grid container style={{ justifyContent: "center" }}>
            <Grid
              item
              md={10}
              sm={12}
              xs={12}
              className={classes.modalBtnWrapper}
              mb={3}
            >
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={"Novi Sad"}
                  displayEmpty
                  disabled
                  // onChange={handleChange}
                >
                  <MenuItem value={"Novi Sad"}>Novi Sad</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {rows.map((row, index) => {
              return (
                <>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.modalBtnWrapper}
                    justifyContent="flex-start"
                  >
                    <Typography variant="body1">
                      <Grid container spacing={1} alignItems="end">
                        <Grid
                          item
                          style={{ display: "flex", minWidth: "220px" }}
                        >
                          <Typography variant="overline">
                            {index + 1}.{row.reportName}
                          </Typography>
                        </Grid>
                        <Grid item alignItems="revert">
                          <LoadingButton
                            disabled={row.exportingPdf}
                            size="small"
                            onClick={row.pdfExport}
                            className={classes.icoButton}
                            loading={row.exportingPdf}
                            loadingIndicator={
                              <CircularProgress
                                size="14px"
                                className={classes.loader}
                              />
                            }
                            loadingPosition="center"
                            style={{ minWidth: "30px" }}
                          >
                            <Typography variant="button" fontSize={"24px"}>
                              {!row.exportingPdf && (
                                <>
                                  <LightTooltip
                                    title={"izvezi pdf"}
                                    placement="top-start"
                                  >
                                    <PictureAsPdfIcon
                                      fontSize="inherit"
                                      className={classes.exportPdfButton}
                                    />
                                  </LightTooltip>
                                </>
                              )}
                            </Typography>
                          </LoadingButton>
                        </Grid>
                        <Grid item alignItems="revert">
                          <LoadingButton
                            disabled={row.exportingExcel}
                            size="small"
                            onClick={row.excelExport}
                            className={classes.icoButton}
                            loading={row.exportingExcel}
                            loadingIndicator={
                              <CircularProgress
                                size="14px"
                                className={classes.loader}
                              />
                            }
                            loadingPosition="center"
                            style={{ minWidth: "30px" }}
                          >
                            <Typography variant="button" fontSize={"24px"}>
                              {!row.exportingExcel && (
                                <LightTooltip
                                  title={"izvezi excel"}
                                  placement="top-start"
                                >
                                  <TableViewIcon
                                    fontSize="inherit"
                                    className={classes.exportExcButton}
                                  />
                                </LightTooltip>
                              )}
                            </Typography>
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>

                  <hr className={classes.divider} />
                </>
              );
            })}

            <Grid
              item
              md={12}
              sm={12}
              xs={12}
              className={classes.modalBtnWrapper}
            >
              <Button
                onClick={closeModal}
                variant="contained"
                className={classes.closeModalBtn}
              >
                {t("buttons.close")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </Dialog>
  );
};

export default PredefinedReportSureVotesModal;
