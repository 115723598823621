export const CapitalizeFirstLetter = (input: string) => {
  if (!input || input.trim() == "") return input;
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const namesToCapitalize = [
  "firstName",
  "lastName",
  "parentName",
  "birthPlace",
  "publishingCity",
  "other",
  "company",
  "workPlace",
  "elementarySchool",
  "highSchool",
  "basicVocationalStudies",
  "specialVocationalStudies",
  "academicStudies",
  "masterAcademicStudies",
  "specialAcademicStudies",
  "doctoralAcademicStudies",
  "hobby",
  "saintDay",
  "recommendation",
  "note1",
  "note2",
  "note3",
];
