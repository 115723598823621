import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../../app/api/hooks";
import AppButton from "../../../component/button/AppButton";
import { useTranslation } from "react-i18next";

interface Props {
  handleClose: any;
  open: boolean;
  handleDelete: any;
  memberName: any;
}

export default function DeleteConfirmationModal({
  handleClose,
  open,
  handleDelete,
  memberName,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="xs"
      fullWidth
      id={"delete-confirmation-modal"}
    >
      <DialogTitle textAlign={"center"}></DialogTitle>
      <DialogContent sx={{ padding: "10px" }}>
        <Grid item md={12}>
          <Typography textAlign="center" id={"delete-confirmation-modal-label"}>
            {t("deleteConfirmationModal.label")}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography
            fontWeight="bold"
            textAlign="center"
            id={"delete-confirmation-modal-value"}
          >
            {memberName}
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} marginTop={3}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            textAlign={"center"}
            display={"flex"}
            justifyContent={"center"}
          >
            <Grid item>
              <AppButton
                label={t("buttons.confirm")}
                color={"#4CAF50"}
                hover={"#4CAF50"}
                onClick={handleDelete}
                id={"delete-confirmation-modal-confirm-button"}
              />
            </Grid>
            <Grid item ml={1}>
              <AppButton
                label={t("buttons.reject")}
                color={"rgba(255, 73, 71, 0.8)"}
                hover={"rgba(255, 73, 71, 0.8)"}
                onClick={handleClose}
                id={"delete-confirmation-modal-reject-button"}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
