import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { INIT_MEMBER } from "../../INITIAL_DATA/initialData";
import { useAppSelector } from "../../app/api/hooks";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import { Member } from "../../models/data/members/Member";
import { selectSelectedMember } from "../../slices/members/memberSlice";
import MemberNameSection from "./MemberNameSection";
import MemberDataSection from "./MemberDataSection";

type Props = {
  isOpen: boolean;
  handleClose: any;
};

export default function MemberViewModal(props: Props) {
  const member: Member = useAppSelector(selectSelectedMember) ?? INIT_MEMBER;
  const { isSmallScreen } = useScreenWidth();

  return (
    <>
      {props.isOpen && (
        <Dialog
          open={props.isOpen}
          onClose={props.handleClose}
          PaperProps={{
            sx: {
              background: "transparent",
              boxShadow: "none",
            },
          }}
          maxWidth={isSmallScreen ? false : "md"}
          fullWidth={!isSmallScreen}
        >
          <DialogTitle sx={{ p: 0 }}>
            <MemberNameSection member={member} />
          </DialogTitle>

          <DialogContent sx={{ p: 0 }}>
            <MemberDataSection member={member} />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
