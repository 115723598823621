import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";

export const useUserManagementStyle = makeStyles()((theme: Theme) => ({
  tableHead: {
    background: colors.primary,
    color: "white",
  },
  tableHeadTitle: {
    color: "white",
    textAlign: "center",
  },
  tableCellIsActive: {
    textAlign: "center",
  },
  createUserButton: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.primary,
    marginBottom: "5px",
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  icons: {
    cursor: "pointer",
  },
  circularProgress: {
    color: colors.primary,
    display: "block",
    margin: "auto",
  },
}));
