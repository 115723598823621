import { Container, Grid } from "@mui/material";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { colors } from "../../app/theme/colors";
import { useCreateReport } from "../../hooks/useCreateReport";
import { useGetColumnsForReport } from "../../hooks/useGetColumnsForReport";
import { useGetVisibleMemberColumns } from "../../hooks/useGetVisibleMemberColumns";
import { useMemberFilter } from "../../hooks/useMemberFilter";
import { usePopover } from "../../hooks/usePopover";
import { InitTableHeader } from "../../INITIAL_DATA/initialTableHeader";
import { initialDataTableContainer } from "../../INITIAL_DATA/table-container/initialDataTableContainer";
import { MemberColumnsDto } from "../../models/data/members/columns/memberColumnsDto";
import { DisplayLanguage } from "../../models/enums/displayLanguage";
import { ReportBy } from "../../models/enums/reportBy";
import { ReportType } from "../../models/enums/reportType";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { CreateReportRequest } from "../../models/requests/reports/createReportRequest";
import { selectDisplayLanguage } from "../../slices/displayLanguages/displayLanguageSlice";
import { setReportTypeAndBy } from "../../slices/reports/reportSlice";
import ActiveFilters from "../table-fiters/ActiveFilters";
import { useGetMembersPagedList } from "../table-fiters/hooks/tableHooks";
import TableFilters from "../table-fiters/TableFilters";
import { TableHeadNamesType } from "../table/data/tableHeadNames";
import TableComponent from "../table/tableComponent/tableComponent";
import DetailExcelFileModal from "./modals/detailExcelFileModal/DetailExcelFileModal";
import ReportsIconsWithModals from "./parts/ReporstIconsWithModals";
import { useTableContainerStyles } from "./tableContainerStyles";
import "./tableContainerStyles.css";

export type ReportsDisabledButtons = {
  currentDataExcel: boolean;
  currentDataPdf: boolean;

  predefinedByLocalCommunityExcel: boolean;
  predefinedByLocalCommunityPdf: boolean;

  predefinedByCityExcel: boolean;
  predefinedByCityPdf: boolean;

  predefinedByElectionPlaceExcel: boolean;
  predefinedByElectionPlacePdf: boolean;

  predefinedDefaultExcel: boolean;
  predefinedDefaultPdf: boolean;
};

type Props = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

const TableContainer: any = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useTableContainerStyles();
  const tableHeadNamesInit = InitTableHeader;
  const places: string[] = [
    "Novi Sad",
    "Petrovaradin",
    "Sremska Kamenica",
    "Futog",
    "Begec",
    "Begeč",
    "Budisava",
    "Bukovac",
    "Veternik",
    "Kac",
    "Kać",
    "Kisac",
    "Kisač",
    "Kovilj",
    "Ledinci",
    "Rumenka",
    "Stari Ledinci",
    "Stepanovicevo",
    "Stepanovićevo",
    "Cenej",
    "Čenej",
  ];

  // Selectors
  const displayLanguage = useAppSelector(selectDisplayLanguage);

  // States

  const [tableHeadNames, setTableHeadNames] =
    useState<TableHeadNamesType>(tableHeadNamesInit);
  const [reportType, setReportType] = useState<ReportType>();
  const [isOpenTableColumnModal, setIsOpenTableColumnModal] =
    useState<boolean>(false);
  const [numberOfResultsPerPage, setNumberOfResultsPerPage] =
    useState<number>(15);
  const [openMenageDocumentDataModal, setOpenMenageDocumentDataModal] =
    useState<boolean>(false);
  const [reportsDisabledButtons, setReportsDisabledButtons] =
    useState<ReportsDisabledButtons>({
      currentDataExcel: false,
      currentDataPdf: false,

      predefinedByLocalCommunityExcel: false,
      predefinedByLocalCommunityPdf: false,

      predefinedByCityExcel: false,
      predefinedByCityPdf: false,

      predefinedByElectionPlaceExcel: false,
      predefinedByElectionPlacePdf: false,

      predefinedDefaultExcel: false,
      predefinedDefaultPdf: false,
    });
  const [dataManipulation, setDataManipulation] =
    useState<MemberDataManipulationRequest>(
      initialDataTableContainer.initDataManipulationRequest
    );
  const [page, setPage] = useState(1);
  const [reportHeaderLabel, setReportHeaderLabel] = useState<string>("");
  // Hooks

  const {
    membersPagedList,
    membersPagedListStatus,
    refetch: refetchMemberPagedList,
  } = useGetMembersPagedList();
  const {
    filter,
    setFilter,
    filterValue,
    setFilterValue,
    filterProperty,
    setFilterProperty,
    memberProperties,
    addFilter,
  } = useMemberFilter({
    dataManipulation,
    setDataManipulation,
    refetchMemberPagedList,
    setPage,
  });
  const { createReport, createDisabled } = useCreateReport();
  const { visibleColumns } = useGetVisibleMemberColumns({ tableHeadNames });
  const filterPopover = usePopover();
  const {
    columnsForDefault,
    columnsForCity,
    columnsForElectionPlace,
    columnsForLocalCommunity,
  } = useGetColumnsForReport({ tableHeadNames });

  const handleCloseMenageDataModal = () => {
    setOpenMenageDocumentDataModal(false);
  };

  const toggleModal = () => {
    setIsOpenTableColumnModal(!isOpenTableColumnModal);

    if (isOpenTableColumnModal) {
      props.setPage(0);
    }
  };

  const createReportHandler = async (
    by: ReportBy,
    type: ReportType,
    columns?: MemberColumnsDto,
    title?: string,
    places?: string[]
  ) => {
    const language = localStorage.getItem("i18nextLng");
    setReportType(type);

    const createReportRequest: CreateReportRequest = {
      by: by,
      type: type,
      dataManipulation: {
        ...dataManipulation,
      },
      columns: columns
        ? columns
        : {
            ...visibleColumns,
          },
      title: title ?? "Lista članova",
      language: language === "rsCyrl" ? 2 : language === "rs" ? 1 : 0,
      hashColor: colors.primary,
      places,
    };

    dispatch(setReportTypeAndBy({ type, by }));

    createReport(createReportRequest);
  };

  // UseEffects
  useEffect(() => {
    if (!displayLanguage) {
      return;
    }

    refetchMemberPagedList({ ...dataManipulation });
  }, [displayLanguage]);

  // Props for components

  const tableFiltersProps = {
    filterPopover,
    memberProperties,
    filter,
    setFilter,
    filterProperty,
    setFilterProperty,
    addFilter,
    filterValue,
    setFilterValue,
    refetchMemberPagedList,
    dataManipulation,
  };
  const activeFiltersProps = {
    filter,
    setFilter,
    refetchMemberPagedList,
    dataManipulation,
    setDataManipulation,
    setFilterProperty,
    setFilterValue,
  };
  const reportProps = {
    excelDefault: () => createReportHandler(ReportBy.Default, ReportType.Excel),
    excelCity: () =>
      createReportHandler(
        ReportBy.City,
        ReportType.Excel,
        { ...columnsForCity },
        "",
        [...places]
      ),
    excelElectionPlace: () =>
      createReportHandler(
        ReportBy.ElectionPlace,
        ReportType.Excel,
        { ...columnsForElectionPlace },
        "",
        [...places]
      ),
    excelLocalCommunity: () =>
      createReportHandler(
        ReportBy.LocalCommunity,
        ReportType.Excel,
        { ...columnsForLocalCommunity },
        "",
        [...places]
      ),

    pdfDefault: () =>
      createReportHandler(
        ReportBy.Default,
        ReportType.Pdf,
        {
          ...columnsForDefault,
        },
        reportHeaderLabel
      ),
    pdfCity: () =>
      createReportHandler(
        ReportBy.City,
        ReportType.Pdf,
        { ...columnsForCity },
        "",
        [...places]
      ),
    pdfElectionPlace: () =>
      createReportHandler(
        ReportBy.ElectionPlace,
        ReportType.Pdf,
        { ...columnsForElectionPlace },
        "",
        [...places]
      ),
    pdfLocalCommunity: () =>
      createReportHandler(
        ReportBy.LocalCommunity,
        ReportType.Pdf,
        { ...columnsForLocalCommunity },
        "",
        [...places]
      ),

    reportsDisabledButtons,

    classes,
    reportHeaderLabel,
    setReportHeaderLabel,
  };
  const tableCompProps = {
    dataManipulation,
    setDataManipulation,
    membersPagedList,
    membersPagedListStatus,
    refetchMemberPagedList,

    tableHeadNamesInit,
    tableHeadNames,
    setTableHeadNames,

    isOpenTableColumnModal,
    toggleModal,

    page,
    setPage,

    numberOfResultsPerPage,
    setNumberOfResultsPerPage,

    handleOpenFilterPopover: tableFiltersProps.filterPopover.handleOpen,
  };
  const detailExcelFileModalProps = {
    openMenageDocumentDataModal,
    handleCloseMenageDataModal,
  };

  return (
    <Container>
      <Grid item xs={12} md={12}>
        <ReportsIconsWithModals {...reportProps} />
      </Grid>

      <ActiveFilters {...activeFiltersProps} />

      <TableFilters {...tableFiltersProps} />

      <Grid item md={12} xs={12}>
        <TableComponent {...tableCompProps} />
      </Grid>

      <DetailExcelFileModal {...detailExcelFileModalProps} />
    </Container>
  );
};

export default TableContainer;
