import { makeStyles } from "tss-react/mui";
import { colors } from "../../app/theme/colors";
import { Theme } from "@mui/material/styles";

export const useTableFiltersStyle = makeStyles()((theme: Theme) => ({
  paper: {
    borderRadius: "5px",
    padding: "20px",
  },
  typographyText_label: {
    color: "#A2A89A",
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px",
    },
  },
  popoverTitle: {
    color: theme.palette.primary.dark,
  },
  textField: {
    width: "100%",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  textField_wrapper: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: "8px",
    },
  },
  title: {
    textAlign: "center",
    color: "#A2A89A",
    textTransform: "uppercase",
  },
  btnSubmit: {
    fontSize: "12px",
    border: "1px solid lightGrey",
    marginTop: "10px",
    borderRadius: "5px",
    height: "20px",
    padding: "13px",
    textAlign: "center",
    color: colors.primary,
    "&:hover": {
      backgroundColor: colors.primary,
      color: "white",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  btnCancel: {
    fontSize: "12px",
    border: "1px solid lightGrey",
    marginTop: "10px",
    borderRadius: "5px",
    height: "20px",
    padding: "13px",
    textAlign: "center",
    color: colors.primary,
    marginLeft: "5px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "5px",
      textAlign: "center",
    },
  },
  buttons_wrapper: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  radioButton: {
    "&.MuiRadio-root": {
      color: colors.primary,
    },
    "&.Mui-checked": {
      color: colors.primary,
    },
  },
  radioButtonTitle: {
    radioButtonTitle: {
      color: colors.inputLabel,
    },
  },
}));
