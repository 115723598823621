import { colors } from "../../../app/theme/colors";
import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useElectionPlaceDetailsModalStyle = makeStyles()(
  (theme: Theme) => ({
    label: {
      textAlign: "start",
      color: colors.sectionTitle,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    modalTitle: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      textAlign: "center",
    },
    labelInfo: {
      textAlign: "start",
      float: "left",
      color: colors.sectionTitle,
      textTransform: "uppercase",
    },
    dialogTitle: {
      cursor: "pointer",
    },
    grid: {
      position: "sticky",
      textAlign: "end",
      border: "none",
      outline: "none",
      color: "white",
      zIndex: 2,
    },
    icon: {
      color: theme.palette.primary.dark,
    },
    electionModalImage: {
      position: "absolute",
      top: "10%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 1,
      width: "100%",
      height: "40%",
    },
    electionDivider: {
      position: "absolute",
      width: "100%",
      left: "0",
      height: "2px!important",
      marginTop: "10px",
    },
    electionButtonContainer: {
      justifyContent: "flex-end",
      display: "flex",
      marginTop: "5%",
    },
  })
);
