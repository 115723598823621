import { Box, Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EmptyGuid } from "../../INITIAL_DATA/initialData";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import BasicInput from "../../component/input/basic-input/BasicInput";
import CustomTooltip from "../../component/tooltip/CustomTooltip";
import useModal from "../../hooks/common/useModal";
import { MemberDetailsDashboard } from "../../models/Responses/members/memberDetailsDashboard";
import { FilterMembers } from "../../models/requests/filterMembers";
import {
  getMemberDetailsDashboard,
  getMemberFullDetailsById,
  resetSelectedMember,
  selectMemberDetailsDashboard,
  selectSavedMember,
  setIsEditing,
} from "../../slices/members/memberSlice";
import MemberCard from "./MemberCard";
import MemberViewModal from "../memberViewModal/MemberViewModal";

const initMemberCardFilter: FilterMembers = {
  firstName: "",
  lastName: "",
  jmbg: "",
  phone: "",
  cardNumber: "",
};

type Props = {};

export default function MemberCards(props: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const memberDetailsDashboard = useAppSelector(selectMemberDetailsDashboard);
  const [memberCardsFilter, setMemberCardsFilter] =
    useState<FilterMembers>(initMemberCardFilter);
  const modal = useModal();
  const selectedSavedMember = useAppSelector(selectSavedMember);

  const getMemberCards = (filter?: FilterMembers) => {
    dispatch(getMemberDetailsDashboard(filter ?? initMemberCardFilter));
  };

  const onChangeFilter =
    (propName: keyof FilterMembers) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const updatedFilter: FilterMembers = {
        ...memberCardsFilter,
        [propName]: e.target.value,
      };

      setMemberCardsFilter(updatedFilter);

      if (
        e.target.value.length >= 3 ||
        propName === "cardNumber" ||
        e.target.value.length === 0
      ) {
        getMemberCards(updatedFilter);
      }
    };

  const onOpenEditModal = async (x: MemberDetailsDashboard) => {
    if (x.id && x.id !== EmptyGuid) {
      await dispatch(getMemberFullDetailsById(x.id));
    }

    dispatch(setIsEditing(true));

    modal.open();
  };

  const onCloseEditModal = () => {
    dispatch(resetSelectedMember());
    dispatch(setIsEditing(false));
    modal.close();
  };

  // On load component => get member cards,
  // After save member from edit modal => get member cards
  useEffect(() => {
    getMemberCards(memberCardsFilter);
  }, [, selectedSavedMember]);

  return (
    <>
      {/* Search Title */}
      <Box>
        <Typography variant="h5">
          {t("dashboardPage.accordionTitle")}
        </Typography>
        <Typography variant="body1">
          {t("dashboardPage.accordionSubtitle")}.
          <span style={{ marginRight: 4 }}></span>
          <CustomTooltip
            infoTitle={""}
            infoText={t("dashboardPage.aboutSearch")}
          />
        </Typography>
      </Box>

      {/* Search Inputs */}
      <Grid container spacing={3} width="auto" mt={1}>
        <Grid item lg={3} md={6} sm={12} xs={12}>
          <BasicInput
            label={t("dashboardPage.accordionPlaceholderFirstName")}
            value={memberCardsFilter.firstName}
            onChange={onChangeFilter("firstName")}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
          <BasicInput
            label={t("dashboardPage.accordionPlaceholderLastName")}
            value={memberCardsFilter.lastName}
            onChange={onChangeFilter("lastName")}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
          <BasicInput
            label={t("dashboardPage.accordionPlaceholderPhone")}
            value={memberCardsFilter.phone}
            onChange={onChangeFilter("phone")}
          />
        </Grid>

        <Grid item lg={3} md={6} sm={12} xs={12}>
          <BasicInput
            label={t("dashboardPage.accordionPlaceholderCardNumber")}
            value={memberCardsFilter.cardNumber}
            onChange={onChangeFilter("cardNumber")}
          />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <BasicInput
            label={t("dashboardPage.accordionPlaceholderJmbg")}
            value={memberCardsFilter.jmbg}
            onChange={onChangeFilter("jmbg")}
          />
        </Grid>
      </Grid>

      {/* Cards */}
      <Grid container spacing={3} mt={5} justifyContent={"center"} justifyItems={"center"}>
        {memberDetailsDashboard?.members.map((member, i) => (
          <Grid
            item
            xl={5}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            onClick={() => onOpenEditModal(member)}>
            <MemberCard member={member} />
          </Grid>
        ))}
      </Grid>

      <MemberViewModal isOpen={modal.isOpen} handleClose={onCloseEditModal} />
    </>
  );
}
