import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useTabSyles = makeStyles()((theme: Theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      buttonContainer: {
        display: 'flex',
      },
}));