import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import {
  getMemberFullDetailsById,
  setIsEditing,
} from "../../slices/members/memberSlice";
import { useUserManagementStyle } from "../user-management/userManagementStyle";
import { NotUpdatedMember } from "../../models/synch/notUpdatedMember";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { LightTooltip } from "../../component/tooltip/lightTooltip";
import TableEditModal from "../table/modal/editTableDataModal/editTableDataModal";

interface NotUpdatedMembersTableProps {
  members: NotUpdatedMember[] | null;
}

const NotUpdatedMembersTable = ({ members }: NotUpdatedMembersTableProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { classes } = useUserManagementStyle();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const selectedMember = useAppSelector((x) => x.members.selectedMember);

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [isTriggeredEdit, setIsTriggeredEdit] = useState<boolean>(false);

  const handleOpenEditModal = (memberId: string) => {
    setIsTriggeredEdit(true);
    dispatch(setIsEditing(true));
    dispatch(getMemberFullDetailsById(memberId));
  };
  useEffect(() => {
    if (selectedMember && isTriggeredEdit) {
      setIsOpenEditModal(true);
    }
  }, [selectedMember]);

  const handleToggleEditModal = () => {
    dispatch(setIsEditing(!isOpenEditModal));
    setIsTriggeredEdit(!isOpenEditModal);
    setIsOpenEditModal(!isOpenEditModal);
  };

  const prefix = "synchTableHeader.";
  const tableHeadNames: string[] = [
    t(`${prefix}action`),
    t(`${prefix}ordinalNumber`),
    t(`${prefix}firstName`),
    t(`${prefix}lastName`),
    t(`${prefix}cardNumber`),
    t(`${prefix}birthDate`),
    t(`${prefix}address`),
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer style={{ alignContent: "flex-end" }} component={Paper}>
            <Table id="user" size="small" >
              <TableHead className={classes.tableHead}>
                {tableHeadNames.map((name, i) => (
                  <TableCell key={i} className={classes.tableHeadTitle}>
                    {name}
                  </TableCell>
                ))}
              </TableHead>
              <TableBody>
                {members &&
                  members?.map((member: NotUpdatedMember, i: number) => {
                    return (
                      <TableRow key={i}>
                        <TableCell>
                          <LightTooltip
                            title={t("tooltips.tableActionRowsEdit")}
                            placement={isMobile ? "top" : "left"}
                            arrow
                          >
                            <CreateOutlinedIcon
                              onClick={(x) => handleOpenEditModal(member.id)}
                              name="createIcon"
                              style={{
                                color: "#00B2FF",
                                cursor: "pointer",
                                border: "none",
                              }}
                            />
                          </LightTooltip>
                        </TableCell>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{member.firstName}</TableCell>
                        <TableCell>{member.lastName}</TableCell>
                        <TableCell>{member.cardNumber}</TableCell>
                        <TableCell>{member.birthDate}</TableCell>
                        <TableCell>{member.address}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <TableEditModal
        handleClose={handleToggleEditModal}
        isOpen={isOpenEditModal}
      />
    </>
  );
};

export default NotUpdatedMembersTable;
