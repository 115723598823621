import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useStatisticsStyles = makeStyles()((theme: Theme) => ({
  alert: {
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tabs: {
    background: "transparent",
  },
  typography: {
    cursor: "pointer",
    textAlign:"center"
  },
}));
