import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../app/api/hooks";
import { AddUpdateMembers } from "../../../smart-membership/add-update-member/addUpdateMember";
import { useTableModalStyles } from "../styles/tableModalStyles";
import sidebarBackgroundImage from "../../../../images/sidebarBackgroundImage.png";
import themeSlice from "../../../../slices/theme/themeSlice";
import { hexToRgb } from "../../../../common/helpers/theme";

interface Props {
  isOpen: boolean;
  handleClose: any;
}

const TableEditModal = ({ handleClose, isOpen }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const primaryRgb = hexToRgb(theme.palette.primary.main);

  const { classes } = useTableModalStyles();
  const selectedMember = useAppSelector(
    (state) => state.members.selectedMember
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="text-center"
      maxWidth="lg"
    >
      <DialogTitle className={classes.dialogTitle}>
        <img
          src={sidebarBackgroundImage}
          alt="Sidebar Background"
          className={classes.tableDataModalImage}
          style={{
            background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
          }}
        />
        <Grid className={classes.grid}>
          <CloseIcon
            onClick={(e) => handleClose(e)}
            className={classes.closeIcon}
          />
        </Grid>
        <h3
          style={{
            color: theme.palette.primary.dark,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {t("dashboardPage.editTableModalTitle")}{" "}
          {selectedMember?.personalData.firstName}{" "}
          {selectedMember?.personalData.lastName}
        </h3>
      </DialogTitle>
      <DialogContent className="tableEdit_modal_body">
        {<AddUpdateMembers />}
      </DialogContent>
    </Dialog>
  );
};

export default TableEditModal;
