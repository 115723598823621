import {Box, Grid, SxProps, Typography, useTheme} from "@mui/material";

type CardProps = {
    title: string;
    children: JSX.Element | null;
    disablePadding?: boolean;
};

export default function DashboardCard(props: CardProps) {
    const theme = useTheme();

    const sx: SxProps = {
        borderRadius: 5,
        background: theme.palette.background.default,
        boxShadow: 5,
        fontSize: 12
    };

    return (
        <Box sx={{ ...sx, p: props.disablePadding ? 0 : 2, overflow: "hidden" }}>
            <Grid container mb={props.disablePadding ? 0 : 2}>
                {!props.disablePadding && (
                    <Grid item xs>
                        <Typography
                            variant="body1"
                            textAlign="start"
                            fontWeight="bold"
                            overflow="hidden"
                        >
                            {props.title}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Grid container overflow="hidden">
                <Grid item xs>
                    {props.children}
                </Grid>
            </Grid>
        </Box>
    );
}
