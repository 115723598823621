import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { colors } from "../../../app/theme/colors";

export const useRequestForgotPasswordStyles = makeStyles()((theme: Theme) => ({
  paper: {
    borderRadius: "210px",
    padding: "5px",
  },
  typographyText_label: {
    color: "#A2A89A",
  },
  typographyText_content: {
    color: "#A2A89A",
  },
  title: {
    textAlign: "center",
    color: "#A2A89A",
    textTransform: "uppercase",
  },
  btn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.primary,
    marginBottom: "5px",
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  btnResetPass: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)",
    float: "right",
    color: colors.green,
    marginBottom: "5px",
    "&:hover": {
      background: colors.green,
      color: "white",
    },
  },
  circularProgress: {
    color: colors.primary,
  },
  passwordValidationWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: "20px",
  },
  passwordValidationSuccessIcon: {
    color: colors.green,
  },
  passwordValidationErrorIcon: {
    color: colors.red,
  },
  passwordValidationSuccess: {
    color: colors.green,
    marginLeft: "5px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "17px",
  },
  passwordValidationError: {
    color: colors.red,
    marginLeft: "5px",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "17px",
  },
}));
