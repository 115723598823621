import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/api/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../component/button/AppButton";
import BasicInput from "../../../component/input/basic-input/BasicInput";
import { Role } from "../../../models/data/accounts/Role";
import { SaveAccountModel } from "../../../models/requests/accounts/saveAccountModel";
import { APIStatus } from "../../../models/types/api/APIStatus";
import { selectStatusSavedAccount } from "../../../slices/accounts/accountSlice";
import { UserFormState } from "../UserManagement";
import { useUserManagementSaveModalStyles } from "./userManagementModalStyles";
import { PersonAdd } from "@mui/icons-material";
import sidebarBackgroundImage from "../../../images/sidebarBackgroundImage.png";
import { hexToRgb } from "../../../common/helpers/theme";
import { useTheme } from "@mui/system";
import { CapitalizeFirstLetter } from "../../smart-membership/smartMembershipUtilies";

type Props = {
  userFormState: UserFormState;
  setUserFormState: Dispatch<SetStateAction<UserFormState>>;
  account: SaveAccountModel;
  setAccount: Dispatch<SetStateAction<SaveAccountModel>>;
  onSave: () => any;
  roles: Role[] | null;
  accountStatus: any;
};

const UserManagementModal = (props: Props) => {
  const { t } = useTranslation();
  const { classes } = useUserManagementSaveModalStyles();
  const theme = useTheme();
  const primaryRgb = hexToRgb(theme.palette.primary.main);
  const statusSaveAccount = useAppSelector(selectStatusSavedAccount);

  const [value, setValue] = useState<Role | null>(null);
  const [inputValue, setInputValue] = useState("");

  const isView = props.userFormState === UserFormState.View;

  const isSaving = statusSaveAccount === APIStatus.PENDING;

  useEffect(() => {
    const existingRole = props.roles?.find(
      (r) => r.name === props.account.roleName
    );
    if (!existingRole) {
      setValue(null);
      return;
    }
    setValue(existingRole);
  }, [props.account]);

  useEffect(() => {
    if (statusSaveAccount === "Fulfilled") {
      props.setUserFormState(UserFormState.None);
    }
  }, [statusSaveAccount]);

  const handleOnKeyPressed = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === "Enter") {
      console.log("Enter key pressed");
      switch (id) {
        case "userManagemantModal_input-userTableHeaderFirstName":
          var userTableHeaderFirstName = document.getElementById(
            "userManagemantModal_input-userTableHeaderFirstName"
          );
          userTableHeaderFirstName?.focus();
          break;
        case "userManagemantModal_input-userTableHeaderLastName":
          var userTableHeaderLastName = document.getElementById(
            "userManagemantModal_input-userTableHeaderLastName"
          );
          userTableHeaderLastName?.focus();
          break;
        case "userManagemantModal_input-userTableHeaderEmail":
          var userTableHeaderEmail = document.getElementById(
            "userManagemantModal_input-userTableHeaderEmail"
          );
          userTableHeaderEmail?.focus();
          break;
        case "userManagemantModal_input-userTableHeaderPhone":
          var userTableHeaderPhone = document.getElementById(
            "userManagemantModal_input-userTableHeaderPhone"
          );
          userTableHeaderPhone?.focus();
          break;
        case "userManagemantModal_input-userTableHeaderPhone":
          var userTableHeaderPhone = document.getElementById(
            "userManagemantModal_input-userTableHeaderPhone"
          );
          userTableHeaderPhone?.focus();
          break;
        case "userManagemantModal_autocomplete-roles":
          var roles = document.getElementById(
            "userManagemantModal_autocomplete-roles"
          );
          roles?.focus();
          break;
      }
    }
  };

  return (
    <Dialog
      open={props.userFormState !== UserFormState.None}
      onClose={() => props.setUserFormState(UserFormState.None)}
    >
      <DialogTitle className={classes.dialogTitle}>
        <img
          src={sidebarBackgroundImage}
          alt="Sidebar Background"
          className={classes.userModalImage}
          style={{
            background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
          }}
        />
        <h3 className={classes.title}>
          {t(`userTablePage.userFormState${props.userFormState.toString()}`)}{" "}
          {t("userTablePage.account")}
        </h3>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1} mt={1}>
          <Grid item md={6} xs={12}>
            <BasicInput
              id="userManagemantModal_input-userTableHeaderFirstName"
              label={t("userTablePage.userTableHeaderFirstName")}
              value={props.account?.firstName}
              onChange={(e) => {
                const capitalizedValue = CapitalizeFirstLetter(e.target.value);
                props.setAccount({
                  ...props.account,
                  firstName: capitalizedValue,
                });
              }}
              readOnly={isView}
              onKeyDown={(e: any) =>
                handleOnKeyPressed(
                  e,
                  "userManagemantModal_input-userTableHeaderLastName"
                )
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BasicInput
              id="userManagemantModal_input-userTableHeaderLastName"
              label={t("userTablePage.userTableHeaderLastName")}
              value={props.account?.lastName}
              onChange={(e) => {
                const capitalizedValue = CapitalizeFirstLetter(e.target.value);
                props.setAccount({
                  ...props.account,
                  lastName: capitalizedValue,
                });
              }}
              readOnly={isView}
              onKeyDown={(e: any) =>
                handleOnKeyPressed(
                  e,
                  "userManagemantModal_input-userTableHeaderEmail"
                )
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BasicInput
              id="userManagemantModal_input-userTableHeaderEmail"
              label={t("userTablePage.userTableHeaderEmail")}
              value={props.account?.email}
              onChange={(e) =>
                props.setAccount({ ...props.account, email: e.target.value })
              }
              readOnly={isView}
              onKeyDown={(e: any) =>
                handleOnKeyPressed(
                  e,
                  "userManagemantModal_input-userTableHeaderPhone"
                )
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <BasicInput
              id="userManagemantModal_input-userTableHeaderPhone"
              label={t("userTablePage.userTableHeaderPhone")}
              value={props.account?.phone}
              onChange={(e) =>
                props.setAccount({ ...props.account, phone: e.target.value })
              }
              readOnly={isView}
              onKeyDown={(e: any) =>
                handleOnKeyPressed(e, "userManagemantModal_autocomplete-roles")
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            {isView && (
              <BasicInput
                id="userManagemantModal_input-userTableHeaderRole"
                label={t("userTablePage.userTableHeaderRole")}
                value={props.account?.roleName}
                onChange={() => {}}
                readOnly={isView}
                onKeyDown={(e: any) =>
                  handleOnKeyPressed(
                    e,
                    "userManagemantModal_autocomplete-roles"
                  )
                }
              />
            )}
            {!isView && (
              <>
                <Typography variant="body1" className={classes.inputTitle}>
                  {t("userTablePage.userTableHeaderRole")}:
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    id="userManagemantModal_autocomplete-roles"
                    value={value}
                    onChange={(event, newValue: Role | null) => {
                      setValue(newValue);
                      props.setAccount({
                        ...props.account,
                        roleName: newValue?.name ?? "",
                      });
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    options={props.roles ?? []}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        className={classes.autocompleteTextField}
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.closeBtnWrapper}
        style={{ float: "right" }}
      >
        <AppButton
          id="userManagement_button-close"
          onClick={() => {
            props.setUserFormState(UserFormState.None);
          }}
          label={t("buttons.close")}
          color={theme.palette.primary.main}
          hover={theme.palette.primary.main}
        />
        <AppButton
          id="userManagement_button-save"
          onClick={props.onSave}
          label={t("buttons.save")}
          startIcon={
            isSaving ? (
              <CircularProgress
                className={classes.circularProgress}
                size="20px"
              />
            ) : (
              <PersonAdd />
            )
          }
          color={colors.green}
          hover={colors.green}
          disabled={isView || isSaving}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UserManagementModal;
