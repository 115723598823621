export const colors = {
  primary: "#2f4454",
  sectionTitle: "#4F5459",
  inputLabel: "#6A6B6B",
  validationErrorMessage: "rgba(255, 73, 71, 0.8)",
  green: "#4CAF50",
  lightBlue: "#00B2FF",
  red: "rgba(255, 73, 71, 0.8)",
  backgroundContent: "rgba(234, 236, 237, 0.9)",

  chart: {
    blue: "dodgerblue",
    red: "crimson",
    purple: "blueviolet",
    green: "forestgreen",
    orange: "coral",
    gray: "gray",
  },
};
