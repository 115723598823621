import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useAddUpdateMemberSyles = makeStyles()((theme: Theme) => ({
    stepperContainer: {
        display: "flex",
        flexWrap:"wrap",
        alignItems:"stretch"
    },
    step: {
        minWidth: "60vw"
    }
}));