import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { Provider } from "react-redux";
import { store } from "./app/api/store";
import setupInterceptors from "./app/api/setupInterceptors";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <App />
  </Provider>
);

setupInterceptors(store);
