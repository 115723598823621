import { Navigate } from "react-router";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "../layout/Layout";
import { useAuthenticatedAccount } from "../../hooks/useAuthenticatedAccount";
import { RoleOrdinal } from "../../models/enums/roles/roles";
import LoginPage from "../../pages/LoginPage";
import NotFoundPage from "../../pages/NotFoundPage";
import {
  selectAuthenticatedAccount,
  selectRole,
} from "../../slices/auth/authSlice";
import { useAppSelector } from "../../app/api/hooks";
import { routes } from "./routes";
import AppNotification from "../notification/AppNotification";

export default function AppRouter() {
  const {
    Login,
    Dashboard,
    MemberCards,
    MemberTable,
    MemberManagement,
    Statistics,
    UserManagement,
    SyncAddresses,
    NotFound,
  } = routes;

  const authenticatedAccount = useAppSelector(selectAuthenticatedAccount);
  const roleOrdinal = authenticatedAccount?.rolePriority;

  return (
    <Router>
      <Routes>
        <Route
          path={Login.pathname}
          element={<Authorize isLogin={true} element={Login.element} />}
        />

        <Route
          path={MemberCards.pathname}
          element={<Authorize element={MemberCards.element} />}
        />

        <Route
          path={Dashboard.pathname}
          element={<Authorize element={Dashboard.element} />}
        />

        <Route
          path={MemberTable.pathname}
          element={<Authorize element={MemberTable.element} />}
        />

        <Route
          path={MemberManagement.pathname}
          element={<Authorize element={MemberManagement.element} />}
        />

        <Route
          path={SyncAddresses.pathname}
          element={
            roleOrdinal === RoleOrdinal.Administrator ||
            roleOrdinal === RoleOrdinal.User ? (
              <Navigate to="/unauthorized" />
            
            ) : (
              <Authorize element={SyncAddresses.element} />
            )
          }
        />

        <Route
          path={Statistics.pathname}
          element={
            <Authorize
              element={Statistics.element}
              forbiddenRoles={[RoleOrdinal.User]}
            />
          }
        />

        <Route
          path={UserManagement.pathname}
          element={
            roleOrdinal === RoleOrdinal.Administrator ||
            roleOrdinal === RoleOrdinal.User ? (
              <Navigate to="/unauthorized" />
            ) : (
             
              <Authorize
              element={UserManagement.element}
              forbiddenRoles={[RoleOrdinal.User]}
            />
            )
          }
        />

        <Route path="*" element={<Authorize element={NotFound.element} />} />
      </Routes>

      <AppNotification />
    </Router>
  );
}

type AuthorizeProps = {
  isLogin?: boolean;
  forbiddenRoles?: RoleOrdinal[];
  element: JSX.Element;
};

function Authorize(props: AuthorizeProps) {
  const role = useAppSelector(selectRole);
  const { authenticatedAccount } = useAuthenticatedAccount();

  if (role || authenticatedAccount?.email) {
    if (props.isLogin) {
      return <Navigate to={routes.Dashboard.pathname} />;
    } else {
      const isAllowedAccess = role
        ? !props.forbiddenRoles?.includes(role)
        : false;

      if (isAllowedAccess) {
        return <Layout>{props.element}</Layout>;
      } else {
        return <NotFoundPage />;
      }
    }
  } else {
    return <LoginPage />;
  }
}
