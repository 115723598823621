import CreditCardIcon from "@mui/icons-material/CreditCard";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from "@mui/icons-material/Language";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import { createTheme, StepLabel, ThemeProvider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import * as React from "react";
import { CSSProperties, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/api/hooks";
import { getCities } from "../../../app/api/npdddApi";
import { colors } from "../../../app/theme/colors";
import { City } from "../../../models/data/addresses/City";
import { Street } from "../../../models/data/addresses/Street";
import { Member } from "../../../models/data/members/Member";
import { MemberAddress } from "../../../models/data/members/MemberAddress";
import { MemberCard } from "../../../models/data/members/MemberCard";
import { MemberContact } from "../../../models/data/members/MemberContact";
import { MemberEducation } from "../../../models/data/members/MemberEducation";
import { MemberEmployment } from "../../../models/data/members/MemberEmployment";
import { MemberOtherData as MemberOtherDataModel } from "../../../models/data/members/MemberOtherData";
import { MembershipFee } from "../../../models/data/members/membershipFee";
import { FindElectionPlaceRequest } from "../../../models/requests/election-places/FindElectionPlaceRequest";
import MemberSectionType from "../../../models/types/memberSectionTypes/memberSectionType";
import { selectDisplayLanguage } from "../../../slices/displayLanguages/displayLanguageSlice";
import {
  findElectionPlaceByAddress,
  resetSelectedElectionPlace,
  selectSelectedElectionPlace,
} from "../../../slices/electionPlaces/electionPlaceSlice";
import {
  deleteMember,
  getAvailableCardNumbers,
  getMemberBasicDetails,
  getMemberExpireList,
  getMemberFullDetailsById,
  getMembersPagedList,
  getNextCardNumber,
  resetSelectedMember,
  saveMember,
  selectAvailableCardNumbers,
  selectDataManipulation,
  selectIsEditing,
  selectNextCardNumber,
} from "../../../slices/members/memberSlice";
import { CanSaveMember } from "../../../utilis/MemberValidations";
import { MemberAddressSection } from "../address-section/MemberAddressSection";
import { MemberCardData } from "../card-section/memberCardData";
import { MemberContactData } from "../contact-section/memberContactData";
import { MemberEducationData } from "../education-section/memberEducationData";
import { MemberEmploymentData } from "../employment-section/memberEmploymentData";
import { EmptyGuid, INIT_MEMBER, INIT_MEMBERSHIP_FEE } from "../initialData";
import LanguageSection from "../languages-section/LanguageSection";
import { MemberOtherData } from "../other-data-section/memberOtherData";
import { MemberPersonalData } from "../personal-data-section/MemberPersonalData";
import { ManageMemberButtons } from "../save-buttons/ManageMemberButtons";
import { useAddUpdateMemberSyles } from "./addUpdateMemberStyles";
import { MemberPersonalData as MemberPersonalDataModel } from "../../../models/data/members/MemberPersonalData";
import { useLocation } from "react-router";
import { routes } from "../../../component/router/routes";
import {
  CapitalizeFirstLetter,
  namesToCapitalize,
} from "../smartMembershipUtilies";
import DeleteConfirmationModal from "../../modals/deleteConfirmationModal.tsx/deleteConfirmationModal";

interface step {
  label: string;
  description: string;
  content: any;
  required: boolean | false;
  type: string;
  icon: any;
}

const theme = createTheme({
  palette: {
    secondary: {
      main: colors.primary,
    },
  },
});

export const AddUpdateMembers = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { classes } = useAddUpdateMemberSyles();

  const availableCardNumbers = useAppSelector(selectAvailableCardNumbers);
  const nextCardNumber = useAppSelector(selectNextCardNumber);
  const selectedMember = useAppSelector(
    (state) => state.members.selectedMember
  );
  const selectedElectionPlace = useAppSelector(selectSelectedElectionPlace);
  const displayLanguages = useAppSelector(selectDisplayLanguage);
  const isEditing = useAppSelector(selectIsEditing);
  const dataManipulation = useAppSelector(selectDataManipulation);

  const init_member_data =
    selectedMember !== null ? selectedMember : INIT_MEMBER;

  const [member, setMember] = useState<Member>({ ...init_member_data });
  const [cities, setCities] = useState<City[]>([]);
  const [streets, setStreets] = useState<Street[]>([]);
  const [residenceCities, setResidenceCities] = useState<City[]>([]);
  const [residenceStreets, setResidenceStreets] = useState<Street[]>([]);
  const [selectedResidenceCity, setSelectedResidenceCity] =
    useState<City | null>(null);
  const [selectedResidenceStreet, setSelectedResidenceStreet] =
    useState<Street | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedStreet, setSelectedStreet] = useState<Street | null>(null);

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);

  useEffect(() => {
    // if (init_member_data.personalData.id === EmptyGuid && (member.card.number === undefined || member.card.number === 0)) {
    //   init_member_data.card.number = nextCardNumber || 0;
    // }
    if (residenceCities.length === 0) {
      getCities().then((cities) => {
        setResidenceCities(cities.data || []);
        setCities(cities.data || []);
      });
    }
  });

  // Set next card number to member
  // When it's not selected (when not editing member)
  useEffect(() => {
    if (nextCardNumber && !selectedMember) {
      setMember({
        ...member,
        card: { ...member.card, number: nextCardNumber },
      });
    }
  }, [nextCardNumber]);

  // Set member after fetch by id
  useEffect(() => {
    if (
      selectedMember !== null &&
      selectedMember?.personalData?.id !== EmptyGuid
    ) {
      setMember(selectedMember);
    } else {
      setMember({ ...init_member_data });
    }
  }, [selectedMember]);

  useEffect(() => {
    if (
      member &&
      member?.address?.streetResidenceId !== EmptyGuid &&
      member?.address?.homeNumberResidence
    ) {
      // if (
      //   member?.address?.streetResidenceName &&
      //   member?.address?.cityResidenceName?.length != undefined &&
      //   member?.address?.cityResidenceName?.length > 2
      // ) {
      //   if (member?.address?.electionPlaceId !== selectedElectionPlace?.id) {
      // const filter: FindElectionPlaceRequest = {
      //   cityId: member?.address?.cityResidenceId || EmptyGuid,
      //   cityName: member?.address?.cityResidenceName || "",
      //   streetName: member?.address?.streetResidenceName || "",
      //   streetNumber: member?.address?.homeNumberResidence || "0",
      // };
      // dispatch(findElectionPlaceByAddress(filter));
      //}
      //}
    } else {
      dispatch(resetSelectedElectionPlace());
    }
  }, [member]);

  // Refetch cities on change display language
  useEffect(() => {
    if (!displayLanguages) {
      return;
    }

    getCities().then((cities) => {
      setResidenceCities(cities.data || []);
      setCities(cities.data || []);
    });
  }, [displayLanguages]);

  useEffect(() => {
    setMember({
      ...member,
      address: {
        ...member.address,
        electionPlaceId: selectedElectionPlace?.id ?? null,
      },
    });
  }, [selectedElectionPlace]);

  useEffect(() => {
    if (isEditing) {
      return;
    }

    if (selectedMember) {
      dispatch(resetSelectedMember());
    }

    if (!availableCardNumbers) {
      dispatch(getAvailableCardNumbers());
    }
  }, []);

  const onChange = (
    value: any,
    name:
      | keyof MemberPersonalDataModel
      | keyof MemberAddress
      | keyof MemberCard
      | keyof MemberContact
      | keyof MemberOtherDataModel
      | keyof MemberEmployment
      | keyof MemberEducation
      | keyof MembershipFee,
    type: MemberSectionType
  ) => {
    var newMember = { ...member };

    if (namesToCapitalize.includes(name) && typeof value === "string") {
      value = CapitalizeFirstLetter(value);
    }

    switch (type) {
      case MemberSectionType.memberPersonalData:
        var newMemberPersonalData = { ...member.personalData, [name]: value };
        newMember.personalData = newMemberPersonalData;
        break;
      case MemberSectionType.memberAddress:
        var newAddress = { ...member.address, [name]: value };
        newMember.address = newAddress;
        break;
      case MemberSectionType.memberCard:
        var newMemberCard = { ...member.card, [name]: value };
        newMember.card = newMemberCard;
        break;
      case MemberSectionType.memberContact:
        var newMemberContact = { ...member.contact, [name]: value };
        newMember.contact = newMemberContact;
        break;
      case MemberSectionType.memberOtherData:
        var newMemberOther = { ...member.otherData, [name]: value };
        newMember.otherData = newMemberOther;
        break;
      case MemberSectionType.memberEmpoyment:
        var newMemberEmployment = { ...member.employment, [name]: value };
        newMember.employment = newMemberEmployment;
        break;
      case MemberSectionType.memberEducation:
        var newMemberEducation = { ...member.education, [name]: value };
        newMember.education = newMemberEducation;
        break;
      case MemberSectionType.membershipFee:
        if (!member.membershipFee) {
          let newFee = { ...INIT_MEMBERSHIP_FEE };
          newFee = { ...newFee, [name]: value };
          newMember.membershipFee = { ...newFee };
        } else {
          newMember.membershipFee = { ...member.membershipFee, [name]: value };
        }
        break;
    }
    setMember(newMember);
  };

  // Clear languages array if there is one empty language
  const clearEmptyLanguage = (): Member => {
    if (!member.languages || member.languages.length === 0) {
      return member;
    }

    const languages = [...member.languages].filter(
      (language, i) => language.language || language.level
    );

    const updatedMember = { ...member, languages: [...languages] };

    setMember(updatedMember);

    return updatedMember;
  };

  const clearEmptyElectionPlace = () => {
    if (member.address.electionPlaceId !== EmptyGuid) {
      return member;
    }

    const updMember = { ...member };

    updMember.address.electionPlaceId = null;

    setMember(updMember);

    return updMember;
  };

  const onSave = async () => {
    let memberForSave: Member = { ...member };

    memberForSave = clearEmptyLanguage();
    memberForSave = clearEmptyElectionPlace();

    if (CanSaveMember(memberForSave)) {
      let savedMemberResponse = null;

      if (selectedMember !== null) {
        savedMemberResponse = await dispatch(
          saveMember({ member: memberForSave, type: "update" })
        );
      } else {
        savedMemberResponse = await dispatch(
          saveMember({ member: memberForSave, type: "create" })
        );
      }

      // In case of Saving in the Edit Modal
      if (isEditing && dataManipulation) {
        dispatch(getMembersPagedList({ ...dataManipulation }));
      }

      if (location.pathname === routes.Dashboard.pathname) {
        dispatch(getMemberExpireList());
      }

      dispatch(getMemberBasicDetails({ data: "" }));
      dispatch(getAvailableCardNumbers());
      dispatch(getNextCardNumber());

      if (!savedMemberResponse || (savedMemberResponse.payload as any).error) {
        return;
      }

      const savedMemberData = savedMemberResponse.payload as Member;

      if (savedMemberData.personalData.id) {
        await dispatch(
          getMemberFullDetailsById(savedMemberData.personalData.id)
        );
      }
    }
  };

  const onClear = async () => {
    setMember(INIT_MEMBER);
    dispatch(resetSelectedMember());
    await dispatch(getNextCardNumber());
  };

  const onDelete = async () => {
    const id = member?.personalData?.id;

    if (id && id !== EmptyGuid) {
      await dispatch(deleteMember(id));
      await dispatch(getMemberBasicDetails({ data: "" }));
      await onClear();
      await setOpenDeleteConfirmationModal(false);
    }
  };

  useEffect(() => {}, [selectedResidenceCity]);

  const steps: step[] = [
    {
      label: t("memberManagementPage.personalDataTitle"),
      description: "",
      content: <MemberPersonalData member={member} onChange={onChange} />,
      required: true,
      type: "personal",
      icon: PersonIcon,
    },
    {
      label: t("memberManagementPage.addressTitle"),
      description: "",
      content: (
        <MemberAddressSection
          member={member}
          setMember={setMember}
          onChange={onChange}
          cities={cities}
          streets={streets}
          setStreets={setStreets}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          selectedStreet={selectedStreet}
          setSelectedStreet={setSelectedStreet}
          residenceCities={residenceCities}
          residenceStreets={residenceStreets}
          setResidenceStreets={setResidenceStreets}
          selectedResidenceCity={selectedResidenceCity}
          setSelectedResidenceCity={setSelectedResidenceCity}
          selectedResidenceStreet={selectedResidenceStreet}
          setSelectedResidenceStreet={setSelectedResidenceStreet}
        />
      ),
      required: true,
      type: "address",
      icon: HomeIcon,
    },
    {
      label: t("memberManagementPage.cardDataTitle"),
      description: "",
      content: <MemberCardData onChange={onChange} member={member} />,
      required: false,
      type: "card",
      icon: CreditCardIcon,
    },
    {
      label: t("memberManagementPage.employmentDataTitle"),
      description: "",
      content: <MemberEmploymentData member={member} onChange={onChange} />,
      required: false,
      type: "employment",
      icon: WorkIcon,
    },
    {
      label: t("memberManagementPage.educationTitle"),
      description: "",
      content: <MemberEducationData member={member} onChange={onChange} />,
      required: false,
      type: "education",
      icon: SchoolIcon,
    },
    {
      label: t("memberManagementPage.contactTitle"),
      description: "",
      content: <MemberContactData member={member} onChange={onChange} />,
      required: false,
      type: "contact",
      icon: PermPhoneMsgIcon,
    },
    {
      label: t("memberManagementPage.languageTitle"),
      description: "",
      // content: <MemberLanguageData member={member} setMember={setMember} />,
      content: <LanguageSection member={member} setMember={setMember} />,
      required: false,
      type: "languages",
      icon: LanguageIcon,
    },
    {
      label: t("memberManagementPage.contactOther"),
      description: "",
      content: <MemberOtherData member={member} onChange={onChange} />,
      required: false,
      type: "other",
      icon: DevicesOtherIcon,
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const validateFields = (type: string): CSSProperties => {
    let result: CSSProperties = {};
    switch (type) {
      case "personal":
        if (
          member?.personalData?.firstName !== null &&
          member?.personalData?.firstName !== "" &&
          member?.personalData?.lastName !== null &&
          member?.personalData?.lastName !== "" &&
          member?.personalData?.birthDate !== null &&
          member?.personalData?.birthDate !== ""
        ) {
          result = { fontSize: "20px" };
        } else {
          result = { color: theme.palette.error.main, fontSize: "20px" };
        }
        break;
      case "address":
        if (
          (member?.address?.cityResidenceId !== null ||
            member?.address?.cityResidenceName !== "") &&
          (member?.address?.streetResidenceId !== null ||
            member?.address?.streetResidenceName !== "")
        ) {
          result = { fontSize: "20px" };
        } else {
          result = { color: theme.palette.error.main, fontSize: "20px" };
        }
        break;
    }
    return result;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <ManageMemberButtons
          onClear={onClear}
          // onDelete={onDelete}
          onSave={onSave}
          member={member}
          openModal={() => setOpenDeleteConfirmationModal(true)}
        />

        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation="vertical"
          className={classes.stepperContainer}
        >
          {steps.map((step, index) => (
            <Step
              className={classes.step}
              key={step.label}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color:
                    member?.personalData?.firstName !== null &&
                    member?.personalData?.firstName !== ""
                      ? theme.palette.success.main
                      : theme.palette.error.main, // circle color (COMPLETED)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: theme.palette.primary.light, // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-root .Mui-active": {
                  color: theme.palette.primary.dark, // circle color (ACTIVE)
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "common.white", // Just text label (ACTIVE)
                  },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "white", // circle's number (ACTIVE)
                },
              }}
            >
              <StepLabel
                StepIconComponent={step.icon}
                onClick={handleStep(index)}
                style={
                  activeStep === index
                    ? { color: theme.palette.info.light, cursor: "pointer" }
                    : { color: theme.palette.info.light, cursor: "pointer" }
                }
              >
                <Typography
                  style={
                    step.required
                      ? validateFields(step.type)
                      : { fontSize: "20px" }
                  }
                >
                  {step.label}
                </Typography>
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <div
                  style={{
                    padding: "15px",
                    backgroundColor: "rgba(47,68,84, 0.05)",
                    borderRadius: "10px",
                  }}
                >
                  {step.content}
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <ManageMemberButtons
          onClear={onClear}
          // onDelete={onDelete}
          onSave={onSave}
          member={member}
          openModal={() => setOpenDeleteConfirmationModal(true)}
        />
      </Box>
      <DeleteConfirmationModal
        handleDelete={onDelete}
        handleClose={() => setOpenDeleteConfirmationModal(false)}
        open={openDeleteConfirmationModal}
        memberName={`${member?.personalData?.firstName} ${member?.personalData?.lastName}`}
      />
    </ThemeProvider>
  );
};
