import { useTranslation } from "react-i18next";
import { MemberAgeStatisticModel } from "../../../models/Responses/members/statistics/memberAgeStatisticModel";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { colors } from "../../../app/theme/colors";

type Props = {
  memberAgeStatistic: MemberAgeStatisticModel[];
};

export default function ChartMemberAgeStatistic(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <ResponsiveContainer width="100%" height={210}>
        <BarChart data={props.memberAgeStatistic}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="gender"
            tickFormatter={(gender: string) => t(`gender.${gender}`)}
          />
          <YAxis />
          <Tooltip labelFormatter={(gender: string) => t(`gender.${gender}`)} />
          <Legend  fontSize={12}/>
          <Bar
            dataKey="from21To30"
            fill={colors.chart.green}
            name={t(`chart.ageStatistic.from21To30`) ?? ""}
          />
          <Bar
            dataKey="from31To40"
            fill={colors.chart.purple}
            name={t(`chart.ageStatistic.from31To40`) ?? ""}
          />
          <Bar
            dataKey="from41To50"
            fill={colors.chart.blue}
            name={t(`chart.ageStatistic.from41To50`) ?? ""}
          />
          <Bar
            dataKey="from51To60"
            fill={colors.chart.orange}
            name={t(`chart.ageStatistic.from51To60`) ?? ""}
          />
          <Bar
            dataKey="from61To70"
            fill={colors.chart.red}
            name={t(`chart.ageStatistic.from61To70`) ?? ""}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
