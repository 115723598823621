import { api } from "../../app/api/api";
import { CreateReportRequest } from "../../models/requests/reports/createReportRequest";

const createReport = async (request: CreateReportRequest) => {
  return api.post("/report/create", request, {
    responseType: "blob",
  }) as Promise<Blob>;
};

export const ReportApi = {
  CreateReport: createReport,
};
