import { useState } from "react";

export const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isOpenPopover = Boolean(anchorEl);

  const idPopover = isOpenPopover ? "popover" : undefined;

  const handleOpenPopover = (e: any) => {
    setAnchorEl(e.target);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return {
    isOpen: isOpenPopover,
    id: idPopover,
    handleOpen: handleOpenPopover,
    handleClose: handleClosePopover,
    anchorEl: anchorEl,
  };
};
