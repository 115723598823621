import { useTranslation } from "react-i18next";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { colors } from "../../../app/theme/colors";
import { MemberGenderStatisticModel } from "../../../models/Responses/members/statistics/memberGenderStatisticModel";

const COLORS = [colors.chart.blue, colors.chart.red, colors.chart.gray];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  payload,
}: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: any;
  outerRadius: any;
  percent: any;
  index: any;
  payload: any;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   const value = `${payload.count} (${(percent * 100).toFixed(2)}%)`;

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

type Props = {
  memberGenderStatistic: MemberGenderStatisticModel[];
};

export default function ChartMemberGenderStatistic(props: Props) {
  const { t } = useTranslation();

  return (
    <>
      <ResponsiveContainer width="100%" height={210}>
        <PieChart>
          <Pie
            data={props.memberGenderStatistic}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={85}
            fill="#8884d8"
            dataKey="percentage"
          >
            {props.memberGenderStatistic.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              );
            })}
          </Pie>

          <Legend
            formatter={(value, entry) => {
              const { gender } = entry.payload as any;
              return t(`gender.${gender}`);
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </>
  );
}
