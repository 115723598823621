import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type Props = {
  iconSize: number;
  isSmallScreen: boolean;
  backgroundImage: string;
};

export const useMemberCardStyles = makeStyles<Props>()(
  (theme: Theme, props: Props) => ({
    root: {
      borderRadius: 10,
      overflow: "auto",
      maxWidth: 700,
      cursor: "pointer",
      transition: "all .2s ease-in-out",

      "&:hover": {
        transform: "scale(1.05)",
      },
    },
    box: {
      backgroundImage: `url(${props.backgroundImage})`,
      backgroundSize: "cover",
      padding: `20px`,
      position: "relative",
      transition: "all .2s ease-in-out",
    },
    circle: {
      position: "absolute",
      bottom: `-${props.iconSize / 2}px`,
      transform: "translateX(-50%)",
      width: `${props.iconSize}px`,
      height: `${props.iconSize}px`,
      borderRadius: "50%",
      backgroundSize: "cover",
    },
    imgLogo: {
      position: "absolute",
            top: "50%",
            left: "80%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            width: "auto",
            height: "100px",
    }
  })
);
