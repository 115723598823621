import { AddUpdateMembers } from "../features/smart-membership/add-update-member/addUpdateMember";
import SelectMemberSection from "../features/smart-membership/select-member/SelectMemberSection";

export default function SmartMembershipPage() {
  return (
    <div style={{ marginTop: 10 }}>
      <SelectMemberSection />
      <AddUpdateMembers />
    </div>
  );
}
