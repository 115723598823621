import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

type LogoCircleStylesProps = {
  fillColor: string;
};

export const useLogoCircleStyles = makeStyles<LogoCircleStylesProps>()(
  (theme: Theme, props: LogoCircleStylesProps) => ({
    root: {
      fontWeight: "bold",
      borderRadius: "50%",
      border: "3px solid #fff",
      backgroundColor: props.fillColor,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "24px",

      transition: "all 0.2s ease-in-out",

      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  })
);
