import { CheckCircle, Error, Info, Warning } from "@mui/icons-material";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import Spinner from "../spinner/Spinner";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import {
  clearNotification,
  selectNotification,
} from "../../slices/notification/notificationSlice";
import { NotificationType } from "../../models/app/notification/notificationType";

export default function AppNotification() {
  const dispatch = useAppDispatch();
  const { message, type } = useAppSelector(selectNotification);

  const determineAlertSeverity = (): AlertColor => {
    switch (type) {
      case NotificationType.Pending:
        return "info";

      case NotificationType.Success:
        return "success";

      case NotificationType.Info:
        return "info";

      case NotificationType.Warning:
        return "warning";

      case NotificationType.Error:
        return "error";

      default:
        return "info";
    }
  };

  const determineAlertIcon = (): JSX.Element | boolean => {
    switch (type) {
      case NotificationType.Pending:
        return <Spinner style={{ width: "1em", height: "1em" }} />;

      case NotificationType.Success:
        return <CheckCircle style={{ fontSize: "1em" }} />;

      case NotificationType.Info:
        return <Info style={{ fontSize: "1em" }} />;

      case NotificationType.Warning:
        return <Warning style={{ fontSize: "1em" }} />;

      case NotificationType.Error:
        return <Error style={{ fontSize: "1em" }} />;

      default:
        return false;
    }
  };

  return (
    <Snackbar
      open={message !== null}
      autoHideDuration={type !== NotificationType.Pending ? 3000 : null}
      onClose={() => {
        if (type !== NotificationType.Pending) {
          dispatch(clearNotification());
        }
      }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    >
      <Alert
        onClose={() => {
          dispatch(clearNotification());
        }}
        severity={determineAlertSeverity()}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
        }}
        icon={determineAlertIcon()}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
