import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useSelectMemberSectionStyles = makeStyles()((theme: Theme) => ({
  card: {
    boxShadow: "0 0 10px rgb(0 0 0 / 0.3)",
    borderRadius: "1rem",
  },
  sectionTitle: {
    textAlign: "start",
    color: theme.palette.info.dark,
    textTransform: "uppercase",
    marginBottom: "1rem",
  },
  inputContainer: {
    marginBottom: "1rem",
  },
  inputTitle: { fontWeight: "bold", color: theme.palette.info.dark, textAlign: "start" },
  autocompleteTextField: {
    background: "transparent",
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
      background: "transparent"
    },

    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "2rem",
    },
  },
  autocomplete: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: theme.palette.info.dark,
        background: "transparent"
      },
    },
  },
}));
