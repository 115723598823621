import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getElectionPlaceByAddress as getElectionPlaceByAddressApi,
  getElectionPlaceById as getElectionPlaceByIdApi,
  getElectionPlaceRegionsByCityId as getElectionPlaceRegionsByCityIdApi,
  getElectionPlacesByCityId as getElectionPlaceByCityIdApi,
} from "../../app/api/npdddApi";
import { RootState } from "../../app/api/store";
import ElectionPlace from "../../models/data/addresses/electionPlaceModel";
import ElectionPlaceRegion from "../../models/data/addresses/electionPlaceRegion";
import { FindElectionPlaceRequest } from "../../models/requests/election-places/FindElectionPlaceRequest";
import { APIData } from "../../models/types/api/APIData";
import { APIError } from "../../models/types/api/APIError";
import { APIResponse } from "../../models/types/api/APIResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import { execute, fulfilled, pending, rejected } from "../helpers/sliceHelpers";

const INIT_API_DATA = {
  status: APIStatus.IDLE,
};

export type ElectionPlaceState = {
  fetchedElectionPlaceRegionsByCityId: APIData<ElectionPlaceRegion[]>;
  fetchedElectionPlaceById: APIData<ElectionPlace>;
  fetchedElectionPlacesByCityId: APIData<ElectionPlace[]>;
  selectedElectionPlace: ElectionPlace | null;
  isLoadingElectionPlace: boolean;
  electionPlacesRegions: ElectionPlaceRegion[];
  electionPlaces: ElectionPlace[];
  isLoadingElectionPlacesRegions: boolean;
  isLoadingElectionPlaces: boolean;
};

const initialState: ElectionPlaceState = {
  fetchedElectionPlaceRegionsByCityId: INIT_API_DATA,
  fetchedElectionPlaceById: INIT_API_DATA,
  fetchedElectionPlacesByCityId: INIT_API_DATA,
  selectedElectionPlace: null,
  isLoadingElectionPlace: false,
  electionPlacesRegions: [],
  electionPlaces: [],
  isLoadingElectionPlacesRegions: false,
  isLoadingElectionPlaces: false,
};

export const getElectionPlaceRegionsByCityId = createAsyncThunk<
  APIResponse<ElectionPlaceRegion[]>,
  string,
  { rejectValue: APIError }
>(
  "ElectionPlace/Get_ElectionPlaceRegions_By_City_Id",
  async (cityId: string, { rejectWithValue }) =>
    execute(getElectionPlaceRegionsByCityIdApi(cityId), rejectWithValue)
);

export const getElectionPlaceById = createAsyncThunk<
  APIResponse<ElectionPlace>,
  string,
  { rejectValue: APIError }
>(
  "ElectionPlace/Get_ElectionPlace_By_Id",
  async (id: string, { rejectWithValue }) =>
    execute(getElectionPlaceByIdApi(id), rejectWithValue)
);

export const getElectionPlacesByCityId = createAsyncThunk<
  APIResponse<ElectionPlace[]>,
  string,
  { rejectValue: APIError }
>(
  "ElectionPlace/Get_ElectionPlace_By_City_Id",
  async (cityId: string, { rejectWithValue }) =>
    execute(getElectionPlaceByCityIdApi(cityId), rejectWithValue)
);

export const findElectionPlaceByAddress = createAsyncThunk<
  APIResponse<ElectionPlace>,
  FindElectionPlaceRequest,
  { rejectValue: APIError }
>(
  "ElectionPlace/Find_ElectionPlace_By_Address",
  async (filter: FindElectionPlaceRequest, { rejectWithValue }) =>
    execute(getElectionPlaceByAddressApi(filter), rejectWithValue)
);

export const resetSelectedElectionPlace = createAsyncThunk(
  "ElectionPlace/Reset_Selected_Election_Place",
  async () => {
    return true;
  }
);

export const resetElectionPlaces = createAsyncThunk(
  "ElectionPlace/Reset_Election_Places",
  async () => {
    return true;
  }
);

export const electionPlaceSlice = createSlice({
  name: "electionPlace",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getElectionPlaceRegionsByCityId
      .addCase(getElectionPlaceRegionsByCityId.pending, (state) => {
        pending(state.fetchedElectionPlaceRegionsByCityId);
        state.isLoadingElectionPlacesRegions = true;
        state.electionPlacesRegions = [];
      })
      .addCase(getElectionPlaceRegionsByCityId.fulfilled, (state, action) => {
        fulfilled(state.fetchedElectionPlaceRegionsByCityId, action);
        state.isLoadingElectionPlacesRegions = false;
        state.electionPlacesRegions = action.payload.data || [];
      })
      .addCase(getElectionPlaceRegionsByCityId.rejected, (state, action) => {
        rejected(state.fetchedElectionPlaceRegionsByCityId, action);
        state.isLoadingElectionPlacesRegions = false;
        state.electionPlacesRegions = [];
      })

      // getElectionPlaceById
      .addCase(getElectionPlaceById.pending, (state) => {
        pending(state.fetchedElectionPlaceById);
        state.isLoadingElectionPlace = true;
      })
      .addCase(getElectionPlaceById.fulfilled, (state, action) => {
        fulfilled(state.fetchedElectionPlaceById, action);

        state.selectedElectionPlace = action.payload.data;
        state.isLoadingElectionPlace = false;
      })
      .addCase(getElectionPlaceById.rejected, (state, action) => {
        rejected(state.fetchedElectionPlaceById, action);
        state.selectedElectionPlace = null;
        state.isLoadingElectionPlace = false;
      })

      // getElectionPlacesByCityId
      .addCase(getElectionPlacesByCityId.pending, (state) => {
        pending(state.fetchedElectionPlacesByCityId);
        state.isLoadingElectionPlaces = true;
        state.electionPlaces = [];
      })
      .addCase(getElectionPlacesByCityId.fulfilled, (state, action) => {
        fulfilled(state.fetchedElectionPlacesByCityId, action);
        state.isLoadingElectionPlaces = false;
        state.electionPlaces = action.payload.data || [];
      })
      .addCase(getElectionPlacesByCityId.rejected, (state, action) => {
        rejected(state.fetchedElectionPlacesByCityId, action);
        state.isLoadingElectionPlaces = false;
        state.electionPlaces = [];
      })

      // findElectionPlacesByAddress
      .addCase(findElectionPlaceByAddress.pending, (state) => {
        pending(state.fetchedElectionPlaceById);
        state.isLoadingElectionPlace = true;
      })
      .addCase(findElectionPlaceByAddress.fulfilled, (state, action) => {
        fulfilled(state.fetchedElectionPlaceById, action);

        state.selectedElectionPlace = action.payload.data;
        state.isLoadingElectionPlace = false;
      })
      .addCase(findElectionPlaceByAddress.rejected, (state, action) => {
        rejected(state.fetchedElectionPlaceById, action);
        state.selectedElectionPlace = null;
        state.isLoadingElectionPlace = false;
      })

      // resetSelectedElectionPlace
      .addCase(resetSelectedElectionPlace.fulfilled, (state) => {
        state.selectedElectionPlace = null;
        state.isLoadingElectionPlace = false;
      })

      // resetElectionPlaces
      .addCase(resetElectionPlaces.fulfilled, (state) => {
        state.electionPlaces = [];
        state.selectedElectionPlace = null;
      });
  },
});

export const selectSelectedElectionPlace = (state: RootState) =>
  state.electionPlace.selectedElectionPlace;

export default electionPlaceSlice.reducer;
