import { PaletteMode } from "@mui/material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/api/store";

export const getThemeMode = () => {
  const themeMode = localStorage.getItem("themeMode");

  if (!themeMode) {
    localStorage.setItem("themeMode", "light");
    return "light";
  }

  const result = themeMode as PaletteMode;

  localStorage.setItem("themeMode", result);

  return result;
};

interface ThemeState {
  mode: PaletteMode;
}

const initialState: ThemeState = {
  mode: getThemeMode(),
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setThemeMode(state, action: PayloadAction<PaletteMode>) {
      state.mode = action.payload;
      localStorage.setItem("themeMode", action.payload);
    },
  },
  extraReducers: (builder) => {},
});

export const { setThemeMode } = themeSlice.actions;

export const selectThemeMode = (state: RootState) => state.theme.mode;

export default themeSlice.reducer;
