type Props = {
  fillColor?: string;
};

export default function MemberTableIcon(props: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 18V0H18V18H0ZM2 6H16V2H2V6ZM7.325 11H10.675V8H7.325V11ZM7.325 16H10.675V13H7.325V16ZM2 11H5.325V8H2V11ZM12.675 11H16V8H12.675V11ZM2 16H5.325V13H2V16ZM12.675 16H16V13H12.675V16Z"
        fill={props.fillColor ?? "#fff"}
      />
    </svg>
  );
}
