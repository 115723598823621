import AppRouter from "./component/router/AppRouter";
import useAppTheme from "./hooks/common/useAppTheme";
import { ThemeProvider } from "@mui/material";

export default function App() {
  const { theme } = useAppTheme();

  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
}