import { AccountCircle, Menu } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/api/hooks";
import { getInitialsFromFullName } from "../../common/helpers/account";
import { getCurrentRouteTitleI18n } from "../../common/helpers/route";
import useMenu from "../../hooks/common/useMenu";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import useSetAppTitle from "../../hooks/common/useSetAppTitle";
import { selectAuthenticatedAccount } from "../../slices/auth/authSlice";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import ThemeModeSwitcher from "../themeModeSwitcher/ThemeModeSwitcher";
import ProfileMenu from "./ProfileMenu";

type HeaderProps = {
  sidebarWidth: number;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
};

export default function Header(props: HeaderProps) {
  const { isSmallScreen } = useScreenWidth();
  const location = useLocation();
  const { t } = useTranslation();
  const menu = useMenu();
  const authenticatedAccount = useAppSelector(selectAuthenticatedAccount);
  const accountName = `${authenticatedAccount?.firstName} ${authenticatedAccount?.lastName}`;
  const theme = useTheme();
  const fillColor = theme.palette.text.primary;
  const pageTitle = getCurrentRouteTitleI18n(location.pathname) ?? "";

  useSetAppTitle({
    titleI18n: pageTitle,
  });

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${props.sidebarWidth}px)`,
        ...(theme.palette.mode === "light"
          ? {
              backgroundColor: "#fff",
            }
          : {}),
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.toggleSidebar}
            >
              <Menu />
            </IconButton>
          </Grid>

          <Grid item>
            <Typography component="div" sx={{ flexGrow: 1, fontSize: "1rem" }}>
              {t(pageTitle)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent={"end"} spacing={3}>
          {!isSmallScreen && (
            <Grid item xs="auto" display="flex" alignItems="center">
              <LanguageSwitcher />
            </Grid>
          )}

          {!isSmallScreen && (
            <Grid item xs="auto" display="flex" alignItems="center">
              <ThemeModeSwitcher />
            </Grid>
          )}

          {(!isSmallScreen || (isSmallScreen && !props.isSidebarOpen)) && (
            <Grid item xs="auto" display="flex" alignItems="center">
              <ProfileMenu
                element={
                  <IconButton onClick={menu.onOpen}>
                    {!authenticatedAccount ? (
                      <AccountCircle sx={{ color: fillColor }} />
                    ) : (
                      <Avatar sx={{ width: 35, height: 35, fontSize: "0.7em" }}>
                        {getInitialsFromFullName(accountName)}
                      </Avatar>
                    )}
                  </IconButton>
                }
                menu={menu}
              />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
