import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../app/api/hooks";
import Statistic from "../features/statistic/statistic";
import {
  getAllStatistic,
  selectStatistic,
} from "../slices/members/memberSlice";

export default function StatisticsPage() {
  const dispatch = useAppDispatch();

  const statisticDetails = useSelector(selectStatistic);

  useEffect(() => {
    if (statisticDetails) {
      return;
    }

    dispatch(getAllStatistic());
  }, [statisticDetails]);

  return (
    <div style={{ marginTop: 10 }}>
      <Statistic statisticDetails={statisticDetails} />
    </div>
  );
}
