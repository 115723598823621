import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Account } from "../../../models/data/accounts/account";
import "./myProfileModal.css";
import { useMyProfileModalStyles } from "./myProfileModalStyles";
import AppButton from "../../../component/button/AppButton";
import sidebarBackgroundImage from "../../../images/sidebarBackgroundImage.png";
import { useState } from "react";
import { hexToRgb } from "../../../common/helpers/theme";

interface Props {
  open: any;
  modalClose: any;
  currentUser: Account | null;
}

export default function MyProfileModal({
  open,
  modalClose,
  currentUser: currentProfile,
}: Props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const primaryRgb = hexToRgb(theme.palette.primary.main);
  const { classes } = useMyProfileModalStyles();

  return (
    <Dialog onClose={modalClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle id="myProfileModal" className={classes.dialogTitle}>
        <img
          src={sidebarBackgroundImage}
          alt="Sidebar Background"
          className={classes.profileModalImage}
          style={{
            background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
          }}
        />
        <Grid className={classes.grid}>
          <CloseIcon onClick={(e) => modalClose(e)} className={classes.icon} />
        </Grid>
        <h3 className={classes.modalTitle}>{t("myProfileData.myProfile")}</h3>
      </DialogTitle>
      <DialogContent>
        <Box className="p-3 mb-2">
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.firstName")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.firstName}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.lastName")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.lastName}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.email")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.email}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.phoneNumber")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.phone}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} sm={12}>
              <InputGroup>
                <Grid item md={4}>
                  <Typography
                    variant="body1"
                    className={classes.typographyText}
                  >
                    {t("myProfileData.role")}:
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography className={classes.typographyText_content}>
                    {currentProfile?.roleName}
                  </Typography>
                </Grid>
              </InputGroup>
            </Grid>
          </Grid>
        </Box>
        <Row>
          <Divider className={classes.profileDivider} />
          <Grid item md={12} sm={12} className={classes.profileButtonContainer}>
            <AppButton
              color={theme.palette.primary.main}
              hover={theme.palette.primary.main}
              label={t("myProfileData.close")}
              onClick={modalClose}
            />
          </Grid>
        </Row>
      </DialogContent>
    </Dialog>
  );
}
