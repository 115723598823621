import { Language, Logout, Person2Outlined } from "@mui/icons-material";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppMenu } from "../../models/app/menu/appMenu";
import LanguageSwitcher from "../languageSwitcher/languageSwitcher";
import ThemeModeSwitcher from "../themeModeSwitcher/ThemeModeSwitcher";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import {
  logout,
  selectAuthenticatedAccount,
} from "../../slices/auth/authSlice";
import useModal from "../../hooks/common/useModal";
import MyProfileModal from "../../features/modals/myProfileModal/myProfileModal";

type Props = {
  element: JSX.Element;
  menu: AppMenu;
};

export default function ProfileMenu(props: Props) {
  const dispatch = useAppDispatch();
  const { isSmallScreen } = useScreenWidth();
  const { t } = useTranslation();
  const authenticatedAccount = useAppSelector(selectAuthenticatedAccount);
  const myProfileModal = useModal();

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      {props.element}

      <Menu
        open={props.menu.isOpen}
        anchorEl={props.menu.anchorEl}
        onClose={props.menu.onClose}
      >
        <MenuList
          style={{
            minWidth: 200,
          }}
        >
          <MenuItem sx={{ justifyContent: "center" }}>
            <Typography variant="h5" textAlign="center">
              {authenticatedAccount?.firstName} {authenticatedAccount?.lastName}
            </Typography>
          </MenuItem>

          <Divider />

          <MenuItem onClick={myProfileModal.open}>
            <ListItemIcon>
              <Person2Outlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("myProfile.myProfileButton")}</ListItemText>
          </MenuItem>

          {isSmallScreen && (
            <>
              <MenuItem>
                <LanguageSwitcher />
              </MenuItem>

              <MenuItem>
                <ThemeModeSwitcher />
              </MenuItem>
            </>
          )}

          <Divider />

          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("sidebar.sidebarLogout")}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>

      <MyProfileModal
        open={myProfileModal.isOpen}
        modalClose={myProfileModal.close}
        currentUser={authenticatedAccount}
      />
    </>
  );
}
