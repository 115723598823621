import { t } from "i18next";
import { Checkbox, FormControlLabel, FormGroup, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/api/hooks";
import SimpleAutocomplete from "../../../component/autocomplete/simpleAutocomplete";
import { City } from "../../../models/data/addresses/City";
import { Street } from "../../../models/data/addresses/Street";
import { Member } from "../../../models/data/members/Member";
import CircularProgress from "@mui/material/CircularProgress";
import ElectionPlaceDetailsModal from "../../modals/electionPlaceDetailsModal/electionPlaceDetailsModal";
import { EmptyGuid } from "../initialData";
import { useAddressSectionStyles } from "./addressSectionStyles";
import MemberAddressForm from "./MemberAddressForm";
import ElectionPlaceRegion from "../../../models/data/addresses/electionPlaceRegion";
import {
  getElectionPlaceById,
  resetSelectedElectionPlace,
  selectSelectedElectionPlace,
} from "../../../slices/electionPlaces/electionPlaceSlice";
import ElectionPlace from "../../../models/data/addresses/electionPlaceModel";
import CustomTooltip from "../../../component/tooltip/CustomTooltip";
import AppButton from "../../../component/button/AppButton";

interface Props {
  member: Member;
  setMember: any;
  onChange: any;
  cities: City[];
  streets: Street[];
  setStreets: any;
  residenceCities: City[];
  residenceStreets: Street[];
  setResidenceStreets: any;
  selectedResidenceCity: City | null;
  selectedResidenceStreet: Street | null;
  setSelectedResidenceCity: any;
  setSelectedResidenceStreet: any;
  selectedCity: City | null;
  selectedStreet: Street | null;
  setSelectedCity: any;
  setSelectedStreet: any;
}

export const MemberAddressSection = ({
  member,
  setMember,
  onChange,
  cities,
  streets,
  setStreets,
  selectedCity,
  setSelectedCity,
  selectedStreet,
  setSelectedStreet,
  residenceCities,
  residenceStreets,
  setResidenceStreets,
  selectedResidenceCity,
  setSelectedResidenceCity,
  selectedResidenceStreet,
  setSelectedResidenceStreet,
}: Props) => {
  const dispatch = useAppDispatch();
  const { classes } = useAddressSectionStyles();
  const theme = useTheme();

  const selectedElectionPlace = useAppSelector(selectSelectedElectionPlace);
  const isLoadingElectionPlace = useAppSelector(
    (state) => state.electionPlace.isLoadingElectionPlace
  );
  const electionPlacesRegions = useAppSelector(
    (state) => state.electionPlace.electionPlacesRegions
  );
  const isLoadingElectionPlaces = useAppSelector(
    (state) => state.electionPlace.isLoadingElectionPlaces
  );
  const electionPlaces = useAppSelector(
    (state) => state.electionPlace.electionPlaces
  );
  const isLoadingRegions = useAppSelector(
    (state) => state.electionPlace.isLoadingElectionPlacesRegions
  );

  const [showElectionPlaceRegions, setShowElectionPlaceRegions] =
    useState(false);
  const [showElectionPlaces, setShowElectionPlaces] = useState(false);
  const [isOpenElectionPlaceDetailsModal, setIsOpenElectionPlaceDetailsModal] =
    useState(false);
  const [selectedElectionplaceRegion, setSelectedElectionPlaceRegion] =
    useState<ElectionPlaceRegion | null>(null);
  const [electionplace, setElectionPlace] = useState<ElectionPlace | null>(
    null
  );

  const handleCloseElectionPlaceDetailsModal = (e: any) => {
    setIsOpenElectionPlaceDetailsModal(false);
  };

  const handleOpenElectionPlaceDetailsModal = () => {
    setIsOpenElectionPlaceDetailsModal(true);
  };

  const onChangeRegion = (e: any, value: ElectionPlaceRegion | null) => {
    setSelectedElectionPlaceRegion(value);

    if (value) {
      dispatch(getElectionPlaceById(value.electionPlaceId));
    } else {
      dispatch(resetSelectedElectionPlace());
    }
  };

  const onChangeElelectionPlace = (e: any, value: ElectionPlace | null) => {
    setElectionPlace(value);
    if (value) {
      if (member.address.electionPlaceId !== value.id) {
        dispatch(getElectionPlaceById(value.id));
      }
    } else {
      dispatch(resetSelectedElectionPlace());
    }
  };

  return (
    <Grid style={{ width: "100%" }}>
      <MemberAddressForm
        selectedMember={member}
        setSelectedMember={setMember}
        cities={cities}
        streets={streets}
        setStreets={setStreets}
        selectedCity={selectedCity}
        setSelectedCity={setSelectedCity}
        selectedStreet={selectedStreet}
        setSelectedStreet={setSelectedStreet}
        residenceCities={residenceCities}
        residenceStreets={residenceStreets}
        setResidenceStreets={setResidenceStreets}
        selectedResidenceCity={selectedResidenceCity}
        setSelectedResidenceCity={setSelectedResidenceCity}
        selectedResidenceStreet={selectedResidenceStreet}
        setSelectedResidenceStreet={setSelectedResidenceStreet}
      />

      <Grid container spacing={2} className={classes.inputContainer}>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  id="memberAddressSelection_checkbox-showElectionPlaceRegions"
                  checked={showElectionPlaceRegions === true}
                  onChange={(e) =>
                    setShowElectionPlaceRegions(e.target.checked)
                  }
                  size="small"
                  className={classes.checkbox}
                />
              }
              label={
                <>
                  {t(
                    "memberManagementPage.addressesElectionPlaceRegionCheckboxLabel"
                  )}
                  <CustomTooltip
                    infoText={t(
                      "tooltips.electionPlaceRegionAddressSectionText"
                    )}
                    infoTitle={t("tooltips.tooltipTitle")}
                  />
                </>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>

      {showElectionPlaceRegions && (
        <>
          {!showElectionPlaces && (
            <>
              <Grid container spacing={2} className={classes.inputContainer}>
                {!showElectionPlaces && (
                  <Grid item lg={8} md={8} sm={12} xs={12}>
                    <SimpleAutocomplete
                      label={t(
                        "memberManagementPage.addressesElectionPlaceRegionArea"
                      )}
                      value={selectedElectionplaceRegion}
                      onChange={onChangeRegion}
                      options={electionPlacesRegions}
                      id={"memberElectionPlaceRegions"}
                      loading={isLoadingRegions}
                      getOptionLabel={(option: ElectionPlaceRegion) =>
                        option.region || ""
                      }
                    />
                  </Grid>
                )}
              </Grid>
              <br />
            </>
          )}

          <Grid container spacing={2} className={classes.inputContainer}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      id="memberAddressSelection_checkbox-showElectionPlaceRegions"
                      checked={showElectionPlaces === true}
                      onChange={(e) => setShowElectionPlaces(e.target.checked)}
                      size="small"
                      className={classes.checkbox}
                    />
                  }
                  label={t(
                    "memberManagementPage.addressesElectionPlaceCheckboxLabel"
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>
          {showElectionPlaces && (
            <Grid container spacing={2} className={classes.inputContainer}>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <SimpleAutocomplete
                  label={t("memberManagementPage.addressesElectionPlaceArea")}
                  value={selectedElectionPlace}
                  getOptionLabel={(option: ElectionPlace) =>
                    `${option.electionPlaceNumber} - ${option.name}`
                  }
                  onChange={onChangeElelectionPlace}
                  options={electionPlaces}
                  loading={isLoadingElectionPlaces}
                  id={"memberElectionPlaces"}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
      <Grid container spacing={2} className={classes.inputContainer}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AppButton
            id="memberAddressSelection_button-electionPlaceDetails"
            float="right"
            color={theme.palette.info.dark}
            disabled={
              selectedElectionPlace === null ||
              selectedElectionPlace.id === EmptyGuid
            }
            hover={theme.palette.primary.main}
            onClick={handleOpenElectionPlaceDetailsModal}
            label={
              (isLoadingElectionPlace && (
                <CircularProgress
                  size="14px"
                  className={classes.circularProgress}
                />
              )) ||
              (!isLoadingElectionPlace && t("buttons.electionPlaceDetails"))
            }
          />
        </Grid>
      </Grid>
      <ElectionPlaceDetailsModal
        onClose={handleCloseElectionPlaceDetailsModal}
        open={isOpenElectionPlaceDetailsModal}
        electionPlace={selectedElectionPlace}
      />
    </Grid>
  );
};
