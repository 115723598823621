import { api } from "../../app/api/api";
import { MembershipType } from "../../models/data/members/membershipType";
import { HandleExpiredMemberRequest } from "../../models/members/handleExpiredMemberRequest";
import { MemberDetailsModel } from "../../models/members/memberDetailsModel";
import { MemberExpire } from "../../models/members/memberExpire";
import { RenewMembershipNotifyRequest } from "../../models/members/renewMembershipNotifyRequest";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { PagedList } from "../../models/Responses/dataManipulations/pagedList";
import { MemberAgeStatisticModel } from "../../models/Responses/members/statistics/memberAgeStatisticModel";
import { MemberCountStatisticModel } from "../../models/Responses/members/statistics/memberCountStatisticModel";
import { MemberGenderStatisticModel } from "../../models/Responses/members/statistics/memberGenderStatisticModel";
import { APIResponse } from "../../models/types/api/APIResponse";

const getMembersPagedList = async (request: MemberDataManipulationRequest) => {
  return api.post("/member/list", request) as Promise<
    APIResponse<PagedList<MemberDetailsModel>>
  >;
};

const getMemberCountStatistic = () => {
  return api.get("/member/member-count-statistic") as Promise<
    APIResponse<MemberCountStatisticModel[]>
  >;
};

const getMemberGenderStatistic = () => {
  return api.get("/member/member-gender-statistic") as Promise<
    APIResponse<MemberGenderStatisticModel[]>
  >;
};

const getMemberAgeStatistic = () => {
  return api.get("/member/member-age-statistic") as Promise<
    APIResponse<MemberAgeStatisticModel[]>
  >;
};

const getMemberExpireList = () => {
  return api.get("/member/member-expire-list") as Promise<
    APIResponse<MemberExpire>
  >;
};

const renewMembershipNotify = (request: RenewMembershipNotifyRequest) => {
  return api.post("/member/renew-membership-notify", request) as Promise<
    APIResponse<null>
  >;
};

const handleExpiredMembershipFee = (request: HandleExpiredMemberRequest) => {
  return api.post("/member/handle-expired-membership-fee", request) as Promise<
    APIResponse<null>
  >;
};

const getAllMembershipTypes = () => {
  return api.get("/member/get-all-membership-types") as Promise<
    APIResponse<MembershipType[]>
  >;
};

export const MemberApi = {
  GetMembersPagedList: getMembersPagedList,
  GetMemberCountStatistic: getMemberCountStatistic,
  GetMemberGenderStatistic: getMemberGenderStatistic,
  GetMemberAgeStatistic: getMemberAgeStatistic,
  GetMemberExpireList: getMemberExpireList,
  RenewMembershipNotify: renewMembershipNotify,
  HandleExpiredMembershipFee: handleExpiredMembershipFee,
  GetAllMembershipTypes: getAllMembershipTypes,
};
