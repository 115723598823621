import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteMember as deleteMemberApi,
  getAvailableCardNumbers as getAvailableCardNumbersApi,
  getMemberBasicDetails as getMemberBasicDetailsApi,
  getMemberDetailsDashboard as getMemberDetailsDashboardApi,
  getMemberFullDetailsById as getMemberFullDetailsByIdApi,
  getNextCardNumber as getNextCardNumberApi,
  getStatistic as getStatisticApi,
  isAvailableCardNumber as isAvailableCardNumberApi,
  saveMember as saveMemberApi,
} from "../../app/api/npdddApi";
import { RootState } from "../../app/api/store";
import i18n from "../../i18n";
import { EmptyGuid, INIT_MEMBER } from "../../INITIAL_DATA/initialData";
import { Member } from "../../models/data/members/Member";
import { MemberPersonalData } from "../../models/data/members/MemberPersonalData";
import { MembershipType } from "../../models/data/members/membershipType";
import { HandleExpiredMemberRequest } from "../../models/members/handleExpiredMemberRequest";
import { MemberDetailsModel } from "../../models/members/memberDetailsModel";
import { MemberExpire } from "../../models/members/memberExpire";
import { RenewMembershipNotifyRequest } from "../../models/members/renewMembershipNotifyRequest";
import { FilterMembers } from "../../models/requests/filterMembers";
import { FilterMembersBasicDetails } from "../../models/requests/filterMembersBasicDetails";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { PagedList } from "../../models/Responses/dataManipulations/pagedList";
import { MemberBasicDetails } from "../../models/Responses/members/memberBasicDetails";
import { MemberDashboard } from "../../models/Responses/members/memberDashboard";
import { MemberFullDetails } from "../../models/Responses/members/MemberFullDetails";
import { MemberAgeStatisticModel } from "../../models/Responses/members/statistics/memberAgeStatisticModel";
import { MemberCountStatisticModel } from "../../models/Responses/members/statistics/memberCountStatisticModel";
import { MemberGenderStatisticModel } from "../../models/Responses/members/statistics/memberGenderStatisticModel";
import StatisticModel from "../../models/statistic/statisticModel";
import { APIData } from "../../models/types/api/APIData";
import { APIError } from "../../models/types/api/APIError";
import { APIResponse } from "../../models/types/api/APIResponse";
import { APIStatus } from "../../models/types/api/APIStatus";
import { execute, fulfilled, pending, rejected } from "../helpers/sliceHelpers";
import {
  setErrorNotification,
  setPendingNotification,
  setSuccessNotification,
} from "../notification/notificationSlice";
import { MemberApi } from "./memberApi";

const INIT_API_DATA = {
  status: APIStatus.IDLE,
};

export type MemberState = {
  membersPagedList: PagedList<MemberDetailsModel> | null;
  memberDetailsDashboard: MemberDashboard | null;

  memberCountStatistic: MemberCountStatisticModel[] | null;
  memberGenderStatistic: MemberGenderStatisticModel[] | null;
  memberAgeStatistic: MemberAgeStatisticModel[] | null;

  fetchedMemberBasicDetails: APIData<MemberBasicDetails[]>;
  membersFullDetails: APIData<MemberFullDetails>;

  savedMember: Member | null;
  deletedMember: MemberPersonalData | null;

  isAvailableCardNumber: APIData<boolean>;
  availableCardNumbers: number[] | null;
  nextCardNumber: number | null;
  statistic: StatisticModel | null;

  selectedMember: Member | null;
  isEditing: boolean;
  dataManipulation: MemberDataManipulationRequest | null;
  membersBasicDetails: MemberBasicDetails[];

  memberExpire: MemberExpire | null;
  membershipTypes: MembershipType[] | null;

  statuses: {
    getAvailableCardNumbersStatus: APIStatus;
    membersPagedListStatus: string;
    saveMemberStatus: APIStatus;
    deleteMemberStatus: APIStatus;
    getNextCardNumberStatus: APIStatus;
    getMemberDetailsDashboard: APIStatus;
    memberCountStatistic: APIStatus;
    memberGenderStatistic: APIStatus;
    memberAgeStatistic: APIStatus;
    getMemberExpireList: APIStatus;
  };
};

const initialState: MemberState = {
  membersPagedList: null,
  memberDetailsDashboard: null,

  memberCountStatistic: null,
  memberGenderStatistic: null,
  memberAgeStatistic: null,

  fetchedMemberBasicDetails: INIT_API_DATA,
  membersFullDetails: INIT_API_DATA,

  savedMember: null,
  deletedMember: null,

  isAvailableCardNumber: INIT_API_DATA,
  availableCardNumbers: null,
  nextCardNumber: null,
  statistic: null,

  selectedMember: null,
  isEditing: false,
  dataManipulation: null,
  membersBasicDetails: [],

  memberExpire: null,
  membershipTypes: null,

  statuses: {
    getAvailableCardNumbersStatus: APIStatus.IDLE,
    membersPagedListStatus: APIStatus.IDLE,
    saveMemberStatus: APIStatus.IDLE,
    deleteMemberStatus: APIStatus.IDLE,
    getNextCardNumberStatus: APIStatus.IDLE,
    getMemberDetailsDashboard: APIStatus.IDLE,
    memberCountStatistic: APIStatus.IDLE,
    memberGenderStatistic: APIStatus.IDLE,
    memberAgeStatistic: APIStatus.IDLE,
    getMemberExpireList: APIStatus.IDLE,
  },
};

export const getMembersPagedList = createAsyncThunk(
  "Member/Get_Members_Paged_List",
  async (request: MemberDataManipulationRequest) => {
    const response = await MemberApi.GetMembersPagedList(request);
    return response.data;
  }
);

export const getMemberCountStatistic = createAsyncThunk<
  MemberCountStatisticModel[] | null,
  void,
  { rejectValue: APIError }
>(
  "Members/Get_Member_Count_Statistic",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await MemberApi.GetMemberCountStatistic();
      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const getMemberGenderStatistic = createAsyncThunk<
  MemberGenderStatisticModel[] | null,
  void,
  { rejectValue: APIError }
>(
  "Members/Get_Member_Gender_Statistic",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await MemberApi.GetMemberGenderStatistic();
      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const getMemberAgeStatistic = createAsyncThunk<
  MemberAgeStatisticModel[] | null,
  void,
  { rejectValue: APIError }
>("Members/Get_Member_Age_Statistic", async (_: void, { rejectWithValue }) => {
  try {
    const response = await MemberApi.GetMemberAgeStatistic();
    return response.data;
  } catch (error) {
    return rejectWithValue(error as APIError);
  }
});

export const getMemberDetailsDashboard = createAsyncThunk(
  "Members/Get_Member_Details_Dashboard",
  async (filter: FilterMembers, { rejectWithValue }) => {
    try {
      const response = await getMemberDetailsDashboardApi(filter);
      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const getMemberBasicDetails = createAsyncThunk<
  APIResponse<MemberBasicDetails[]>,
  FilterMembersBasicDetails,
  { rejectValue: APIError }
>(
  "Members/Get_Member_Basic_Details",
  async (filter: FilterMembersBasicDetails, { rejectWithValue }) =>
    execute(getMemberBasicDetailsApi(filter), rejectWithValue)
);

export const getMemberFullDetailsById = createAsyncThunk<
  APIResponse<Member>,
  string,
  { rejectValue: APIError }
>(
  "Members/Get_Member_Full_Details_By_Id",
  async (id: string, { rejectWithValue }) => {
    if (id !== EmptyGuid) {
      return execute(getMemberFullDetailsByIdApi(id), rejectWithValue);
    } else {
      const apiResponse: APIResponse<Member> = {
        data: INIT_MEMBER,
        httpStatusCode: 200,
        messageKey: "",
        succeeded: true,
      };
      return apiResponse;
    }
  }
);

export const getIsAvailableCardNumber = createAsyncThunk<
  APIResponse<boolean>,
  { cardNumber: number; memberCardId: string | null },
  { rejectValue: APIError }
>(
  "Members/Is_Available_Card_Number",
  async (
    {
      cardNumber,
      memberCardId,
    }: { cardNumber: number; memberCardId: string | null },
    { rejectWithValue }
  ) =>
    execute(isAvailableCardNumberApi(cardNumber, memberCardId), rejectWithValue)
);

export const getAvailableCardNumbers = createAsyncThunk<
  number[] | null,
  void,
  { rejectValue: APIError }
>(
  "Members/Get_Available_Card_Numbers",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await getAvailableCardNumbersApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error as APIError);
    }
  }
);

export const getNextCardNumber = createAsyncThunk<
  number | null,
  void,
  { rejectValue: APIError }
>("Members/Get_Next_Card_Number", async (_: any, { rejectWithValue }) => {
  try {
    const response = await getNextCardNumberApi();
    return response.data;
  } catch (error) {
    return rejectWithValue(error as APIError);
  }
});

export const resetSelectedMember = createAsyncThunk(
  "Members/Reset_Selected_Member",
  async () => {
    return true;
  }
);

export const getAllStatistic = createAsyncThunk(
  "Members/Get_Statistic",
  async () => {
    const response = await getStatisticApi();
    return response;
  }
);

export const saveMember = createAsyncThunk(
  "Members/Save_Member",
  async (
    { member, type }: { member: Member; type: "create" | "update" },
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(
        setPendingNotification(
          i18n.t(`notifications.${type}PersonalDataPending`) + "..."
        )
      );

      const response = await saveMemberApi(member);

      dispatch(
        setSuccessNotification(
          i18n.t(`notifications.${type}PersonalDataFulfilled`) + "!"
        )
      );

      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const deleteMember = createAsyncThunk(
  "Members/Delete_Member",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(i18n.t(`notifications.deleteMemberPending`) + "...")
      );

      const response = await deleteMemberApi(id);

      dispatch(
        setSuccessNotification(i18n.t(`notifications.deleteMemberFulfilled`))
      );

      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const getMemberExpireList = createAsyncThunk(
  "Members/Get_Member_Expire_List",
  async (_: void, { rejectWithValue, dispatch }) => {
    try {
      const response = await MemberApi.GetMemberExpireList();
      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const renewMembershipNotify = createAsyncThunk(
  "Members/Renew_membership_notify",
  async (
    request: RenewMembershipNotifyRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(
        setPendingNotification(
          i18n.t("messageKey.SendEmailRenewMembership_Pending")
        )
      );

      const response = await MemberApi.RenewMembershipNotify(request);

      dispatch(setSuccessNotification(i18n.t(`messageKey.${response.messageKey}`)));

      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const handleExpiredMember = createAsyncThunk(
  "Members/Handle_expired_member",
  async (
    request: HandleExpiredMemberRequest,
    { rejectWithValue, dispatch }
  ) => {
    try {
      dispatch(
        setPendingNotification(i18n.t("messageKey.HandleExpiredMember_Pending"))
      );

      const response = await MemberApi.HandleExpiredMembershipFee(request);

      dispatch(setSuccessNotification(i18n.t(`messageKey.${response.messageKey}`)));

      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const getAllMembershipTypes = createAsyncThunk(
  "Members/Get_all_membership_types",
  async () => {
    const response = await MemberApi.GetAllMembershipTypes();
    return response.data;
  }
);

export const memberSlice = createSlice({
  name: "members",
  initialState,
  reducers: {
    setIsEditing(state, action) {
      state.isEditing = action.payload;
    },
    setDataManipulation(state, action) {
      state.dataManipulation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // Get Members Paged List
      .addCase(getMembersPagedList.pending, (state) => {
        state.statuses.membersPagedListStatus = APIStatus.PENDING;
      })
      .addCase(getMembersPagedList.fulfilled, (state, action) => {
        state.statuses.membersPagedListStatus = APIStatus.FULFILLED;
        state.membersPagedList = action.payload;
      })
      .addCase(getMembersPagedList.rejected, (state) => {
        state.statuses.membersPagedListStatus = APIStatus.REJECTED;
      })

      // Get Member Count Statistic
      .addCase(getMemberCountStatistic.pending, (state) => {
        state.statuses.memberCountStatistic = APIStatus.PENDING;
      })
      .addCase(getMemberCountStatistic.fulfilled, (state, action) => {
        state.statuses.memberCountStatistic = APIStatus.FULFILLED;
        state.memberCountStatistic = action.payload;
      })
      .addCase(getMemberCountStatistic.rejected, (state) => {
        state.statuses.memberCountStatistic = APIStatus.REJECTED;
        state.memberCountStatistic = null;
      })

      // Get Member Gender Statistic
      .addCase(getMemberGenderStatistic.pending, (state) => {
        state.statuses.memberGenderStatistic = APIStatus.PENDING;
      })
      .addCase(getMemberGenderStatistic.fulfilled, (state, action) => {
        state.statuses.memberGenderStatistic = APIStatus.FULFILLED;
        state.memberGenderStatistic = action.payload;
      })
      .addCase(getMemberGenderStatistic.rejected, (state) => {
        state.statuses.memberGenderStatistic = APIStatus.REJECTED;
        state.memberGenderStatistic = null;
      })

      // Get Member Age Statistic
      .addCase(getMemberAgeStatistic.pending, (state) => {
        state.statuses.memberAgeStatistic = APIStatus.PENDING;
      })
      .addCase(getMemberAgeStatistic.fulfilled, (state, action) => {
        state.statuses.memberAgeStatistic = APIStatus.FULFILLED;
        state.memberAgeStatistic = action.payload;
      })
      .addCase(getMemberAgeStatistic.rejected, (state) => {
        state.statuses.memberAgeStatistic = APIStatus.REJECTED;
        state.memberAgeStatistic = null;
      })

      // Reset Selected Member
      .addCase(resetSelectedMember.fulfilled, (state) => {
        state.selectedMember = null;
      })

      // isAvailableCardNumber
      .addCase(getIsAvailableCardNumber.pending, (state) =>
        pending(state.isAvailableCardNumber)
      )
      .addCase(getIsAvailableCardNumber.fulfilled, (state, action) => {
        fulfilled(state.isAvailableCardNumber, action);

        state.isAvailableCardNumber.status = APIStatus.FULFILLED;

        if (state.isAvailableCardNumber.response) {
          state.isAvailableCardNumber.response = action.payload;

          if (
            action.payload.data !== null &&
            action.payload.data !== undefined
          ) {
            state.isAvailableCardNumber.data = action.payload.data;
          }
        }
      })
      .addCase(getIsAvailableCardNumber.rejected, (state, action) =>
        rejected(state.isAvailableCardNumber, action)
      )

      // getAvailableCardNumbers
      .addCase(getAvailableCardNumbers.pending, (state) => {
        state.availableCardNumbers = null;
        state.statuses.getAvailableCardNumbersStatus = APIStatus.PENDING;
      })
      .addCase(getAvailableCardNumbers.fulfilled, (state, action) => {
        state.availableCardNumbers = action.payload;
        state.statuses.getAvailableCardNumbersStatus = APIStatus.FULFILLED;
      })
      .addCase(getAvailableCardNumbers.rejected, (state, action) => {
        state.availableCardNumbers = null;
        state.statuses.getAvailableCardNumbersStatus = APIStatus.REJECTED;
      })

      // Get Next Card Number
      .addCase(getNextCardNumber.pending, (state, action) => {
        state.nextCardNumber = null;
        state.statuses.getNextCardNumberStatus = APIStatus.PENDING;
      })
      .addCase(getNextCardNumber.fulfilled, (state, action) => {
        state.nextCardNumber = action.payload;
        state.statuses.getNextCardNumberStatus = APIStatus.FULFILLED;
      })
      .addCase(getNextCardNumber.rejected, (state, action) => {
        state.nextCardNumber = null;
        state.statuses.getNextCardNumberStatus = APIStatus.REJECTED;
      })

      .addCase(getAllStatistic.fulfilled, (state, { payload }) => {
        state.statistic = payload;
      })

      // Get Member Details Dashboard
      .addCase(getMemberDetailsDashboard.pending, (state) => {
        state.memberDetailsDashboard = null;
        state.statuses.getMemberDetailsDashboard = APIStatus.PENDING;
      })
      .addCase(getMemberDetailsDashboard.fulfilled, (state, action) => {
        state.memberDetailsDashboard = action.payload;
        state.statuses.getMemberDetailsDashboard = APIStatus.FULFILLED;
      })
      .addCase(getMemberDetailsDashboard.rejected, (state, action) => {
        state.memberDetailsDashboard = null;
        state.statuses.getMemberDetailsDashboard = APIStatus.REJECTED;
      })

      // getMemberBasicDetails
      .addCase(getMemberBasicDetails.pending, (state) =>
        pending(state.fetchedMemberBasicDetails)
      )
      .addCase(getMemberBasicDetails.fulfilled, (state, action) => {
        // fulfilled(state.fetchedMemberBasicDetails, action);

        state.membersBasicDetails = action.payload.data || [];
      })
      .addCase(getMemberBasicDetails.rejected, (state, action) =>
        rejected(state.fetchedMemberBasicDetails, action)
      )

      // getMemberFullDetailsById
      .addCase(getMemberFullDetailsById.pending, (state) =>
        pending(state.membersFullDetails)
      )
      .addCase(getMemberFullDetailsById.fulfilled, (state, action) => {
        fulfilled(state.membersFullDetails, action);
        state.selectedMember = action.payload.data;
      })
      .addCase(getMemberFullDetailsById.rejected, (state, action) =>
        rejected(state.membersFullDetails, action)
      )

      // Save Member
      .addCase(saveMember.pending, (state, action) => {
        state.savedMember = null;
        state.statuses.saveMemberStatus = APIStatus.PENDING;
      })
      .addCase(saveMember.fulfilled, (state, action) => {
        state.savedMember = action.payload;
        state.statuses.saveMemberStatus = APIStatus.FULFILLED;
      })
      .addCase(saveMember.rejected, (state, action) => {
        state.savedMember = null;
        state.statuses.saveMemberStatus = APIStatus.REJECTED;
      })

      // Delete Member
      .addCase(deleteMember.pending, (state, action) => {
        state.deletedMember = null;
        state.statuses.deleteMemberStatus = APIStatus.PENDING;
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.deletedMember = action.payload;
        state.statuses.deleteMemberStatus = APIStatus.FULFILLED;
      })
      .addCase(deleteMember.rejected, (state, action) => {
        state.deletedMember = null;
        state.statuses.deleteMemberStatus = APIStatus.REJECTED;
      })

      // Get member expire
      .addCase(getMemberExpireList.pending, (state, action) => {
        state.statuses.getMemberExpireList = APIStatus.PENDING;
      })
      .addCase(getMemberExpireList.fulfilled, (state, action) => {
        state.memberExpire = action.payload;
        state.statuses.getMemberExpireList = APIStatus.FULFILLED;
      })
      .addCase(getMemberExpireList.rejected, (state, action) => {
        state.statuses.getMemberExpireList = APIStatus.REJECTED;
      })

      // Get all membership types
      .addCase(getAllMembershipTypes.fulfilled, (state, action) => {
        state.membershipTypes = action.payload;
      });
  },
});

export const { setIsEditing, setDataManipulation } = memberSlice.actions;

export const selectMembersPagedList = (state: RootState) =>
  state.members.membersPagedList;

export const selectMembersPagedListStatus = (state: RootState) =>
  state.members.statuses.membersPagedListStatus;

export const selectStatistic = (state: RootState) => state.members.statistic;

export const selectSavedMember = (state: RootState) =>
  state.members.savedMember;

export const selectSaveMemberStatus = (state: RootState) =>
  state.members.statuses.saveMemberStatus;

export const selectDeleteMemberStatus = (state: RootState) =>
  state.members.statuses.deleteMemberStatus;

export const selectSelectedMember = (state: RootState) =>
  state.members.selectedMember;

export const selectNextCardNumber = (state: RootState) =>
  state.members.nextCardNumber;

export const selectNextCardNumberStatus = (state: RootState) =>
  state.members.statuses.getNextCardNumberStatus;

export const selectMemberDetailsDashboard = (state: RootState) =>
  state.members.memberDetailsDashboard;

export const selectStatusGetMemberDetailsDashboard = (state: RootState) =>
  state.members.statuses.getMemberDetailsDashboard;

export const selectAvailableCardNumbers = (state: RootState) =>
  state.members.availableCardNumbers;

export const selectStatusGetAvailableCardNumbers = (state: RootState) =>
  state.members.statuses.getAvailableCardNumbersStatus;

export const selectMemberCountStatistic = (state: RootState) => {
  return {
    data: state.members.memberCountStatistic,
    status: state.members.statuses.memberCountStatistic,
  };
};

export const selectMemberGenderStatistic = (state: RootState) => {
  return {
    data: state.members.memberGenderStatistic,
    status: state.members.statuses.memberGenderStatistic,
  };
};

export const selectMemberAgeStatistic = (state: RootState) => {
  return {
    data: state.members.memberAgeStatistic,
    status: state.members.statuses.memberAgeStatistic,
  };
};

export const selectIsEditing = (state: RootState) => state.members.isEditing;

export const selectDataManipulation = (state: RootState) =>
  state.members.dataManipulation;

export const selectMemberExpire = (state: RootState) =>
  state.members.memberExpire;

export const selectMemberExpireStatus = (state: RootState) =>
  state.members.statuses.getMemberExpireList;

export const selectMembershipTypes = (state: RootState) =>
  state.members.membershipTypes;

export default memberSlice.reducer;
