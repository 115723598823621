import { Rgb } from "../../models/app/theme/rgb";

export function hexToRgb(hex: string) {
  var regexArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!regexArray) {
    return null;
  }

  const result: Rgb = {
    r: parseInt(regexArray[1], 16),
    g: parseInt(regexArray[2], 16),
    b: parseInt(regexArray[3], 16),
  };

  return result;
}
