import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "rs",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          
          header: {
            dashboardTitle: "Dashboard",
            memberCards: "Member list",
            smartMembershipTitle: "Member management",
            statisticTitle: "Statistic",
            userManagementTitle: "User management",
            syncAddressesTitle: "Sync addresses",
            notFoundTitle: "Not found page",
            memberTableTitle: "Member table",
            capillarVotesTitle: "Kapilarni glasovi"
          },
          link: {
            capillaryVotes:"Capillary votes"
          },
          common: {
            appTitle: "Membership",
            selectMemberLabel: "Select member",
            cardNumberInputManual: "Manually choose",
            from: "From",
            to: "To",
            selectProperty: "Select property",
            value: "Value",
            equals: "Equals",
            update: "Update",
            add: "Add",
            cancel: "Cancel",
            remove: "Remove",
            between: "Between",
            noResults: "No results",
            true: "Yes",
            false: "No",
            create: "Create",
            membershipFees: "Membership fees",
            donations: "Donations",
            degree: "degree",
          },
          month: {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
          },
          gender: {
            0: "Male",
            1: "Female",
            2: "Unknown",
          },
          messages: {
            reports: {
              pdfReport: {
                pending: "Creating PDF report",
                success: "Successfully created PDF report",
                error: "Error with creating PDF report",
              },
              excelReport: {
                pending: "Creating Excel report",
                success: "Successfully created Excel report",
                error: "Error with creating Excel report",
              },
            },
            login: {
              pending: "Login in progress",
              success: "Successfully logged in",
              needResetPassword:
                "Successfully authenticated. Need to reset password now",
            },
            resetPasswordOnFirstLogin: {
              pending: "Setting new password in progress",
              success: "Successfully changed password",
            },
          },
          sidebar: {
            sidebarDashboard: "Dashboard",
            sidebarAddMembers: "Member management",
            sidebarMemberTable: "Member table",
            sidebarLogout: "Logout",
            sidebarStatistic: "Statistic",
            userManagement: "User management",
          },
          myProfile: {
            myProfileButton: "My profile",
            userManagementButton: "User management",
            logoutButton: "Logout",
            chooseLanguage: "Choose language",
            serbianLatinLanguage: "Serbian (latin)",
            englishLagnuage: "English",
            serbianCyrlLanguage: "Serbian (cyrillic)",
          },
          myProfileData: {
            myProfile: "My profile",
            firstName: "First name",
            lastName: "Last name",
            email: "Email address",
            phoneNumber: "Phone number",
            role: "Role",
            close: "Close",
          },
          accountManagement: {
            accountManagementText: "User account management",
            close: "Close",
            selectAccount: "Select existing user account",
            account: "User account",
            aboutAccount: "User account data",
            firstName: "First name",
            lastName: "Last name",
            email: "Email",
            phoneNumber: "Phone number",
            role: "Role",
            cancelSelection: "Cancel",
            create: "Create",
            update: "Update",
          },
          loginPage: {
            loginHeader: "Login",
            loginTittle: "Easy Membership",
            loginEmail: "E-mail",
            loginPassword: "Password",
            loginForgotPassword: "Forgot password?",
            loginHelperEmail: "Enter e-mail",
            loginHelperPassowrd: "Enter password",
          },
          resetPasswordOnFirstLoginModal: {
            title: "Set new password",
            label: "New password",
            submit: "Set password",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "FORGOT YOUR PASSWORD?",
            forgotPasswordEmail: "E-mail address",
            forgotPasswordEmailHelper: "Enter your account email address.",
            codeTitle: "Code",
            codeHelper: "Enter the code you received.",
            newPasswordTitle: "New Password",
            newPasswordHelper: "Enter the new desired password.",
            confirmNewPassword: "Confirm your new password",
            confirmNewPasswordHelper: "Re-enter the new desired password.",
            minimumCharacters: "Minimum 8 characters",
            minimumCapital: "minimum 1 capital (uppercase) letter",
            minimumNumber: "minimum 1 number",
            minimumSpecialCharacter: "minimum 1 special character",
            passwordConfrimed: "password confrimed",
          },
          deleteConfirmationModal: {
            label: "Are you sure you want to delete the member?",
          },
          dashboardPage: {
            accordionHeader: "Search",
            accordionTitle: "Search fields:",
            accordionSubtitle: "Enter at least 3 characters",
            accordionPlaceholderFirstName: "First name",
            accordionPlaceholderLastName: "Last name",
            accordionPlaceholderPhone: "Phone",
            accordionPlaceholderJmbg: "JMBG",
            nextCardNumberLabel: "Next card number",
            accordionPlaceholderCardNumber: "Card number",
            viewTableModalTitle: "Member's details:",
            editTableModalTitle: "Edit member information:",
            totalCountMember: "Total number of members",
            searchMembers: "Search members",
            aboutSearch:
              "At least 3 characters for all inputs except for Card number. You search members by Card number based on an exact input.",
          },
          memberManagementPage: {
            searchExistingMember: "Search for existing members",
            personalDataTitle: "Personal data",
            personalDataFirstName: "First name",
            personalDataParentName: "Parent name",
            personalDataLastName: "Last name",
            personalDataBirthDate: "Date of birth",
            personalDataBirthPlace: "Birth place",
            personalDataIdNumber: "ID number",
            personalDataUin: "JMBG",
            personalDataMale: "Male",
            personalDataFemale: "Female",
            personalDataErrorMessageFirstName:
              "The name must contain at least two letters",
            personalDataErrorMessageLastName:
              "The last name must contain at least two letters",
            personalDataErrorMessageDateofbirth: "Date of birth is required",
            addressTitle: "Residence information",
            addressesCity: "Place",
            addressesStreet: "Street",
            addressesHomeNumber: "Home number.",
            addressesElectionPlaceArea: "Election place",
            addressesElectionPlaceRegionArea: "Election place region",
            addressesResidential: "Residential address is the same",
            addressesElectionPlaceRegionNumber: "Election place region no.",
            addressesElectionPlaceRegionName:
              "Name of the election place region",
            addressesElectionPlaceCheckboxLabel:
              "Select an election place manually",
            addressesElectionPlaceRegionCheckboxLabel:
              "Select an election place region manually",
            addressesElectionPlaceDetailsModalTitle: "Election place details",
            addressesElectionPlaceDetailsModalNumber: "Number:",
            addressesElectionPlaceDetailsModalName: "Name:",
            addressesElectionPlaceDetailsModalAddress: "Address:",
            addressesElectionPlaceDetailsModalPlace: "Place:",
            addressesDataErrorMessageCity: "Place of residence is mandatory",
            addressesDataErrorMessageStreet:
              "The street of residence is mandatory",
            addressesDataErrorHomeNumber: "Home number is mandatory",
            contactTitle: "Contact data",
            contactMobilePhone: "Mobile phone",
            contactLandingPhone: "Landline phone",
            contactBussinesPhone: "Business phone",
            contactEmail: "E-mail address",
            contactTwitter: "Twitter",
            contactInstagram: "Instagram",
            contactFacebook: "Facebook",
            contactOther: "Other",
            cardDataTitle: "Membership card information",
            cardDataNumber: "Membership card number",
            cardDataIssued: "Membership card issued",
            cardDataPlaceOfIssue: "Place of publishing",
            cardDataDateOfIssue: "Publishing date",
            cardDataManual: "Manually select",
            cardDataErrorMessageCardNumber: "The membership number is not free",
            employmentDataTitle: "Employment data",
            employmentDataIsEmplyed: "Employed",
            employmentDataIsEmplyedYes: "Yes",
            employmentDataIsEmplyedNo: "No",
            employmentDataIsEmplyedOther: "Other",
            employmentDataCompany: "Other",
            employmentDataNameOfCompany: "Name of company, institution and hq",
            employmentDataWorkPlace: "Workplace",
            employmentDataTemporary: "Employed permanently",
            educationTitle: "Education data",
            educationElementarySchool: "Elementary school",
            educationHighSchool: "High school (course)",
            educationBasicVocationalSchool: "Basic vocational studies",
            educationSpecialistVocationalSchool:
              "Specialist vocational studies",
            educationAcademicStudies: "Academic studies",
            educationMasterAcademicStudies: "Master of academic studies",
            educationSpecialistAcademicStudies: "Specialist academic studies",
            educationDoctoralAcademicStudies: "Doctoral studies",
            educationComputerSkills: "Ability to work on a computer",
            educationNo: "No",
            educationYes: "Yes",
            educationLevelThird: "III degree",
            educationLevelFourth: "IV degree",
            languageTitle: "Member languages",
            languageLanguage: "Language",
            languageLevel: "Level",
            languageActive: "Active",
            languagePassive: "Passive",
            otherDataTitle: "Other data",
            otherDataHobby: "Hobby",
            otherDataSaintDay: "Saint day",
            otherDataSaintDayDate: "Saint day date",
            otherDataRecommendation: "Member's recommendation",
            otherDataNoteOne: "Note",
            otherDataNoteSecond: "Note",
            otherDataNoteThird: "Note",
          },
          memberTablePage: {
            personalData: "Personal data",
            address: "Address data",
            card: "Card data",
            contact: "Contact data",
            education: "Education data",
            employment: "Employment data",
            languages: "Languages data",
            otherData: "Other data",
            allMember: "All personal data",
            allAddress: "All address data",
            allCard: "All card data",
            allContact: "All contact data",
            allEducation: "All education data",
            allEmployment: "All employment data",
            allLanguages: "All languages data",
            allOtherData: "All other data",
            predefinedReports: "Predefined reports of members",
            predefinedReportByLocalCommunity: "Report by local community",
            predefinedReportByCity: "Report by city",
            predefinedReportByElectionPlace: "Report by election place",
            predefinedReportDefault: "Predefined report",
            exportExcelTitle: "Export excel",
            settingsTitle: "Settings",
            exportExcel: "Export excel",
            exportPdf: "Export pdf",
            selectionOfTableColumns: "Selection of table columns",
            tableActionTitle: "Action",
            tableOrdinalNumberTitle: "Ordinal no.",
            tableFirstNameTitle: "First name",
            tableLastNameTitle: "Last name",
            tableLastNameParentName: "Parent name",
            tableUinTitle: "Jmbg",
            tableBirthDateTitle: "Date of birth",
            tableAgeTitle: "Years of age",
            tableBirthPlaceTitle: "Birth place",
            tableIdNumberTitle: "ID number",
            tableGenderTitle: "Gender",
            tableCityTitle: "Place",
            tableStreetTitle: "Street",
            tableHomeNumberTitle: "Home number",
            tableCityRegionTitle: "City",
            tableMunicipalityTitle: "Municipality",
            tableResidenceCityTitle: "Place of residence",
            tableResidenceStreetTitle: "Street of residence",
            tableResidenceHomeNumberTitle: "Home number of residence",
            tableResidenceCityRegionTitle: "City of residence",
            tableResidenceMunicipalityTitle: "Municipality of residence",
            tableElectionPlaceNameTitle: "Election place",
            tableElectionPlaceNumberTitle: "Election place number",
            tableElectionPlaceAddressTitle: "Address of election place",
            tableLocalCommunityNameTitle: "Local community",
            tableIdCardNumberTitle: "Membership card number",
            tableIsCardPublishedTitle: "Membership card published",
            tableMemberCardCityTitle: "Place of membership card publishing",
            tableMemberCardDateTitle: "Date of membership card publishing",
            tableLandingPhoneTitle: "Landline phone",
            tableMobilePhoneTitle: "Mobile phone",
            tableBusinessPhoneTitle: "Business phone",
            tableEmailTitle: "E-mail",
            tableFacebookTitle: "Facebook",
            tableInstagramTitle: "Instagram",
            tableTwitterTitle: "Twitter",
            tableOtherContactTitle: "Other contact",
            tableElementarySchoolTitle: "Elementary school",
            tableHighSchoolTitle: "High school",
            tableHighSchoolLevelTitle: "High school degree",
            tableVocationalStudiesTitle: "Vocational studies",
            tableSpecialistVocationalStudiesTitle: "Special vocational studies",
            tableBasicAcademicStudiesTitle: "Basic academic studies",
            tableBasicAcademicStudiesLevelTitle:
              "Level of basic academic studies",
            tableMasterAcademicStudiesTitle: "Master of academic studies",
            tableMasterAcademicStudiesLevelTitle:
              "Master's degree in academic studies",
            tableSpecialAcademicStudiesTitle: "Special academic studies",
            tableDoctoralAcademicStudiesTitle: "Doctoral studies",
            tableWorkOnComputerTitle: "Work on computers",
            tableIsEmployedTitle: "Employed",
            tableCompanyTitle: "Company",
            tableWorkPlaceTitle: "Work place",
            tableTemporaryEmployed: "Permanently employed",
            tableOtherEmployment: "Other employment",
            tableLanguagesTitle: "Languages",
            tableLanguageSubtitleLanguage: "Language",
            tableLanguageSubtitleLevel: "Level",
            tableLanguageSubtitleActive: "Actively uses",
            tableHobbyTitle: "Hobby",
            tableSaintDay: "Saint day",
            tableSaintDayDate: "Date of saint day",
            tableRecommendationTitle: "Member's recommendation",
            tableNoteТitle: "Note",
            tableNoteFirstTitle: "Note",
            tableNoteSecondTitle: "Note",
            tableNoteThirdTitle: "Note",
            detailOfExcelFileTitle: "Detail of excel file",
            detailOfExcelFileGeneral: "General",
            detailOfExcelFileNameOfDocument: "Name of document",
            detailOfExcelFileHeader: "Header of document",
            detailOfExcelFileFirstLine: "First line",
            detailOfExcelFileSecondLine: "Second line",
            filterTitle: "Filter",
            filterNoRequirement: "No requirement",
            filterEquals: "Equal to",
            filterNotEquals: "Not equal to",
            filterEmpty: "Empty",
            filterNotEmpty: "Not empty",
            filterLessThan: "Less than",
            filterLessThanOrEqualTo: "Less than or equal to",
            filterGreaterThan: "Greater than",
            filterGreaterThanOrEqualTo: "Greater than or equal to",
            filterStartsWith: "Starts with",
            filterNotStartsWith: "Not starts with",
            filterEndsWith: "EndsWith",
            filterNotEndsWith: "Not ends with",
            filterContains: "Contains",
            filterNotContains: "Not contains",
            filterFieldPropName: "Field",
            filterFieldFirstRequirement: "First requirement",
            filterFieldSecondRequirement: "Second requirement",
            filterLogicalOperatorAnd: "and",
            filterLogicalOperatorOr: "or",
            filterFieldFirstData: "First data",
            filterFieldSecondData: "Second data",
            resultsPerPage: "Results per page",
            tableFiltersTitle: "Filters",
            showingResults: "Showing",
            showingOf: "of",
            results: "results",
            rowsPerPage: "Rows per page",

            getExcelReportCurrentDataFileName: "Members - Excel",
            getPdfReportCurrentDataFileName: "Members - PDF",

            appliedFiltersLabel: "Applied filters",
          },
          userTablePage: {
            userTableHeaderAction: "Action",
            userTableHeaderOridinalNumber: "Ordinal number",
            userTableHeaderId: "Id",
            userTableHeaderFirstName: "First name",
            userTableHeaderLastName: "Last name",
            userTableHeaderEmail: "Email",
            userTableHeaderPhone: "Phone",
            userTableHeaderRole: "Role",
            userTableHeaderIsActive: "Is active",
            userTableHeaderIsDelete: "Is deleted",
            userTableAlert: "Are you sure you want to",
            userTableAlertDeactivate: "deactivate",
            userTableAlertActivate: "activate",
            userTableAlertRemove: "remove",
            userTableAlertGetBack: "get back",
            userTableAlertUser: "user",
            createNewUserIsDeleted: "Is deleted",
            account: "account",
            userFormStateCreate: "Create",
            userFormStateEdit: "Edit",
            userFormStateView: "View",
            userFormStateNone: "",
          },
          statisticPage: {
            ageStatistic: "Age statistic",
            statisticByCity: "Place statistic",
            totalMembers: "Total members: ",
            ageOfMembers: "Age of members and gender",
          },
          alerts: {
            statisticPageAlert: "Copied!",
          },
          buttons: {
            loginButton: "Login",
            dashboardAddMember: "create",
            dashboardUpdateMember: "update",
            dashboardClearFields: "clear all fields",
            dashboardDeleteMember: "delete",
            close: "Close",
            selectionOfTableColumns: "selection of table columns",
            save: "Save",
            electionPlaceDetails: "Election place details",
            cancelFilter: "Cancel filter",
            submitFilter: "Submit filter",
            menageDocumentData: "Menage document data",
            sendRequest: "Send a request",
            resetPassword: "Reset password",
            createNewUser: "create new user",
            confirm: "Yes",
            reject: "No",
          },
          tooltips: {
            predefinedReports: "Predefined reports of members",
            pdfReport: "PDF report of members",
            excelReport: "Excel report of members",
            tableActionRowsEdit: "Edit member information",
            tableActionRowsView: "View member information",
            tableActionRowsDelete: "Delete the member",
            tableActionRowsUserEdit: "Edit user information",
            tableActionRowsUserView: "View user data",
            tableActionRowsUserDeactivate: "Deactivate user",
            tableActionRowsUserActivate: "Activate user",
            tableActionRowsUserRemove: "Remove user",
            tableActionRowsUserGetBack: "Get back user",
            tooltipsStatistic:
              "By clicking on the text, you copy the content to the clipboard memory",
            manuallySelectCardDataText:
              "Manually select a free member card number.",
            isAddressSameAddressSectionText:
              "If the residential address is not the same as the address on the ID card, you can also add the residential address for records.",
            electionPlaceRegionAddressSectionText:
              "If the region of the polling station is not set automatically, you can manually choose from the offered regions from the specified place.",
            otherEmploymentText:
              "Additional information about employment if there are any specifics.",
            memberRecommendationOtherText:
              "Name and lastname of the member who gave the recommendation.",
            memberNoteOtherText: "Additional information about the member.",
            tooltipTitle: "INFORMATION",
            languageLevelLanguageSection:
              "Language level according to the CEFR standard.",
            contactOtherContactSectionText:
              "Additional contact networks (Skype, LinkedIn ...)",
          },
          icons: {
            filters: "Select filters",
          },
          notifications: {
            createPersonalDataPending: "Creating member...",
            createPersonalDataFulfilled: "Member is successfully created!",
            updatePersonalDataPending: "Updating member...",
            updatePersonalDataFulfilled: "Member is successfully updated!",
            deleteMemberPending: "Deleting member...",
            deleteMemberFulfilled: "Member has been successfully deleted!",

            loginPending: "Login is in progress...",
            loginFulfilled: "You have successfully logged in.",

            createAccountPending: "Creating account",
            createAccountFulfilled:
              "Account created, еmail has been sent successfully",
            updateAccountPending: "Updating account",
            updateAccountFulfilled: "Account updated",

            getExcelReportCurrentDataPending:
              "Creating Excel report of members in table...",
            getExcelReportCurrentDataFulfilled:
              "Creating Excel report of members in table finished.",
            getPdfReportCurrentDataPending:
              "Creating PDF report of members in table...",
            getPdfReportCurrentDataFulfilled:
              "Creating PDF report of members in table finished.",
          },
          updateElectionPlaceModal: {
            updateElectionPlaces: "Update addresses",
            uploadDocumnet: "Upload a document",
            upload: "Upload",
            allowedDocumentTypes: 'Allowed document type "docx"',
            close: "Close",
          },
          updateElectionPlaceTable: {
            lastName: "Last name",
            firstName: "First name",
            city: "Place",
            street: "Street",
            homeNumber: "Home number",
            phone: "Phone",
            electionPlaces: "Election place",
            sureVotes: "Sure votes - manual election place adjustment",
          },
          updateMembershipModal: {
            updateMembership: "Update membership",
            uploadDocument: "Upload document",
            upload: "Upload",
            allowedDocumentTypes: 'Allowed document type "docx"',
            close: "Close",
          },
          updatePlacesAndStreetsModal: {
            updatePlacesAndStreets: "Update places and streets",
            uploadDocument: "Upload document",
            upload: "Upload",
            allowedDocumentTypes: 'Allowed document type "docx"',
            close: "Close",
          },
          syncAddressesPage: {
            updateMembership: "Update membership",
            updateElectionPlace: "Update election places",
            updatePlacesAndStreets: "Update places and streets",
            save: "Save",
          },
          errorNotifications: {
            undefined: "Internal server error.",
            "-500": "Internal server error.",
            0: "Internal server error.",
            1: "Member address doesn't exist.",
            2: "Member card doesn't exist.",
            3: "Member contact doesn't exist.",
            4: "Member education doesn't exist.",
            5: "Member employment doesn't exist.",
            6: "Member language doesn't exist.",
            7: "Member other data doesn't exist.",
            8: "Member personal data doesn't exist.",
            9: "Member with this data already exists.",
            10: "Account doesn't exist.",
            11: "Account with this email doesn't exist.",
            12: "Email address is already in use.",
            13: "Role with this name doesn't exist.",
            14: "Account is not active. Please contact administrator.",
            15: "Wrong password.",
            16: "Invalid refresh token.",
            17: "Your session expired.",
            18: "Place municipality doesn't exist.",
            19: "City region doesn't exist.",
            20: "Place doesn't exist.",
            21: "Country doesn't exist.",
            22: "Local community doesn't exist.",
            23: "Local community street doesn't exist.",
            24: "Municipality doesn't exist.",
            25: "State doesn't exist.",
            26: "Street doesn't exist.",
            27: "Election place region doesn't exist.",
            28: "Election place doesn't exist.",
            29: "Request reset password time expired.",
            30: "Role doesn't exist.",
          },
          switch: {
            isActive: "Active",
          },
          reportHeaderTitleModal: {
            title: "Member report",
            titleInReport: "Title in report",
            closeModal: "Close",
            getTheReport: "Get the report",
          },
          toastMessages: {
            requestResetPasswordEmailError:
              "Entered data is not valid, please check it and try again!",
            requestResetPasswordEmailSuccess:
              "You have successfully sent a request!",
            requestResetPasswordSuccess:
              "You have successfully reset a password!",

            createdAccountError:
              "Entered data is not valid, please check it and try again!",
          },
          chart: {
            countStatistic: {
              title: "Membership",
              created: "Created",
              deleted: "Deleted",
              newCount: "New",
              totalCount: "Total",
            },
            genderStatistic: {
              title: "Gender statistic",
            },
            ageStatistic: {
              title: "Age statistic",
              from21To30: "From 21 to 30",
              from31To40: "From 31 to 40",
              from41To50: "From 41 to 50",
              from51To60: "From 51 to 60",
              from61To70: "From 61 to 70",
            },
          },
          memberExpireList: {
            renewTitle: "For renew membership",
            expiredTitle: "Expired membership",
            renewActionLabel: "Renew membership",
            deactivateActionLabel: "Deactivate member",
            notifyActionLabel: "Send notification to member",
            daysRemaining: "{{day}} days remaining",
            daysAgo: "{{day}} days ago",
            paying: "Paying",
            amount: "Amount",
            startDate: "Start date",
            membershipType: "Membership type",
          },
          donationTable: {
            firstName: "First name",
            lastName: "Last name",
            phone: "Phone",
            amount: "Amount",
            donatedDate: "Donated",
            purpose: "Purpose",
            isBusiness: "Business",
            companyName: "Company",
            companyPib: "Pib",
            createDonation: "New donation",
            donationsToAssociationTitle: "Donations to association",
            donationsFromAssociationTitle: "Donations from association",
            saveDonationTitle: "Save donation",
            editDonationTooltip: "Edit donation",
            removeDonationTooltip: "Remove donation",
            viewDonationTooltip: "View donation",
          },
          donationTabs: {
            toAssociationFromIndividuals: "Individuals",
            toAssociationFromLegalEntities: "Legal entities",
            fromAssociation: "Donations from association",
          },
          membershipFeeTabs: {
            forRenewMembership: "For renew membership",
            expiredMembership: "Expired membership",
          },
          messageKey: {
            InternalServerError: "Internal server error.",
            undefined: "Error with communicating with server.",

            Login_Pending: "Login in progress...",
            Login_AccountByEmailNotFound:
              "Account with this e-mail doesn't exist.",
            Login_TooManyAttempts:
              "Too many failed login attempts. Contact your administrator.",
            Login_NeedToChangePassword:
              "Need to change your password. Contact your administrator.",
            Login_WrongPassword: "Wrong password.",
            Login_AccountInactive:
              "Account is inactive. Contact your administrator.",
            Login_Success: "Successfully logged in!",

            GetAuthenticatedAccount_NoAccountAuthenticated:
              "Account is not authenticated.",
            GetAuthenticatedAccount_AccountByIdNotFound:
              "Account doesn't exist.",
            GetAuthenticatedAccount_Success:
              "Account is successfully authenticated.",

            RefreshToken_AccountByRefreshTokenNotFound:
              "Account doesn't exist by this token.",
            RefreshToken_SessionExpired: "Your session has expired.",
            RefreshToken_Success: "Session has been successfully refreshed.",

            ForgotPassword_Pending: "Saving new password...",
            ForgotPassword_TokenExpired: "Token expired. Send request again.",
            ForgotPassword_AccountByEmailNotFound: "Account doesn't exist.",
            ForgotPassword_Success: "Successfully saved new password.",

            RequestForgotPassword_Pending:
              "Sending request for forgot password...",
            RequestForgotPassword_AccountByEmailNotFound:
              "Account doesn't exist.",
            RequestForgotPassword_EmailSent:
              "Request has been sent to this email address.",

            ResetPassword_Pending: "Resetting password...",
            ResetPassword_AccountByIdNotFound: "Account doesn't exist.",
            ResetPassword_AccountByEmailNotFound: "Account doesn't exist.",
            ResetPassword_IncorrectOldPassword: "Current password incorrect.",
            ResetPassword_Success: "Successfully saved new password.",

            CreateAccount_Pending: "Creating new account...",
            CreateAccount_Success: "Account created successfully.",

            UpdateAccount_Pending: "Updating account...",
            UpdateAccount_AccountByIdNotFound: "Account doesn't exist.",
            UpdateAccount_Success: "Account updated successfully.",

            SetPassword_AccountByIdNotFound: "Account doesn't exist.",

            SetStatus_AccountByIdNotFound: "Account doesn't exist.",

            IncrementFailedLogin_AccountByIdNotFound: "Account doesn't exist.",

            UpdateDetailsAfterLogin_AccountByIdNotFound:
              "Account doesn't exist.",

            CreateAssociation_AccountByIdNotFound: "Account doesn't exist.",
            CreateAssociation_AssociationByIdNotFound: "Association not found.",
            CreateAssociation_Success: "Association created successfully.",

            UpdateAssociation_AssociationByIdNotFound: "Association not found.",
            UpdateAssociation_AccountByIdNotFound: "Account doesn't exist.",
            UpdateAssociation_DatabaseByIdNotFound: "Database not found.",
            UpdateAssociation_ColorByIdNotFound: "Colors not found.",
            UpdateAssociation_Success: "Association updated successfully.",

            GetAllRoles_AccountNotFound: "Account doesn't exist.",

            GetByEmail_AccountByEmailNotFound: "Account not found by email.",

            GetAllAccounts_AccountIsNotAuthenticated:
              "Account isn't authenticated.",

            GetElectionPlaceRegionById_ElectionPlaceRegionByIdNotFound:
              "Election place region not found.",
            UpdateElectionPlaceRegion_ElectionPlaceRegionByIdNotFound:
              "Election place region not found.",
            DeleteElectionPlaceRegion_ElectionPlaceRegionByIdNotFound:
              "Election place region not found.",
            GetMemberAddressById_MemberAddressByIdNotFound:
              "Member address not found.",
            UpdateMemberAddress_MemberAddressByIdNotFound:
              "Member address not found.",
            DeleteMemberAddress_MemberAddressByIdNotFound:
              "Member address not found.",
            DeleteMemberPersonalData_MemberByIdNotFound:
              "Member doesn't exist.",
            SavePersonalData_MemberAlreadyExists: "Member already exists.",

            SendEmailRenewMembership_Pending: "Sending email...",
            SendEmailRenewMembership_MemberByEmailNotFound:
              "Member with this e-mail not found.",
            SendEmailRenewMembership_Success:
              "Email about renewing membership successfully has been sent.",

            HandleExpiredMember_Pending: "Executing...",
            HandleExpiredMember_MemberByIdNotFound: "Member not found.",
            HandleExpiredMember_Deleted: "Member deleted successfully.",
            HandleExpiredMember_Renewed: "Membership fee successfully renewed.",

            CreateMembershipFee_MembershipTypeByIdNotFound:
              "Membership type isn't selected.",
            UpdateMembershipFee_MembershipTypeByIdNotFound:
              "Membership type isn't selected.",

            SaveDonation_Create_Pending: "Creating donation...",
            SaveDonation_Update_Pending: "Updating donation...",
            SaveDonation_Success: "Donation saved.",
            SaveDonation_MemberByIdNotFound: "Member not found.",
            SaveDonation_DonationByIdNotFound: "Donation not found.",

            RemoveDonation_Pending: "Removing donation...",
            RemoveDonation_Success: "Donation removed.",
            RemoveDonation_DonationByIdNotFound: "Donation not found.",
          },
          synch: {
            electionPlaces: "election places of total",
            processed: "processed",
            updatedTotalStreets: "Updated streets",
            updatedTotalCities: "Updated cities",
            updatedTotalElectionPlaces: "Updated election places",
            updatedTotalElectionPlacesRegion: "Updated election places regions",
            members: "members of total",
            updatedTotalMembers: "Updated members",
            notUpdatedTotalMembers: "Not updated members",
          },
          synchTableHeader: {
            action: "Action",
            ordinalNumber: "Ordinal no.",
            firstName: "First name",
            lastName: "Last name",
            birthDate: "Birth date",
            address: "Address",
            cardNumber: "Card number",
          },
        },
      },
      rs: {
        translation: {
          header: {
            dashboardTitle: "Kontrolna tabla",
            memberCards: "Lista članova",
            smartMembershipTitle: "Upravljanje članovima",
            statisticTitle: "Statistika",
            userManagementTitle: "Upravljanje korisnicima",
            syncAddressesTitle: "Sinhronizujte adrese",
            notFoundTitle: "Nije pronađena stranica",
            memberTableTitle: "Tabela članova",
          },
          link: {
            capillaryVotes:"Kapilarni glasovi"
          },
          common: {
            appTitle: "Članstvo",
            selectMemberLabel: "Pretraga članova",
            cardNumberInputManual: "Ručno odaberi",
            from: "Od",
            to: "Do",
            selectProperty: "Izaberite polje",
            value: "Vrednost",
            equals: "Isto",
            update: "Ažuriraj",
            add: "Dodaj",
            cancel: "Poništi",
            remove: "Obriši",
            between: "Između",
            noResults: "Nema rezultata",
            true: "Da",
            false: "Ne",
            create: "Kreiraj",
            membershipFees: "Članarine",
            donations: "Donacije",
            degree: "stepen",
          },
          month: {
            1: "Januar",
            2: "Februar",
            3: "Mart",
            4: "April",
            5: "Maj",
            6: "Jun",
            7: "Jul",
            8: "Avgust",
            9: "Septembar",
            10: "Oktobar",
            11: "Novembar",
            12: "Decembar",
          },
          gender: {
            0: "Muško",
            1: "Žensko",
            2: "Nepoznato",
          },
          messages: {
            reports: {
              pdfReport: {
                pending: "Kreiranje PDF izveštaja",
                success: "Uspešno je kreiran PDF izveštaj",
                error: "Greška prilikom kreiranja PDF izveštaja",
              },
              excelReport: {
                pending: "Kreiranje Excel izveštaja",
                success: "Uspešno je kreiran Excel izveštaj",
                error: "Greška prilikom kreiranja Excel izveštaja",
              },
            },
            login: {
              pending: "Prijavljivanje u toku",
              success: "Uspešno ste se prijavili",
              needResetPassword:
                "Uspešna autentifikacija. Sada je potrebno resetovati lozinku",
            },
            resetPasswordOnFirstLogin: {
              pending: "Postavljanje nove lozinke u toku",
              success: "Uspešno ste postavili novu lozinku",
            },
          },
          sidebar: {
            sidebarDashboard: "Kontrolna tabla",
            sidebarAddMembers: "Upravljanje članovima",
            sidebarMemberTable: "Tabela članova",
            sidebarLogout: "Odjavi se",
            sidebarStatistic: "Statistika",
            userManagement: "Upravljanje korisnicima",
          },
          myProfile: {
            myProfileButton: "Moj profil",
            userManagementButton: "Upravljanje kor.",
            logoutButton: "Odjavi se",
            chooseLanguage: "Izaberite jezik",
            serbianLatinLanguage: "Srpski (latinica)",
            englishLagnuage: "Engleski",
            serbianCyrlLanguage: "Srpski (ćirilica)",
          },
          myProfileData: {
            myProfile: "Moj profil",
            firstName: "Ime",
            lastName: "Prezime",
            email: "Email adresa",
            phoneNumber: "Telefon",
            role: "Rola",
            close: "Zatvori",
          },
          accountManagement: {
            accountManagementText: "Upravljanje korisnicima",
            close: "Zatvori",
            selectAccount: "Izaberite postojećeg korisnika",
            account: "Korisnik",
            aboutAccount: "Podaci o korisniku",
            firstName: "Ime",
            lastName: "Prezime",
            email: "Email",
            phoneNumber: "Telefon",
            role: "Rola",
            cancelSelection: "Poništi odabir",
            create: "Kreiraj",
            update: "Ažuriraj",
          },
          loginPage: {
            loginHeader: "Prijavite se",
            loginTittle: "Članstvo",
            loginEmail: "E-pošta",
            loginPassword: "Lozinka",
            loginForgotPassword: "Zaboravili ste lozinku?",
            loginHelperEmail: "Unesite e-poštu",
            loginHelperPassowrd: "Unesite lozinku",
          },
          resetPasswordOnFirstLoginModal: {
            title: "Postavite novu lozinku",
            label: "Nova lozinka",
            submit: "Postavite lozinku",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "ZABORAVILI STE LOZINKU?",
            forgotPasswordEmail: "Email adresa",
            forgotPasswordEmailHelper: "Unesite email adresu naloga.",
            codeTitle: "Kod",
            codeHelper: "Unesite kod koji ste dobili.",
            newPasswordTitle: "Nova Lozinka",
            newPasswordHelper: "Unesite novu željenu lozinku.",
            confirmNewPassword: "Potvrdite novu lozinku",
            confirmNewPasswordHelper: "Unesite ponovo novu željenu lozinku.",
            minimumCharacters: "najmanje 8 znakova",
            minimumCapital: "najmanje 1 veliko slovo",
            minimumNumber: "najmanje 1 broj",
            minimumSpecialCharacter: "najmanje 1 specijalni znak",
            passwordConfirmed: "lozinka potvrdjena",
          },
          deleteConfirmationModal: {
            label: "Da li ste sigurni da želite da obrišete člana?",
          },
          dashboardPage: {
            accordionHeader: "Pretraga",
            accordionTitle: "Polja za pretragu:",
            accordionSubtitle: "Unesite barem 3 karaktera",
            accordionPlaceholderFirstName: "Ime",
            accordionPlaceholderLastName: "Prezime",
            accordionPlaceholderPhone: "Telefon",
            accordionPlaceholderJmbg: "JMBG",
            nextCardNumberLabel: "Sledeći čl. broj",
            accordionPlaceholderCardNumber: "Broj članske karte",
            viewTableModalTitle: "Detalji člana:",
            editTableModalTitle: "Izmenite podatke člana:",
            totalCountMember: "Ukupan broj članova",
            searchMembers: "Pretraga članova",
            aboutSearch:
              "Barem 3 karaktera za sva polja osim članske karte. Člansku kartu pretražujete na osnovu tačnog unosa.",
          },
          memberManagementPage: {
            searchExistingMember: "Pretraga postojećih članova",
            personalDataTitle: "Lični podaci",
            personalDataFirstName: "Ime",
            personalDataParentName: "Ime roditelja",
            personalDataLastName: "Prezime",
            personalDataBirthDate: "Datum rođenja",
            personalDataAge: "Godine",
            personalDataBirthPlace: "Mesto rođenja",
            personalDataIdNumber: "Broj lične karte",
            personalDataUin: "JMBG",
            personalDataMale: "Muško",
            personalDataFemale: "Žensko",
            personalDataErrorMessageFirstName:
              "Ime mora sadržati makar dva slova",
            personalDataErrorMessageLastName:
              "Prezime mora sadržati makar dva slova",
            personalDataErrorMessageDateofbirth: "Datum rođenja je obavezan",
            addressTitle: "Podaci prebivališta",
            addressesCity: "Mesto",
            addressesStreet: "Ulica",
            addressesHomeNumber: "Kućni br.",
            addressesResidential: "Adresa stanovanja je ista",
            addressesElectionPlaceArea: "Biračko mesto",
            addressesElectionPlaceRegionArea: "Područje biračkog mesta",
            addressesElectionPlaceRegionNumber: "Broj br. mesta",
            addressesElectionPlaceRegionName: "Naziv bir. mesta",
            addressesElectionPlaceCheckboxLabel: "Ručno izaberite bir. mesto",
            addressesElectionPlaceRegionCheckboxLabel:
              "Ručno izaberite regiju bir. mesta",
            addressesElectionPlaceDetailsModalTitle: "Detalji biračkog mesta",
            addressesElectionPlaceDetailsModalNumber: "Broj:",
            addressesElectionPlaceDetailsModalName: "Naziv:",
            addressesElectionPlaceDetailsModalAddress: "Adresa:",
            addressesElectionPlaceDetailsModalPlace: "Mesto:",
            addressesDataErrorMessageCity: "Mesto prebivališta je obavezno",
            addressesDataErrorMessageStreet: "Ulica prebivališta je obavezna",
            addressesDataErrorHomeNumber: "Kućni broj je obavezan",
            contactTitle: "Kontakt podaci",
            contactMobilePhone: "Mobilni telefon",
            contactLandingPhone: "Fiksni telefon",
            contactBussinesPhone: "Poslovni telefon",
            contactEmail: "E-mail adresa",
            contactTwitter: "Twitter",
            contactInstagram: "Instagram",
            contactFacebook: "Facebook",
            contactOther: "Drugo",
            cardDataTitle: "Podaci članske karte",
            cardDataNumber: "Broj članske karte",
            cardDataIssued: "Članska karta izdata",
            cardDataPlaceOfIssue: "Mesto izdavanja",
            cardDataDateOfIssue: "Datum izdavanja",
            cardDataManual: "Manuelno odaberi",
            cardDataErrorMessageCardNumber: "Članski broj nije slobodan",
            employmentDataTitle: "Podaci o zaposlenju",
            employmentDataIsEmplyed: "Zaposlen/a",
            employmentDataIsEmplyedYes: "Da",
            employmentDataIsEmplyedNo: "Ne",
            employmentDataIsEmplyedOther: "Ostalo",
            employmentDataCompany: "Ostalo",
            employmentDataNameOfCompany:
              "Naziv firme, preduzeća, ustanove i sedišta",
            employmentDataWorkPlace: "Radno mesto",
            employmentDataTemporary: "Zaposlen/a za stalno",
            educationTitle: "Podaci o školovanju",
            educationElementarySchool: "Osnovna škola",
            educationHighSchool: "Srednja škola (smer)",
            educationBasicVocationalSchool: "Osnovne strukovne studije",
            educationSpecialistVocationalSchool:
              "Specijalističke strukovne studije",
            educationAcademicStudies: "Akademske studije",
            educationMasterAcademicStudies: "Master akademske studije",
            educationSpecialistAcademicStudies:
              "Specijalističke akademske studije",
            educationDoctoralAcademicStudies: "Doktorske akademske studije",
            educationComputerSkills: "Osposobljenost za rad na računaru",
            educationNo: "Ne",
            educationYes: "Da",
            educationLevelThird: "III stepen",
            educationLevelFourth: "IV stepen",
            languageTitle: "Jezici člana",
            languageLanguage: "Jezik",
            languageLevel: "Nivo",
            languageActive: "Aktivno",
            languagePassive: "Pasivno",
            otherDataTitle: "Ostali podaci",
            otherDataHobby: "Hobi",
            otherDataSaintDay: "Krsna slava",
            otherDataSaintDayDate: "Datum krsne slave",
            otherDataRecommendation: "Preporuka člana",
            otherDataNoteOne: "Beleška",
            otherDataNoteSecond: "Beleška",
            otherDataNoteThird: "Beleška",
          },
          memberTablePage: {
            personalData: "Opšti podaci člana",
            address: "Prebivalište člana",
            card: "Podaci članske karte",
            contact: "Kontakt podaci člana",
            education: "Obrazovanje člana",
            employment: "Zaposlenje člana",
            languages: "Jezici člana",
            otherData: "Ostali podaci člana",
            allMember: "Svi opšti podaci",
            allAddress: "Svi podaci prebivališta",
            allCard: "Svi podaci članske karte",
            allContact: "Svi kontakt podaci",
            allEducation: "Svi podaci obrazovanja",
            allEmployment: "Svi podaci zaposlenja",
            allLanguages: "Svi podaci jezika",
            allOtherData: "Svi ostali podaci",
            predefinedReports: "Predefinisani izveštaji članova",
            predefinedReportByLocalCommunity: "Izveštaj po mesnoj zajednici",
            predefinedReportByCity: "Izveštaj po gradu",
            predefinedReportByElectionPlace: "Izveštaj po biračkom mestu",
            predefinedReportDefault: "Predefinisan izveštaj",
            exportExcelTitle: "Izvoz excel",
            settingsTitle: "Podešavanja",
            exportExcel: "Izvezi excel",
            exportPdf: "Izvezi pdf",
            selectionOfTableColumns: "Izbor kolona tabele",
            tableActionTitle: "Akcija",
            tableOrdinalNumberTitle: "R. br.",
            tableFirstNameTitle: "Ime",
            tableLastNameTitle: "Prezime",
            tableLastNameParentName: "Ime roditelja",
            tableUinTitle: "Jmbg",
            tableBirthDateTitle: "Datum rođenja",
            tableAgeTitle: "Godine starosti",
            tableBirthPlaceTitle: "Mesto rođenja",
            tableIdNumberTitle: "Br. lične karte",
            tableGenderTitle: "Pol",
            tableResidenceCityTitle: "Mesto",
            tableResidenceStreetTitle: "Ulica",
            tableResidenceHomeNumberTitle: "Kućni broj",
            tableResidenceCityRegionTitle: "Grad",
            tableResidenceMunicipalityTitle: "Opština",
            tableCityTitle: "Mesto stanovanja",
            tableStreetTitle: "Ulica stanovanja",
            tableHomeNumberTitle: "Kućni br. stanovanja",
            tableCityRegionTitle: "Grad stanovanja",
            tableMunicipalityTitle: "Opština stanovanja",
            tableElectionPlaceNameTitle: "Biračko mesto",
            tableElectionPlaceNumberTitle: "Br. biračkog mesta",
            tableElectionPlaceAddressTitle: "Adr. biračkog mesta",
            tableLocalCommunityNameTitle: "M.Z.",
            tableIdCardNumberTitle: "Br. članske karte",
            tableIsCardPublishedTitle: "Čl. karta izdata",
            tableMemberCardCityTitle: "Mesto izdavanja",
            tableMemberCardDateTitle: "Datum izdavanja",
            tableLandingPhoneTitle: "Fiksni telefon",
            tableMobilePhoneTitle: "Mobilni telefon",
            tableBusinessPhoneTitle: "Poslovni telefon",
            tableEmailTitle: "E-mail",
            tableFacebookTitle: "Facebook",
            tableInstagramTitle: "Instagram",
            tableTwitterTitle: "Twitter",
            tableOtherContactTitle: "Ostalo kontakt",
            tableElementarySchoolTitle: "Osnovna škola",
            tableHighSchoolTitle: "Srednja škola",
            tableHighSchoolLevelTitle: "Stepen srednje škole",
            tableVocationalStudiesTitle: "Strukovne studije",
            tableSpecialistVocationalStudiesTitle: "Spec. struk. studije",
            tableBasicAcademicStudiesTitle: "Osnovne aka. studijе",
            tableBasicAcademicStudiesLevelTitle: "Stepen osn. akademskih stud.",
            tableMasterAcademicStudiesTitle: "Master aka. studija",
            tableMasterAcademicStudiesLevelTitle: "Stepen master aka. studija",
            tableSpecialAcademicStudiesTitle: "Spec. aka. studije",
            tableDoctoralAcademicStudiesTitle: "Doktorske aka. studije",
            tableWorkOnComputerTitle: "Rad na računaru",
            tableIsEmployedTitle: "Zaposlen/a",
            tableCompanyTitle: "Firma",
            tableWorkPlaceTitle: "Radno mesto",
            tableTemporaryEmployed: "Zaposlen/a za stalno",
            tableOtherEmployment: "Ostalo zaposlenje",
            tableLanguagesTitle: "Jezici",
            tableLanguageSubtitleLanguage: "Jezik",
            tableLanguageSubtitleLevel: "Nivo",
            tableLanguageSubtitleActive: "Aktivno koristi",
            tableHobbyTitle: "Hobi",
            tableSaintDay: "Krsna slava",
            tableSaintDayDate: "Datum krsne slave",
            tableRecommendationTitle: "Preporuka člana",
            tableNoteТitle: "Beleška",
            tableNoteFirstTitle: "Beleška",
            tableNoteSecondTitle: "Beleška",
            tableNoteThirdTitle: "Beleška",
            detailOfExcelFileTitle: "Detalji excel fajla",
            detailOfExcelFileGeneral: "Generalno",
            detailOfExcelFileNameOfDocument: "Naziv dokumenta",
            detailOfExcelFileHeader: "Zaglavlje dokumenta",
            detailOfExcelFileFirstLine: "Prva linija",
            detailOfExcelFileSecondLine: "Druga linija",
            filterTitle: "Filteri",
            filterNoRequirement: "Bez uslova",
            filterEquals: "Jednako",
            filterNotEquals: "Nije jednako",
            filterEmpty: "Prazno",
            filterNotEmpty: "Nije prazno",
            filterLessThan: "Manje od",
            filterLessThanOrEqualTo: "Manje od ili jednako",
            filterGreaterThan: "Veće od",
            filterGreaterThanOrEqualTo: "Veće od ili jednako",
            filterStartsWith: "Počinje sa",
            filterNotStartsWith: "Ne počinje sa",
            filterEndsWith: "Zavrašava se sa",
            filterNotEndsWith: "Ne završava se sa",
            filterContains: "Sadrži",
            filterNotContains: "Ne sadrži",
            filterFieldPropName: "Polje",
            filterFieldFirstRequirement: "Prvi uslov",
            filterFieldSecondRequirement: "Drugi uslov",
            filterLogicalOperatorAnd: "i",
            filterLogicalOperatorOr: "ili",
            filterFieldFirstData: "Prvi podatak",
            filterFieldSecondData: "Drugi podatak",
            resultsPerPage: "Rezultata po stranici",
            tableFiltersTitle: "Filteri tabele",
            showingResults: "Prikazuje",
            showingOf: "od",
            results: "rezultata",
            rowsPerPage: "Rezultata po stranici",

            getExcelReportCurrentDataFileName: "Članovi - Excel",
            getPdfReportCurrentDataFileName: "Članovi - PDF",

            appliedFiltersLabel: "Primenjeni filteri",
          },
          userTablePage: {
            userTableHeaderAction: "Akcija",
            userTableHeaderOridinalNumber: "Redni broj",
            userTableHeaderId: "Id",
            userTableHeaderFirstName: "Ime",
            userTableHeaderLastName: "Prezime",
            userTableHeaderEmail: "Email",
            userTableHeaderPhone: "Telefon",
            userTableHeaderRole: "Rola",
            userTableHeaderIsActive: "Aktivan",
            userTableHeaderIsDelete: "Obrisan",
            userTableAlert: "Da li sigurno želite da",
            userTableAlertDeactivate: "deaktivirate",
            userTableAlertActivate: "aktivirate",
            userTableAlertRemove: "obrišete",
            userTableAlertGetBack: "vratite",
            userTableAlertUser: "korisnika",
            createNewUserIsDeleted: "Izbrisano je",
            account: "nalog",
            userFormStateCreate: "Kreiraj",
            userFormStateEdit: "Izmeni",
            userFormStateView: "Pregledaj",
            userFormStateNone: "",
          },
          statisticPage: {
            ageStatistic: "Starosna statistika",
            statisticByCity: "Statistika po mestu",
            totalMembers: "Ukupno članova: ",
            ageOfMembers: "Godine članova i pol: ",
          },
          alerts: {
            statisticPageAlert: "Kopirano!",
          },
          buttons: {
            loginButton: "Prijavite se",
            dashboardAddMember: "kreiraj",
            dashboardUpdateMember: "ažuriraj",
            dashboardClearFields: "očisti polja",
            dashboardDeleteMember: "obriši",
            close: "Zatvori",
            selectionOfTableColumns: "izbor kolona tabele",
            save: "Sačuvaj",
            electionPlaceDetails: "Detalji biračkog mesta",
            cancelFilter: "Poništite filtere",
            submitFilter: "Primenite filtere",
            menageDocumentData: "Upravljajte podacima dokumenta",
            sendRequest: "Pošaljite zahtev",
            resetPassword: "Resetujte lozinku",
            createNewUser: "kreirajte novog korisnika",
            confirm: "Da",
            reject: "Ne",
          },
          tooltips: {
            predefinedReports: "Predefinisani izveštaji članova",
            pdfReport: "PDF izveštaj članova",
            excelReport: "Excel izveštaj članova",
            tableActionRowsEdit: "Izmenite informacije o članovima",
            tableActionRowsView: "Pogledajte podatke člana",
            tableActionRowsDelete: "Obrišite člana ",
            tableActionRowsUserEdit: "Izmenite informacije o korisnicima",
            tableActionRowsUserView: "Pogledajte podatke korisnika",
            tableActionRowsUserDeactivate: "Deaktivirajte korisnika",
            tableActionRowsUserActivate: "Aktivirajte korisnika",
            tableActionRowsUserRemove: "Obrišite korisnika",
            tableActionRowsUserGetBack: "Vratite korisnika",
            tooltipsStatistic:
              "Klikom na tekst kopirate sadržaj u memoriju klipborda",
            manuallySelectCardDataText:
              "Manuelno odaberite slobodan broj članske karte.",
            isAddressSameAddressSectionText:
              "Ukoliko adresa stanovanja nije ista kao i adresa iz lične karte, možete dodati i adresu stanovanja zbog evidencije.",
            electionPlaceRegionAddressSectionText:
              "Ukoliko regija biračkog mesta nije postavljena automatski, možete ručno izabrati od ponuđenih regija iz navedenog mesta.",
            otherEmploymentText:
              "Dodatne informacije o zaposlenju ukoliko ima nekih specifičnosti.",
            memberRecommendationOtherText:
              " Ime i prezime člana koji je dao preporuku.",
            memberNoteOtherText: "Dodatne informacije o članu",
            tooltipTitle: "INFORMACIJE",
            languageLevelLanguageSection: "Nivo jezika po CEFR standardu.",
            contactOtherContactSectionText:
              "Dodatne mreže za kontakt (Skype, LinkedIn ...)",
          },
          icons: {
            filters: "Izaberite filtere",
          },
          notifications: {
            createPersonalDataPending: "Kreiranje člana...",
            createPersonalDataFulfilled: "Uspešno je kreiran član!",
            updatePersonalDataPending: "Ažuriranje člana...",
            updatePersonalDataFulfilled: "Uspešno je ažuriran član!",
            deleteMemberPending: "Brisanje člana...",
            deleteMemberFulfilled: "Član je uspešno obrisan!",

            loginPending: "Prijavljivanje na sistem...",
            loginFulfilled: "Uspešno ste se prijavili na sistem.",

            createAccountPending: "Kreiranje korisničkog naloga",
            createAccountFulfilled:
              "Korisnički nalog kreiran, e-mail je poslat",
            updateAccountPending: "Ažuriranje korisničkog naloga",
            updateAccountFulfilled: "Korisnički nalog ažuriran",

            getExcelReportCurrentDataPending:
              "Kreiranje Excel izveštaja članova iz tabele...",
            getExcelReportCurrentDataFulfilled:
              "Kreiranje Excel izveštaja članova iz tabele završeno.",
            getPdfReportCurrentDataPending:
              "Kreiranje PDF izveštaja članova iz tabele...",
            getPdfReportCurrentDataFulfilled:
              "Kreiranje PDF izveštaja članova iz tabele završeno.",
          },
          updateElectionPlaceModal: {
            updateElectionPlaces: "Ažuriraj adrese",
            uploadDocumnet: "Otpremite dokument",
            upload: "Otpremite",
            allowedDocumentTypes: 'Dozvoljeni tipovi dokumenta "docx"',
            close: "Zatvori",
          },
          updateElectionPlaceTable: {
            lastName: "Prezime",
            firstName: "Ime",
            city: "Mesto",
            street: "Ulica",
            homeNumber: "Kućni broj",
            phone: "Telefon",
            electionPlaces: "Područje biračkog mesta",
            sureVotes: "Sigurni glasovi - manuelno podšavanje biračkog mesta",
          },
          updateMembershipModal: {
            updateMembership: "Ažuriranje članstva",
            uploadDocumnet: "Otpremite dokument",
            upload: "Otpremite",
            allowedDocumentTypes: 'Dozvoljeni tipovi dokumenata "docx"',
            close: "Zatvori",
          },
          updatePlacesAndStreetsModal: {
            updatePlacesAndStreets: "Ažuriranje mesta i ulica",
            uploadDocumnet: "Otpremite dokument",
            upload: "Otpremite",
            allowedDocumentTypes: 'Dozvoljeni tipovi dokumenta "docx"',
            close: "Zatvori",
          },
          syncAddressesPage: {
            updateMembership: "Ažuriranje članstva",
            updateElectionPlace: "Ažuriranje biračkih mesta",
            updatePlacesAndStreets: "Ažuriranje mesta i ulica",
            save: "Sačuvaj",
          },
          errorNotifications: {
            undefined: "Dogodila se greška na serveru.",
            "-500": "Dogodila se greška na serveru.",
            0: "Dogodila se greška na serveru.",
            1: "Adresa člana ne postoji.",
            2: "Članska karta člana ne postoji.",
            3: "Kontakt podaci člana ne postoje.",
            4: "Podaci o obrazovanju člana ne postoje.",
            5: "Podaci o zaposlenju člana ne postoje.",
            6: "Strani jezik člana ne postoji.",
            7: "Ostali podaci člana ne postoje.",
            8: "Lični podaci člana ne postoje.",
            9: "Član sa ovim podacima već postoji.",
            10: "Korisnički nalog ne postoji.",
            11: "Korisnički nalog sa ovim email-om ne postoji.",
            12: "Email adresa je već u upotrebi.",
            13: "Korisnička rola sa ovim nazivom ne postoji.",
            14: "Korisnička nalog nije aktivan. Molimo Vas kontaktirajte administratora.",
            15: "Pogrešna lozinka.",
            16: 'Neispravan "refresh token".',
            17: "Vaša sesija je istekla.",
            18: "Opština ne postoji.",
            19: "Regija ne postoji.",
            20: "Mesto ne postoji.",
            21: "Zemlja ne postoji.",
            22: "Mesna zajednica ne postoji.",
            23: "Mesna zajednica ne postoji.",
            24: "Opština ne postoji.",
            25: "Država ne postoji.",
            26: "Ulica ne postoji.",
            27: "Regija biračkog mesta ne postoji.",
            28: "Biračko mesto ne postoji.",
            29: "Isteklo je vreme za resetovanje Vaše lozinke.",
            30: "Rola ne postoji.",
          },
          switch: {
            isActive: "Aktivan",
          },
          reportHeaderTitleModal: {
            title: "Izveštaj članova",
            titleInReport: "Naslov u izveštaju",
            closeModal: "Zatvori",
            getTheReport: "Preuzmi izveštaj",
          },
          toastMessages: {
            requestResetPasswordEmailError:
              "Uneti podaci nisu validni, proverite ih i pokušajte ponovo!",
            requestResetPasswordEmailSuccess: "Uspešno ste poslali zahtev!",
            requestResetPasswordSuccess: "Uspešno ste resetovali lozinku!",
            createdAccountError:
              "Uneti podaci nisu validni, proverite ih i pokušajte ponovo!",
          },
          chart: {
            countStatistic: {
              title: "Članstvo",
              created: "Kreirani",
              deleted: "Obrisani",
              newCount: "Novi",
              totalCount: "Ukupno",
            },
            genderStatistic: {
              title: "Polna statistika",
            },
            ageStatistic: {
              title: "Starosna statistika",
              from21To30: "Od 21 do 30",
              from31To40: "Od 31 do 40",
              from41To50: "Od 41 do 50",
              from51To60: "Od 51 do 60",
              from61To70: "Od 61 do 70",
            },
          },
          memberExpireList: {
            renewTitle: "Za obnovu članarine",
            expiredTitle: "Istekla članarina",
            renewActionLabel: "Obnovi članarinu",
            deactivateActionLabel: "Deaktiviraj člana",
            notifyActionLabel: "Pošalji obaveštenje članu",
            daysRemaining: "{{day}} dana preostalo",
            daysAgo: "Pre {{day}} dana",
            paying: "Plaća",
            amount: "Iznos",
            startDate: "Datum početka",
            membershipType: "Tip članarine",
          },
          donationTable: {
            firstName: "Ime",
            lastName: "Prezime",
            phone: "Broj telefona",
            amount: "Iznos",
            donatedDate: "Datum doniranja",
            purpose: "Namena",
            isBusiness: "Pravno lice",
            companyName: "Firma",
            companyPib: "Pib",
            createDonation: "Nova donacija",
            donationsToAssociationTitle: "Donacije udruženju",
            donationsFromAssociationTitle: "Donacije od udruženja",
            saveDonationTitle: "Sačuvaj donaciju",
            editDonationTooltip: "Izmeni donaciju",
            removeDonationTooltip: "Ukloni donaciju",
            viewDonationTooltip: "Pregledaj donaciju",
          },
          donationTabs: {
            toAssociationFromIndividuals: "Fizička lica",
            toAssociationFromLegalEntities: "Pravna lica",
            fromAssociation: "Donacije od udruženja",
          },
          membershipFeeTabs: {
            forRenewMembership: "Za obnovu članarine",
            expiredMembership: "Istekla članarina",
          },
          messageKey: {
            InternalServerError: "Dogodila se greška na serveru.",
            undefined: "Greška prilikom uspostavljanja veze sa serverom.",

            Login_Pending: "Prijavljivanje u toku...",
            Login_AccountByEmailNotFound: "Nalog sa ovim email-om ne postoji.",
            Login_TooManyAttempts:
              "Previše neuspelih pokušaja prijavljivanja. Kontaktirajte administratora.",
            Login_NeedToChangePassword:
              "Potrebno je da promenite lozinku. Kontaktirajte administratora.",
            Login_WrongPassword: "Pogrešna lozinka.",
            Login_AccountInactive:
              "Nalog nije aktivan. Kontaktirajte administratora.",
            Login_Success: "Uspešno ste se prijavili!",

            GetAuthenticatedAccount_NoAccountAuthenticated:
              "Nalog nije prijavljen.",
            GetAuthenticatedAccount_AccountByIdNotFound: "Nalog nije pronađen.",
            GetAuthenticatedAccount_Success: "Uspešno autentifikovan nalog.",

            RefreshToken_AccountByRefreshTokenNotFound:
              "Nalog nije pronađen na osnovu datog tokena.",
            RefreshToken_SessionExpired: "Sesija je istekla.",
            RefreshToken_Success: "Uspešno osvežena sesija.",

            ForgotPassword_Pending: "Čuvanje nove lozinke...",
            ForgotPassword_TokenExpired:
              "Token je istekao. Pošaljite zahtev ponovo.",
            ForgotPassword_AccountByEmailNotFound: "Nalog nije pronađen.",
            ForgotPassword_Success: "Uspešno ste sačuvali svoju novu lozinku.",

            RequestForgotPassword_Pending:
              "Slanje zahteva za resetovanje lozinke...",
            RequestForgotPassword_AccountByEmailNotFound:
              "Nalog nije pronađen.",
            RequestForgotPassword_EmailSent:
              "Zahtev za resetovanje lozinke je poslat na email adresu.",

            ResetPassword_Pending: "Resetovanje lozinke...",
            ResetPassword_AccountByIdNotFound: "Nalog nije pronađen.",
            ResetPassword_AccountByEmailNotFound: "Nalog nije pronađen.",
            ResetPassword_IncorrectOldPassword: "Netačna trenutna lozinka.",
            ResetPassword_Success: "Uspešno ste sačuvali svoju novu lozinku.",

            CreateAccount_Pending: "Kreiranje korisničkog naloga...",
            CreateAccount_Success: "Korisnički nalog je uspešno kreiran.",

            UpdateAccount_Pending: "Ažuriranje korisničkog naloga...",
            UpdateAccount_AccountByIdNotFound: "Nalog nije pronađen.",
            UpdateAccount_Success: "Korisnički nalog je uspešno ažuriran.",

            SetPassword_AccountByIdNotFound: "Nalog nije pronađen.",

            SetStatus_AccountByIdNotFound: "Nalog nije pronađen.",

            IncrementFailedLogin_AccountByIdNotFound: "Nalog nije pronađen.",

            UpdateDetailsAfterLogin_AccountByIdNotFound: "Nalog nije pronađen.",

            CreateAssociation_AccountByIdNotFound: "Nalog nije pronađen.",
            CreateAssociation_AssociationByIdNotFound:
              "Udruženje nije pronađeno.",
            CreateAssociation_Success: "Udruženje je uspešno kreirano.",

            UpdateAssociation_AssociationByIdNotFound:
              "Udruženje nije pronađeno.",
            UpdateAssociation_AccountByIdNotFound: "Nalog nije pronađen.",
            UpdateAssociation_DatabaseByIdNotFound:
              "Baza podataka nije pronađena.",
            UpdateAssociation_ColorByIdNotFound: "Boje nisu pronađene.",
            UpdateAssociation_Success: "Udruženje je uspešno ažurirano.",

            GetAllRoles_AccountNotFound: "Nalog nije pronađen.",

            GetByEmail_AccountByEmailNotFound:
              "Nalog nije pronađen na osnovu date email adrese.",

            GetAllAccounts_AccountIsNotAuthenticated:
              "Nalog nije autentifikovan.",

            GetElectionPlaceRegionById_ElectionPlaceRegionByIdNotFound:
              "Region biračkog mesta nije pronađen.",
            UpdateElectionPlaceRegion_ElectionPlaceRegionByIdNotFound:
              "Region biračkog mesta nije pronađen.",
            DeleteElectionPlaceRegion_ElectionPlaceRegionByIdNotFound:
              "Region biračkog mesta nije pronađen.",
            GetMemberAddressById_MemberAddressByIdNotFound:
              "Adresa člana nije pronađena.",
            UpdateMemberAddress_MemberAddressByIdNotFound:
              "Adresa člana nije pronađena.",
            DeleteMemberAddress_MemberAddressByIdNotFound:
              "Adresa člana nije pronađena.",
            DeleteMemberPersonalData_MemberByIdNotFound: "Član nije pronađen.",
            SavePersonalData_MemberAlreadyExists: "Član već postoji.",

            SendEmailRenewMembership_Pending: "Slanje mejla...",
            SendEmailRenewMembership_MemberByEmailNotFound:
              "Član sa ovim imejl-om nije pronađen.",
            SendEmailRenewMembership_Success:
              "Poslat imejl o obaveštenju isteka članarine.",

            HandleExpiredMember_Pending: "Izvršavanje...",
            HandleExpiredMember_MemberByIdNotFound: "Član nije pronađen.",
            HandleExpiredMember_Deleted: "Član je uspešno obrisan.",
            HandleExpiredMember_Renewed: "Članarina je uspešno obnovljena.",

            CreateMembershipFee_MembershipTypeByIdNotFound:
              "Nije selektovan tip članarine.",
            UpdateMembershipFee_MembershipTypeByIdNotFound:
              "Nije selektovan tip članarine.",

            SaveDonation_Create_Pending: "Kreiranje donacije...",
            SaveDonation_Update_Pending: "Ažuriranje donacije...",
            SaveDonation_Success: "Donacija sačuvana.",
            SaveDonation_MemberByIdNotFound: "Član nije pronađen.",
            SaveDonation_DonationByIdNotFound: "Donacija nije pronađena.",

            RemoveDonation_Pending: "Uklanjanje donacije...",
            RemoveDonation_Success: "Donacija uklonjena.",
            RemoveDonation_DonationByIdNotFound: "Donacija nije pronađena.",
          },
          synch: {
            electionPlaces: "biračkih mesta od ukupno",
            processed: "obrađeno",
            updatedTotalStreets: "Ažurirano ulica",
            updatedTotalCities: "Ažurirano mesta",
            updatedTotalElectionPlaces: "Ažurirano biračkih mesta",
            updatedTotalElectionPlacesRegion:
              "Ažurirano područja biračkih mesta",
            members: "članova od ukupno",
            updatedTotalMembers: "Ažurirano članova",
            notUpdatedTotalMembers: "Neuspešno ažurirani članovi",
          },
          synchTableHeader: {
            action: "Akcija",
            ordinalNumber: "R. br.",
            firstName: "Ime",
            lastName: "Prezime",
            birthDate: "Datum rođenja",
            address: "Adresa",
            cardNumber: "Br. članske",
          },
        },
      },
      rsCyrl: {
        translation: {
          header: {
            dashboardTitle: "Контролна табла",
            memberCards: "Листа чланова",
            smartMembershipTitle: "Управљање члановима",
            statisticTitle: "Статистика",
            userManagementTitle: "Управљање корисницима",
            syncAddressesTitle: "Синхронизујте адресе",
            notFoundTitle: "Није пронађена страница",
            memberTableTitle: "Табела чланова",
          },
          link: {
            capillaryVotes:"Капиларни гласови"
          },
          common: {
            appTitle: "Чланство",
            selectMemberLabel: "Претрага чланова",
            cardNumberInputManual: "Ручно одабери",
            from: "Од",
            to: "До",
            selectProperty: "Изаберите поље",
            value: "Вредност",
            equals: "Исто",
            update: "Ажурирај",
            add: "Додај",
            cancel: "Поништи",
            remove: "Обриши",
            between: "Између",
            noResults: "Нема резултата",
            true: "Да",
            false: "Не",
            create: "Креирај",
            membershipFees: "Чланарине",
            donations: "Донације",
            degree: "степен",
          },
          month: {
            1: "Јануар",
            2: "Фебруар",
            3: "Март",
            4: "Април",
            5: "Мај",
            6: "Јун",
            7: "Јул",
            8: "Август",
            9: "Септембар",
            10: "Октобар",
            11: "Новембар",
            12: "Децембар",
          },
          gender: {
            0: "Мушко",
            1: "Женско",
            2: "Непознато",
          },
          messages: {
            reports: {
              pdfReport: {
                pending: "Креирање PDF извештаја",
                success: "Успешно је креиран PDF извештај",
                error: "Грешка приликом креирања PDF извештаја",
              },
              excelReport: {
                pending: "Креирање Excel извештаја",
                success: "Успешно је креиран Excel извештај",
                error: "Грешка приликом креирања Excel извештаја",
              },
            },
            login: {
              pending: "Пријављивање у току",
              success: "Успешно сте се пријавили",
              needResetPassword:
                "Успешна аутентификација. Сада је потребно ресетовати лозинку",
            },
            resetPasswordOnFirstLogin: {
              pending: "Постављање нове лозинке у току",
              success: "Успешно сте поставили нову лозинку",
            },
          },
          sidebar: {
            sidebarDashboard: "Контролна табла",
            sidebarAddMembers: "Управљање члановима",
            sidebarMemberTable: "Табела чланова",
            sidebarLogout: "Одјави се",
            sidebarStatistic: "Статистика",
            userManagement: "Управљање корисницима",
          },
          myProfile: {
            myProfileButton: "Мој профил",
            userManagementButton: "Управљање кор.",
            logoutButton: "Одјави се",
            chooseLanguage: "Изаберите језик",
            serbianLatinLanguage: "Српски (латиница)",
            englishLagnuage: "Енглески",
            serbianCyrlLanguage: "Српски (ћирилица)",
          },
          myProfileData: {
            myProfile: "Мој профил",
            firstName: "Име",
            lastName: "Презиме",
            email: "Имејл адреса",
            phoneNumber: "Телефон",
            role: "Рола",
            close: "Затвори",
          },
          accountManagement: {
            accountManagementText: "Управљање корисницима",
            close: "Затвори",
            selectAccount: "Изаберите постојећег корисника",
            account: "Корисник",
            aboutAccount: "Подаци о кориснику",
            firstName: "Име",
            lastName: "Презиме",
            email: "Имејл",
            phoneNumber: "Телефон",
            role: "Рола",
            cancelSelection: "Поништи одабир",
            create: "Креирај",
            update: "Ажурирај",
          },
          loginPage: {
            loginHeader: "Пријавите се",
            loginTittle: "Чланство",
            loginEmail: "Е-пошта",
            loginPassword: "Лозинка",
            loginForgotPassword: "Заборавили сте лозинку?",
            loginHelperEmail: "Унесите е-пошту",
            loginHelperPassowrd: "Унесите лозинку",
          },
          resetPasswordOnFirstLoginModal: {
            title: "Поставите нову лозинку",
            label: "Нова лозинка",
            submit: "Поставите лозинку",
          },
          forgotPasswordModal: {
            forgotPasswordTitle: "ЗАБОРАВИЛИ СТЕ ЛОЗИНКУ?",
            forgotPasswordEmail: "Email адреса",
            forgotPasswordEmailHelper: "Унесите email адресу налога.",
            codeTitle: "Код",
            codeHelper: "Унесите код који сте добили.",
            newPasswordTitle: "Нова Лозинка",
            newPasswordHelper: "Унесите нову жељену лозинку.",
            confirmNewPassword: "Потврдите нову лозинку",
            confirmNewPasswordHelper: "Унесите поново нову жељену лозинку.",
            minimumCharacters: "најмање 8 знакова",
            minimumCapital: "најмање 1 велико слово",
            minimumNumber: "најмање 1 број",
            minimumSpecialCharacter: "најмање 1 специјални знак",
            passwordConfirmed: "лозинка потврдјена",
          },
          deleteConfirmationModal: {
            label: "Да ли сте сигурни да желите да обришете члана?",
          },
          dashboardPage: {
            accordionHeader: "Претрага",
            accordionTitle: "Поља за претрагу:",
            accordionSubtitle: "Унесите барем 3 карактера",
            accordionPlaceholderFirstName: "Име",
            accordionPlaceholderLastName: "Презиме",
            accordionPlaceholderPhone: "Телефон",
            accordionPlaceholderJmbg: "ЈМБГ",
            nextCardNumberLabel: "Следећи чл. број",
            accordionPlaceholderCardNumber: "Број чланске карте",
            viewTableModalTitle: "Детаљи члана:",
            editTableModalTitle: "Измените податке члана:",
            totalCountMember: "Укупан број чланова",
            searchMembers: "Претрага чланова",
            aboutSearch:
              "Барем 3 карактера за сва поља осим чланске карте. Чланску карту претражујете на основу тачног уноса.",
          },
          memberManagementPage: {
            searchExistingMember: "Претрага постојећих чланова:",
            personalDataTitle: "Лични подаци",
            personalDataFirstName: "Име",
            personalDataParentName: "Име родитеља",
            personalDataLastName: "Презиме",
            personalDataBirthDate: "Датум рођења",
            personalDataBirthPlace: "Место рођења",
            personalDataIdNumber: "Број личне карте",
            personalDataUin: "ЈМБГ",
            personalDataMale: "Мушко",
            personalDataFemale: "Женско",
            personalDataErrorMessageFirstName:
              "Име мора садржати макар два слова",
            personalDataErrorMessageLastName:
              "Презиме мора садржати макар два слова",
            personalDataErrorMessageDateofbirth: "Датум рођења је обавезан ",
            addressTitle: "Подаци пребивалишта",
            addressesCity: "Место",
            addressesStreet: "Улица",
            addressesHomeNumber: "Кућни бр.",
            addressesResidential: "Адреса становања је иста",
            addressesElectionPlaceArea: "Бирачко место",
            addressesElectionPlaceRegionArea: "Подручје бирачког места",
            addressesElectionPlaceRegionNumber: "Број бр. места",
            addressesElectionPlaceRegionName: "Назив бир. места",
            addressesElectionPlaceCheckboxLabel: "Ручно изаберите бир. место",
            addressesElectionPlaceRegionCheckboxLabel:
              "Ручно изаберите регију бир. места",
            addressesElectionPlaceDetailsModalTitle: "Детаљи бирачког места",
            addressesElectionPlaceDetailsModalNumber: "Број:",
            addressesElectionPlaceDetailsModalName: "Назив:",
            addressesElectionPlaceDetailsModalAddress: "Адреса:",
            addressesElectionPlaceDetailsModalPlace: "Место:",
            addressesDataErrorMessageCity: "Место пребивалишта је обавезно",
            addressesDataErrorMessageStreet: "Улица пребивалишта је обавезна",
            addressesDataErrorHomeNumber: "Кућни број је обавезан",
            contactTitle: "Контакт подаци",
            contactMobilePhone: "Мобилни телефон",
            contactLandingPhone: "Фиксни телефон",
            contactBussinesPhone: "Пословни телефон",
            contactEmail: "Е-маил адреса",
            contactTwitter: "Twitter",
            contactInstagram: "Instagram",
            contactFacebook: "Facebook",
            contactOther: "Друго",
            cardDataTitle: "Подаци чланске карте",
            cardDataNumber: "Број чланске карте",
            cardDataIssued: "Чланска карта издата",
            cardDataPlaceOfIssue: "Место издавања",
            cardDataDateOfIssue: "Датум издавања",
            cardDataManual: "Мануелно одабери",
            cardDataErrorMessageCardNumber: "Члански број није слободан",
            employmentDataTitle: "Подаци о запослењу",
            employmentDataIsEmplyed: "Запослен/а",
            employmentDataIsEmplyedYes: "Да",
            employmentDataIsEmplyedNo: "Не",
            employmentDataIsEmplyedOther: "Остало",
            employmentDataCompany: "Остало",
            employmentDataNameOfCompany:
              "Назив фирме, предузећа, установе и седишта",
            employmentDataWorkPlace: "Радно место",
            employmentDataTemporary: "Запослен/а за стално",
            educationTitle: "Подаци о школовању",
            educationElementarySchool: "Основна школа",
            educationHighSchool: "Средња школа (смер)",
            educationBasicVocationalSchool: "Основне струковне студије",
            educationSpecialistVocationalSchool:
              "Специјалистичке струковне студије",
            educationAcademicStudies: "Академске студије",
            educationMasterAcademicStudies: "Мастер академске студије",
            educationSpecialistAcademicStudies:
              "Специјалистичке академске студије",
            educationDoctoralAcademicStudies: "Докторске академске студије",
            educationComputerSkills: "Оспособљеност за рад на рачунару",
            educationNo: "Не",
            educationYes: "Да",
            educationLevelThird: "III степен",
            educationLevelFourth: "IV степен",
            languageTitle: "Језици члана",
            languageLanguage: "Језик",
            languageLevel: "Ниво",
            languageActive: "Активно",
            languagePassive: "Пасивно",
            otherDataTitle: "Остали подаци",
            otherDataHobby: "Хоби",
            otherDataSaintDay: "Крсна слава",
            otherDataSaintDayDate: "Датум крсне славе",
            otherDataRecommendation: "Препорука члана",
            otherDataNoteOne: "Белешка",
            otherDataNoteSecond: "Белешка",
            otherDataNoteThird: "Белешка",
          },
          memberTablePage: {
            personalData: "Општи подаци члана",
            address: "Пребивалиште члана",
            card: "Подаци чланске карте",
            contact: "Контакт подаци члана",
            education: "Образовање члана",
            employment: "Запослење члана",
            languages: "Језици члана",
            otherData: "Остали подаци члана",
            allMember: "Сви општи подаци",
            allAddress: "Сви подаци пребивалишта",
            allCard: "Сви подаци чланске карте",
            allContact: "Сви контакт подаци",
            allEducation: "Сви подаци образовања",
            allEmployment: "Сви подаци запослења",
            allLanguages: "Сви подаци језика",
            allOtherData: "Сви остали подаци",
            predefinedReports: "Предефинисани извештаји чланова",
            predefinedReportByLocalCommunity: "Извештај по месној заједници",
            predefinedReportByCity: "Извештај по граду",
            predefinedReportByElectionPlace: "Извештај по бирачком месту",
            predefinedReportDefault: "Предефинисан извештај",
            exportExcelTitle: "Извоз excel",
            settingsTitle: "Подешавања",
            exportExcel: "Извези excel",
            exportPdf: "извези pdf",
            selectionOfTableColumns: "Избор колона табеле",
            tableActionTitle: "Акција",
            tableOrdinalNumberTitle: "Р. бр.",
            tableFirstNameTitle: "Име",
            tableLastNameTitle: "Презиме",
            tableLastNameParentName: "Име родитеља",
            tableUinTitle: "Јмбг",
            tableBirthDateTitle: "Датум рођења",
            tableAgeTitle: "Године старости",
            tableBirthPlaceTitle: "Место рођења",
            tableIdNumberTitle: "Бр. личне карте",
            tableGenderTitle: "Пол",
            tableResidenceCityTitle: "Место",
            tableResidenceStreetTitle: "Улица",
            tableResidenceHomeNumberTitle: "Кућни број",
            tableResidenceCityRegionTitle: "Град",
            tableResidenceMunicipalityTitle: "Општина",
            tableCityTitle: "Место становања",
            tableStreetTitle: "Улица становања",
            tableHomeNumberTitle: "Кућни бр. становања",
            tableCityRegionTitle: "Град становања",
            tableMunicipalityTitle: "Општина становања",
            tableElectionPlaceNameTitle: "Бирачко место",
            tableElectionPlaceNumberTitle: "Бр. бирачког места",
            tableElectionPlaceAddressTitle: "Адр. бирачког места",
            tableLocalCommunityNameTitle: "М.З.",
            tableIdCardNumberTitle: "Број чл. карте",
            tableIsCardPublishedTitle: "Чл. карта издата",
            tableMemberCardCityTitle: "Место издавања",
            tableMemberCardDateTitle: "Датум издавања",
            tableLandingPhoneTitle: "Фиксни телефон",
            tableMobilePhoneTitle: "Мобилни телефон",
            tableBusinessPhoneTitle: "Пословни телефон",
            tableEmailTitle: "Е-маил",
            tableFacebookTitle: "Facebook",
            tableInstagramTitle: "Instagram",
            tableTwitterTitle: "Twitter",
            tableOtherContactTitle: "Остало контакт",
            tableElementarySchoolTitle: "Основна школа",
            tableHighSchoolTitle: "Средња школа",
            tableHighSchoolLevelTitle: "Степен средње школе",
            tableVocationalStudiesTitle: "Струковне студије",
            tableSpecialistVocationalStudiesTitle: "Спец. струк. студије",
            tableBasicAcademicStudiesTitle: "Основне ака. студије",
            tableBasicAcademicStudiesLevelTitle:
              "Степен осн. академских студија",
            tableMasterAcademicStudiesTitle: "Мастер ака. студије",
            tableMasterAcademicStudiesLevelTitle: "Степен мастер ака. студија",
            tableSpecialAcademicStudiesTitle: "Специјалне ака. студије",
            tableDoctoralAcademicStudiesTitle: "Докторске ака. студије",
            tableWorkOnComputerTitle: "Рад на рачунару",
            tableIsEmployedTitle: "Запослен/а",
            tableCompanyTitle: "Фирма",
            tableWorkPlaceTitle: "Радно место",
            tableTemporaryEmployed: "Запослен/а за стално",
            tableOtherEmployment: "Остало запослење",
            tableLanguagesTitle: "Језици",
            tableLanguageSubtitleLanguage: "Језик",
            tableLanguageSubtitleLevel: "Ниво",
            tableLanguageSubtitleActive: "Активно користи",
            tableHobbyTitle: "Хоби",
            tableSaintDay: "Крсна слава",
            tableSaintDayDate: "Датум крсне славе",
            tableRecommendationTitle: "Препорука члана",
            tableNoteТitle: "Белешка",
            tableNoteFirstTitle: "Белешка",
            tableNoteSecondTitle: "Белешка",
            tableNoteThirdTitle: "Белешка",
            detailOfExcelFileTitle: "Детаљи excel фајла",
            detailOfExcelFileGeneral: "Генерално",
            detailOfExcelFileNameOfDocument: "Назив документа",
            detailOfExcelFileHeader: "Заглавље документа",
            detailOfExcelFileFirstLine: "Прва линија",
            detailOfExcelFileSecondLine: "Друга линија",
            filterTitle: "Филтери",
            filterNoRequirement: "Без услова",
            filterEquals: "Једнако",
            filterNotEquals: "Није једнако",
            filterEmpty: "Празно",
            filterNotEmpty: "Није празно",
            filterLessThan: "Мање од",
            filterLessThanOrEqualTo: "Мање од или једнако",
            filterGreaterThan: "Веће од",
            filterGreaterThanOrEqualTo: "Веће од или једнако",
            filterStartsWith: "Почиње са",
            filterNotStartsWith: "Не почиње са",
            filterEndsWith: "Заврашава се са",
            filterNotEndsWith: "Не завршава се са",
            filterContains: "Садржи",
            filterNotContains: "Не садржи",
            filterFieldPropName: "Поље",
            filterFieldFirstRequirement: "Први услов",
            filterFieldSecondRequirement: "Други услов",
            filterLogicalOperatorAnd: "и",
            filterLogicalOperatorOr: "или",
            filterFieldFirstData: "Први податак",
            filterFieldSecondData: "Други податак",
            resultsPerPage: "Резултата по страници",
            tableFiltersTitle: "Филтери табеле",
            showingResults: "Приказује",
            showingOf: "од",
            results: "резултата",
            rowsPerPage: "Резултата по страници",

            getExcelReportCurrentDataFileName: "Чланови - Excel",
            getPdfReportCurrentDataFileName: "Чланови - PDF",

            appliedFiltersLabel: "Примењени филтери",
          },
          userTablePage: {
            userTableHeaderAction: "Акција",
            userTableHeaderOridinalNumber: "Редни бр.",
            userTableHeaderId: "Ид",
            userTableHeaderFirstName: "Име",
            userTableHeaderLastName: "Презиме",
            userTableHeaderEmail: "Емаил",
            userTableHeaderPhone: "Телефон",
            userTableHeaderRole: "Рола",
            userTableHeaderIsActive: "Активан",
            userTableHeaderIsDelete: "Обрисан",
            userTableAlert: "Да ли сигурно желите да",
            userTableAlertDeactivate: "деактивирате",
            userTableAlertActivate: "активирате",
            userTableAlertRemove: "обришете",
            userTableAlertGetBack: "вратите",
            userTableAlertUser: "корисника",
            createNewUserIsDeleted: "Избрисан",
            account: "налог",
            userFormStateCreate: "Креирај",
            userFormStateEdit: "Измени",
            userFormStateView: "Прегледај",
            userFormStateNone: "",
          },
          statisticPage: {
            ageStatistic: "Старосна статистика",
            statisticByCity: "Статистика по месту",
            totalMembers: "Укупно чланова: ",
            ageOfMembers: "Године чланова и пол: ",
          },
          alerts: {
            statisticPageAlert: "Копирано!",
          },
          buttons: {
            loginButton: "Пријавите се",
            dashboardAddMember: "креирај",
            dashboardUpdateMember: "ажурирај",
            dashboardClearFields: "очисти поља",
            dashboardDeleteMember: "обриши",
            close: "Затвори",
            selectionOfTableColumns: "избор колона табеле",
            save: "Сачувај",
            electionPlaceDetails: "Детаљи бир. места",
            cancelFilter: "Поништите филтере",
            submitFilter: "Примените филтере",
            menageDocumentData: "Управљајте подацима документа",
            sendRequest: "Пошаљите захтев",
            resetPassword: "Ресетујте лозинку",
            createNewUser: "креирајте новог корисника",
            confirm: "Да",
            reject: "Не",
          },
          tooltips: {
            predefinedReports: "Предефинисани извештаји чланова",
            pdfReport: "PDF извештај чланова",
            excelReport: "Excel извештај чланова",
            tableActionRowsEdit: "Измените информације о члановима",
            tableActionRowsView: "Погледајте податке члана",
            tableActionRowsDelete: "Обришите члана",
            tableActionRowsUserEdit: "Измените информације о корисницима",
            tableActionRowsUserView: "Погледајте податке корисника",
            tableActionRowsUserDeactivate: "Деактивирајте корисника",
            tableActionRowsUserActivate: "Aктивирајте корисника",
            tableActionRowsUserRemove: "Обришите корисника",
            tableActionRowsUserGetBack: "Вратите корисника",
            tooltipsStatistic:
              "Кликом на текст копирате садржај у меморију клипборда",
            manuallySelectCardDataText:
              "Мануелно изаберите слободан број чланских карата.",
            isAddressSameAddressSectionText:
              "Уколико адреса становања није иста као адреса на личној карти, можете додати и адресу становања због евиденције.",
            electionPlaceRegionAddressSectionText:
              " Уколико регија бирачког места није постављена аутоматски, можете ручно изабрати од понуђених регија из наведеног места.",
            otherEmploymentText:
              "Додатне информације о запослењу ако постоје неке специфичности.",
            memberRecommendationOtherText:
              "Име и презиме члана који је дао препоруку.",
            memberNoteOtherText: "Додатне информације о члану.",
            tooltipTitle: "ИНФОРМАЦИЈЕ",
            languageLevelLanguageSection: "Ниво језика по ЦЕФР стандарду.",
            contactOtherContactSectionText:
              "Додатне мреже за контакт (Skype, LinkedIn ...)",
          },
          icons: {
            filters: "Изаберите филтере",
          },
          notifications: {
            createPersonalDataPending: "Креирање члана...",
            createPersonalDataFulfilled: "Успешно је креиран члан!",
            updatePersonalDataPending: "Ажурирање члана...",
            updatePersonalDataFulfilled: "Успешно је ажуриран члан!",
            deleteMemberPending: "Брисање члана...",
            deleteMemberFulfilled: "Члан је успешно обрисан!",

            loginPending: "Пријављивање на систем...",
            loginFulfilled: "Успешно сте се пријавили на систем.",

            createAccountPending: "Креирање корисничког налога",
            createAccountFulfilled: "Кориснички налог креиран, имејл је послат",
            updateAccountPending: "Ажурирање корисничког налога",
            updateAccountFulfilled: "Кориснички налог је ажуриран",

            getExcelReportCurrentDataPending:
              "Креирање Excel извештаја чланова из табеле...",
            getExcelReportCurrentDataFulfilled:
              "Креирање Excel извештаја чланова из табеле завршено.",
            getPdfReportCurrentDataPending:
              "Креирање PDF извештаја чланова из табеле...",
            getPdfReportCurrentDataFulfilled:
              "Креирање PDF извештаја чланова из табеле завршено.",
          },
          updateElectionPlaceModal: {
            updateElectionPlaces: "Ажурирај адресе",
            uploadDocumnet: "Отпремите документ",
            upload: "Отпремите",
            allowedDocumentTypes: 'Дозвољени типови докумената "docx"',
            close: "Затвори",
          },
          updateElectionPlaceTable: {
            lastName: "Презиме",
            firstName: "Име",
            city: "Место",
            street: "Улица",
            homeNumber: "Кућни број",
            phone: "Телефон",
            electionPlaces: "Подручје бирачког места",
            sureVotes: "Сигурни гласови - мануелно подешавање бирачког места",
          },
          updateMembershipModal: {
            updateMembership: "Ажурирање чланства",
            uploadDocumnet: "Отпремите документ",
            upload: "Отпремите",
            allowedDocumentTypes: 'Дозвољени типови докумената "docx"',
            close: "Затвори",
          },
          updatePlacesAndStreetsModal: {
            updatePlacesAndStreets: "Ажурирање места и улица",
            uploadDocumnet: "Отпремите документ",
            upload: "Отпремите",
            allowedDocumentTypes: 'Дозвољени типови докумената "docx"',
            close: "Затвори",
          },
          syncAddressesPage: {
            updateMembership: "Ажурирање чланства",
            updateElectionPlace: "Ажурирање бирачких места",
            updatePlacesAndStreets: "Ажурирање места и улица",
            save: "Сачувај",
          },
          errorNotifications: {
            undefined: "Догодила се грешка на серверу.",
            "-500": "Догодила се грешка на серверу.",
            0: "Догодила се грешка на серверу.",
            1: "Адреса члана не постоји.",
            2: "Чланска карта члана не постоји.",
            3: "Контакт подаци члана не постоје.",
            4: "Подаци о образовању члана не постоје.",
            5: "Подаци о запослењу члана не постоје.",
            6: "Страни језик члана не постоји.",
            7: "Остали подаци члана не постоје.",
            8: "Лични подаци члана не постоје.",
            9: "Члан са овим подацима већ постоји.",
            10: "Кориснички налог не постоји.",
            11: "Кориснички налог са овим емаил-ом не постоји.",
            12: "Имејл адреса је већ у употреби.",
            13: "Корисничка рола са овим називом не постоји.",
            14: "Корисничка налог није активан. Молимо Вас контактирајте администратора.",
            15: "Погрешна лозинка.",
            16: 'Неисправан "рефреш токен".',
            17: "Ваша сесија је истекла.",
            18: "Општина не постоји.",
            19: "Регија не постоји.",
            20: "Место не постоји.",
            21: "Земља не постоји.",
            22: "Месна заједница не постоји.",
            23: "Месна заједница не постоји.",
            24: "Општина не постоји.",
            25: "Држава не постоји.",
            26: "Улица не постоји.",
            27: "Регија бирачког места не постоји.",
            28: "Бирачко место не постоји.",
            29: "Истекло је време за ресетовање Ваше лозинке.",
            30: "Рола не постоји.",
          },
          switch: {
            isActive: "Активан",
          },
          reportHeaderTitleModal: {
            title: "Извештај чланова",
            titleInReport: "Наслов у извештају",
            closeModal: "Затвори",
            getTheReport: "Преузми извештај",
          },
          toastMessages: {
            requestResetPasswordEmailError:
              "Унети подаци нису валидни, проверите их и покушајте поново!",
            requestResetPasswordEmailSuccess: "Успешно сте послали захтев!",
            requestResetPasswordSuccess: "Успешно сте ресетовали лозинку!",
            createdAccountError:
              "Унети подаци нису валидни, проверите их и покушајте поново!",
          },
          chart: {
            countStatistic: {
              title: "Чланство",
              created: "Креирани",
              deleted: "Обрисани",
              newCount: "Нови",
              totalCount: "Укупно",
            },
            genderStatistic: {
              title: "Полна статистика",
            },
            ageStatistic: {
              title: "Старосна статистика",
              from21To30: "Од 21 до 30",
              from31To40: "Од 31 до 40",
              from41To50: "Од 41 до 50",
              from51To60: "Од 51 до 60",
              from61To70: "Од 61 до 70",
            },
          },
          memberExpireList: {
            renewTitle: "За обнову чланарине",
            expiredTitle: "Истекла чланарина",
            renewActionLabel: "Обнови чланарину",
            deactivateActionLabel: "Деактивирај члана",
            notifyActionLabel: "Пошаљи обавештење члану",
            daysRemaining: "{{day}} дана преостало",
            daysAgo: "Пре {{day}} дана",
            paying: "Плаћа",
            amount: "Износ",
            startDate: "Датум почетка",
            membershipType: "Тип чланарине",
          },
          donationTable: {
            firstName: "Име",
            lastName: "Презиме",
            phone: "Број телефона",
            amount: "Износ",
            donatedDate: "Датум донирања",
            purpose: "Намена",
            isBusiness: "Правно лице",
            companyName: "Фирма",
            companyPib: "Пиб",
            createDonation: "Нова донација",
            donationsToAssociationTitle: "Донације удружењу",
            donationsFromAssociationTitle: "Донације од удружења",
            saveDonationTitle: "Сачувај донацију",
            editDonationTooltip: "Измени донацију",
            removeDonationTooltip: "Уклони донацију",
            viewDonationTooltip: "Прегледај донацију",
          },
          donationTabs: {
            toAssociationFromIndividuals: "Физичка лица",
            toAssociationFromLegalEntities: "Правна лица",
            fromAssociation: "Донације од удружења",
          },
          membershipFeeTabs: {
            forRenewMembership: "За обнову чланарине",
            expiredMembership: "Истекла чланарина",
          },
          messageKey: {
            InternalServerError: "Догодила се грешка на серверу.",
            undefined: "Грешка приликом успостављања везе са сервером.",

            Login_Pending: "Пријављивање у току...",
            Login_AccountByEmailNotFound: "Налог са овим имејлом не постоји.",
            Login_TooManyAttempts:
              "Превише неуспелих покушаја пријављивања. Контактирајте администратора.",
            Login_NeedToChangePassword:
              "Потребно је да промените лозинку. Контактирајте администратора.",
            Login_WrongPassword: "Погрешна лозинка.",
            Login_AccountInactive:
              "Налог није активан. Контактирајте администратора.",
            Login_Success: "Успешно сте се пријавили!",

            GetAuthenticatedAccount_NoAccountAuthenticated:
              "Налог није пријављен.",
            GetAuthenticatedAccount_AccountByIdNotFound: "Налог није пронађен.",
            GetAuthenticatedAccount_Success: "Успешно аутентификован налог.",

            RefreshToken_AccountByRefreshTokenNotFound:
              "Налог није пронађен на основу датог токена.",
            RefreshToken_SessionExpired: "Сесија је истекла.",
            RefreshToken_Success: "Успешно освежена сесија.",

            ForgotPassword_Pending: "Чување нове лозинке...",
            ForgotPassword_TokenExpired:
              "Токен је истекао. Пошаљите захтев поново.",
            ForgotPassword_AccountByEmailNotFound: "Налог није пронађен.",
            ForgotPassword_Success: "Успешно сте сачували своју нову лозинку.",

            RequestForgotPassword_Pending:
              "Слање захтева за ресетовање лозинке...",
            RequestForgotPassword_AccountByEmailNotFound:
              "Налог није пронађен.",
            RequestForgotPassword_EmailSent:
              "Захтев за ресетовање лозинке је послат на имејл.",

            ResetPassword_Pending: "Ресетовање лозинке...",
            ResetPassword_AccountByIdNotFound: "Налог није пронађен.",
            ResetPassword_AccountByEmailNotFound: "Налог није пронађен.",
            ResetPassword_IncorrectOldPassword: "Нетачна тренутна лозинка.",
            ResetPassword_Success: "Успешно сте сачували своју нову лозинку.",

            CreateAccount_Pending: "Креирање корисничког налога...",
            CreateAccount_Success: "Кориснички налог је успешно креиран.",

            UpdateAccount_Pending: "Ажурирање корисничког налога...",
            UpdateAccount_AccountByIdNotFound: "Налог није пронађен.",
            UpdateAccount_Success: "Кориснички налог је успешно ажуриран.",

            SetPassword_AccountByIdNotFound: "Налог није пронађен.",

            SetStatus_AccountByIdNotFound: "Налог није пронађен.",

            IncrementFailedLogin_AccountByIdNotFound: "Налог није пронађен.",

            UpdateDetailsAfterLogin_AccountByIdNotFound: "Налог није пронађен.",

            CreateAssociation_AccountByIdNotFound: "Налог није пронађен.",
            CreateAssociation_AssociationByIdNotFound:
              "Удружење није пронађено.",
            CreateAssociation_Success: "Удружење је успешно креирано.",

            UpdateAssociation_AssociationByIdNotFound:
              "Удружење није пронађено.",
            UpdateAssociation_AccountByIdNotFound: "Налог није пронађен.",
            UpdateAssociation_DatabaseByIdNotFound:
              "База података није пронађена.",
            UpdateAssociation_ColorByIdNotFound: "Боје нису пронађене.",
            UpdateAssociation_Success: "Удружење је успешно ажурирано.",

            GetAllRoles_AccountNotFound: "Налог није пронађен.",

            GetByEmail_AccountByEmailNotFound:
              "Налог није пронађен на основу дате имејл адресе.",

            GetAllAccounts_AccountIsNotAuthenticated:
              "Налог није аутентификован.",

            GetElectionPlaceById_ElectionPlaceByIdNotFound:
              "Бирачко место није пронађено.",
            UpdateElectionPlace_ElectionPlaceByIdNotFound:
              "Бирачко место није пронађено.",
            DeleteElectionPlace_ElectionPlaceByIdNotFound:
              "Бирачко место није пронађено.",

            GetElectionPlaceRegionById_ElectionPlaceRegionByIdNotFound:
              "Регион бирачког места није пронађен.",
            UpdateElectionPlaceRegion_ElectionPlaceRegionByIdNotFound:
              "Регион бирачког места није пронађен.",
            DeleteElectionPlaceRegion_ElectionPlaceRegionByIdNotFound:
              "Регион бирачког места није пронађен.",
            GetMemberAddressById_MemberAddressByIdNotFound:
              "Адреса члана није пронађена.",
            UpdateMemberAddress_MemberAddressByIdNotFound:
              "Адреса члана није пронађена.",
            DeleteMemberAddress_MemberAddressByIdNotFound:
              "Адреса члана није пронађена.",
            DeleteMemberPersonalData_MemberByIdNotFound: "Члан није пронађен.",
            SavePersonalData_MemberAlreadyExists: "Члан већ постоји.",

            SendEmailRenewMembership_Pending: "Слање мејла...",
            SendEmailRenewMembership_MemberByEmailNotFound:
              "Члан са овим имејл-ом није пронађен.",
            SendEmailRenewMembership_Success:
              "Послат имејл о обавештењу истека чланарине.",

            HandleExpiredMember_Pending: "Извршавање...",
            HandleExpiredMember_MemberByIdNotFound: "Члан није пронађен.",
            HandleExpiredMember_Deleted: "Члан је успешно обрисан.",
            HandleExpiredMember_Renewed: "Чланарина је успешно обновљена.",

            CreateMembershipFee_MembershipTypeByIdNotFound:
              "Није селектован тип чланарине.",
            UpdateMembershipFee_MembershipTypeByIdNotFound:
              "Није селектован тип чланарине.",

            SaveDonation_Create_Pending: "Креирање донације...",
            SaveDonation_Update_Pending: "Ажурирање донације...",
            SaveDonation_Success: "Донација сачувана.",
            SaveDonation_MemberByIdNotFound: "Члан није пронађен.",
            SaveDonation_DonationByIdNotFound: "Донација није пронађена.",

            RemoveDonation_Pending: "Уклањање донације...",
            RemoveDonation_Success: "Донација уклоњена.",
            RemoveDonation_DonationByIdNotFound: "Донација није пронађена.",
          },
          synch: {
            electionPlaces: "бирачких места од укупно",
            processed: "обрађено",
            updatedTotalStreets: "Ажурирано улица",
            updatedTotalCities: "Ажурирано места",
            updatedTotalElectionPlaces: "Ажурирано бирачких места",
            updatedTotalElectionPlacesRegion:
              "Ажурирано подручја бирачких места",
            members: "чланова од укупно",
            updatedTotalMembers: "Ажурирано чланова",
            notUpdatedTotalMembers: "Неуспешно ажурирани чланови",
          },
          synchTableHeader: {
            action: "Акција",
            ordinalNumber: "Р. бр.",
            firstName: "Име",
            lastName: "Презиме",
            birthDate: "Датум рођења",
            address: "Адреса",
            cardNumber: "Бр. чланске",
          },
        },
      },
    },
  });

export default i18n;
