import { Member } from "../models/data/members/Member";
import { MemberAddress } from "../models/data/members/MemberAddress";
import { MemberCard } from "../models/data/members/MemberCard";
import { MemberContact } from "../models/data/members/MemberContact";
import { MemberEducation } from "../models/data/members/MemberEducation";
import { MemberEmployment } from "../models/data/members/MemberEmployment";
import { MemberLanguage } from "../models/data/members/MemberLanguage";
import { MemberOtherData } from "../models/data/members/MemberOtherData";
import { MemberPersonalData } from "../models/data/members/MemberPersonalData";
import SynchMessage from "../models/synch/synchMessage";

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const INIT_PERSONAL_DATA: MemberPersonalData = {
  id: EmptyGuid,
  firstName: "",
  lastName: "",
  parentName: "",
  jmbg: "",
  birthDate: "",
  birthPlace: "",
  idNumber: "",
  gender: "",
};

const INIT_ADDRESS: MemberAddress = {
  id: EmptyGuid,

  streetId: null,
  streetName: "",
  cityId: null,
  cityName: "",
  cityRegionId: null,
  cityRegionName: "",
  municipalityId: null,
  municipalityName: "",
  homeNumber: "",

  streetResidenceId: null,
  streetResidenceName: "",
  cityResidenceId: null,
  cityResidenceName: "",
  cityRegionResidenceId: null,
  cityRegionResidenceName: "",
  municipalityResidenceId: null,
  municipalityResidenceName: "",
  homeNumberResidence: "",

  electionPlaceId: null,
  electionPlaceName: "",
  electionPlaceAddress: "",
  isAddressSame: true,
  memberId: null,
};

const INIT_CONTACT: MemberContact = {
  id: EmptyGuid,
  email: "",
  facebook: "",
  instagram: "",
  landPhone: "",
  memberId: null,
  mobilePhone: "",
  other: "",
  twiter: "",
  workPhone: "",
};

const INIT_CARD: MemberCard = {
  id: EmptyGuid,
  establishedDate: null,
  isPublished: false,
  number: 0,
  publishingCity: "",
  memberId: null,
};

const INIT_EMPLOYMENT: MemberEmployment = {
  id: EmptyGuid,
  company: "",
  isEmployed: null,
  other: "",
  permanentEmployed: false,
  workPlace: "",
  memberId: null,
};

const INIT_EDUCATION: MemberEducation = {
  id: EmptyGuid,
  highSchool: "",
  academicStudies: "",
  academicStudiesYear: 0,
  basicVocationalStudies: "",
  doctoralAcademicStudies: "",
  elementarySchool: "",
  highSchoolYear: 0,
  masterAcademicStudies: "",
  masterAcademicStudiesYear: 0,
  specialAcademicStudies: "",
  specialVocationalStudies: "",
  workOnComputer: null,
  memberId: null,
};

export const INIT_LANGUAGE: MemberLanguage = {
  id: EmptyGuid,
  language: "",
  level: "",
  activeSpeaking: false,
  memberId: null,
};

const INIT_OTHER_DATA: MemberOtherData = {
  id: EmptyGuid,
  hobby: "",
  saintDay: "",
  saintDayDate: null,
  note1: "",
  note2: "",
  note3: "",
  recommendation: "",
  memberId: null,
};

export const INIT_MEMBER: Member = {
  personalData: INIT_PERSONAL_DATA,
  address: INIT_ADDRESS,
  card: INIT_CARD,
  contact: INIT_CONTACT,
  education: INIT_EDUCATION,
  employment: INIT_EMPLOYMENT,
  languages: [INIT_LANGUAGE],
  otherData: INIT_OTHER_DATA,
  membershipFee: null,
};

export const INIT_SYNCH_RESULT: SynchMessage = {
  electionPlaceTotal: 0,
  citiesCurrentCount: 0,
  electionPlaceCurrentCount: 0,
  electionPlaceRegionCurrentCount: 0,
  streetsCurrentCount: 0,
  membersTotal: 0,
  updatedMembersCount: 0,
  notUpdatedMembersCount: 0,
  notUpdatedMembers: [],
};
