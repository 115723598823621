import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/api/hooks";
import { Member } from "../../models/data/members/Member";
import { MemberDataManipulationRequest } from "../../models/requests/members/memberDataManipulationRequest";
import { APIResponse } from "../../models/types/api/APIResponse";
import {
  deleteMember,
  getMemberFullDetailsById,
  setDataManipulation,
  setIsEditing,
} from "../../slices/members/memberSlice";
import DeleteConfirmationModal from "../modals/deleteConfirmationModal.tsx/deleteConfirmationModal";

interface Props {
  memberId: string | undefined;
  setIdForDelete: Dispatch<SetStateAction<string>>;
  handleToggleEditModal: () => void;
  handleToggleViewModal: () => void;
  dataManipulation: MemberDataManipulationRequest;
  fetchMembersPagedList: any;
  memberName: any;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 15,
  },
}));

export default function TableActionRows({
  memberId,
  setIdForDelete,
  handleToggleEditModal,
  handleToggleViewModal,
  dataManipulation,
  fetchMembersPagedList,
  memberName,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);

  const handleOpenViewModal = () => {
    if (memberId && memberId !== "") {
      dispatch(getMemberFullDetailsById(memberId)).then(() => {
        handleToggleViewModal();
      });
    }
  };

  const handleDeleteMember = async (e: any) => {
    if (memberId && memberId.length > 0) {
      setIdForDelete(memberId);
      await dispatch(deleteMember(memberId));
      await fetchMembersPagedList({ ...dataManipulation });
    } else {
      setIdForDelete("");
    }
  };

  const handleOpenEditModal = async () => {
    if (memberId && memberId !== "") {
      const response = await dispatch(getMemberFullDetailsById(memberId));

      const result = response.payload as APIResponse<Member>;

      if (!result) {
        return;
      }

      dispatch(setIsEditing(true));
      dispatch(setDataManipulation({ ...dataManipulation }));

      handleToggleEditModal();
    }
  };

  return (
    <div>
      <LightTooltip
        title={t("tooltips.tableActionRowsEdit")}
        placement={isMobile ? "top" : "left"}
        arrow
      >
        <CreateOutlinedIcon
          onClick={handleOpenEditModal}
          name="createIcon"
          style={{ color: "#00B2FF", cursor: "pointer" }}
        />
      </LightTooltip>
      <LightTooltip
        title={t("tooltips.tableActionRowsView")}
        placement="top"
        arrow
      >
        <VisibilityOutlinedIcon
          style={{
            color: "#3C7F9C",
            marginLeft: "10px",
            marginRight: "10px",
            cursor: "pointer",
          }}
          className="accountTable_visibility_icon"
          onClick={handleOpenViewModal}
        />
      </LightTooltip>
      <LightTooltip
        title={t("tooltips.tableActionRowsDelete")}
        placement="right"
        arrow
      >
        <DeleteOutlineOutlinedIcon
          style={{ color: "#DE2C2C", cursor: "pointer" }}
          onClick={() => setOpenDeleteConfirmationModal(true)}
        />
      </LightTooltip>
      <DeleteConfirmationModal
        open={openDeleteConfirmationModal}
        handleClose={() => setOpenDeleteConfirmationModal(false)}
        memberName={memberName}
        handleDelete={(e: any) => handleDeleteMember(e)}
      />
    </div>
  );
}
