import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useBasicInputStyles = makeStyles()((theme: Theme) => ({
  inputTitle: { textAlign: "start" },
  input: {
    borderRadius: "5px",
    height: "2rem",
  },
  formControl: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  formControlError: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: theme.palette.error.main,
      },
    },
  },
  errorMessage: {
    textAlign: "start",
    color: theme.palette.error.main,
    fontSize: 12,
  },
  requiredSymbolError: {
    color: theme.palette.error.main,
  },
}));
