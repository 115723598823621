import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement={props.placement}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));
