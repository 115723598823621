import { useAppSelector } from "../../app/api/hooks";
import { useAppDispatch } from "../../app/api/hooks";
import {
  getMemberExpireList,
  selectMemberExpire,
  selectMemberExpireStatus,
} from "../../slices/members/memberSlice";
import { useEffect } from "react";

export default function useGetMemberExpireList() {
  const dispatch = useAppDispatch();
  const memberExpire = useAppSelector(selectMemberExpire);
  const memberExpireLoadingStatus = useAppSelector(selectMemberExpireStatus);

  const get = () => dispatch(getMemberExpireList());

  useEffect(() => {
    get();
  }, []);

  return { memberExpire, refetchMemberExpire: get, memberExpireLoadingStatus };
}
