import {AxiosInstance, AxiosResponse} from "axios";
import mem from "mem";
import {Token} from "../../models/enums/auth/token";
import {DisplayLanguage} from "../../models/enums/displayLanguage";
import {refreshToken as refresh} from "../../slices/auth/authSlice";
import {adminApi, api} from "./api";

const doRefresh = async (dispatch: any) => {
    return await dispatch(
      refresh({
          currentRefreshToken: localStorage.getItem(Token.RefreshToken) ?? "",
      })
  );
};

const maxAge = 5000;

export const memoizedRefreshToken = mem(doRefresh, {
  maxAge,
});

const handleErrorResponse = async (
  err: any,
  dispatch: any,
  axiosInstance: AxiosInstance
) => {
  if (err?.code === "ERR_NETWORK") {
    err.code = 0;
    return Promise.reject(err);
  }

  const config = err.config;

  if (err?.response?.status === 401 && !config?.sent) {
    config.sent = true;

    const response = await memoizedRefreshToken(dispatch);

    if (response?.payload) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${response?.accessToken}`,
      };
    }

    return axiosInstance(config);
  }

  return Promise.reject(err?.response?.data);
};

export default function setup(store: any) {
  const { dispatch } = store;

  // Request interceptors

  api.interceptors.request.use(
    (config) => {
      let displayLanguage = DisplayLanguage.SerbianLatin;

      const localStorageTranslation = localStorage.getItem("i18nextLng");

      switch (localStorageTranslation) {
        case "rs":
          displayLanguage = DisplayLanguage.SerbianLatin;
          break;

        case "rsCyrl":
          displayLanguage = DisplayLanguage.SerbianCyrillic;
          break;

        case "en":
          displayLanguage = DisplayLanguage.English;
          break;

        case "en-US":
          displayLanguage = DisplayLanguage.English;
          break;

        default:
          displayLanguage = DisplayLanguage.SerbianLatin;
          break;
      }

      config.headers["displaylanguage"] = displayLanguage;

      const token = localStorage.getItem(Token.AccessToken);

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  adminApi.interceptors.request.use(
    (config) => {
      let displayLanguage = DisplayLanguage.SerbianLatin;

      const localStorageTranslation = localStorage.getItem("i18nextLng");

      switch (localStorageTranslation) {
        case "rs":
          displayLanguage = DisplayLanguage.SerbianLatin;
          break;

        case "rsCyrl":
          displayLanguage = DisplayLanguage.SerbianCyrillic;
          break;

        case "en":
          displayLanguage = DisplayLanguage.English;
          break;

        case "en-US":
          displayLanguage = DisplayLanguage.English;
          break;

        default:
          displayLanguage = DisplayLanguage.SerbianLatin;
          break;
      }

      config.headers["displaylanguage"] = displayLanguage;

      const token = localStorage.getItem(Token.AccessToken);

      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptors

  api.interceptors.response.use(
    (res: AxiosResponse) => {
      return res.data;
    },
    async (err) => {
      return await handleErrorResponse(err, dispatch, adminApi);
    }
  );
  adminApi.interceptors.response.use(
    (res: AxiosResponse) => {
      return res.data;
    },
    async (err) => {
      return await handleErrorResponse(err, dispatch, adminApi);
    }
  );
};
