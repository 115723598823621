import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/api/hooks";
import { Account } from "../models/data/accounts/account";
import {
  getAuthenticatedUser,
  selectAuthenticatedAccount,
  selectAccessToken,
} from "../slices/auth/authSlice";
import {
  getMemberBasicDetails,
  getNextCardNumber,
} from "../slices/members/memberSlice";

export const useAuthenticatedAccount = () => {
  const dispatch = useAppDispatch();

  const authenticatedAccount: Account | null = useAppSelector(
    selectAuthenticatedAccount
  );
  const token = useAppSelector(selectAccessToken);

  useEffect(() => {
    if (token) {
      (async () => {
        await dispatch(getAuthenticatedUser());
      })();

      dispatch(getMemberBasicDetails({ data: "" }));
      dispatch(getNextCardNumber());
    }
  }, [token]);

  return { authenticatedAccount };
};
