import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/api/hooks";
import { selectDisplayLanguage } from "../../slices/displayLanguages/displayLanguageSlice";

type Props = {
  titleI18n: string;
};

export default function useSetAppTitle(props: Props) {
  const { t } = useTranslation();
  const displayLanguage = useAppSelector(selectDisplayLanguage);

  useEffect(() => {
    document.title = t(props.titleI18n);
  }, [displayLanguage, props.titleI18n, t]);
}
