import Close from "@mui/icons-material/Close";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { EmptyGuid } from "../../INITIAL_DATA/initialData";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { colors } from "../../app/theme/colors";
import AppButton from "../../component/button/AppButton";
import BasicInput from "../../component/input/basic-input/BasicInput";
import { Donated } from "../../models/donations/donated";
import { Donation } from "../../models/donations/donation";
import {
  resetSelectedMember,
  selectSelectedMember,
} from "../../slices/members/memberSlice";
import SelectMemberSection from "../smart-membership/select-member/SelectMemberSection";
import { DonationFormType } from "./DonationTable";
import sidebarBackgroundImage from "../../images/sidebarBackgroundImage.png";
import { hexToRgb } from "../../common/helpers/theme";

const initialDonation: Donation = {
  id: EmptyGuid,
  donated: Donated.ToAssociationFromIndividuals,
  firstName: "",
  lastName: "",
  phone: "",
  amount: 0,
  donatedDate: "",
  purpose: "",
  isBusiness: false,
  companyName: "",
  companyPib: "",
  memberId: null,
};

type DonationSaveModalProps = {
  donationFormType: DonationFormType;
  setDonationFormType: Dispatch<SetStateAction<DonationFormType>>;
  onSave: any;
  donation: Donation;
  setDonation: Dispatch<SetStateAction<Donation>>;
};

export function DonationSaveModal(props: DonationSaveModalProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const theme = useTheme();
  const primaryRgb = hexToRgb(theme.palette.primary.main);
  const selectedMember = useAppSelector(selectSelectedMember);

  const isRead = props.donationFormType === DonationFormType.Read;

  const donationAmount =
    props.donation.amount === 0 ? "" : props.donation.amount;

  const formattedDate = () =>
    props.donation.donatedDate && props.donation.donatedDate.substring(0, 10);

  const onCloseModal = () => {
    props.setDonationFormType(DonationFormType.None);
    props.setDonation({ ...initialDonation, donated: props.donation.donated });
    dispatch(resetSelectedMember());
  };

  useEffect(() => {
    if (
      !selectedMember?.personalData?.firstName ||
      !selectedMember?.personalData?.lastName
    ) {
      props.setDonation({
        ...props.donation,
        firstName: "",
        lastName: "",
        phone: "",
        memberId: null,
      });
      return;
    }

    props.setDonation({
      ...props.donation,
      firstName: selectedMember?.personalData?.firstName,
      lastName: selectedMember?.personalData?.lastName,
      phone: selectedMember?.contact?.mobilePhone ?? "",
      memberId: selectedMember?.personalData?.id,
    });
  }, [selectedMember]);

  const validation = {
    purpose: props.donation?.purpose,
    amount: donationAmount,
    donatedDate: formattedDate(),
  };

  return (
    <Dialog
      open={props.donationFormType !== DonationFormType.None}
      onClose={onCloseModal}
      fullWidth
    >
      <DialogTitle
        style={{
          color: "white",
        }}
      >
        <img
          src={sidebarBackgroundImage}
          alt="Sidebar Background"
          className={classes.donationModalImage}
          style={{
            background: `linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
          }}
        />
        <Grid
          style={{
            position: "sticky",
            textAlign: "end",
            border: "none",
            outline: "none",
            zIndex: 2,
          }}
        >
          <Close onClick={onCloseModal} style={{ cursor: "pointer" }} />
        </Grid>

        <h3
          style={{
            color: theme.palette.primary.dark,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {t("donationTable.saveDonationTitle")}
        </h3>
      </DialogTitle>

      <DialogContent>
        {(props.donationFormType === DonationFormType.Create ||
          props.donationFormType === DonationFormType.Update) && (
          <Grid container spacing={1} mt={1}>
            <Grid item xs={12}>
              <SelectMemberSection fullWidth />
            </Grid>
          </Grid>
        )}

        <Grid container spacing={1} mt={1}>
          <Grid item md={3} sm={12} xs={12} display="flex" alignItems="center">
            <FormGroup>
              <FormControlLabel
                label={t("donationTable.isBusiness")}
                className={classes.checkboxLabel}
                control={
                  <Checkbox
                    checked={props.donation.isBusiness}
                    onChange={(e) => {
                      props.setDonation({
                        ...props.donation,
                        isBusiness: e.target.checked,
                      });
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                    className={classes.checkbox}
                    size="small"
                    readOnly={isRead}
                  />
                }
                disabled={isRead}
              />
            </FormGroup>
          </Grid>

          {props.donation.isBusiness && (
            <>
              <Grid item md={5} sm={12} xs={12}>
                <BasicInput
                  id="donations-companyName"
                  label={t("donationTable.companyName")}
                  value={props.donation.companyName}
                  onChange={(e) =>
                    props.setDonation({
                      ...props.donation,
                      companyName: e.target.value,
                    })
                  }
                  readOnly={isRead || !props.donation.isBusiness}
                />
              </Grid>

              <Grid item md={4} sm={12} xs={12}>
                <BasicInput
                  id="donations-companyPib"
                  label={t("donationTable.companyPib")}
                  value={props.donation.companyPib}
                  onChange={(e) =>
                    props.setDonation({
                      ...props.donation,
                      companyPib: e.target.value,
                    })
                  }
                  readOnly={isRead || !props.donation.isBusiness}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item md={6} sm={12} xs={12}>
            <BasicInput
              id="donations-firstName"
              label={t("donationTable.firstName")}
              value={props.donation?.firstName}
              onChange={(e) =>
                props.setDonation({
                  ...props.donation,
                  firstName: e.target.value,
                })
              }
              readOnly={isRead}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <BasicInput
              id="donations-lastName"
              label={t("donationTable.lastName")}
              value={props.donation?.lastName}
              onChange={(e) =>
                props.setDonation({
                  ...props.donation,
                  lastName: e.target.value,
                })
              }
              readOnly={isRead}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item sm={12} xs={12}>
            <BasicInput
              id="donations-phone"
              label={t("donationTable.phone")}
              value={props.donation?.phone}
              onChange={(e) =>
                props.setDonation({
                  ...props.donation,
                  phone: e.target.value,
                })
              }
              readOnly={isRead}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item md={6} sm={12} xs={12}>
            <BasicInput
              id="donations-purpose"
              label={t("donationTable.purpose")}
              value={props.donation?.purpose}
              onChange={(e) =>
                props.setDonation({
                  ...props.donation,
                  purpose: e.target.value,
                })
              }
              readOnly={isRead}
              error={!validation.purpose}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <BasicInput
              id="donations-amount"
              label={t("donationTable.amount")}
              value={donationAmount}
              type="number"
              onWheel={(e: any) => e.target.blur()}
              onChange={(e) => {
                if (!isNaN(+e.target.value)) {
                  props.setDonation({
                    ...props.donation,
                    amount: +e.target.value,
                  });
                }
              }}
              readOnly={isRead}
              error={!validation.amount}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} mt={1}>
          <Grid item md={12} sm={12} xs={12}>
            <BasicInput
              id="donations-donatedDate"
              label={t("donationTable.donatedDate")}
              type="date"
              value={formattedDate()}
              onChange={(e) =>
                props.setDonation({
                  ...props.donation,
                  donatedDate: e.target.value,
                })
              }
              readOnly={isRead}
              error={!validation.donatedDate}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        className={classes.closeBtnWrapper}
        style={{ float: "right" }}
      >
        <AppButton
          id="userManagement_button-close"
          onClick={onCloseModal}
          label={t("buttons.close")}
          color={theme.palette.primary.main}
          hover={theme.palette.primary.main}
        />
        <AppButton
          id="userManagement_button-save"
          onClick={props.onSave}
          label={t(
            `common.${props.donation.id === EmptyGuid ? "create" : "update"}`
          )}
          startIcon={<VolunteerActivismIcon />}
          color={colors.green}
          hover={colors.green}
          disabled={
            isRead ||
            !(validation.purpose && validation.amount && validation.donatedDate)
          }
        />
      </DialogActions>
    </Dialog>
  );
}

export const useStyles = makeStyles()(() => ({
  title: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  textCenter: { textAlign: "center" },
  closeBtnWrapper: { textAlign: "right", marginTop: "10px" },
  closeBtn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: colors.primary,
    "&:hover": {
      background: colors.primary,
      color: "white",
    },
  },
  saveBtn: {
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "5px",
    color: colors.green,
    "&:hover": {
      background: colors.green,
      color: "white",
    },
  },
  autocompleteTextField: {
    "& .MuiInputBase-input": {
      marginTop: "-0.2rem",
    },

    "& .MuiInputBase-root": {
      borderRadius: "5px",
      height: "2rem",
    },
    backgroundColor: "white",
  },
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  circularProgressIcon: {
    color: colors.primary,
  },
  dialogTitle: {
    cursor: "pointer",
    background: colors.primary,
  },
  circularProgress: {
    color: colors.primary,
    display: "block",
    margin: "auto",
    fontSize: "20px",
    width: "20px",
  },
  checkboxLabel: {
    textAlign: "start",
    marginTop: "7%",
    "& .MuiFormControlLabel-label": {
      color: colors.inputLabel,
    },
    "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label": {
      fontSize: "0.9rem",
    },
  },
  checkbox: {
    color: colors.primary,
    "&.Mui-checked": {
      color: colors.primary,
    },
  },
  donationModalImage: {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: "100%",
    height: "39%",
  },
}));
