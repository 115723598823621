export const formatNumber = (value: number): string => {
  return value.toLocaleString("sr-RS");
};

export const formatDate = (result: string | null): string => {
  if (!result) {
    return "";
  }

  const date = new Date(result);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  return date.toLocaleDateString("sr-RS", options) || "";
};

export const isNullOrWhiteSpace = (value: string | null): boolean => {
  if (value === null) {
    return true;
  } else if (value.trim().length === 0) {
    return true;
  } else {
    return false;
  }
};
