import { routes } from "../../component/router/routes";

export const getCurrentRouteTitleI18n = (pathname: string): string | null => {
  for (const key in routes) {
    if (routes.hasOwnProperty(key)) {
      const route = routes[key];

      if (route.pathname === pathname) {
        return route.titleI18n;
      }
    }
  }

  return null;
};
