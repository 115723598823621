import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import accountReducer from "../../slices/accounts/accountSlice";
import authReducer from "../../slices/auth/authSlice";
import displayLanguageReducer from "../../slices/displayLanguages/displayLanguageSlice";
import donationReducer from "../../slices/donations/donationSlice";
import electionPlaceReducer from "../../slices/electionPlaces/electionPlaceSlice";
import membersReducer from "../../slices/members/memberSlice";
import reportReducer from "../../slices/reports/reportSlice";
import notificationReducer from "../../slices/notification/notificationSlice";
import themeReducer from "../../slices/theme/themeSlice";
import synchReducer from "../../slices/synch/synchSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    members: membersReducer,
    electionPlace: electionPlaceReducer,
    report: reportReducer,
    displayLanguage: displayLanguageReducer,
    notification: notificationReducer,
    donation: donationReducer,
    theme: themeReducer,
    synch: synchReducer,
  },
  devTools: true, // false for prod env
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
