import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../app/api/hooks";
import { ReportType } from "../models/enums/reportType";
import { CreateReportRequest } from "../models/requests/reports/createReportRequest";
import { APIStatus } from "../models/types/api/APIStatus";
import {
  createReport as createReportRequest,
  selectReport,
  selectReportStatus,
} from "../slices/reports/reportSlice";

function downloadBlob(blob: any, name: string) {
  // Convert your blob into a Blob URL (a special url that points
  // to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the
  // latest Firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export const useCreateReport = () => {
  const dispatch = useAppDispatch();
  const report = useAppSelector(selectReport);
  const reportStatus = useAppSelector(selectReportStatus);
  const { t } = useTranslation();
  const [createDisabled, setCreateDisabled] = useState<boolean>(false);

  const createReport = (request: CreateReportRequest) => {
    setCreateDisabled(true);

    dispatch(createReportRequest(request));
  };

  useEffect(() => {
    if (report && createDisabled === true) {
      if (reportStatus === APIStatus.FULFILLED) {
        const fileName = `Izveštaj (${Math.floor(Date.now() / 1000)})`;
        downloadBlob(report, fileName);

        setCreateDisabled(false);
      }
    }
  }, [reportStatus, report]);

  return { createReport, report, reportStatus, createDisabled };
};
