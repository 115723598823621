import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/api/hooks";
import { DataTypeEnum } from "../../../models/app/table-container/dataTypeEnum";
import { PropertyDefinition } from "../../../models/app/table-container/propertyDefinition";
import { MemberDataManipulationRequest } from "../../../models/requests/members/memberDataManipulationRequest";
import {
  getMembersPagedList,
  selectMembersPagedList,
  selectMembersPagedListStatus,
} from "../../../slices/members/memberSlice";

export const useGetMembersPagedList = () => {
  const dispatch = useAppDispatch();
  const membersPagedList = useAppSelector(selectMembersPagedList);
  const membersPagedListStatus = useAppSelector(selectMembersPagedListStatus);

  const initDataManipulationRequest: MemberDataManipulationRequest = {
    memberFilter: {},
    sort: {},
    metaData: {
      currentPage: 1,
      pageSize: 15,
    },
    skip: true,
  };

  const fetch = (request: MemberDataManipulationRequest) =>
    dispatch(getMembersPagedList(request));

  useEffect(() => {
    fetch(initDataManipulationRequest);
  }, []);

  return { membersPagedList, refetch: fetch, membersPagedListStatus };
};

export const useGetMemberProperties = () => {
  const { t } = useTranslation();

  const parents = {
    personalData: "PersonalData",
    address: "Address",
    contact: "Contact",
    card: "Card",
    employment: "Employment",
    education: "Education",
    languages: "Languages",
    otherData: "OtherData",
  };

  const memberProperties: PropertyDefinition[] = [
    // Member
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableFirstNameTitle"),
      parent: parents.personalData,
      child: "FirstName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableLastNameTitle"),
      parent: parents.personalData,
      child: "LastName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableLastNameParentName"),
      parent: parents.personalData,
      child: "ParentName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableUinTitle"),
      parent: parents.personalData,
      child: "Jmbg",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableBirthDateTitle"),
      parent: parents.personalData,
      child: "BirthDate",
      dataType: DataTypeEnum.date,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableAgeTitle"),
      parent: parents.personalData,
      child: "Age",
      dataType: DataTypeEnum.int,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableBirthPlaceTitle"),
      parent: parents.personalData,
      child: "BirthPlace",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableIdNumberTitle"),
      parent: parents.personalData,
      child: "IdNumber",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.personalData"),
      childLabel: t("memberTablePage.tableGenderTitle"),
      parent: parents.personalData,
      child: "Gender",
      dataType: DataTypeEnum.string,
    },

    // Address
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableCityTitle"),
      parent: parents.address,
      child: "CityName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableStreetTitle"),
      parent: parents.address,
      child: "StreetName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableHomeNumberTitle"),
      parent: parents.address,
      child: "HomeNumber",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableResidenceCityTitle"),
      parent: parents.address,
      child: "CityResidenceName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableResidenceStreetTitle"),
      parent: parents.address,
      child: "StreetResidenceName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableResidenceHomeNumberTitle"),
      parent: parents.address,
      child: "HomeNumberResidence",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableElectionPlaceAddressTitle"),
      parent: parents.address,
      child: "ElectionPlaceAddress",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableElectionPlaceNameTitle"),
      parent: parents.address,
      child: "ElectionPlaceName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableElectionPlaceNumberTitle"),
      parent: parents.address,
      child: "ElectionPlaceNumber",
      dataType: DataTypeEnum.int,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableLocalCommunityNameTitle"),
      parent: parents.address,
      child: "LocalCommunityName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableCityRegionTitle"),
      parent: parents.address,
      child: "CityRegionName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableResidenceCityRegionTitle"),
      parent: parents.address,
      child: "CityRegionResidenceName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableMunicipalityTitle"),
      parent: parents.address,
      child: "MunicipalityName",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.address"),
      childLabel: t("memberTablePage.tableResidenceMunicipalityTitle"),
      parent: parents.address,
      child: "MunicipalityResidenceName",
      dataType: DataTypeEnum.string,
    },

    // Card
    {
      parentLabel: t("memberTablePage.card"),
      childLabel: t("memberTablePage.tableIdCardNumberTitle"),
      parent: parents.card,
      child: "Number",
      dataType: DataTypeEnum.int,
    },
    // {
    //   parentLabel: t("memberTablePage.card"),
    //   childLabel: t("memberTablePage.tableIsCardPublishedTitle"),
    //   parent: parents.card,
    //   child: "IsPublished",
    // },
    {
      parentLabel: t("memberTablePage.card"),
      childLabel: t("memberTablePage.tableMemberCardCityTitle"),
      parent: parents.card,
      child: "PublishingCity",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.card"),
      childLabel: t("memberTablePage.tableMemberCardDateTitle"),
      parent: parents.card,
      child: "EstablishedDate",
      dataType: DataTypeEnum.date,
    },

    // Contact
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableLandingPhoneTitle"),
      parent: parents.contact,
      child: "LandPhone",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableMobilePhoneTitle"),
      parent: parents.contact,
      child: "MobilePhone",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableBusinessPhoneTitle"),
      parent: parents.contact,
      child: "WorkPhone",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableEmailTitle"),
      parent: parents.contact,
      child: "Email",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableFacebookTitle"),
      parent: parents.contact,
      child: "Facebook",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableInstagramTitle"),
      parent: parents.contact,
      child: "Instagram",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableTwitterTitle"),
      parent: parents.contact,
      child: "Twiter",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.contact"),
      childLabel: t("memberTablePage.tableOtherContactTitle"),
      parent: parents.contact,
      child: "Other",
      dataType: DataTypeEnum.string,
    },

    // Education
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableElementarySchoolTitle"),
      parent: parents.education,
      child: "ElementarySchool",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableHighSchoolTitle"),
      parent: parents.education,
      child: "HighSchool",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableHighSchoolLevelTitle"),
      parent: parents.education,
      child: "HighSchoolYear",
      dataType: DataTypeEnum.int,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableVocationalStudiesTitle"),
      parent: parents.education,
      child: "BasicVocationalStudies",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableSpecialistVocationalStudiesTitle"),
      parent: parents.education,
      child: "SpecialVocationalStudies",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableBasicAcademicStudiesTitle"),
      parent: parents.education,
      child: "AcademicStudies",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableBasicAcademicStudiesLevelTitle"),
      parent: parents.education,
      child: "AcademicStudiesYear",
      dataType: DataTypeEnum.int,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableMasterAcademicStudiesTitle"),
      parent: parents.education,
      child: "MasterAcademicStudies",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableMasterAcademicStudiesLevelTitle"),
      parent: parents.education,
      child: "MasterAcademicStudiesYear",
      dataType: DataTypeEnum.int,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableSpecialAcademicStudiesTitle"),
      parent: parents.education,
      child: "SpecialAcademicStudies",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.education"),
      childLabel: t("memberTablePage.tableDoctoralAcademicStudiesTitle"),
      parent: parents.education,
      child: "DoctoralAcademicStudies",
      dataType: DataTypeEnum.string,
    },
    // {
    //   parentLabel: t("memberTablePage.education"),
    //   childLabel: t("memberTablePage.tableWorkOnComputerTitle"),
    //   parent: parents.education,
    //   child: "WorkOnComputer",
    // },

    // Employment
    // {
    //   parentLabel: t("memberTablePage.employment"),
    //   childLabel: t("memberTablePage.tableIsEmployedTitle"),
    //   parent: parents.employment,
    //   child: "IsEmployed",
    // },
    {
      parentLabel: t("memberTablePage.employment"),
      childLabel: t("memberTablePage.tableCompanyTitle"),
      parent: parents.employment,
      child: "Company",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.employment"),
      childLabel: t("memberTablePage.tableWorkPlaceTitle"),
      parent: parents.employment,
      child: "WorkPlace",
      dataType: DataTypeEnum.string,
    },
    // {
    //   parentLabel: t("memberTablePage.employment"),
    //   childLabel: t("memberTablePage.tableTemporaryEmployed"),
    //   parent: parents.employment,
    //   child: "PermanentEmployed",
    // },
    {
      parentLabel: t("memberTablePage.employment"),
      childLabel: t("memberTablePage.tableOtherEmployment"),
      parent: parents.employment,
      child: "Other",
      dataType: DataTypeEnum.string,
    },

    // Languages
    {
      parentLabel: t("memberTablePage.languages"),
      childLabel: t("memberTablePage.tableLanguageSubtitleLanguage"),
      parent: parents.languages,
      child: "Language",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.languages"),
      childLabel: t("memberTablePage.tableLanguageSubtitleLevel"),
      parent: parents.languages,
      child: "Level",
      dataType: DataTypeEnum.string,
    },
    // {
    //   parentLabel: t("memberTablePage.languages"),
    //   childLabel: t("memberTablePage.tableLanguageSubtitleActive"),
    //   parent: parents.languages,
    //   child: "ActiveSpeaking",
    // },

    // Other data
    {
      parentLabel: t("memberTablePage.otherData"),
      childLabel: t("memberTablePage.tableHobbyTitle"),
      parent: parents.otherData,
      child: "Hobby",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.otherData"),
      childLabel: t("memberTablePage.tableSaintDay"),
      parent: parents.otherData,
      child: "SaintDay",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.otherData"),
      childLabel: t("memberTablePage.tableSaintDayDate"),
      parent: parents.otherData,
      child: "SaintDayDate",
      dataType: DataTypeEnum.date,
    },
    {
      parentLabel: t("memberTablePage.otherData"),
      childLabel: t("memberTablePage.tableRecommendationTitle"),
      parent: parents.otherData,
      child: "Recommendation",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.otherData"),
      childLabel: t("memberTablePage.tableNoteFirstTitle"),
      parent: parents.otherData,
      child: "Note1",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.otherData"),
      childLabel: t("memberTablePage.tableNoteSecondTitle"),
      parent: parents.otherData,
      child: "Note2",
      dataType: DataTypeEnum.string,
    },
    {
      parentLabel: t("memberTablePage.otherData"),
      childLabel: t("memberTablePage.tableNoteThirdTitle"),
      parent: parents.otherData,
      child: "Note3",
      dataType: DataTypeEnum.string,
    },
  ];

  return { memberParents: parents, memberProperties };
};
