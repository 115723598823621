import { EmptyGuid } from "../features/smart-membership/initialData";
import { Member } from "../models/data/members/Member";

export const CanSaveMember = (member: Member): boolean => {
  if (
    member.personalData.firstName !== null &&
    member.personalData.firstName.length > 2 &&

    member.personalData.lastName !== null &&
    member.personalData.lastName.length > 2 &&

    member.address.cityResidenceName &&

    member.address.streetResidenceName &&

    member.address.homeNumberResidence &&
    
    isValidDate(member.personalData.birthDate)
  ) {
    return true;
  }
  return false;
};

export const CanDeleteMember = (member: Member) => {
  if (member.personalData.id === EmptyGuid) return false;

  return true;
};

export const IsNewMember = (member: Member) => {
  return member.personalData.id === EmptyGuid;
};

function isValidDate(str: string | null): boolean {
  if (str === null) return false;

  let noSpace = str.replace(/\s/g, "");
  if (noSpace.length < 3) {
    return false;
  }
  return true; // Date.parse(noSpace) > 0
}
