import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EmptyGuid } from "../../INITIAL_DATA/initialData";
import { api } from "../../app/api/api";
import { RootState } from "../../app/api/store";
import i18n from "../../i18n";
import { Donated } from "../../models/donations/donated";
import { Donation } from "../../models/donations/donation";
import { APIResponse } from "../../models/types/api/APIResponse";
import {
  setErrorNotification,
  setPendingNotification,
  setSuccessNotification,
} from "../notification/notificationSlice";
import { RemoveDonationModel } from "../../models/donations/removeDonationModel";

interface IDonationApi {
  GetAllDonations(donated: Donated): Promise<APIResponse<Donation[]>>;
  SaveDonation(request: Donation): Promise<APIResponse<Donation>>;
  RemoveDonation(request: RemoveDonationModel): Promise<APIResponse<null>>;
}

class DonationApi implements IDonationApi {
  GetAllDonations(donated: Donated): Promise<APIResponse<Donation[]>> {
    return api.get(`/donation/get-all-donations?donated=${donated}`);
  }

  SaveDonation(request: Donation): Promise<APIResponse<Donation>> {
    return api.put("/donation/save-donation", request);
  }

  RemoveDonation(request: RemoveDonationModel): Promise<APIResponse<null>> {
    return api.put("/donation/remove-donation", request);
  }
}

const donationApi = new DonationApi();

type DonationState = {
  donation: Donation | null;
  donations: Donation[] | null;
};

const initialState: DonationState = {
  donation: null,
  donations: null,
};

export const getAllDonations = createAsyncThunk(
  "Donation/Get_all_donations",
  async (donated: Donated) => {
    const response = await donationApi.GetAllDonations(donated);
    return response.data;
  }
);

export const saveDonation = createAsyncThunk(
  "Donation/Save_donation",
  async (request: Donation, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(
          i18n.t(
            `messageKey.SaveDonation_${
              request.id === EmptyGuid ? "Create" : "Update"
            }_Pending`
          )
        )
      );

      const response = await donationApi.SaveDonation(request);

      dispatch(
        setSuccessNotification(i18n.t(`messageKey.${response.messageKey}`))
      );

      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

export const removeDonation = createAsyncThunk(
  "Donation/Remove_donation",
  async (request: RemoveDonationModel, { rejectWithValue, dispatch }) => {
    try {
      dispatch(
        setPendingNotification(i18n.t("messageKey.RemoveDonation_Pending"))
      );

      const response = await donationApi.RemoveDonation(request);

      dispatch(
        setSuccessNotification(i18n.t(`messageKey.${response.messageKey}`))
      );

      return response.data;
    } catch (error) {
      const errorApiResponse = error as APIResponse<null>;
      const errorMessage = i18n.t(`messageKey.${errorApiResponse.messageKey}`);
      dispatch(setErrorNotification(errorMessage));
      return rejectWithValue(errorApiResponse.messageKey);
    }
  }
);

const donationSlice = createSlice({
  name: "donations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllDonations.fulfilled, (state, action) => {
        state.donations = action.payload;
      })

      .addCase(saveDonation.fulfilled, (state, action) => {
        state.donation = action.payload;
      });
  },
});

export const selectDonation = (state: RootState) => state.donation.donation;

export const selectDonations = (state: RootState) => state.donation.donations;

export default donationSlice.reducer;
