import { Member } from "../../models/data/members/Member";
import { MemberAddress } from "../../models/data/members/MemberAddress";
import { MemberCard } from "../../models/data/members/MemberCard";
import { MemberContact } from "../../models/data/members/MemberContact";
import { MemberEducation } from "../../models/data/members/MemberEducation";
import { MemberEmployment } from "../../models/data/members/MemberEmployment";
import { MemberOtherData } from "../../models/data/members/MemberOtherData";
import { MemberPersonalData } from "../../models/data/members/MemberPersonalData";
import { MembershipFee } from "../../models/data/members/membershipFee";

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

const INIT_PERSONAL_DATA: MemberPersonalData = {
  id: EmptyGuid,
  firstName: "",
  lastName: "",
  parentName: "",
  jmbg: "",
  birthDate: "",
  birthPlace: "",
  idNumber: "",
  gender: "",
};

const INIT_ADDRESS: MemberAddress = {
  id: EmptyGuid,

  streetId: null,
  streetName: "",
  cityId: null,
  cityName: "",
  cityRegionId: null,
  cityRegionName: "",
  municipalityId: null,
  municipalityName: "",
  homeNumber: "",

  streetResidenceId: null,
  streetResidenceName: "",
  cityResidenceId: null,
  cityResidenceName: "",
  cityRegionResidenceId: null,
  cityRegionResidenceName: "",
  municipalityResidenceId: null,
  municipalityResidenceName: "",
  homeNumberResidence: "",

  electionPlaceId: null,
  electionPlaceName: "",
  electionPlaceAddress: "",
  isAddressSame: true,
  memberId: null,
};

const INIT_CONTACT: MemberContact = {
  id: EmptyGuid,
  email: "",
  facebook: "",
  instagram: "",
  landPhone: "",
  memberId: null,
  mobilePhone: "",
  other: "",
  twiter: "",
  workPhone: "",
};

const INIT_CARD: MemberCard = {
  id: EmptyGuid,
  establishedDate: null,
  isPublished: false,
  number: 0,
  publishingCity: "",
  memberId: null,
};

const INIT_EMPLOYMENT: MemberEmployment = {
  id: EmptyGuid,
  company: "",
  isEmployed: null,
  other: "",
  permanentEmployed: false,
  workPlace: "",
  memberId: null,
};

const INIT_EDUCATION: MemberEducation = {
  id: EmptyGuid,
  highSchool: "",
  academicStudies: "",
  academicStudiesYear: 0,
  basicVocationalStudies: "",
  doctoralAcademicStudies: "",
  elementarySchool: "",
  highSchoolYear: 0,
  masterAcademicStudies: "",
  masterAcademicStudiesYear: 0,
  specialAcademicStudies: "",
  specialVocationalStudies: "",
  workOnComputer: null,
  memberId: null,
};

const INIT_OTHER_DATA: MemberOtherData = {
  id: EmptyGuid,
  hobby: "",
  saintDay: "",
  saintDayDate: null,
  note1: "",
  note2: "",
  note3: "",
  recommendation: "",
  memberId: null,
};

export const INIT_MEMBERSHIP_FEE: MembershipFee = {
  id: EmptyGuid,
  paying: false,
  amount: 0,
  startDate: null,
  memberId: EmptyGuid,
  membershipTypeId: null,
};

export const INIT_MEMBER: Member = {
  personalData: INIT_PERSONAL_DATA,
  address: INIT_ADDRESS,
  card: INIT_CARD,
  contact: INIT_CONTACT,
  education: INIT_EDUCATION,
  employment: INIT_EMPLOYMENT,
  languages: [],
  otherData: INIT_OTHER_DATA,
  membershipFee: null,
};
