import { Grid, Pagination } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useTableStyles } from "../tableComponent/useTableStyles";

type Props = {
  totalCountMembers: number;
  page: number;
  handleChangePage: any;
};

const TablePagination = (props: Props) => {
  const { classes } = useTableStyles()
  const theme = useTheme();

  return (
    <Grid md={12} xs={12}>
      <ThemeProvider theme={theme}>
        <Pagination
          className={classes.pagination}
          count={props.totalCountMembers}
          page={props.page}
          onChange={props.handleChangePage}
          variant="outlined"
          showFirstButton
          showLastButton
        />
      </ThemeProvider>
    </Grid>
  );
};

export default TablePagination;
