import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputBase,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useDetailExcelFileModalStyle from "./DetailExcelFileModalStyle";

const DetailExcelFileModal = ({
  openMenageDocumentDataModal: open,
  handleCloseMenageDataModal: closeModal,
}: {
  openMenageDocumentDataModal: boolean;
  handleCloseMenageDataModal: () => void;
}) => {
  const { t } = useTranslation();
  const { classes } = useDetailExcelFileModalStyle();

  const FormControl = ({ label, style }: { label: string; style?: {} }) => (
    <div style={{ display: "flex", ...style }}>
      <span>{label}:</span>
      <InputBase className={classes.inputBase} placeholder="" />
    </div>
  );

  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle className={classes.titleWrapper}>
        <h3 className={classes.title}>
          {t("memberTablePage.detailOfExcelFileTitle")}
        </h3>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center">
          <Grid container justifyContent="center">
            <Grid item md={10} className={classes.mt10}>
              <div className={classes.headerWrapper}>
                <p>{t("memberTablePage.detailOfExcelFileHeader")}:</p>
                <FormControl
                  label={t("memberTablePage.detailOfExcelFileFirstLine")}
                />
                <FormControl
                  label={t("memberTablePage.detailOfExcelFileSecondLine")}
                  style={{ marginTop: "25px" }}
                />
              </div>
            </Grid>
            <Grid item md={12} className={classes.buttonGrid}>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className={classes.successBtn}
                  >
                    {t("buttons.save")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={closeModal}
                    variant="contained"
                    className={classes.dangerBtn}
                  >
                    {t("buttons.close")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DetailExcelFileModal;
