import { AccountCircle } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/api/hooks";
import { routes } from "../router/routes";
import { getInitialsFromFullName } from "../../common/helpers/account";
import { hexToRgb } from "../../common/helpers/theme";
import useMenu from "../../hooks/common/useMenu";
import useScreenWidth from "../../hooks/common/useScreenWidth";
import sidebarBackgroundImage from "../../images/sidebarBackgroundImage.png";
import {
  logout,
  selectAuthenticatedAccount,
} from "../../slices/auth/authSlice";
import DashboardIcon from "../icons/DashboardIcon";
import LogoutIcon from "../icons/LogoutIcon";
import MemberManagementIcon from "../icons/MemberManagementIcon";
import MemberTableIcon from "../icons/MemberTableIcon";
import StatisticsIcon from "../icons/StatisticsIcon";
import UserManagementIcon from "../icons/UserManagementIcon";
import OrganizationSection from "./OrganizationSection";
import ProfileMenu from "./ProfileMenu";
import SidebarListItem from "./SidebarListItem";
import CreditCardIcon from "../icons/CreditCardIcon";
import SyncIcon from "@mui/icons-material/Sync";
import SyncAddressesIcon from "../icons/SyncAddressesIcon";
import { RoleOrdinal } from "../../models/enums/roles/roles";
import SidebarListLinkItem from "./SidebarListLinkItem";
import MoveDownIcon from '@mui/icons-material/MoveDown';
type Props = {
  sidebarWidth: number;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
};

export default function Sidebar(props: Props) {
  const { isSmallScreen } = useScreenWidth();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const fillColor = theme.palette.text.primary;
  const primaryRgb = hexToRgb(theme.palette.primary.main);
  const menu = useMenu();
  const { t } = useTranslation();
  const authenticatedAccount = useAppSelector(selectAuthenticatedAccount);
  const accountName = `${authenticatedAccount?.firstName} ${authenticatedAccount?.lastName}`;
  const organizationName = useAppSelector(
    (state) => state.auth.organizationName
  );
  const organizationLogo = useAppSelector(
    (state) => state.auth.organizationLogo
  );

  const roleOrdinal = authenticatedAccount?.rolePriority;

  const onLogout = () => {
    dispatch(logout());
  };

  const content = (
    <Box sx={{ textAlign: "center" }}>
      <OrganizationSection
        organizationName={organizationName || ""}
        isSidebarOpen={props.isSidebarOpen}
        logo={organizationLogo || ""}
      />

      <List sx={{ mt: 5 }}>
        <SidebarListItem
          pathname={routes.Dashboard.pathname}
          iconElement={<DashboardIcon fillColor={fillColor} />}
          title={t(routes.Dashboard.titleI18n)}
          isSidebarOpen={props.isSidebarOpen}
        />
        <SidebarListItem
          pathname={routes.MemberCards.pathname}
          iconElement={
            <CreditCardIcon fillColor={fillColor} width={21} height={17} />
          }
          title={t(routes.MemberCards.titleI18n)}
          isSidebarOpen={props.isSidebarOpen}
        />
        <SidebarListItem
          pathname={routes.MemberTable.pathname}
          iconElement={<MemberTableIcon fillColor={fillColor} />}
          title={t(routes.MemberTable.titleI18n)}
          isSidebarOpen={props.isSidebarOpen}
        />
        <SidebarListItem
          pathname={routes.MemberManagement.pathname}
          iconElement={<MemberManagementIcon fillColor={fillColor} />}
          title={t(routes.MemberManagement.titleI18n)}
          isSidebarOpen={props.isSidebarOpen}
        />
        <Divider sx={{ width: "90%", margin: "auto", my: 1 }} />
        <SidebarListItem
          pathname={routes.Statistics.pathname}
          iconElement={<StatisticsIcon fillColor={fillColor} />}
          title={t(routes.Statistics.titleI18n)}
          isSidebarOpen={props.isSidebarOpen}
        />
        {roleOrdinal !== RoleOrdinal.Administrator &&
          roleOrdinal !== RoleOrdinal.User && (
            <SidebarListItem
              pathname={routes.UserManagement.pathname}
              iconElement={<UserManagementIcon fillColor={fillColor} />}
              title={t(routes.UserManagement.titleI18n)}
              isSidebarOpen={props.isSidebarOpen}
            />
          )}
        {roleOrdinal !== RoleOrdinal.Administrator &&
          roleOrdinal !== RoleOrdinal.User && (
            <>
              <Divider sx={{ width: "90%", margin: "auto", my: 1 }} />
              <SidebarListItem
                pathname={routes.SyncAddresses.pathname}
                iconElement={<SyncAddressesIcon fillColor={fillColor} />}
                title={t(routes.SyncAddresses.titleI18n)}
                isSidebarOpen={props.isSidebarOpen}
              />
            </>
          )}

        {/* <SidebarListItem
            iconElement={<StatisticsIcon fillColor={fillColor} />}
            title={"kapilarni glasovi"}
            isSidebarOpen={props.isSidebarOpen}
          /> */}

        <Divider sx={{ width: "90%", margin: "auto", my: 1 }} />
        <SidebarListLinkItem
          pathname={routes.SyncAddresses.pathname}
          iconElement={<SyncAddressesIcon fillColor={fillColor} />}
          title={t(routes.SyncAddresses.titleI18n)}
          isSidebarOpen={props.isSidebarOpen}
        />

        {props.isSidebarOpen && isSmallScreen && (
          <ProfileMenu
            element={
              <SidebarListItem
                iconElement={
                  <IconButton sx={{ padding: 0 }}>
                    {!authenticatedAccount ? (
                      <AccountCircle sx={{ color: fillColor }} />
                    ) : (
                      <Avatar sx={{ width: 25, height: 25, fontSize: "0.5em" }}>
                        {getInitialsFromFullName(accountName)}
                      </Avatar>
                    )}
                  </IconButton>
                }
                isSidebarOpen={props.isSidebarOpen}
                onClick={menu.onOpen}
                title={accountName}
              />
            }
            menu={menu}
          />
        )}
      </List>
    </Box>
  );

  return (
    <Box component="nav" sx={{ flexShrink: { sm: 0 } }}>
      <Drawer
        variant="persistent"
        open={true}
        onClose={props.toggleSidebar}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.sidebarWidth,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundImage: `url(${sidebarBackgroundImage}), 
              linear-gradient(rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5), rgba(${primaryRgb?.r}, ${primaryRgb?.g}, ${primaryRgb?.b}, 0.5))`,
            backgroundSize: "cover",
          },
        }}
      >
        {content}

        <List>
          <Divider sx={{ width: "90%", margin: "auto", mb: 1 }} />

          <SidebarListItem
            title={t("sidebar.sidebarLogout")}
            iconElement={<LogoutIcon fillColor={fillColor} />}
            isSidebarOpen={props.isSidebarOpen}
            onClick={onLogout}
          />
        </List>
      </Drawer>
    </Box>
  );
}
