import { useState } from "react";
import { AppMenu } from "../../models/app/menu/appMenu";

export default function useMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);

  const onOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const result: AppMenu = { isOpen, onOpen, onClose, anchorEl };

  return result;
}
